import { DateField, EditButton, NumberField, Pagination, ReferenceManyField as RaReferenceManyField, SearchInput, TextField, TopToolbar } from 'react-admin';
import DividerField from '../Fields/DividerField';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import { GasDistributorStationReferenceDatagrid } from '../GasDistributorStation/GasDistributorStationDatagrid';
import GasQualityCertificateBanner from './GasQualityCertificateBanner';
import MoreContent from '../Layout/MoreContent';
import NestedField from '../Fields/NestedField';
import NestedGridField from '../Fields/NestedGridField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import ReferenceListLinkButton from '../Buttons/ReferenceListLinkButton';
import ReferenceManyActions from '../Fields/referenceManyField/ReferenceManyActions';
import ReferenceManyField from '../Fields/referenceManyField/ReferenceManyField';
import ReferenceManyFilter from '../Fields/referenceManyField/ReferenceManyFilter';
import ReportCard from '../Report/ReportCard';
import Separator from '../Layout/Separator';
import { Show } from '../Layout/Show';
import TitleForRecord from '../Layout/TitleForRecord';
import WriteButton from './GasQualityCertificateWriteButton';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';
import N2Field from "./GasQualityCertificateN2Field";
import CO2Field from "./GasQualityCertificateCO2Field";
import DensityField from "./GasQualityCertificateDensityField";



const ReferenceManyFilters = (props) => {
    return (
        <ReferenceManyFilter {...props}>
            <SearchInput source="q" id={`${props.reference}.q`} alwaysOn />
        </ReferenceManyFilter>
    )
}

const ReferenceManyActionButtons = (props) => {
    return (
        <ReferenceManyActions >
            <ReferenceListLinkButton {...props} />
        </ReferenceManyActions>
    )
}

const GasQualityCertificateActions = ({ basePath, className, data, hasEdit, hasList, resource, ...props }) => {

    return (
        <TopToolbar {...props}>
            {/* {can('writeGasQualityCertificate', 'GasQualityCertificate') && <WriteButton resource={resource} basePath={basePath} record={data} />} */}
            {can('write', 'GasQualityCertificate') && <WriteButton resource={resource} basePath={basePath} record={data} />}
            {can('update', 'GasQualityCertificate') && <EditButton resource={resource} basePath={basePath} record={data} />}
        </TopToolbar>
    )
};

const GasQualityCertificateShow = props => {
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show title={<TitleForRecord source="name" {...props} />} actions={<GasQualityCertificateActions />} {...props} >
            <GasQualityCertificateBanner />
            <CardShowLayout>
                {can('getOne', 'GasQualityCertificate', 'n2') && <N2Field source="n2" style={{ maximumFractionDigits: 4 }} />}
                {can('getOne', 'GasQualityCertificate', 'co2') && <CO2Field source="co2" style={{ maximumFractionDigits: 4 }} />}
                {can('getOne', 'GasQualityCertificate', 'density') && <DensityField source="density" style={{ maximumFractionDigits: 4 }} />}
                {can('getOne', 'GasQualityCertificate', 'validFrom') && <DateField source="validFrom" options={dateTimeOptions} />}
                {can('getOne', 'GasQualityCertificate', 'validTo') && <DateField source="validTo" options={dateTimeOptions} />}
                {can('getOne', 'GasQualityCertificate', 'writtenAt') && <DateField source="writtenAt" options={dateTimeOptions} />}
                {can('getOne', 'GasQualityCertificate', 'gasExtractionPoint') && <TextField source="gasExtractionPoint" />}
            </CardShowLayout>
            {can('getOne', 'GasQualityCertificate', 'reports', 'Report') && <NestedGridField source="reports" label="resources.GasConsumer.fields.reports" >
                <ReportCard />
            </NestedGridField>}
            {can('getOne', 'GasQualityCertificate', 'gasDistributorStations', 'GasDistributorStation') &&
                <ReferenceManyField
                    reference="GasDistributorStation"
                    target="gasQualityCertificate_some"
                    label="resources.GasQualityCertificate.fields.gasDistributorStations"
                    sort={{ field: 'name', order: 'ASC' }} perPage={100}
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    perPage={25}
                    pagination={<Pagination />}
                >
                    <GasDistributorStationReferenceDatagrid />
                </ReferenceManyField>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {can('getOne', 'GasQualityCertificate', 'gqsId') && <TextField source="gqsId" />}
                    <DividerField />
                    {can('getOne', 'GasQualityCertificate', 'id') && <TextField source="id" />}
                    {can('getOne', 'GasQualityCertificate', 'createdBy', "User") && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {can('getOne', 'GasQualityCertificate', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'GasQualityCertificate', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>

            {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
            {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="GasQualityCertificate" />
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>}
        </Show>
    );
};

export default GasQualityCertificateShow;