import React from 'react';
import CronInput2 from '../Inputs/CronInput2';



const choices = [
    { id: 'HOURLY', name: 'Ежечасно', cron: "10 * * * *" },
    { id: 'DAYLY', name: 'Ежедневно', cron: "10 10 * * *" },
    { id: 'WEEKLY', name: 'Еженедельно', cron: "10 10 * * 1" },
    { id: 'MONTHLY', name: 'Ежемесячно', cron: "10 10 1 * *" },
    { id: 'QUATERLY', name: 'Ежеквартально', cron: "10 10 1 */3 *" },
    { id: 'YEARLY', name: 'Ежегодно', cron: "10 10 1 1 *" },
]


const ReportDespatchSchedulerInput = (props) => {



    return (
        <CronInput2
            {...props}
            options={choices}
            optionValue="cron"
        />
    );
}

export default ReportDespatchSchedulerInput;