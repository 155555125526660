import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReferenceArrayInput } from "react-admin";
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import QuickCreateButton from "../Buttons/QuickCreateButton";
import { GasDistributorStationOutputQuickCreateForm } from "../GasDistributorStationOutput/GasDistributorStationOutputCreate";
import { can } from "../../abilityProvider";



const matchTrue = () => true;

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    reference: {
        display: "inline-block",
        width: "90%"
    }
});

const ReferenceArrayGasDistributorStationOutput = props => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <ReferenceArrayInput {...props} classes={{ container: classes.reference }} >
                <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
            </ReferenceArrayInput>
            {can('create', 'GasDistributorStationOutput') && <QuickCreateButton {...props} isArray>
                <GasDistributorStationOutputQuickCreateForm />
            </QuickCreateButton>}
        </div>
    );
};

export default ReferenceArrayGasDistributorStationOutput;
