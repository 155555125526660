import React from 'react';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    root: { 
        width: 240
    },
}));

const Aside = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
        </div>
    );
}


export default Aside;