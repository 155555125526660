import React, { useEffect } from 'react';
import get from 'lodash/get'
import { useForm, useFormState } from 'react-final-form';
import { useGetList, ReferenceArrayInput } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';



const matchTrue = () => true;

const DashboardReferenceArrayInput = props => {
    const { source, reference, filter } = props
    const form = useForm();
    const { values } = useFormState({ subscription: { values: true } });
    const value = get(values, source)
    const { ids } = useGetList(
        reference,
        { page: 1, perPage: 100 },
        { field: 'name', order: 'DESC' },
        { ...filter, useDefault: true },
        { enabled: !value }
    );

    useEffect(() => {
        if (!value && ids.length > 0) {
            form.change(source, ids)
        }
    }, [ids])

    return (
        <ReferenceArrayInput {...props}>
            <AutocompleteArrayInput matchSuggestion={matchTrue} />
        </ReferenceArrayInput>
    );
};

export default DashboardReferenceArrayInput;