import React from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DefaultIcon from '@material-ui/icons/ViewList';
import { MenuItemLink, getResources, useTranslate, DashboardMenuItem } from 'react-admin';
import classnames from 'classnames';
import MenuGroup from './MenuGroup';
import { AboutAppIcon } from '../AboutApp';


export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: 4,
        '&:last-child': {
            marginBottom: 0,
        },
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
        },
    },
    active: {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.12),
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.20),
        },
    },
    icon: { color: "inherit" },
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: '1.5em',
        marginBottom: '1em',
        [theme.breakpoints.only('xs')]: {
            marginTop: 0,
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
        width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
}), { name: 'RaMenu' });

// const useStyles = makeStyles(theme => ({
//     root: {
//         borderRadius: theme.shape.borderRadius,
//         marginLeft: theme.spacing(),
//         marginRight: theme.spacing(),
//         marginBottom: 4,
//         '&:last-child': {
//             marginBottom: 0,
//         },
//         '&:hover': {
//             backgroundColor: fade(theme.palette.primary.main, 0.12),
//         },
//     },
//     active: {
//         color: theme.palette.primary.main,
//         backgroundColor: fade(theme.palette.primary.main, 0.12),
//         '&:hover': {
//             backgroundColor: fade(theme.palette.primary.main, 0.20),
//         },
//     },
//     icon: { color: "inherit" },
//     main: {
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'flex-start',
//         marginTop: '1.5em',

//     },
//     stickyContainer: {
//         height: "100%",
//     },
//     sticky: {
//         position: "sticky",
//         top: '1.5em',
//     }
// }));


const Menu = ({
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...props
}) => {
    const translate = useTranslate();
    const classes = useStyles({ classes: classesOverride });
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resourcesReactAdmin = useSelector(getResources, shallowEqual);
    const accessResourses = useSelector(state => state.access && state.access.accessResources, shallowEqual);

    useSelector(state => state.router.location.pathname); // used to force redraw on navigation

    if (!accessResourses) return null;

    const menuAboutApp = {
        hasList: true,
        icon: AboutAppIcon,
        name: "About",
        options: { menuGroup: "utils" },
    }

    const resources = [...resourcesReactAdmin, menuAboutApp]

    const filteredResources = resources.filter(resource => !(accessResourses[resource.name] === false))
    const groupedResources = groupBy(filteredResources, resource => resource.options.menuGroup || 'default');

    return (
        <div
            className={classnames(
                classes.main,
                {
                    [classes.open]: open,
                    [classes.closed]: !open,
                },
                className
            )}
        // {...rest}
        >
            {hasDashboard && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} classes={{ root: classes.root, active: classes.active, icon: classes.icon }} dense={false} />}
            {Object.keys(groupedResources).map(group => (
                <MenuGroup
                    key={group}
                    title={translate(`menu.groups.${group}`)}
                >
                    {groupedResources[group].filter(r => r.hasList).map(resource => (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            classes={{ root: classes.root, active: classes.active, icon: classes.icon }}
                            primaryText={translate(`resources.${resource.name}.menu`, { _: resource.name, smart_count: 2 })}
                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                    ))}
                </MenuGroup>
            ))
            }
        </div>
        // <div className={classes.stickyContainer}>
        //     <div />
        //     <div className={classNames(classes.main, classes.sticky, className)} {...props}>
        //         {hasDashboard && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} classes={{ root: classes.root, active: classes.active, icon: classes.icon }} dense={false} />}
        //         {Object.keys(groupedResources).map(group => (
        //             <MenuGroup
        //                 key={group}
        //                 title={translate(`menu.groups.${group}`)}
        //             >
        //                 {groupedResources[group].filter(r => r.hasList).map(resource => (
        //                     <MenuItemLink
        //                         key={resource.name}
        //                         to={`/${resource.name}`}
        //                         classes={{ root: classes.root, active: classes.active, icon: classes.icon }}
        //                         primaryText={translate(`resources.${resource.name}.menu`, { _: resource.name, smart_count: 2 })}
        //                         leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        //                         onClick={onMenuClick}
        //                         sidebarIsOpen={open}
        //                     />
        //                 ))}
        //             </MenuGroup>
        //         ))
        //         }
        //     </div>
        // </div>

    );
};

Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};

export default Menu;
