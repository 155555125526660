import React from 'react';
import useListPropsPreferencesLocal from '../../../controller/preferences/useListPropsPreferencesLocal';


const ListProrsPreferences = props => {
    useListPropsPreferencesLocal()
    return null;
};

export default ListProrsPreferences;
