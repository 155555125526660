import { ReferenceInput, ReferenceArrayInput, AutocompleteInput } from 'react-admin';
import { can, rulesToFields } from '../../abilityProvider';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import QuickFilter from "../Layout/filter/QuickFilter";
import EnumInput from '../Inputs/EnumInput';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import NullableBooleanInput from "../Inputs/BooleanInput";
import React from 'react';
import SearchInput from '../Inputs/SearchInput';
import { matchTrue } from '../../utils';




const filterRoles = (value) => {
    const { role_in, role } = rulesToFields("getList", "User");
    return role_in ? role_in.includes(value.name) : true;
}

const UserFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
        <EnumInput source="role" filter={filterRoles} emptyText="ra.message.no" />
        <NullableBooleanInput source="banned" style={{ width: 150 }} />
        {can('getList', 'User', 'counterparty', 'Counterparty') && <ReferenceInput source="counterparty.id" label="resources.User.fields.counterparty" reference="Counterparty" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceInput>}
        {can('getList', 'User', 'access') && <ReferenceInput source="accessCounterparty.id" label="resources.User.fields.accessCounterparty" reference="Counterparty" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceInput>}
        {can('getList', 'User', 'access') && <ReferenceArrayInput source="accessGasRegions_and" label="resources.User.fields.accessGasRegion" reference="GasRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'User', 'access') && <ReferenceArrayInput source="accessGasDistricts_and" label="resources.User.fields.accessGasDistrict" reference="GasDistrict" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'User', 'access') && <ReferenceArrayInput source="accessGasConsumers_and" label="resources.User.fields.accessGasConsumer" reference="GasConsumer" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'User', 'access') && <ReferenceArrayInput source="accessGasMeterPads_and" label="resources.User.fields.accessGasMeterPad" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'User', 'access') && <ReferenceArrayInput source="accessGasMeterUnits_and" label="resources.User.fields.accessGasMeterUnit" reference="GasMeterUnit" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'User', 'access') && <QuickFilter source="user_not_access" label="resources.User.fields.access_is_null" defaultValue={true} />}
    </ManualSubmitFilter>
)



export default UserFilter;