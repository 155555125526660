import React from "react";
import { Children, cloneElement } from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        display: "flex",
        marginTop: -1,
        marginBottom: -1
    }
});

const RowToolbar = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, (button) => cloneElement(button, props))}
        </div>
    );
};


export default RowToolbar