import englishMessages from 'ra-language-english';
import { raFormLayoutLanguageEnglish } from '@react-admin/ra-form-layout';



const en = {
    ...englishMessages,
    ...raFormLayoutLanguageEnglish
}

export default en;