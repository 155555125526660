import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, SaveButton, ReferenceInput, ReferenceArrayInput, required } from 'react-admin';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import GasDistrictBanner from './GasDistrictBanner';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));

const GasDistrictToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasDistrict') && <SaveButton />}
            {can("update", "GasDistrict") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasDistrict') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const GasDistrictEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props}>
            <GasDistrictBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasDistrictToolbar />}>
                    {can('update', 'GasDistrict', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'GasDistrict', 'description') && <TextInput source="description" multiline fullWidth />}
                    {can('update', 'GasDistrict', 'aisId') && <TextInput source="aisId" />}
                    {can('update', 'GasDistrict', 'gasRegion', 'GasRegion') && <ReferenceInput source="gasRegion.id" reference="GasRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistrict.fields.gasRegion" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasDistrict', 'gasMeterPads', 'GasMeterPad') && <ReferenceArrayInput source="gasMeterPadsIds" reference="GasMeterPad" filter={{ gasDistrict: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistrict.fields.gasMeterPads" fullWidth>
                        <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                    </ReferenceArrayInput>}
                    {can('update', 'GasDistrict', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasDistrict" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.reports" fullWidth />}
                    {can('update', 'GasDistrict', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasDistrict" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasDistrictEdit