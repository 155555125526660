import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

export const GET_ONE_GAS_METER = gql`
    query GasMeter( $where: GasMeterWhereUniqueInput! ) {
        data: GasMeter( where: $where ) {id dbId}
    }
`

export default (args) => {
    return apolloCoreClient.query({
        query: GET_ONE_GAS_METER,
        variables: { ...args },
        // fetchPolicy: 'cache-first', установлен для пропуска дополнительных запросов при асинхронной валидации dbId в Мастере создания Устройства
        fetchPolicy: 'cache-first',
        onError: (error) => {
            alert(error)
        }
    });
};
