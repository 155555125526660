import React from 'react';
import Chip from '@material-ui/core/Chip';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({

    icon: {
        color: "inherit",
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.getContrastText(theme.palette.info.main),
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.getContrastText(theme.palette.warning.main),
    },
}));


const AccessChip = ({record, ...props}) => {
    const classes = useStyles();

    if (!record) return null;

    const isUserAccess = record && record.accessResource;
    const isUserCounterpartyAccess = record && record.counterparty;
    const label = isUserAccess ? "Доступ по пользователю" : isUserCounterpartyAccess ? "Доступ по контрагенту" : "Нет доступа";
    const icon = (!isUserAccess && !isUserCounterpartyAccess) ? (<PriorityHighIcon />) : null;
    const isWarning = (!isUserAccess && !isUserCounterpartyAccess);


    return <Chip
        size="small"
        icon={icon}
        classes={{root: classes.root, icon: classes.icon}}
        className={isWarning ? classes.warning : classes.info}
        label={label}
    />;
}

AccessChip.defaultProps = {
    addLabel: false,
};

export default AccessChip;