import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TodayIcon from '@material-ui/icons/Today';
import WarningIcon from '@material-ui/icons/Warning';
import ReportIcon from '@material-ui/icons/Report';
import CertificateIcon from '../Icons/Certificate';
import { makeStyles } from '@material-ui/core/styles';


const getIcon = status => {
    switch (status) {
        case 'Hourly':
            return (<ScheduleIcon />)
        case 'Daily':
            return (<TodayIcon />)
        case 'Event':
            return (<WarningIcon />)
        case 'Other':
            return (<ReportIcon />)
        case 'Summary':
            return (<ReportIcon />)
        case 'GasQualityCertificate':
            return (<CertificateIcon />)
        default:
            return (<ReportIcon />)
            break;
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        margin: ".125rem",
    },
    Hourly: {
        color: "#2e7d32",
        backgroundColor: "#c8e6c9",
    },
    Daily: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    Event: {
        color: "#ef6c00",
        backgroundColor: "#ffe0b2",
    },
    Summary: {
        color: "#B71C1C",
        backgroundColor: "#FFCDD2",
    },
    GasQualityCertificate: {
        color: "#00796b",
        backgroundColor: "#b2dfdb",
    },
    Other: {
        color: "#4527a0",
        backgroundColor: "#d1c4e9",
    },
}));

const ReportAvatar = ({ className, classes: classesOverride, record, ...props}) => {
    const classes = useStyles({ classes: classesOverride });

    if (!record) return null;
    const icon = getIcon(record.type)

    return (
        <Avatar aria-label="daily" variant="rounded" classes={{colorDefault: classes[record.type]}}>
            {React.cloneElement(icon)}
        </Avatar>

    );
};

export default ReportAvatar;