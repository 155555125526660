import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, SaveButton, NumberInput, BooleanInput, ReferenceInput } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import GasMeterPointBanner from './GasMeterPointBanner';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import { can } from "../../abilityProvider";
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));


const redirectAfterDelete = (basePath, id, data) => {
    const gasMeterUnitId = data?.gasMeterUnit?.id
    return gasMeterUnitId ? `/GasMeterUnit/${gasMeterUnitId}/show` : '/GasMeterUnit'
}

const GasMeterPointToolbar = ({ hasEdit, hasList, ...props }) => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasMeterPoint') && <SaveButton redirect="show" />}
            {can("update", "GasMeterPoint") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasMeterPoint') && <RemoveButton redirect={redirectAfterDelete} basePath="/GasMeterUnit" />}
            <CancelButton />
        </Toolbar>
    );
}

const GasMeterPointEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasMeterPointBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasMeterPointToolbar />}>
                    {can('update', 'GasMeterPoint', 'name') && <TextInput source="name" fullWidth label="resources.GasMeterPoint.fields.name" />}
                    {can('update', 'GasMeterPoint', 'description') && <TextInput source="description" fullWidth label="resources.GasMeterPoint.fields.description" />}
                    {can('update', 'GasMeterPoint', 'aisId') && <TextInput source="aisId" />}
                    {can('update', 'GasMeterPoint', 'gasMeterUnit', 'GasMeterUnit') && <ReferenceInput source="gasMeterUnit.id" reference="GasMeterUnit" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPoint.fields.gasMeterUnit" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasMeterPoint', 'number') && <NumberInput source="number" label="resources.GasMeterPoint.fields.number" />}
                    {can('update', 'GasMeterPoint', 'disabled') && <BooleanInput source="disabled" label="resources.GasMeterPoint.fields.disabled" />}
                    {can('update', 'GasMeterPoint', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasMeterPoint" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPoint.fields.reports" fullWidth />}
                    {can('update', 'GasMeterPoint', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasMeterPoint" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPoint.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasMeterPointEdit