import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import SendEmailNow from '../Icons/SendEmailNow';
import {
    useNotify,
    Button,
    fetchStart,
    fetchEnd,
    useRefresh,
} from 'react-admin';
import runNow from "../../dataProvider/ReportDespatch/ReportDespatch/runNow";









const RunNowReportDespatchButton = ({
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    ...rest }) => {

    const dispatch = useDispatch();
    const refresh = useRefresh();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const handleRunNow = useCallback(() => {

        setLoading(true);
        dispatch(fetchStart());

        runNow({
            where: { id: record.id }
        }).then(() => {
            notify('ra.notification.despatch_runned', 'info', { smart_count: 1 });
            refresh();
        })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    }, [setLoading, notify, refresh, fetchStart, fetchEnd, record])


    return (
        <Button
            onClick={handleRunNow}
            label='ra.action.despatch_run'
            disabled={loading}
            key="button"
            className={className}
        >
            <SendEmailNow />
        </Button>
    );
};

export default RunNowReportDespatchButton;