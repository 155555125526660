import React, { Fragment } from 'react'
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import useGasMeterUnitExporter from './useGasMeterUnitExporter';
import GasMeterUnitFilter from './GasMeterUnitFilter';
import GasMeterUnitBulkActionButtons from './GasMeterUnitBulkActionButtons';
import GasMeterUnitDatagrid, { useDefaultDatagridColumns } from './GasMeterUnitDatagrid';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';



const GasMeterUnitList = (props) => {
    const exporter = useGasMeterUnitExporter()
    const manualSubmitForm = React.useRef(null)
    const columns = useDefaultDatagridColumns()

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/6GasMeterUnit" target="_blank" />
            <List
                {...props}
                actions={<ListActions columns={columns} />}
                bulkActionButtons={<GasMeterUnitBulkActionButtons />}
                exporter={exporter}
                filters={<GasMeterUnitFilter formRef={manualSubmitForm} />}
            >
                <GasMeterUnitDatagrid columns={columns} />
            </List>
        </Fragment>
    )
};

GasMeterUnitList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default GasMeterUnitList;