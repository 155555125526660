import React from 'react';
import GasQualityCertificateEmpty from "./GasQualityCertificateEmpty"
import List from '../Layout/list/List';
import ListActions from './GasQualityCertificateListActions';
import useGasQualityCertificateExporter from './useGasQualityCertificateExporter';
import GasQualityCertificateBulkActionButtons from './GasQualityCertificateBulkActionButtons';
import GasQualityCertificateFilter from './GasQualityCertificateFilter';
import GasQualityCertificateDatagrid, { useDefaultDatagridColumns } from './GasQualityCertificateDatagrid';



const GasQualityCertificateList = (props) => {
    const exporter = useGasQualityCertificateExporter()
    const manualSubmitForm = React.useRef(null);
    const columns = useDefaultDatagridColumns()

    return (
        <List
            {...props}
            actions={<ListActions columns={columns} />}
            bulkActionButtons={<GasQualityCertificateBulkActionButtons />}
            empty={<GasQualityCertificateEmpty />}
            exporter={exporter}
            filters={<GasQualityCertificateFilter formRef={manualSubmitForm} />}
        >
            <GasQualityCertificateDatagrid columns={columns} />
        </List>
    )
};

GasQualityCertificateList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default GasQualityCertificateList;