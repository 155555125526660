import client from './prometheusFetchClient';
import decorate from './decorate';
import builtQuery from './builtQuery';



export const knownResources = ['MetricGasMeter'];
const dataProvider = async (_type, _resource, _params) => {
  const {type, resource, params} = await decorate(_type, _resource, _params);  
  const {query, variables, parseResponse} = builtQuery(type, resource, params);

  return client(query, variables, parseResponse);
};



export default {
    dataProvider,
    knownResources,
};