import React from 'react'
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { handleFocus } from 'react-color/lib//helpers/interaction'


const useStyles = makeStyles(theme => ({
    root: ({ color }) => ({
        background: color,
        width: 24,
        height: 24,
        borderRadius: 4,
        cursor: 'pointer',
        outline: 'none',
    }),
    focus: ({ color }) => ({
        boxShadow: `0 0 4px ${color}`,
    })
}));


const ENTER = 13

export const Swatch = ({
    color,
    onClick = () => { },
    onHover,
    title = color,
    focus, }) => {

    const classes = useStyles({ color })
    const handleClick = e => onClick(color, e)
    const handleKeyDown = e => e.keyCode === ENTER && onClick(color, e)
    const handleHover = e => onHover(color, e)

    const optionalEvents = {}
    if (onHover) {
        optionalEvents.onMouseOver = handleHover
    }

    return (
        <div
            className={classnames(classes.root, {
                [classes.focus]: focus,
            })}
            onClick={handleClick}
            title={title}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            {...optionalEvents}
        />
    )
}

export default handleFocus(Swatch)