import React from 'react';
import { TextField, DateField, Pagination, ReferenceManyField as RaReferenceManyField } from 'react-admin';
import DividerField from '../Fields/DividerField';
import { Show } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import NestedGridField from '../Fields/NestedGridField';
import TitleForRecord from '../Layout/TitleForRecord';
import Breadcrumbs from '../Layout/Breadcrumbs';
import Breadcrumb from '../Layout/Breadcrumb';
import GasMeterPointBanner from './GasMeterPointBanner';
import BooleanField from '../Fields/BooleanField';
import ReportCard from '../Report/ReportCard';
import CardBlankLayout from "../Layout/CardBlankLayout";
import { can } from "../../abilityProvider";
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import NestedField from '../Fields/NestedField';
import { dateTimeOptions } from '../../utils';



const GasMeterPointBreadcrumbs = (props) => {
    return (
        <Breadcrumbs {...props}>
            <Breadcrumb resource={"GasRegion"} />
            <Breadcrumb resource={"GasDistrict"} />
            <Breadcrumb resource={"GasConsumer"} />
            <Breadcrumb resource={"GasMeterPad"} />
            <Breadcrumb resource={"GasMeterUnit"} />
        </Breadcrumbs>
    );
}

const GasMeterPointShow = props => {
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show breadcrumbs={<GasMeterPointBreadcrumbs />} title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasMeterPointBanner />

            <CardShowLayout>
                {can('getOne', 'GasMeterPoint', 'number') && <TextField source="number" />}
                {can('getOne', 'GasMeterPoint', 'disabled') && <BooleanField source="disabled" addLabel valueLabelTrue={'resources.GasMeterPoint.enums.disabled.true'} valueLabelFalse={'resources.GasMeterPoint.enums.disabled.false'} label={'resources.GasMeterPoint.fields.state'} />}
            </CardShowLayout>

            {can('getOne', 'GasMeterPoint', 'reports', 'Report') && <NestedGridField source="reports" label="resources.GasMeterPoint.fields.reports" >
                <ReportCard />
            </NestedGridField>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {can('getOne', 'GasMeterPoint', 'aisId') && <TextField source="aisId" />}
                    <DividerField />
                    {can('getOne') && <TextField source="id" />}
                    {can('getOne', 'GasMeterPoint', 'createdBy', 'User') && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {can('getOne', 'GasMeterPoint', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'GasMeterPoint', 'createdAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>

            { can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
            { can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="GasMeterPoint" />
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>}
        </Show>
    );
};

export default GasMeterPointShow;