import get from 'lodash/get';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import { useTranslate, useResourceContext } from 'react-admin';
import { booleanFormatter, dateFormatter, enumFormatter, parseHeaders, parseHeadesTitles, parseRecord } from '../../exporter';


const getRelatedIds = (records, field) => {
    return Array.from(new Set(records.reduce((acc, record) => {
        const id = get(record, field)
        return id ? [...acc, id] : acc
    }, [])));
};


const customExporter = (title, model, { translate, ...options }) => (records, fetchRelatedRecords, dataProvider, resource) => {

    const headers = parseHeaders(model)
    const rename = parseHeadesTitles(model, translate)

    dataProvider
        .getMany('MetricGasMeter', { ids: getRelatedIds(records, "gasMeter.dbId") })
        .then(({ data }) => {
            return data.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
        })
        .then(metricsMap => {
            const data = records.map(record => parseRecord(model, {
                ...record,
                metrics: metricsMap[get(record, "gasMeter.dbId")],
            }, translate));
            const BOM = "\uFEFF";

            jsonExport(data, {
                rowDelimiter: ";",
                headers,
                rename,
                ...options,
            }, (err, csv) => {
                downloadCSV(BOM + csv, title);
            });
        });



};

const useExporter = () => {
    const translate = useTranslate()
    const resource = useResourceContext()
    const fileName = translate(`resources.${resource}.name`, { smart_count: 2 })
    const modelExport = {
        id: true,
        aisId: true,
        name: true,
        description: true,
        inventoryNumber: true,
        createdBy: {
            id: true,
            name: true,
            __resource: "User",
        },
        createdAt: dateFormatter,
        updatedAt: dateFormatter,
        gasMeterPad: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasMeterPad",
        },
        gasMeterPoints: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasMeterPoint",
        },
        controller: {
            id: true,
            serialNumber: true,
            phoneNumber: true,
            type: enumFormatter,
            url: true,
            __resource: "Controller"
        },
        gasMeter: {
            id: true,
            serialNumber: true,
            dbId: true,
            type: enumFormatter,
            __resource: "GasMeter"
        },
        metrics: {
            updatedAt: dateFormatter,
            __resource: "MetricGasMeter"
        },
        enableWriteGasQualityCertificate: booleanFormatter,
        fixedAsset: true,
        linkToir: true,
        reports: {
            id: true,
            name: true,
            __resource: "Report",
        },
        tags: {
            id: true,
            name: true,
            __resource: "Tag",
        },
        __resource: "GasMeterUnit",
    }
    const exporter = customExporter(fileName, modelExport, { translate })

    return exporter
}



export default useExporter;