import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, BooleanInput, required } from 'react-admin';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import ReportDespatchSchedulerInput from './ReportDespatchSchedulerInput';
import CronInput2 from '../Inputs/CronInput2';
import EnumInput from '../Inputs/EnumInput'
import ContactReferenceArrayInput from '../Contact/ContactReferenceArrayInput';
import { can } from "../../abilityProvider";


const validateName = [required()]
const validateTo = [required()]
const validateReportsIds = [required()]
const validateCron = [required()]


const ReportDespatchCreate = props => {

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'ReportDespatch', 'name') && <TextInput source="name" validate={validateName} placeholder="Будет использовано в качестве темы электронного письма" fullWidth />}
                {can('create', 'ReportDespatch', 'description') && <TextInput source="description" placeholder="Будет использовано в качестве текста электронного письма" multiline fullWidth />}
                {can('create', 'ReportDespatch', 'to') && <ContactReferenceArrayInput source="toIds" label="resources.ReportDespatch.fields.to" validate={validateTo} reference="Contact" sort={{ field: 'name', order: 'ASC' }} />}
                {can('create', 'ReportDespatch', 'cc') && <ContactReferenceArrayInput source="ccIds" label="resources.ReportDespatch.fields.cc" reference="Contact" sort={{ field: 'name', order: 'ASC' }} />}
                {can('create', 'ReportDespatch', 'bcc') && <ContactReferenceArrayInput source="bccIds" label="resources.ReportDespatch.fields.bcc" reference="Contact" sort={{ field: 'name', order: 'ASC' }} />}
                {can('create', 'ReportDespatch', 'reportsIds', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ external: true }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.ReportDespatch.fields.reports" validate={validateReportsIds} fullWidth />}
                {can('update', 'ReportDespatch', 'rangeOption') && <EnumInput source="rangeOption" name="RangeOption" validate={validateCron} />}
                {can('create', 'ReportDespatch', 'cron') && <ReportDespatchSchedulerInput source="cron" label="resources.ReportDespatch.fields.scheduler" validate={validateCron} />}
                {can('create', 'ReportDespatch', 'enabled') && <BooleanInput source="enabled" initialValue={true} />}
            </SimpleForm>
        </Create>
    );
};

export default ReportDespatchCreate;