import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'react-admin';
import { TITLE_APP } from '../../appsettings'
import WidgetBanner from './WidgetBanner';
import GridLayoutSimple from '../Layout/GridLayoutSimple';
import NewReleaseCard from "./NewReleaseCard";
import DeviceCreateWizardCardButton from "../Device/DeviceCreateWizardCardButton";
import { can } from "../../abilityProvider";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 16,
    },
}));

const MainScreen = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Title title={TITLE_APP} />
            <WidgetBanner />
            <GridLayoutSimple xs={{ count: "auto-fill", size: [32, "1fr"] }} label="resources.Main.fields.scope" style={{ padding: 16 }}>
                <NewReleaseCard />
                {can('create', 'GasMeterUnit') && can('create', 'GasMeterPoint') && can('create', 'Controller') && can('create', 'GasMeter') && (
                    <DeviceCreateWizardCardButton />
                )}

            </GridLayoutSimple>
        </div>

    );
};

export default MainScreen;