import { Datagrid, ListContextProvider, ListView, SearchInput, TextField, useListController } from 'react-admin';

import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import React from 'react';
import { can } from "../../abilityProvider";
import useListStyles from '../Layout/list/useListStyles';



const ReportFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
    </ManualSubmitFilter>
);

const ReportList = props => {
    const classes = useListStyles();
    const controllerProps = useListController(props);
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContextProvider value={controllerProps}>
            <ListView
                filters={<ReportFilter formRef={manualSubmitForm} />}
                {...props}
            >
                <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
                    {can('getList', 'Report', 'name') && <TextField source="name" />}
                    {can('getList', 'Report', 'description') && <TextField source="description" />}
                </Datagrid>
            </ListView>
        </ListContextProvider>
    )
};

ReportList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default ReportList;