export const register = () => {
    if ('serviceWorker' in navigator) {

        window.addEventListener('load', () => {
            const swUrl = `/sw.js`;

            navigator.serviceWorker.register(swUrl)
                .then((sw) => {
                    console.log('Service Worker registration successful')
                })
                .catch(function (error) {
                    console.error('Service Worker Error', error);
                });
        });
    }
};
