import { gql } from '@apollo/client';

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id
    name
    email
    userId
  }
`

export const REPORT_DESPATCH_FRAGMENT = gql`
  fragment ReportDespatchFragment on ReportDespatch {
    id
    name
    description
    createdAt
    createdById
    updatedAt
    to {...ContactFragment}
    cc {...ContactFragment}
    bcc {...ContactFragment}
    reportsIds
    enabled
    cron
    rangeOption
    lastExecutionAt
    nextExecutionAt
  }
  ${CONTACT_FRAGMENT}
`

