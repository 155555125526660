import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRedirect, useRefresh, fetchStart, fetchEnd, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import RemoveIcon from '@material-ui/icons/Delete';
import removeToTrash from '../../dataProvider/Core/removeToTrash';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';


export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    onSave,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    removeButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}));

const RemoveButton = ({ 
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    redirect: redirectTo,
    ...rest }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);
    
    const handleClick = (e) => {

        e.stopPropagation();
        setLoading(true);
        dispatch(fetchStart());

        removeToTrash({
            resource,
            where: {id: record.id}
        }).then(() => {
            notify( 'ra.notification.moved_to_trash', 'info', { smart_count: 1 } );
            redirect(redirectTo, basePath, record.id);
            refresh();
        })
        .catch((error) => {
            notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
        })
        .finally(() => {
            setLoading(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    };

    return (
        <MenuItem
            onClick={handleClick}
            disabled={loading}
            className={`${classes.removeButton} ${className}`}
            {...rest}
        >
            <ListItemIcon style={{color: "inherit"}}>
                <RemoveIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{translate('ra.action.remove')}</Typography>
        </MenuItem>
    );
        
};

export default RemoveButton;