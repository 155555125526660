import React from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import get from 'lodash/get';
import { ReferenceInput } from 'react-admin';
import AutocompleteInput from '../../Inputs/AutoompleteInput'
import Suggestion from './ControllerConfigurationSuggestion'



const ReferenceConfigurationInput = (props) => {
    const form = useForm();
    const { values } = useFormState();
    const selectConfigurationsObject = useSelector(state => get(state, `admin.resources.ControllerConfiguration.data`));

    const handleChangeConfiguration = (value) => {
        const metaTemplate = get(selectConfigurationsObject, `${value}.metaTemplate`)
        form.change('controller.meta', metaTemplate || {})
    };

    return (
        <ReferenceInput key={values.controller?.type} {...props} filter={{ type: values.controller?.type ? values.controller.type : null, status_not: "DRAFT" }} onChange={handleChangeConfiguration}>
            <AutocompleteInput optionText="name" suggestionItem={<Suggestion />} options={{ disabled: !values.controller?.type }} />
        </ReferenceInput>
    );
};

export default ReferenceConfigurationInput;