import { useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import set from 'lodash/set';
import get from 'lodash/get';



const usePreferenceController = (params) => {

    const dataProvider = useDataProvider();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const readStorage = useCallback(async (path) => {
        if (!path) return;
        setLoading(true)

        const [key, ...subkeys] = path.split('.');
        const response = await dataProvider.getPreferences('Preference', { key });
        const preferences = response.data?.preferences?.value
        setData(prev => {
            const newPreferences = set({ ...prev }, [key, ...subkeys], get(preferences, subkeys))
            return newPreferences
        });
        setLoading(false)
        setLoaded(true)

    }, [setData]);

    const writeStorage = useCallback((path, value) => {
        if (!path) return;

        const [key, ...subkeys] = path.split('.');

        setData(prev => {
            const newPreferences = set({ ...prev }, [key, ...subkeys], value)
            dataProvider.setPreferences('Preference', { key, value: get(newPreferences, [key]) });
            return newPreferences
        });

    }, [setData]);

    return {
        data,
        loading,
        loaded,
        readStorage,
        writeStorage
    };
}

export default usePreferenceController