import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import sanitizeFieldRestProps from './sanitizeFieldRestProps';
import cronstrue from 'cronstrue/i18n';


const CronField = ({ className, resource, source, record = {}, ...props }) => {

    const cron = get(record, source);
    if (!cron) return null;
    const cronHumanize = cronstrue.toString(cron, {
        use24HourTimeFormat: true,
        verbose: true,
        locale: "ru",
    });

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeFieldRestProps(props)}
        >
            {cronHumanize}
        </Typography>
    )
};

CronField.defaultProps = {
    addLabel: true,
};

export default CronField;