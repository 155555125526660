import gql from 'graphql-tag';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient'



const GET_MANY_GAS_DISTRIBUTOR_STATIONS = gql`
    query allGasDistributorStations($page: Int, $perPage: Int, $filter: GasDistributorStationFilter) {
    items: allGasDistributorStations(page: $page, perPage: $perPage, filter: $filter) {
        id
        gasDistributorStationOutputs {
            gasMeterPads {
                gasMeterUnits{
                    enableWriteGasQualityCertificate
                    gasMeter {
                        dbId
                        configuration{
                            ns
                            scadaSet
                        }
                    }
                }
            }
        }
    }
}`

const flatArrayOfObjects = (array, path) => {

    const parts = path.split(".")
    const [part, ...otherParts] = parts
    return (Array.isArray(array) && otherParts.length > 0) ? [].concat(...array.map((item, i) => {
        const select = item[part]
        return flatArrayOfObjects(select, otherParts.join("."))
    })) : Array.isArray(array)
            ? array.flatMap(item => item[part])
            : array;
};

const getGasMeters = async (_gasDistributorStations) => {
    const gasDistributorStationsIds = _gasDistributorStations.map(gasDistributorStation => gasDistributorStation.id)

    const result = await apolloCoreClient.query({
        query: GET_MANY_GAS_DISTRIBUTOR_STATIONS,
        variables: {
            perPage: gasDistributorStationsIds.length,
            filter: {
                id_in: gasDistributorStationsIds
            }
        },
        onError: (error) => {
            alert(error)
        }
    })

    const gasDistributorStations = result.data.items;

    const gasMeters = gasDistributorStations.flatMap(_gasDistributorStation => {
        const findGasDistributorStation = _gasDistributorStations.find(item => item.id === _gasDistributorStation.id);
        const gasDistributorStationGasMeterUnits = flatArrayOfObjects(_gasDistributorStation.gasDistributorStationOutputs, "gasMeterPads.gasMeterUnits");
        const gms = gasDistributorStationGasMeterUnits.filter(gmu => gmu.enableWriteGasQualityCertificate).map(gmu => ({ ...gmu.gasMeter, gasQualityCertificateId: findGasDistributorStation.gasQualityCertificateId }))

        return gms;
    });

    return gasMeters
};



const getWriteValues = async (_gasQualityCertificates) => {

    const writeTags = [];
    const gasQualityCertificates = Array.isArray(_gasQualityCertificates) ? _gasQualityCertificates.map(gqs => ({ ...gqs })) : [{ ..._gasQualityCertificates }];
    const gasDistributorStations = gasQualityCertificates.flatMap(gasQualityCertificate => {
        const gds = gasQualityCertificate.gasDistributorStations.map(gasDistributorStation => ({
            gasQualityCertificateId: gasQualityCertificate.id,
            ...gasDistributorStation
        }))
        return gds;
    });
    const gasMeters = await getGasMeters(gasDistributorStations);

    gasMeters.forEach(gasMeter => {
        const dbId = gasMeter.dbId
        const ns = gasMeter.configuration && gasMeter.configuration.ns
        const scadaSet = gasMeter.configuration && gasMeter.configuration.scadaSet
        const findGasQualityCertificateTags = scadaSet && scadaSet.children && scadaSet.children.find(item => item.id === "gasQualityCertificate")
        const findGasQualityCertificate = gasQualityCertificates.find(gasQualityCertificate => gasQualityCertificate.id === gasMeter.gasQualityCertificateId)
        const tags = findGasQualityCertificateTags && findGasQualityCertificateTags.items
        const values = findGasQualityCertificate && {
            co2: findGasQualityCertificate.co2,
            density: findGasQualityCertificate.density,
            n2: findGasQualityCertificate.n2,
            validFrom: findGasQualityCertificate.validFrom,
        }

        if (dbId && ns && tags) {
            Object.keys(values).forEach(key => {
                const tag = tags.find(item => item.id.toString() === key)
                const value = values[key]
                if (tag && value !== undefined) {
                    writeTags.push({
                        id: { ns: ns, serial: dbId, id: tag.idRef_wr },
                        value: value.toString(),
                    })

                    if (!findGasQualityCertificate.writeTags) findGasQualityCertificate.writeTags = []

                    findGasQualityCertificate.writeTags.push({
                        id: { ns: ns, serialid: dbId, id: tag.idRef_wr },
                        value: value.toString(),
                    })

                }
            })
        }
    });

    return {
        writeTags,
        gasQualityCertificates,
    }

};

export default getWriteValues;