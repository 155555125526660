import React from 'react';
import { NullableBooleanInput, ReferenceArrayInput, ReferenceInput, TextInput } from 'react-admin';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import AutocompleteInput from '../Inputs/AutoompleteInput';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import SearchInput from '../Inputs/SearchInput';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import GasDistributorStationOutputSuggestionItem, { gasDistributorStationOutputOptionText } from './GasDistributorStationOutputSuggestionItem';
import { can } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';
import { matchTrue } from '../../utils';



const gasDistributorStationOutputFilterToQuery = searchText => ({ searchWithGDS: searchText });

const useStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}));

const GasMeterPadFilter = (props) => {
    const classes = useStyles()
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn />
            {can('getList', 'GasMeterPad', 'aisId') && <TextInput source="aisId" alwaysOn resettable className={classes.mediumWidth} />}
            {can('getList', 'GasMeterPad', 'gasDistrict', 'GasDistrict') && <ReferenceInput source="gasDistrictReference" label="resources.GasMeterPad.fields.gasDistrict" reference="GasDistrict" sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterPad', 'gasConsumer', 'GasConsumer') && <ReferenceInput source="gasConsumerReference" label="resources.GasMeterPad.fields.gasConsumer" reference="GasConsumer" sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterPad', 'gasDistributorOrganization', 'GasDistributorOrganization') && <ReferenceInput source="gasDistributorOrganizationReference" label="resources.GasMeterPad.fields.gasDistributorOrganization" reference="GasDistributorOrganization" sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterPad', 'gasDistributorStation', 'GasDistributorStation') && <ReferenceInput source="gasDistributorStationByGasDistributorStationOutputReference" label="resources.GasMeterPad.fields.gasDistributorStation" reference="GasDistributorStation" sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterPad', 'gasDistributorStationOutput', 'GasDistributorStationOutput') && <ReferenceInput
                source="gasDistributorStationOutputReference"
                filterToQuery={gasDistributorStationOutputFilterToQuery}
                label="resources.GasMeterPad.fields.gasDistributorStationOutput"
                reference="GasDistributorStationOutput"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={100}
                allowEmpty
                emptyText="ra.message.no">
                <AutocompleteInput optionText={gasDistributorStationOutputOptionText} suggestionItem={<GasDistributorStationOutputSuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterPad', 'disabled') && <NullableBooleanInput source="disabled" />}
            {can('getList', 'GasMeterPad', 'isProvider') && <NullableBooleanInput source="isProvider" />}
            {can('getList', 'GasMeterPad', 'enableWriteGasQualityCertificate', 'GasMeterUnit') && <NullableBooleanInput source="gasMeterUnits_some.enableWriteGasQualityCertificate" label="resources.GasMeterUnit.fields.enableWriteGasQualityCertificate" />}
            {can('getList', 'GasMeterPad', 'tags', 'Tag') && <ReferenceArrayInput source="tags_and" label="resources.Tag.fields.tags_and" reference="Tag" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false}>
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasMeterPad', 'tags', 'Tag') && <ReferenceArrayInput source="tags_not_and" label="resources.Tag.fields.tags_not_and" reference="Tag" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasMeterPad', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_and" label="resources.Report.fields.reports_and" reference="Report" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
            {can('getList', 'GasMeterPad', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_not_and" label="resources.Report.fields.reports_not_and" reference="Report" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
        </ManualSubmitFilter>
    )
};



export default GasMeterPadFilter;