import Icon from '@material-ui/icons/History';
import HistoryList from './HistoryList';
import HistoryShow from './HistoryShow';

const HistoryIcon = Icon;


export {
    HistoryIcon,
    HistoryList,
    HistoryShow,
};


