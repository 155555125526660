import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { ResourceContextProvider, ListContextProvider, useTranslate, Datagrid, SimpleList, DateField, TextField } from 'react-admin';
import TagArrayField from "../Tag/TagArrayField";
import ResourceLinkField from '../Fields/ResourceLinkField';
import TagChip from "../Tag/TagChip";
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import useListStyles from '../Layout/list/useListStyles';



const GasMeterPadsReferenceDatagrid = ({ basePath, resource, source, record, ...props }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();
    const translate = useTranslate();
    const ownership = get(record, source);

    if (ownership.length === 0) {
        return (
            <CardContent>
                <Typography variant="body2">
                    {translate('ra.navigation.no_results')}
                </Typography>
            </CardContent>
        )
    }

    const data = ownership.map(item => ({
        id: item.gasMeterPad.id,
        name: item.gasMeterPad.name,
        description: item.gasMeterPad.description,
        aisId: item.gasMeterPad.aisId,
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
    }))


    return (
        <ResourceContextProvider value={'GasMeterPad'}>
            <ListContextProvider
                value={{
                    resource: 'GasMeterPad',
                    basePath: '/GasMeterPad',
                    data: keyBy(data, 'id'),
                    ids: data.map(({ id }) => id),
                    total: data.length,
                    currentSort: { field: 'name', order: 'ASC' },
                    selectedIds: [],
                }}
            >
                {isMobile ? (
                    <SimpleList
                        primaryText={record => record.name}
                        secondaryText={record => `${new Date(record.dateFrom).toLocaleDateString()} - ${new Date(record.dateTo).toLocaleDateString()}`}
                        tertiaryText={record => record.aisId}
                        linkType="show"
                    />
                ) : (
                    <Datagrid {...props} rowClick="show" classes={{ row: classes.row }} >
                        <ResourceLinkField source="name" target="_blank" />
                        <TextField source="aisId" headerClassName={classes.noWrap} />
                        <TagArrayField source="tags" sortable={false}>
                            <TagChip />
                        </TagArrayField>
                        <DateField source="dateFrom" textAlign="right" headerClassName={classes.noWrap} cellClassName={classes.cellMinWidth} sortable={false} />
                        <DateField source="dateTo" textAlign="right" headerClassName={classes.noWrap} cellClassName={classes.cellMinWidth} sortable={false} />
                    </Datagrid>
                )}
            </ListContextProvider>
        </ResourceContextProvider>
    );
};

export default GasMeterPadsReferenceDatagrid;
