import React from 'react';
import get from 'lodash/get';
import TextField from '@material-ui/core/TextField';
import { useForm, useFormState } from "react-final-form";
import { useInput, InputHelperText } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';



const JsonVariableInput = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  record,
  resource,
  source,
  validate,
  variant = 'filled',
  inputProps,
  className,
  ...rest
}) => {

  const { values } = useFormState();
  const jsonVariable = get(values, source)


  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    defaultValue: jsonVariable ? jsonVariable.value : undefined,
    resource,
    source: `${source}.value`,
    validate,
    ...rest,
  });

  return jsonVariable && jsonVariable.name ? (
    <TextField
      id={id}
      {...input}
      className={className}
      variant={variant}
      error={!!(touched && error)}
      helperText={
        (touched && error) || helperText ? (
          <InputHelperText
            touched={touched}
            error={error}
            helperText={helperText}
          />
        ) : null
      }
      required={isRequired}
      label={jsonVariable.name}
      margin={margin}
      inputProps={inputProps}
      {...options}
      {...sanitizeRestProps(rest)}
    />
  ) : null
};


export default JsonVariableInput;
