import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { REPORT_DESPATCH_FRAGMENT } from '../typeDefs';

export const RUN_REPORT_DESPATCH = gql`
    mutation runReportDespatch($where: ReportDespatchWhereUniqueInput!) {
        data: runReportDespatch(where: $where) {
            item {...ReportDespatchFragment}
        }
    }
    ${REPORT_DESPATCH_FRAGMENT}
`

export default (args) => {
    return apolloClient.mutate({
        mutation: RUN_REPORT_DESPATCH,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    })
};
