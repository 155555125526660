import { gql } from '@apollo/client';



export const FLOWRESULT_FRAGMENT = gql`
  fragment FlowResultFragment on FlowResult {
    flow
    status
    diagnostics
    result
    shortAction
  }
`

export const GENERALRESULT_FRAGMENT = gql`
  fragment GeneralResultFragment on GeneralResult {
    name
    status
    flowResults {
      ...FlowResultFragment
    }
  }
  ${FLOWRESULT_FRAGMENT}
`



export const GET_MANY_DEVICESTATUSES = gql`
  query getManyDeviceStatuses($page: Int, $perPage: Int, $sort: DeviceStatusSort, $filter: DeviceStatusFilter) {
    data: listDeviceStatuses(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      items {
        id
        dbId
        status
        date
      }
      pageInfo{
        totalItems
      }
    }
  }
`;

export const GET_ONE_DEVICESTATUS = gql`
  query getOneDeviceStatus($where: DeviceStatusWhereUniqueInput!) {
    data: deviceStatus(where: $where) {
      item {
        id
        dbId
        status
        date
        checkHasGaps {
          ...GeneralResultFragment
        }
        checkHasGapsLastFiveDays {
          ...GeneralResultFragment
        }
        checkHasConfigurationProblems {
          ...GeneralResultFragment
        }
        checkCorrectorWithGapsStatus {
          ...GeneralResultFragment
        }
        checkRequiresManualDataReget {
          ...GeneralResultFragment
        }
        checkHasLoadDataInterruptions {
          ...GeneralResultFragment
        }
      }
    }
  }
  ${GENERALRESULT_FRAGMENT}
`;

export const REFRESH_DEVICESTATUS = gql`
  mutation updateDevicesStatus($dbIds: [Int]!) {
    data: updateDevicesStatus(dbIds: $dbIds) {
      items {
        id
        dbId
        status
        date
      }
    }
  }
`;

export const GET_ENUMS = gql`
  query getEnums($name: String!) {
    data: __type(name: $name) {
      name
      enumValues{
        name
        description
      }                          
    }
  }
`