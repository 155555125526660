import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useRouteMatch, useLocation, Link } from 'react-router-dom'
import getDocumentationPath from './getDocumentationPath';
import MenuItemIcon from './MenuItemIcon';



const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        marginBottom: 4,
        '&:last-child': {
            marginBottom: 0,
        },
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
        },
        '&$focusVisible': {
            backgroundColor: fade(theme.palette.primary.main, 0.08),
          },
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: fade(theme.palette.primary.main, 0.12),
          },
          '&.Mui-selected:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.20),
          },
    },
    expandIcon: {
        fontSize: 20,
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    listItemText: {
        margin: 0,
    },
    nested: ({depth}) => ({
        paddingLeft: theme.spacing(2) + theme.spacing(2*depth),
    }),
}));

const MenuItem = ({
    item,
    depth,
    ...props
}) => {
    const classes = useStyles({depth});
    const location = useLocation();
    const match = useRouteMatch();
    const { id, name, icon, route, open=false, children } = item;
    const [expand, setExpand] = React.useState(open);
    const selected = route && route.path === location.pathname.replace(match.path, "") 

    const handleClick = () => {
        setExpand(!expand);
    };
    
    const resultProps = {}
    if (route) {
        resultProps.component = Link;
        resultProps.to = getDocumentationPath(route.path, match.url);
    }
    if (children) {
        resultProps.onClick = handleClick;
    }



    return (id && name) ? 
       <>
            <ListItem 
                dense 
                button 
                selected={selected}
                classes={{root: classes.root}} 
                className={classes.nested}
                {...resultProps}
            >
                {icon && <MenuItemIcon icon={icon} {...icon.props.menuItemIconProps}/>} 
                <ListItemText primary={name} classes={{root: classes.listItemText}} />
                {Array.isArray(children)
                    ? <ExpandMore className={`${classes.expandIcon} ${expand ? classes.expandOpen : ""}`}/>
                    : null
                }
            </ListItem>
            {Array.isArray(children) && (
                <Collapse in={expand} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                        {children.map(child => (<MenuItem item={child} key={child.id} depth={depth+1}/>))}
                    </List>
                </Collapse>
            )}
       </> : null;
}


export default MenuItem;