import React from 'react';
import classNames from 'classnames';
import { Link as RRLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
            '&:visited': {
                color: theme.palette.primary.dark,
            },
        },
    }),
    { name: 'RaLink' }
);

const Link = props => {
    const {
        to,
        children,
        classes: classesOverride,
        className,
        ...rest
    } = props;
    const classes = useStyles(props);
    return (
        <RRLink
            to={to}
            className={classNames(classes.link, className)}
            {...rest}
        >
            {children}
        </RRLink>
    );
};


export default Link;
