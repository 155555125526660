import React, { Fragment } from 'react';
import { Datagrid, TextField } from 'react-admin';
import List from '../Layout/list/List';
import BooleanField from '../Fields/BooleanField';
import ResourceLinkField from '../Fields/ResourceLinkField';
import EnumField from '../Fields/EnumField';
import CronField from '../Fields/CronField';
import useListStyles from '../Layout/list/useListStyles';
import { can } from "../../abilityProvider";
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';



const ReportDespatchList = ({ modelExport, ...props }) => {
    const classes = useListStyles();

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/ReportDespatch" target="_blank" />
            <List
                {...props}
            >
                <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
                    {can('getList', 'ReportDespatch', 'name') && <ResourceLinkField source="name" target="_blank" />}
                    {can('getList', 'ReportDespatch', 'description') && <TextField source="description" headerClassName={classes.noWrap} />}
                    {can('getList', 'ReportDespatch', 'rangeOption') && <EnumField source="rangeOption" />}
                    {can('getList', 'ReportDespatch', 'cron') && <CronField source="cron" label="resources.ReportDespatch.fields.scheduler" sortable={false} />}
                    {can('getList', 'ReportDespatch', 'enabled') && <BooleanField source="enabled" valueLabelTrue={'resources.ReportDespatch.enums.enabled.true'} valueLabelFalse={'resources.ReportDespatch.enums.enabled.false'} label={'resources.ReportDespatch.fields.state'} />}
                </Datagrid>
            </List>
        </Fragment>
    )
};

ReportDespatchList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default ReportDespatchList;