import React from 'react';
import url from 'url';
import { useShowController } from 'react-admin';
import { ReportView } from '../Layout/Report';
import TitleForRecord from '../Layout/TitleForRecord';
import Breadcrumbs from '../Layout/Breadcrumbs';
import Breadcrumb from '../Layout/Breadcrumb';
import ReactReport from './ReactReport';



const ReportBreadcrumbs = ({target, ...props}) => {
    return (
        <Breadcrumbs resource={target.resource} data={{id: target.id}}>
            <Breadcrumb resource={"GasRegion"}/>
            <Breadcrumb resource={"GasDistrict"}/>
            <Breadcrumb resource={"GasConsumer"}/>
            <Breadcrumb resource={"GasMeterPad"}/>
            <Breadcrumb resource={"GasMeterUnit"}/>
        </Breadcrumbs>
    );
}

const ReportShow = props => {
    const controllerProps = useShowController(props);
    const target = url.parse(props.location.search, true).query;

    return (
        <ReportView 
            breadcrumbs={<ReportBreadcrumbs target={target} />}
            title={<TitleForRecord source="name" {...props} />}         
            {...props}
            {...controllerProps}>           
            <ReactReport {...props} />                   
        </ReportView>
    );
};

export default ReportShow;