import React, { Children, cloneElement } from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
import useLastupdateGasMeter from '../../dataProvider/Prometheus/useLastupdateGasMeter';


const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      '&:first-child': {
          marginTop: 0,
      },
    },

}));

const ReportNestedGridField = ({children, classes: classesOverride, record, source, resource, basePath, version, className, label, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    const lastUpdate = useLastupdateGasMeter(props)
    const choices = get(record, source);
    const referenceRecord = record
    if (!record || !choices.length) return null;

    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
            <div>
            <Grid container spacing={2} >
                {choices.map(choice => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={choice.id}>
                        {cloneElement(Children.only(children), {          
                            record: choice,
                            resource,
                            basePath,
                            version,
                            referenceRecord,
                            lastUpdate,
                        })}
                    </Grid>
                ))}
            </Grid>

            </div>
            
            
        </div> 
    );
};

export default ReportNestedGridField;