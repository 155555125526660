import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import LabelIcon from '@material-ui/icons/Label';
import {
    useDataProvider,
    useNotify,
    useRefresh,
    Button as RaButton,
    useUnselectAll,
    sanitizeEmptyValues,
    fetchStart,
    fetchEnd,
    AutocompleteArrayInput, ReferenceArrayInput,
} from 'react-admin';

import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import intersectionBy from 'lodash/intersectionBy'
import { groupBy } from 'lodash';
import updateManyTags from "../../dataProvider/Core/updataManyTags.js";





const useStyles = makeStyles(theme => ({
    mt3: { marginTop: 16 },
    referenceArrayInput: {
        zIndex: 1000
    }
}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const BulkUpdateTagsButton = ({ selectedIds, basePath, record, resource, className, redirect: redirectTo, ...props }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [records, setRecords] = useState([]);
    const [intersectionTags, setIntersectionTags] = useState([]);
    const dataProvider = useDataProvider();

    const handleOpen = useCallback(() => {
        setOpen(true);
        dispatch(fetchStart());
        dataProvider.getMany(resource, {
            ids: selectedIds
        })
            .then(result => {
                const items = result.data;

                const tags = items.map(gasMeterPad => gasMeterPad.tags)
                const intersectionTags = intersectionBy.apply(null, [...tags, "id"])

                setIntersectionTags(intersectionTags);
                setRecords(items);
            })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
    },
        [selectedIds, dispatch]
    );


    const handleClose = () => {
        setOpen(false);
        setRecords([])
        setIntersectionTags([])
    };

    const handleUpdateManyInOneRequest = (indexedTags, newTags) => {
        const items = records.map(pad => {
            const prevTags = pad.tags;
            const resultTags = [
                ...newTags,
                ...prevTags.map(tag => {
                    const target = newTags.find(indexedTag => indexedTag.id === tag.id)
                    console.log(target)
                    const result = target ? target : !intersectionTags.some(intersectioned => intersectioned.id === tag.id) && tag
                    return result ? {
                        id: result.id,
                    } : null;
                }).filter(el => el)
            ];
            return ({
                id: pad.id,
                tags: resultTags
            });
        });
        return updateManyTags({ records: items, resource }).then(result => {

            const data = Object.values(result.data).map(el => el.id)
            dispatch(refresh())
            notify('ra.notification.updated', 'info', { smart_count: data.length })
            return { data };
        })
            .catch(error => {
                notify(error)
            });
    }

    const handleSubmit = values => {

        const indexedTags = values.tags
        const newTags = values.tagsIds.map(id => ({ id }));

        dispatch(fetchStart())

        // handleUpdateMany(indexedDisablePeriods, newDisablePeriods)
        handleUpdateManyInOneRequest(indexedTags, newTags)
            .finally(() => {
                dispatch(fetchEnd())

            });

        setOpen(false);
        setRecords([])
        setIntersectionTags([])
    };

    return (
        <>
            <RaButton
                onClick={handleOpen}
                // disabled={loading}
                label='ra.action.tags_update'
                className={`ra-bulk-disable-button ${className}`}
                key="button"
            >
                <LabelIcon />
            </RaButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
                <DialogTitle id="form-dialog-title">Редактирование меток</DialogTitle>
                {/* <DialogContent>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Метки обновятся обновятся на установленные для выбранных площадок
                </Typography>
            </DialogContent> */}
                <Form
                    initialValues={{
                        tags: intersectionTags,
                        tagsIds: intersectionTags.map(tag => tag.id)
                    }}
                    onSubmit={handleSubmit}
                    mutators={{ ...arrayMutators }}
                    subscription={defaultSubscription}
                    // key={version} // support for refresh button
                    keepDirtyOnReinitialize
                    render={formProps => (
                        <form>
                            <Box padding="0 24px">
                                {intersectionTags && <ReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: resource }}
                                    label="resources.GasMeterPad.fields.tags"
                                    fullWidth>
                                    <AutocompleteArrayInput options={{ suggestionsContainerProps: { style: { zIndex: 10000000000000 } } }} />
                                </ReferenceArrayInput>}
                            </Box>
                            <DialogActions>
                                <Button onClick={handleClose}>
                                    Отмена
                                </Button>
                                <Button onClick={formProps.handleSubmit} color="primary">
                                    Обновить
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </>);
};

export default BulkUpdateTagsButton;