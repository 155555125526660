import { URI_PROMETHEUS } from '../../appsettings';


const fetchRequest = (query, variables, uri) => {
  const url = `${uri}/api/v1/${query}${variables ? `{${variables}}`: ""}`;
  return fetch(
      url,
      {
          method: "GET",
          credentials: "omit",
          headers: {
              'Content-Type': 'application/json',
          },
          referrerPolicy: "no-referrer-when-downgrade",
          mode: "cors"
      }
  )
  .then(response => {
      if (!response.ok) {
          throw response
      };
      return response.json();
  })
  .then( ({data}) => {
    return data;
  })
  .catch(error => {
      throw Error(error)
  })
}



const prometheusFetchClient = (query, variables, parseResponse, uri = URI_PROMETHEUS) => {
    if (Array.isArray(query)) {
        return Promise.all(query.map(q => fetchRequest(q, variables, uri)))
        .then( result => {
            return parseResponse(result)
        })
    } else {
        return fetchRequest(query, variables, uri).then( result => {
          return parseResponse(result)
      })
    }
}

export default prometheusFetchClient;