import { BulkExportButton } from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import React from 'react';
import { can } from "../../abilityProvider";



const CounterpartyBulkActionButtons = props => (
    <>
        {can('getList', 'Counterparty') && <BulkExportButton {...props} />}
        {can('remove', 'Counterparty') && <BulkRemoveButton {...props} />}
    </>
)



export default CounterpartyBulkActionButtons;