import React, { cloneElement, Children, isValidElement } from 'react';
import PropTypes from 'prop-types';
// import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useResourceDefinition, useShowContext, ShowActions as DefaultActions, TitleForRecord } from 'react-admin';
import Remove from './Remove';



export const ShowView = (props) => {
    const {
        actions,
        aside,
        breadcrumbs,
        children,
        classes: classesOverride,
        className,
        component: Content,
        title,
        ...rest
    } = props;

    const classes = useStyles(props);

    const {
        basePath,
        defaultTitle,
        hasList,
        record,
        resource,
        version,
    } = useShowContext(props);
    const { hasEdit } = useResourceDefinition(props);

    if (record && record.deleted) {
        return (
            <Remove
                resource={resource}
                basePath={basePath}
                record={record}
                title={title}
                defaultTitle={defaultTitle}
                hasList={hasList}
                hasEdit={hasEdit}
            />
        );
    };

    const finalActions =
        typeof actions === 'undefined' && hasEdit ? (
            <DefaultActions />
        ) : (
            actions
        );

    if (!children) {
        return null;
    }
    return (
        <div
            className={classnames('show-page', classes.root, className)}
            {...sanitizeRestProps(rest)}
        >
            <TitleForRecord
                title={title}
                record={record}
                defaultTitle={defaultTitle}
            />
            <div className={classes.toolbar}>
                {breadcrumbs && cloneElement(breadcrumbs, {
                    basePath,
                    data: record,
                    hasList,
                    hasEdit,
                    resource,
                })}
                {finalActions && cloneElement(finalActions, {
                    basePath,
                    data: record,
                    hasList,
                    hasEdit,
                    resource,
                    style: { marginLeft: "auto" },
                    //  Ensure we don't override any user provided props
                    ...finalActions.props,
                })}
            </div>
            <div
                className={classnames(classes.main, {
                    [classes.noActions]: !finalActions,
                })}
            >
                <Content className={classes.card}>
                    {record && Children.map(children, (field, index) => (
                        isValidElement(field) ? cloneElement(field, {
                            key: index,
                            resource,
                            basePath,
                            record,
                            version,
                        }) : null
                    ))}
                </Content>
                {aside &&
                    cloneElement(aside, {
                        resource,
                        basePath,
                        record,
                        version,
                    })}
            </div>
        </div>
    );
};

ShowView.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    defaultTitle: PropTypes.any,
    hasEdit: PropTypes.bool,
    hasList: PropTypes.bool,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    record: PropTypes.object,
    resource: PropTypes.string,
    title: PropTypes.any,
    version: PropTypes.node,
};

ShowView.defaultProps = {
    classes: {},
    component: "div",
};

const useStyles = makeStyles(theme => (
    {
        root: {},
        main: {
            display: 'flex',
        },
        noActions: {
            marginTop: '1em',
        },
        card: {
            flex: '1 1 auto',
        },
        toolbar: {
            display: 'flex',
            justifyContent: "space-between",
            [theme.breakpoints.down('xs')]: {
                backgroundColor: theme.palette.background.paper,
            },
        },
    }
), { name: 'RaShow' });

const sanitizeRestProps = ({
    basePath = null,
    defaultTitle = null,
    hasCreate = null,
    hasEdit = null,
    hasList = null,
    hasShow = null,
    history = null,
    id = null,
    loaded = null,
    loading = null,
    location = null,
    match = null,
    options = null,
    permissions = null,
    ...rest
}) => rest;
