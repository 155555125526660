import React from 'react';
import Chip from '@material-ui/core/Chip';



const ContactChip = ({ className, classes: classesOverride, record, basePath, ...props }) => {

    if (!record) return null

    const label = `${record.name} <${record.email}>`

    return (
        <Chip label={label} className={className} {...props} />

    );
};

export default ContactChip;