import Icon from '../Icons/GRSOutput';
import GasDistributorStationOutputCreate from './GasDistributorStationOutputCreate';
import GasDistributorStationOutputEdit from './GasDistributorStationOutputEdit';
import GasDistributorStationOutputShow from './GasDistributorStationOutputShow';

const GasDistributorStationOutputIcon = Icon;


export {
    GasDistributorStationOutputIcon,
    GasDistributorStationOutputShow,
    GasDistributorStationOutputCreate,
    GasDistributorStationOutputEdit,
};
