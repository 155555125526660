import { MenuItemLink, UserMenu, useTranslate } from 'react-admin';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AUTH_TOKEN_COOKIE } from '../../appsettings';
import AvatarField from '../Fields/AvatarField';
import { SUBSCRIBE_ONLINE_USERS } from '../../dataProvider/Core/onlineUsersSubscription';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import decodeJwt from 'jwt-decode'
import { getCookie } from '../../utils';
import getOnlineUsers from '../../dataProvider/Core/getOnlineUsers';
import getProfile from '../../dataProvider/Core/getProfile';
import { makeStyles } from '@material-ui/core/styles';
import { setAccess } from '../../redux/access/actions';
import { setOnlineUsers } from '../../redux/onlineUsers/actions';
import { setUser } from '../../redux/user/actions';
import { useSubscription } from '@apollo/client';

const useStyles = makeStyles(theme => ({
    userPreview: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(2),
        minWidth: 256,
    },
    headerAvatar: {
        margin: -theme.spacing(0.5),
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.primary.light,
    },
}));

const ConfigurationMenu = (props) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            to="/Profile"
            primaryText={translate('ra.auth.user_menu')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
};




const MyUserMenu = ({ ...props }) => {
    const user = useSelector(state => state.user, shallowEqual);
    const onlineUsers = useSelector(state => state.onlineUsers, shallowEqual);
    const dispatch = useDispatch();
    const classes = useStyles();
    const translate = useTranslate();
    const onSubscriptionData = ({ client, subscriptionData: { data: { onlineUsers: { node, previousValues } } } }) => {
        if (node && node.online) {

            if (!onlineUsers.includes(node.id)) { dispatch(setOnlineUsers([...onlineUsers, node.id])) };
        } else {

            const filterArray = onlineUsers.filter(id => {
                const userId = node ? node.id : previousValues.id
                return id !== userId
            });
            dispatch(setOnlineUsers(filterArray));
        };
    };

    useSubscription(SUBSCRIBE_ONLINE_USERS, {
        client: apolloCoreClient,
        onSubscriptionData,
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const { data: { onlineUsers: _onlineUsers } } = await getOnlineUsers();
                const onlineUsersIds = _onlineUsers.map(user => user.id)
                dispatch(setOnlineUsers(onlineUsersIds));

            } catch (error) {
                console.log('error: ', error)
            }
        }
        fetchData();

        return () => {
            dispatch(setOnlineUsers([]));
        };

    }, [dispatch]);

    useEffect(() => {

        async function fetchData() {
            try {
                const { userId } = decodeJwt(getCookie(AUTH_TOKEN_COOKIE));
                const { data: { data: profile } } = await getProfile({ where: { authId: userId } });

                dispatch(setUser(profile));
                dispatch(setAccess(profile));



            } catch (error) {
                console.log('error: ', error)
            }
        }
        fetchData();

        return () => {
            dispatch(setUser(null));
            dispatch(setAccess(null));
        };

    }, [dispatch, setUser, setAccess]);

    return (
        <UserMenu icon={<AvatarField record={user} size="small" className={classes.headerAvatar} />} {...props}>
            {user && (
                <div className={classes.userPreview}>
                    <AvatarField record={user} size="large" />
                    <Typography variant="body1" style={{ marginTop: 8 }}>{user.name}</Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>{translate(`resources.User.enums.role.${user.role}`, { _: user.role })}</Typography>
                </div>)}
            {user && (<ConfigurationMenu record={user} />)}
        </UserMenu>
    );
}


export default MyUserMenu;



