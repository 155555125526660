import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import EnumChip from '../Fields/EnumChip';
import OnlineChip from '../Fields/OnlineChip';
import AccessChip from './AccessChip';
import { can } from "../../abilityProvider";
import { DateField, ChipField } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },

    },

    chipBanned: {
        color: theme.palette.getContrastText(theme.palette.error.main),
        backgroundColor: theme.palette.error.main,
    },
}));

const UserBanner = ({ classes: classesOverride, className, ...props }) => {
    const classes = useStyles({ classes: classesOverride });
    const { record, resource } = props;
    if (!record) { return null }
    const name = record.name;
    const description = record.description;
    const id = record.id;
    const banned = record.banned;


    return (
        <Card className={`${classes.root} ${className}`}>
            <div style={{ padding: 16 }}>
                <Grid container spacing={4}>
                    <Grid item xs >
                        {can('getOne', 'User', 'name') && <Typography variant="h6">{name}</Typography>}
                        {can('getOne', 'User', 'description') && <Typography variant="subtitle1" gutterBottom>{description}</Typography>}
                        {can('getOne', 'User', 'online') && <OnlineChip record={record} />}
                        <AccessChip record={record} />
                        {banned && <Chip className={`${classes.chip} ${classes.chipBanned}`} label="Заблокирован" size="small" />}
                    </Grid>
                    <Grid item >
                        {/*<div style={{display: 'flex', flexDirection: 'column'}}>*/}
                        <EnumChip {...props} source="role" />
                        {/*<Typography variant="subtitle2" color="textSecondary" style={{fontSize: 12, textAlign: 'center'}}>Был в сети в</Typography>*/}
                        {/*<DateField record={record}  source="lastLogin" options={dateTimeOptions}/>*/}
                        {/*</div>*/}
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};



export default UserBanner;