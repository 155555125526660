import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { REPORT_DESPATCH_FRAGMENT } from '../typeDefs';

export const CREATE_REPORT_DESPATCH = gql`
    mutation createReportDespatch($input: ReportDespatchCreateInput!) {
        data: createReportDespatch(input: $input) {
            item {...ReportDespatchFragment}
        }
    }
    ${REPORT_DESPATCH_FRAGMENT}
`

export default (args) => {
    return apolloClient.mutate({
        mutation: CREATE_REPORT_DESPATCH,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    })
};
