import React from 'react';
import { useForm } from 'react-final-form';
import EnumInput from '../../Inputs/EnumInput'


const TypeInput = (props) => {
    const form = useForm();

    const handleChangeType = (event) => {
        const value = event.target.value
        form.change('controller.configuration', null)
        form.change('controller.meta', null)
    }

    return (
        <EnumInput {...props} onChange={handleChangeType} />
    );
};

export default TypeInput;