import { SET_ACCESS } from './actions';



export default (previousState=null, { type, payload }) => {
    if (type === SET_ACCESS) {

        if (!payload) return false;
        const { accessResource, counterparty } = payload;

        const accessByUser = accessResource || (counterparty && counterparty.accessResource);
        const accessByResource = {
            GasRegion: {
                GasRegion: true,
                GasDistrict: true,
                GasMeterPad: true,
                GasMeterUnit: true,
                GasConsumer: true,
                GasDistributorOrganization: true,
                GasDistributorStation: true,
                GasDistributorStationOutput: true,
            },
            GasDistrict: {
                GasRegion: false,
                GasDistrict: true,
                GasMeterPad: true,
                GasMeterUnit: true,
                GasConsumer: true,
                GasDistributorOrganization: true,
                GasDistributorStation: true,
                GasDistributorStationOutput: true,
            },
            GasMeterPad: {
                GasRegion: false,
                GasDistrict: false,
                GasMeterPad: true,
                GasMeterUnit: true,
                GasConsumer: false,
                GasDistributorOrganization: false,
                GasDistributorStation: false,
                GasDistributorStationOutput: false,
            },
            GasMeterUnit: {
                GasRegion: false,
                GasDistrict: false,
                GasMeterPad: false,
                GasMeterUnit: true,
                GasConsumer: false,
                GasDistributorOrganization: false,
                GasDistributorStation: false,
                GasDistributorStationOutput: false,
            },
            GasConsumer: {
                GasRegion: false,
                GasDistrict: false,
                GasMeterPad: true,
                GasMeterUnit: true,
                GasConsumer: true,
                GasDistributorOrganization: false,
                GasDistributorStation: false,
                GasDistributorStationOutput: false,
            },
        };

        const accessResources = accessByResource[accessByUser];

        return accessResources ? {
            accessResource,
            accessResources,
         } : false;
    }
    return previousState;
}