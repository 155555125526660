import React, { memo } from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
import sanitizeFieldRestProps from './sanitizeFieldRestProps';



export const BooleanField = ({ className, classes: classesOverride, emptyText, source, record, valueLabelTrue, valueLabelFalse, ...props }) => {
    const translate = useTranslate();
    if (!record) return null;
    const value = get(record, source);
    const valueText = (value === true)
        ? valueLabelTrue
        : (value === false)
            ? valueLabelFalse
            : emptyText;

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeFieldRestProps(props)}
        >
            {translate(valueText, { _: valueText })}
        </Typography>
    );
};

BooleanField.defaultProps = {
    addLabel: true,
    valueLabelTrue: 'ra.boolean.true',
    valueLabelFalse: 'ra.boolean.false',
};


const Memorized = memo(BooleanField)

Memorized.defaultProps = {
    addLabel: true
};

export default Memorized;
