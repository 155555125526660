import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContentFilter from '@material-ui/icons/FilterList';
import classnames from 'classnames';
import { Button } from 'react-admin';


const useStyles = makeStyles(
    {
        root: { display: 'inline-block' },
    },
    { name: 'ManualSubmitFilterButton' }
);

const ManualSubmitFilterButton = ({
    formRef,
    classes: classesOverride,
    className,
    resource,
    ...props
}) => {


    const classes = useStyles(props);


    const handleFilter = useCallback(event => {
        event.preventDefault();
        (typeof formRef.current.requestSubmit === 'function') ? formRef.current.requestSubmit() : formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    }, [formRef]);


    return (
        <div className={classnames(classes.root, className)} {...props}>
            <Button
                className="apply-filter"
                label="ra.action.apply_filter"
                disableElevation
                variant="contained"
                type="submit"
                onClick={handleFilter}
            >
                <ContentFilter />
            </Button>
        </div>
    );
};

export default ManualSubmitFilterButton;
