import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckboxBlank from '../../Icons/CheckboxBlank';
import { useInput, FieldTitle, InputHelperText } from 'react-admin';
import sanitizeRestProps from '../sanitizeRestProps';
import ColorPicker from './ColorPicker';



const ColorInput = ({
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onFocus,
    onChange,
    options,
    resource,
    source,
    validate,
    variant = 'filled',
    inputProps,
    className,
    ...rest
}) => {

    const {
        id,
        input,
        isRequired,
        meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        resource,
        source,
        validate,
        ...rest,
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const handleFocus = (event) => {
        if (!Boolean(event.relatedTarget?.parentElement?.id === "color-input-popover")) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = ({ hex }) => {
        input.onChange(hex)
    };

    return (
        <>
            <TextField
                id={id}
                {...input}
                className={className}
                variant={variant}
                error={!!(touched && error)}
                helperText={
                    (touched && error) || helperText ? (
                        <InputHelperText
                            touched={touched}
                            error={error}
                            helperText={helperText}
                        />
                    ) : null
                }
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
                margin={margin}
                onFocus={handleFocus}
                InputProps={{
                    ...inputProps,
                    startAdornment: (
                        <InputAdornment position="start">
                            <CheckboxBlank style={{ color: input.value }} />
                        </InputAdornment>
                    ),
                }}
                {...options}
                {...sanitizeRestProps(rest)}
            />
            <Popover
                id="color-input-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <ColorPicker
                    color={input.value}
                    onChange={handleChange}
                />
            </Popover>
        </>
    );

};


export default ColorInput;
