import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';


const useChipStyles = makeStyles(theme => ({

}));

const GasMeterPadProviderChip = ({ className, classes: classesOverride, record, source, resource, hideWhen, ...props}) => {
    const classes = useChipStyles({ classes: classesOverride });

    if (!record) return null;

    const isProvider = record.isProvider

    if (isProvider === hideWhen) return null;

    const label = isProvider ? "Поставщик" : "Потребитель";

    return (
        <Chip
            size="small"
            classes={{root:classes.root}}
            label={label}
        />
    );
};

export default GasMeterPadProviderChip;