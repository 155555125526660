import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, SaveButton, ReferenceInput, ReferenceArrayInput, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import BooleanInput from '../Inputs/BooleanInput';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import GasMeterPadBanner from './GasMeterPadBanner';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";
import DisabledPeriodsArrayInput from './GasMeterPadDisabledPeriodsArrayInput';
import GasDistributorStationOutputSuggestionItem, { gasDistributorStationOutputOptionText } from './GasDistributorStationOutputSuggestionItem';
import { matchTrue } from '../../utils';



const gasDistributorStationOutputFilterToQuery = searchText => ({ searchWithGDS: searchText });

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));

const GasMeterPadToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasMeterPad') && <SaveButton />}
            {can("update", "GasMeterPad") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasMeterPad') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const GasMeterPadEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasMeterPadBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasMeterPadToolbar />}>
                    {can('update', 'GasMeterPad', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'GasMeterPad', 'description') && <TextInput source="description" multiline fullWidth />}
                    {can('update', 'GasMeterPad', 'addrManual') && <TextInput source="addrManual" fullWidth />}
                    {can('update', 'GasMeterPad', 'aisId') && <TextInput source="aisId" />}
                    {can('update', 'GasMeterPad', 'gasDistrict', 'GasDistrict') && <ReferenceInput source="gasDistrict.id" reference="GasDistrict" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasDistrict" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasMeterPad', 'gasConsumer', 'GasConsumer') && <ReferenceInput source="gasConsumer.id" reference="GasConsumer" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasConsumer" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasMeterPad', 'gasDistributorOrganization', 'GasDistributorOrganization') && <ReferenceInput source="gasDistributorOrganization.id" reference="GasDistributorOrganization" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasDistributorOrganization" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasMeterPad', 'gasDistributorStationOutput', 'GasDistributorStationOutput') && <ReferenceInput
                        source="gasDistributorStationOutput.id"
                        filterToQuery={gasDistributorStationOutputFilterToQuery}
                        reference="GasDistributorStationOutput"
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={100}
                        label="resources.GasMeterPad.fields.gasDistributorStationOutput"
                        allowEmpty
                        emptyText="ra.message.no" >
                        <AutocompleteInput optionText={gasDistributorStationOutputOptionText} suggestionItem={<GasDistributorStationOutputSuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasMeterPad', 'gasMeterUnits', 'GasMeterUnit') && <ReferenceArrayInput source="gasMeterUnitsIds" reference="GasMeterUnit" filter={{ gasMeterPad: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasMeterUnits" fullWidth>
                        <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                    </ReferenceArrayInput>}
                    {can('update', 'GasMeterPad', 'disabled') && <BooleanInput source="disabled" />}
                    {can('update', 'GasMeterPad', 'isProvider') && <BooleanInput source="isProvider" />}
                    {can('update', 'GasMeterPad', 'disabledPeriods') && <DisabledPeriodsArrayInput source="disabledPeriods" />}
                    {can('update', 'GasMeterPad', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.reports" fullWidth />}
                    {can('update', 'GasMeterPad', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasMeterPadEdit