import React from 'react';
import { FilterButton, sanitizeListRestProps, useListContext } from 'react-admin';
import ManualSubmitFilterForm from './ManualSubmitFilterForm';
import ManualSubmitFilterButton from './ManualSubmitFilterButton';
import FilterButtonWithSave from './FilterButtonWithSave';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(
    {
        button: {},
        form: {},
    },
    { name: 'ManualSubmitFilter' }
);



const Filter = props => {
    const classes = useStyles(props);
    const {
        resource,
        // showFilter,
        hideFilter,
        setFilters,
        displayedFilters,
        filterValues,
    } = useListContext(props);


    const renderButton = () => {
        const {
            classes: classesOverride,
            context,
            children,
            variant,
            formRef,
            ...rest
        } = props;

        return (
            <>
                <ManualSubmitFilterButton
                    className={classes.button}
                    resource={resource}
                    formRef={formRef}
                    {...sanitizeListRestProps(rest)}
                />
                {/* <FilterButton
                    className={classes.button}
                    resource={resource}
                    filters={React.Children.toArray(children)}
                    showFilter={showFilter}
                    displayedFilters={displayedFilters}
                    filterValues={filterValues}
                    {...sanitizeListRestProps(rest)}
                /> */}
                <FilterButtonWithSave
                    className={classes.button}
                    filters={React.Children.toArray(children)}
                    {...sanitizeListRestProps(rest)}
                />
            </>
        );
    };

    const renderForm = () => {
        const { classes: classesOverride, context, children, formRef, ...rest } = props;

        return (
            <ManualSubmitFilterForm
                className={classes.form}
                resource={resource}
                filters={React.Children.toArray(children)}
                hideFilter={hideFilter}
                displayedFilters={displayedFilters}
                initialValues={filterValues}
                setFilters={setFilters}
                formRef={formRef}
                {...sanitizeListRestProps(rest)}
            />
        );
    };

    return props.context === 'button' ? renderButton() : renderForm();
};

Filter.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    context: PropTypes.oneOf(['form', 'button']),
};

export default Filter;
