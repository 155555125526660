import buildGraphQLProvider from 'ra-data-graphql-simple';
import apolloCoreClient from './apolloCoreClient';
import decorate from './decorate';
import buildQuery from './builtQuery';
import { __schema as schema } from './schema';

const buildCoreProvider = () => buildGraphQLProvider({
    client: apolloCoreClient,
    buildQuery: buildQuery,
    introspection: { schema },
});


export const knownResources = ['GasRegion', 'GasDistrict', 'BalanceRegion', 'GasDistributorOrganization', 'GasDistributorStation', 'GasDistributorStationOutput', 'GasConsumer', 'GasMeterPad', 'GasMeterUnit', 'GasMeterPoint', 'GasQualityCertificate', 'Dashboard', 'Report', 'Tag', 'Counterparty', 'Access', 'User', 'Profile', 'History', 'TrashItem', 'GasQualityCertificateGasDistributorStation', 'GasMeterConfiguration', "ControllerConfiguration", "Preference"];

export const dataProvider = (type, resource, params) => {
    return buildCoreProvider().then(async (provider) => {

        const { params: paramsD } = await decorate(type, resource, params);

        return provider(type, resource, paramsD)
    }
    )
};

export default {
    dataProvider,
    knownResources,
};