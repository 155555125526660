import React, { forwardRef } from 'react';
//import { Link } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
    menuItem: {
        minWidth: 256,
    },
    avatar: {
      
    },
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} underline="none" {...props} />);

const UserApp = forwardRef(({ name, description, url, avatar, ...props}, ref) => {
    const classes = useStyles();
    return (
        <MenuItem
            component={LinkRef}
            ref={ref}
            href={url}
            target="_blank"
            rel="noopener"
            color="inherit"
            classes={{root: classes.menuItem}}
            {...props}
        
        >
            <ListItemAvatar>
            <Avatar variant="rounded" alt={name} src={avatar}>
                {name.charAt(0).toUpperCase()}
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} />
        </MenuItem>
    )
});


export default UserApp;


