import React from 'react';
import Show from './Show';
import Documentation from '../Documentation';
import { routes, sidebar } from '../Documentation/config';





const AboutAppShow = (props) => {
    
    return (
        <Show /*aside={<Versions />}*/ title="resources.About.name" {...props}>
            <Documentation configuration={{ routes, sidebar }} />
        </Show>
    );
};

export default AboutAppShow;