import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';

import Snackbar from '@material-ui/core/Snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'

function ReactReport(props) {
  
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const [statusloader, setStatusloader] = useState('');
  const [module, setModule] = useState(null);

  const handleUpdateStatusProcess = (open, message, notifyStatus) => {
 
    if(message) {
      if(!notifyStatus) {        
        setStatusloader(message);
      } else {
        notify(message, notifyStatus);
      }
    } 

    if(open != null) { 
      setOpen(open)
    }
  }

  useEffect(() => {
    if(!module) {
      import(`./Templates/${props.record.template}`).then(module => {
        setModule({ def: module.default });        
      });
    }  
    return () => {};
  }, [module]);

  const { def: Component } = module || {};

 

  return (
      <div>
        {Component && <Component onUpdateStatusProcess={handleUpdateStatusProcess} {...props} />}
        <Snackbar          
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}                        
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <div id="message-id" style={{ width: '500px', position: 'relative' }}>                    
              <CircularProgress style={{ color: 'white', marginLeft: '235px' }} size={30} top={10} thickness={10} />
              <div style={{ width: '100%', textAlign: 'center' }}>Подготовка документа. Ожидайте</div>
              {(!Array.isArray(statusloader) ? [statusloader] : statusloader).map((x,i) => (<div key={'st'+i} style={{ width: '100%', textAlign: 'center' }}>{x}</div>))}          
            </div>
          }
        />   
      </div>
    )
}

/*
class ReactReport extends omponent {  
    
  constructor(props){
      super(props) 
      this.state = {
        open: false,
        statusloader: ''
      }
  } 

  componentDidMount() {
    import(`./Templates/${this.props.record.template}`).then(module => this.setState({ module: module.default }));   
  }

  handleUpdateStatusProcess(open, message, notifyStatus) {
    if(open != null) { 
      this.setState({ open });
    }
  
    if(message) {
      if(!notifyStatus) {        
        this.setState({ statusloader: message });
      } else {
        //showNotification(message, notifyStatus);
      }
    } 
  }

  render() {
    const { module: Component } = this.state 
    //key={this.props.record.template}
    return(
      <div>
        {Component && <Component onUpdateStatusProcess={this.handleUpdateStatusProcess.bind(this)} {...this.props} />}
        <Snackbar          
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.open}                        
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <div id="message-id" style={{ width: '500px', position: 'relative' }}>                    
              <CircularProgress style={{ color: 'white', marginLeft: '235px' }} size={30} top={10} thickness={10} />
              <div style={{ width: '100%', textAlign: 'center' }}>Подготовка документа. Ожидайте</div>
              {(!Array.isArray(this.state.statusloader) ? [this.state.statusloader] : this.state.statusloader).map((x,i) => (<div key={'st'+i} style={{ width: '100%', textAlign: 'center' }}>{x}</div>))}          
            </div>
          }
        />   
      </div>
    )
  }
}
*/

export default ReactReport
