import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { useReferenceManyFieldController, useTranslate, ListContextProvider, ResourceContextProvider } from 'ra-core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReferenceManyToolbar from './ReferenceManyToolbar';
import sanitizeFieldRestProps from '../sanitizeFieldRestProps';
import useEffectShowStorageProps from "../../../controller/preferences/useEffectShowStorageProps";
import useStateShowStorageProps from "../../../controller/preferences/useStateShowStorageProps";

// import ShowPropsPreferences from "../../Layout/details/ShowPropsPreferences";


export const ReferenceManyField = props => {
    const {
        basePath,
        children,
        filter,
        page = 1,
        perPage,
        record,
        reference,
        resource,
        sort,
        source,
        target,
    } = props;

    if (React.Children.count(children) !== 1) {
        throw new Error(
            '<ReferenceManyField> only accepts a single child (like <Datagrid>)'
        );
    }

    const storageProps = useStateShowStorageProps(props)

    const controllerProps = useReferenceManyFieldController({
        basePath,
        filter,
        page,
        perPage,
        record,
        reference,
        resource,
        sort,
        source,
        target,
        ...storageProps,
    });

    useEffectShowStorageProps(props, controllerProps)


    return (
        <ResourceContextProvider value={reference}>
            <ListContextProvider value={controllerProps}>
                {/* <ShowPropsPreferences resource={resource} /> */}
                <ReferenceManyFieldView {...props} {...controllerProps} />
            </ListContextProvider>
        </ResourceContextProvider>
    );
};

ReferenceManyField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    filter: PropTypes.object,
    label: PropTypes.string,
    perPage: PropTypes.number,
    record: PropTypes.any,
    reference: PropTypes.string.isRequired,
    resource: PropTypes.string,
    sortBy: PropTypes.string,
    sortByOrder: PropTypes.oneOf(['ASC', 'DESC']),
    source: PropTypes.string.isRequired,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    target: PropTypes.string.isRequired,
};

ReferenceManyField.defaultProps = {
    filter: {},
    perPage: 25,
    sort: { field: 'id', order: 'DESC' },
    source: 'id',
    addLabel: true,
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
}));

export const ReferenceManyFieldView = props => {

    const classes = useStyles();
    const translate = useTranslate();
    const { basePath, children, pagination, reference, className, label, filters, actions, addLabel, ...rest } = props;

    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
            {(filters || actions) && (
                <ReferenceManyToolbar
                    {...props}
                    filters={filters}
                    actions={actions}
                    basePath={basePath}
                    reference={reference}
                />
            )}
            <Paper >
                {cloneElement(Children.only(children), {
                    ...sanitizeFieldRestProps(rest),
                    basePath,
                    resource: reference,
                })}
                {pagination &&
                    props.total !== undefined &&
                    cloneElement(pagination, rest)}
            </Paper>
        </div>
    );
};

ReferenceManyFieldView.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element,
    className: PropTypes.string,
    currentSort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.any,
    ids: PropTypes.array,
    loaded: PropTypes.bool,
    pagination: PropTypes.element,
    reference: PropTypes.string,
    setSort: PropTypes.func,
};

export default ReferenceManyField;
