import gql from 'graphql-tag'
import apolloDatabusClient from './apolloDatabusClient';



export const WRITE_GAS_QUALITY_CERTIFICATE = gql`
    mutation writeGasQualityCertificate( $input: WriteManyTagsInput! ) {
        data: writeManyTags( input: $input ) {
            id { ns serial id}
            value
            quality
            timestamp
            timestampSrv
            utcOffset
        }
    }
`;

export default ({ writeTags, ...args}) => {
    
    return apolloDatabusClient.mutate({
        mutation: WRITE_GAS_QUALITY_CERTIFICATE,
        variables: {
            input: {
                writeTags
            }
        },
        onError: (error) => {
            alert(error)
        }
    })
     

};