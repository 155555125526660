
import { useEffect } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import { useListContext } from 'react-admin'
import { LOCAL_STORAGE } from '../../appsettings';



const useListPropsPreferencesLocal = () => {
    const { resource, perPage, setPerPage, currentSort, setSort, filterValues, displayedFilters, setFilters } = useListContext()
    const listPropsContext = { perPage, currentSort, filterValues, displayedFilters };
    const listPropsContextSignature = JSON.stringify(listPropsContext)

    useEffect(() => {
        const storageProps = JSON.parse(localStorage.getItem(LOCAL_STORAGE)) || {};
        const listPropsPreferences = get(storageProps, [resource, "list", "props"], {});
        set(storageProps, [resource, "list", "props"], { ...listPropsPreferences, perPage, currentSort, filterValues, displayedFilters });
        localStorage.setItem(LOCAL_STORAGE, JSON.stringify(storageProps));
    }, [listPropsContextSignature])

    useEffect(() => {
        const storageProps = JSON.parse(localStorage.getItem(LOCAL_STORAGE)) || {};
        const listPropsPreferences = get(storageProps, [resource, "list", "props"], listPropsContext);
        if (isEqual(listPropsContext, listPropsPreferences)) return
        setPerPage(listPropsPreferences.perPage)
        setSort(listPropsPreferences.currentSort.field, listPropsPreferences.currentSort.order)
        setFilters(listPropsPreferences.filterValues, listPropsPreferences.displayedFilters)
    }, [])
}

export default useListPropsPreferencesLocal