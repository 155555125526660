import { ReferenceArrayInput, ReferenceInput, SaveButton, SimpleForm, TextInput, Toolbar, required } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import CancelButton from '../Buttons/CancelButton';
import CardBlankLayout from '../Layout/CardBlankLayout';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import GasDistributorStationOutputBanner from './GasDistributorStationOutputBanner';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import React from 'react';
import RemoveButton from '../Buttons/RemoveButton';
import ReportReferenceArrayInput from "../Report/ReportReferenceArrayInput";
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';

const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));

const GasDistributorStationOutputToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {/* {can('update', 'GasDistributorStationOutput') && <SaveButton />} */}
            {can("update", "GasDistributorStationOutput") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasDistributorStationOutput') && <RemoveButton redirect="/GasDistributorStation" />}
            <CancelButton />
        </Toolbar>
    );
}

const GasDistributorStationOutputEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasDistributorStationOutputBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasDistributorStationOutputToolbar />}>
                    {can('update', 'GasDistributorStationOutput', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'GasDistributorStationOutput', 'description') && <TextInput source="description" multiline fullWidth />}
                    {can('update', 'GasDistributorStationOutput', 'aisId') && <TextInput source="aisId" />}
                    {can('update', 'GasDistributorStationOutput', 'gasMeterPads', 'GasMeterPad') && <ReferenceArrayInput source="gasMeterPadsIds" reference="GasMeterPad" filter={{ gasDistributorStationOutput: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStationOutput.fields.gasMeterPads" fullWidth>
                        <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                    </ReferenceArrayInput>}
                    {can('update', 'GasDistributorStationOutput', 'gasDistributorStation', 'GasDistributorStation') && <ReferenceInput source="gasDistributorStation.id" reference="GasDistributorStation" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStationOutput.fields.gasDistributorStation" allowEmpty emptyText="ra.message.no">
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasDistributorStationOutput', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasDistributorStationOutput" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStationOutput.fields.reports" fullWidth />}
                    {can('update', 'GasDistributorStationOutput', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasDistributorStationOutput" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStationOutput.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasDistributorStationOutputEdit