import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getCookie } from '../../utils';
import { PACKAGE_VERSION, PACKAGE_VERSION_COOKIE } from '../../appsettings';



const useStyles = makeStyles(theme => ({
   root:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
        marginRight: 8,
    } 
}));

const ChangelogIcon = ({children, ...props}) => {
    const classes = useStyles();
    const version = getCookie(PACKAGE_VERSION_COOKIE);
    
return version !== PACKAGE_VERSION ? (<span className={classes.root}>🔥</span>) : null;
}

export default ChangelogIcon;