import Icon from '../Icons/Certificate';
import GasQualityCertificateList from './GasQualityCertificateList';
import GasQualityCertificateCreate from './GasQualityCertificateCreate';
import GasQualityCertificateEdit from './GasQualityCertificateEdit';
import GasQualityCertificateShow from './GasQualityCertificateShow';

const GasQualityCertificateIcon = Icon;


export {
    GasQualityCertificateIcon,
    GasQualityCertificateList,
    GasQualityCertificateShow,
    GasQualityCertificateCreate,
    GasQualityCertificateEdit,
};
