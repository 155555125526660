import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRedirect, useRefresh, fetchStart, fetchEnd, Button } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import refreshDeviceStatus from '../../../dataProvider/Analitics/refreshDeviceStatus';



export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    onSave,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
}));

const DeviceStatusRefreshButton = ({
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    redirect: redirectTo,
    ...rest }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);
    const dbId = record.dbId;


    const handleClick = useCallback(() => {
        setLoading(true);
        dispatch(fetchStart());

        refreshDeviceStatus({
            dbId,
        }).then((response) => {
            notify('ra.notification.status_refreshed', 'info', { smart_count: 1 });
            redirect(redirectTo, basePath, record.id, record);
            refresh();
        })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    },
        [dbId, dispatch]
    );


    return (
        <Button
            onClick={handleClick}
            disabled={loading}
            label='ra.action.refresh'
            className={`ra-refresh-button ${className}`}
            key="button"
            {...sanitizeRestProps(rest)}
        >
            <RefreshIcon />
        </Button>
    );

};

export default DeviceStatusRefreshButton;