import { useEffect, useState } from 'react'
import moment from 'moment'
import { orderBy } from 'lodash';
import { URI_PROMETHEUS } from '../../appsettings';
import useDeepCompareEffect from 'use-deep-compare-effect'


export default function({filter, version}) {

  const [state, setState] = useState({
    data: undefined,
    error: undefined,
    loading: true,
  })

  useDeepCompareEffect(() => {

    if (!(filter && filter.dbIds && filter.dbIds.length)) return;
  
    const filterPrometheus = `{dbid=~"${filter.dbIds.join('|')}"}`
    const start = moment().subtract(30, "days").unix();
    const end = moment().unix();

    fetch(
      //`${URI_PROMETHEUS}/api/v1/query?query=askug_convertItem_convert_lastupdate${filterPrometheus}`,
      `${URI_PROMETHEUS}/api/v1/query_range?query=askug_convertItem_convert_lastupdate${filterPrometheus}&start=${start}&end=${end}&step=4838`,
      {
        method: "GET",
        credentials: "omit",
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: "no-referrer-when-downgrade",
        mode: "cors"
      }
    )
    .then(response => {
      if (!response.ok) { throw response };
      return response.json();
    })
    .then(({data: {result}}) => {
      
      setState({
        data: result.map(r => ({ metric : r.metric, value: orderBy(r.values, [0], "desc")[0] || [] })),
        loading: false,
        error: undefined,
      });
    })
    .catch( error => {
      setState({
        data: undefined,
        loading: false,
        error,
      });
    })

  }, [filter, version])

  return state;
}
