import Icon from '@material-ui/icons/History';
import GasMeterPointEdit from './GasMeterPointEdit';
import GasMeterPointShow from './GasMeterPointShow';

const GasMeterPointIcon = Icon;


export {
    GasMeterPointIcon,
    GasMeterPointShow,
    GasMeterPointEdit,
};
