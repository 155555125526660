import React, { Fragment } from 'react';
import List from '../Layout/list/List';
import ListAction from '../Layout/list/ListActionsWithSelectColumnsButton';
import useGasRegionExporter from './useGasRegionExporter';
import GasRegionBulkActionButtons from './GasRegionBulkActionButtons';
import GasRegionFilter from './GasRegionFilter';
import GasRegionDatagrid, { useDefaultDatagridColumns } from './GasRegionDatagrid';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';




const GasRegionList = (props) => {
    const exporter = useGasRegionExporter()
    const manualSubmitForm = React.useRef(null);
    const columns = useDefaultDatagridColumns()

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/2GasRegion" target="_blank" />
            <List
                {...props}
                actions={<ListAction columns={columns} />}
                bulkActionButtons={<GasRegionBulkActionButtons />}
                exporter={exporter}
                filters={<GasRegionFilter formRef={manualSubmitForm} />}
            >
                <GasRegionDatagrid columns={columns} />
            </List>
        </Fragment>
    )
};

GasRegionList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default GasRegionList;