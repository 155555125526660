import React from 'react';
import { ShowContextProvider, useShowController, ChipField, TabbedShowLayout, Tab, TextField, DateField, Pagination, ReferenceManyField as RaReferenceManyField, ReferenceArrayField, SingleFieldList } from 'react-admin';
import { ShowView } from '../Layout/ShowView';
import CardShowLayout from '../Layout/CardShowLayout';
import CardBlankLayout from '../Layout/CardBlankLayout';
import SimpleShowLayout from '../Layout/SimpleShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import NestedField from '../Fields/NestedField';
import EnumField from '../Fields/EnumField';
import PhoneField from '../Fields/PhoneField';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import UserBanner from './UserBanner';
import { can } from "../../abilityProvider";
// import { makeStyles } from '@material-ui/core/styles';
import { dateTimeOptions } from '../../utils';



// const useStyles = makeStyles(theme => ({

// }));

const UserShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const accessResource = controllerProps.record && controllerProps.record.accessResource;

    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView title={<TitleForRecord source="name" {...props} />} {...props} {...controllerProps} >
                <UserBanner />

                <SimpleShowLayout>
                    {can('getOne', 'User', 'name') && <TextField source="name" />}
                    {can('getOne', 'User', 'description') && <TextField source="description" />}
                    {can('getOne', 'User', 'position') && <TextField source="position" />}
                    {can('getOne', 'User', 'role') && <EnumField source="role" />}
                    {can('getOne', 'User', 'login') && <TextField source="login" />}
                    {can('getOne', 'User', 'email') && <TextField source="email" />}
                    {can('getOne', 'User', 'phone') && <PhoneField source="phone" />}
                    {can('getOne', 'User', 'phone') && <PhoneField source="phoneContact" />}
                    {can('getOne', 'User', 'counterparty') && <NestedField source="counterparty" reference="Counterparty" >
                        <TextField source="name" />
                    </NestedField>}
                </SimpleShowLayout>

                <SimpleShowLayout label="resources.Counterparty.tabs.access">
                    {can('getOne', 'User', 'access') && accessResource && <ReferenceArrayField source="accessIds" reference={accessResource} label={`resources.User.fields.access${accessResource}`}>
                        <SingleFieldList linkType="show">
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>}
                </SimpleShowLayout>

                <Separator action={(<div ref={showMoreButton} ></div>)} />

                <MoreContent showMoreButton={showMoreButton}>
                    <CardShowLayout>
                        <TextField source="id" />
                        {can('getOne', 'User', 'name') && <NestedField source="createdBy" reference="User" >
                            <TextField source="name" />
                        </NestedField>}
                        <DateField source="createdAt" options={dateTimeOptions} />
                        <DateField source="updatedAt" options={dateTimeOptions} />
                    </CardShowLayout>
                </MoreContent>

                {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
                {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                    <CardBlankLayout>
                        <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                            <ReferenceHistoryDatagrid source="User" />
                        </RaReferenceManyField>
                    </CardBlankLayout>
                </MoreContent>}
            </ShowView>
        </ShowContextProvider>
    );
};

export default UserShow;