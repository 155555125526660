import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '../../authPrivider/getAccessToken';
import { URI_REPORT_DESPATCH } from '../../appsettings';
import { omitDeep } from './utils';



const httpLink = createHttpLink({
  uri: URI_REPORT_DESPATCH
})

const authLink = setContext(async (_, { headers }) => {

  const token = await getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation);
});

export default new ApolloClient({
  link: from([
    authLink,
    cleanTypenameLink,
    httpLink
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});
