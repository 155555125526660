import React from 'react';
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';
import {
    useTranslate,
    useResourceContext,
    getFieldLabelTranslationArgs,
} from 'react-admin';

import { usePreferences } from '@react-admin/ra-preferences';
import useColumnsPreferences from '../../../controller/preferences/useColumnsPreferences';

const SelectColumnsMenu = ({
    columns,
    ...rest
}) => {

    const translate = useTranslate();
    const resource = useResourceContext();
    const [columnsPref, setColumnsPref] = useColumnsPreferences(resource, Object.keys(columns))

    const addColumn = (event) => {
        setColumnsPref(
            Object.keys(columns).filter(
                name => name === event.target.name || columnsPref.includes(name)
            )
        );
    };

    const removeColumn = (event) => {
        // always leave at least one column
        if (columnsPref.length > 1) {
            setColumnsPref(
                columnsPref.filter(name => name !== event.target.name)
            );
        }
    };

    return (
        <Box mx={2} my={1} {...rest}>
            {Object.keys(columns).map(name => (
                <div key={name}>
                    <FormControlLabel
                        disabled={columns[name].props.alwaysOn}
                        control={
                            <Checkbox
                                name={name}
                                checked={columnsPref.includes(name)}
                                onClick={
                                    columnsPref.includes(name)
                                        ? removeColumn
                                        : addColumn
                                }
                                color="primary"
                            />
                        }
                        label={translate(
                            ...getFieldLabelTranslationArgs({
                                label: columns[name].props.label,
                                resource,
                                source: name,
                            })
                        )}
                    />
                </div>
            ))}
        </Box>
    );
};

export default SelectColumnsMenu;
