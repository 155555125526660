import gql from 'graphql-tag'
import { apolloAuthClient } from './apolloAuthClient';



const REFRESH_TOKEN_MUTATION = gql`
  mutation refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {       
       token,
       refreshToken,      
    }
  }
`;

export default (refreshToken) => {
    return apolloAuthClient.mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        variables: { refreshToken },
        onError: (error) => {
            alert(error)
        },
    });
};
