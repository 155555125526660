import Icon from '@material-ui/icons/History';
// import DashboardList from './DashboardList';
import DashboardShow from './DashboardShow';

const DashboardIcon = Icon;


export {
    DashboardIcon,
    // DashboardList,
    DashboardShow,
};
