import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, ReferenceInput, ReferenceArrayInput, required } from 'react-admin';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import ReportReferenceArrayInputWithDefault from '../Report/ReportReferenceArrayInputWithDefault';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";
import { useSelector, shallowEqual } from 'react-redux';



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

const GasDistrictCreate = props => {
    // const classes = useStyles();
    const user = useSelector(state => state.user, shallowEqual);
    const accessRegion = ['GasRegion'].includes(user && user.accessResource || '');

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'GasDistrict', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasDistrict', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasDistrict', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'GasDistrict', 'gasRegion', 'GasRegion') && accessRegion && <ReferenceInput source="gasRegion.id" reference="GasRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistrict.fields.gasRegion" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasDistrict', 'gasMeterPads', 'GasMeterPad') && <ReferenceArrayInput source="gasMeterPadsIds" reference="GasMeterPad" filter={{ gasDistrict: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistrict.fields.gasMeterPads" fullWidth>
                    <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                </ReferenceArrayInput>}
                {can('create', 'GasDistrict', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "GasDistrict" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistrict.fields.reports" fullWidth />}
                {can('create', 'GasDistrict', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasDistrict" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistrict.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasDistrictCreate;