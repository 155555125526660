import React, { Fragment } from 'react';
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import useGasDistrictExporter from './useGasDistrictExporter';
import GasDistrictBulkActionButtons from './GasDistrictBulkActionButtons';
import GasDistrictFilter from './GasDistrictFilter';
import GasDistrictDatagrid, { useDefaultDatagridColumns } from './GasDistrictDatagrid';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';



const GasDistrictList = (props) => {
    const exporter = useGasDistrictExporter()
    const manualSubmitForm = React.useRef(null);
    const columns = useDefaultDatagridColumns()

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/3GasDistrict" target="_blank" />
            <List
                {...props}
                actions={<ListActions columns={columns} />}
                bulkActionButtons={<GasDistrictBulkActionButtons />}
                exporter={exporter}
                filters={<GasDistrictFilter formRef={manualSubmitForm} />}
            >
                <GasDistrictDatagrid columns={columns} />
            </List>
        </Fragment>
    )
};

GasDistrictList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default GasDistrictList;