import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { CONTACT_FRAGMENT } from '../typeDefs';


export const GET_ONE_CONTACT = gql`
    query getOneContact( $where: ContactWhereUniqueInput! ) {
        data: getOneContact(where: $where) {
            item {...ContactFragment}
        }
    }
    ${CONTACT_FRAGMENT}
`

export default (args) => {
    return apolloClient.query({
        query: GET_ONE_CONTACT,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    })
};
