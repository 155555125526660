import React from 'react';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { SelectArrayInput, ReferenceArrayInput, SearchInput } from 'react-admin';
import CheckboxGroupInput from '../Inputs/CheckboxGroupInput';



const useStyles = makeStyles(theme => ({
    referenceArrayInput: {
        marginTop: 8,
        marginBottom: 4,
    },
}));





const getFilterResource = (resource, accessResource) => {

    if (resource === accessResource) return undefined;
    switch (resource) {
        case 'GasRegion':
            return undefined;
            break;
        case 'GasDistrict':
            return { reference: 'GasRegion', filter: "gasRegion" };
            break;
        case 'GasConsumer':
            return undefined;
            break;
        case 'GasMeterPad':
            return { reference: 'GasConsumer', filter: "gasConsumer" };
            break;
        case 'GasMeterUnit':
            return { reference: 'GasMeterPad', filter: "gasMeterPad" };
            break;
        default:
            return undefined;
    };
}

const AccessReferenceArrayInput = ({ className, ...props }) => {
    const classes = useStyles();
    const { values } = useFormState();
    let filter = {};

    const accessResource = props.record && props.record.accessResource;
    const referenceResource = values && values.accessResource;
    const searchFilter = values && values.q;
    const filterResource = getFilterResource(referenceResource, accessResource);
    const filterValue = filterResource && values[filterResource.filter];


    if (filterValue && filterValue.length) {
        filter[filterResource.filter] = { id_in: filterValue }
    }

    if (searchFilter) {
        filter.q = searchFilter
    }


    if (!referenceResource) return null;

    return (
        <>
            <SearchInput resource="Counterparty" source="q" className={className} resettable />
            {filterResource && <ReferenceArrayInput resource="Counterparty" source={`${filterResource.filter}`} reference={filterResource.reference} sort={{ field: 'name', order: 'ASC' }} perPage={100} label={`resources.Counterparty.fields.filter${filterResource.reference}`} fullWidth>
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>}
            <ReferenceArrayInput {...props} reference={referenceResource} filter={filter} sort={{ field: 'name', order: 'ASC' }} perPage={100} fullWidth>
                <CheckboxGroupInput optionText="name" row={false} />
            </ReferenceArrayInput>
        </>


    );
};

export default AccessReferenceArrayInput;



