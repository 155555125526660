import React, { Fragment } from 'react';
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import useGasDistributorOrganizationExporter from './useGasDistributorOrganizationExporter';
import GasDistributorOrganizationBulkActionButtons from './GasDistributorOrganizationBulkActionButtons';
import GasDistributorOrganizationFilter from './GasDistributorOrganizationFilter';
import GasDistributorOrganizationDatagrid, { useDefaultDatagridColumns } from './GasDistributorOrganizationDatagrid';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';





const GasDistributorOrganizationList = (props) => {
    const exporter = useGasDistributorOrganizationExporter()
    const manualSubmitForm = React.useRef(null)
    const columns = useDefaultDatagridColumns()

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/8GasDistributorOrganization" target="_blank" />
            <List
                {...props}
                actions={<ListActions columns={columns} />}
                bulkActionButtons={<GasDistributorOrganizationBulkActionButtons />}
                exporter={exporter}
                filters={<GasDistributorOrganizationFilter formRef={manualSubmitForm} />}
            >
                <GasDistributorOrganizationDatagrid columns={columns} />
            </List>
        </Fragment>
    )
};

GasDistributorOrganizationList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default GasDistributorOrganizationList;