import { DateField, Pagination, ReferenceManyField as RaReferenceManyField, SearchInput, TextField } from 'react-admin';
import DividerField from '../Fields/DividerField';
import CardBlankLayout from "../Layout/CardBlankLayout";
import CardShowLayout from '../Layout/CardShowLayout';
import { GasConsumerReferenceDatagrid } from '../GasConsumer/GasConsumerDatagrid';
import GasDistributorOrganizationBanner from './GasDistributorOrganizationBanner';
import { GasMeterPadReferenceDatagrid } from '../GasMeterPad/GasMeterPadDatagrid';
import MoreContent from '../Layout/MoreContent';
import NestedField from '../Fields/NestedField';
import NestedGridField from '../Fields/NestedGridField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import ReferenceListLinkButton from '../Buttons/ReferenceListLinkButton';
import ReferenceManyActions from '../Fields/referenceManyField/ReferenceManyActions';
import ReferenceManyField from '../Fields/referenceManyField/ReferenceManyField';
import ReferenceManyFilter from '../Fields/referenceManyField/ReferenceManyFilter';
import ReportCard from "../Report/ReportCard";
import Separator from '../Layout/Separator';
import { Show } from '../Layout/Show';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';

const ReferenceManyFilters = (props) => {
    return (
        <ReferenceManyFilter {...props}>
            <SearchInput source="q" id={`${props.reference}.q`} alwaysOn />
        </ReferenceManyFilter>
    )
}

const ReferenceManyActionButtons = (props) => {
    return (
        <ReferenceManyActions >
            <ReferenceListLinkButton {...props} />
        </ReferenceManyActions>
    )
}

const GasDistributorOrganizationShow = props => {
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasDistributorOrganizationBanner />

            <CardShowLayout>
                {can('getOne', 'GasDistributorOrganization', 'addrManual') && <TextField source="addrManual" />}
            </CardShowLayout>

            {can('getOne', 'GasDistributorOrganization', 'reports', 'Report') && <NestedGridField source="reports" label="resources.GasDistributorOrganization.fields.reports" >
                <ReportCard />
            </NestedGridField>}
            {can('getOne', 'GasDistributorOrganization', 'gasConsumers', 'GasConsumer') &&
                <ReferenceManyField
                    reference="GasConsumer"
                    target="gasDistributorOrganizationByGasMeterPadReference"
                    label="resources.GasDistributorOrganization.fields.gasConsumers"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={100}
                    pagination={<Pagination />}
                >
                    <GasConsumerReferenceDatagrid />
                </ReferenceManyField>}
            {can('getOne', 'GasDistributorOrganization', 'gasMeterPads', 'GasMeterPad') &&
                <ReferenceManyField
                    reference="GasMeterPad"
                    target="gasDistributorOrganizationReference"
                    label="resources.GasDistributorOrganization.fields.gasMeterPads"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={100}
                    pagination={<Pagination />}
                >
                    <GasMeterPadReferenceDatagrid />
                </ReferenceManyField>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {can('getOne', 'GasDistributorOrganization', 'aisId') && <TextField source="aisId" />}
                    <DividerField />
                    {can('getOne', 'GasDistributorOrganization', 'id') && <TextField source="id" />}
                    {can('getOne', 'GasDistributorOrganization', 'createdBy', 'User') && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {can('getOne', 'GasDistributorOrganization', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'GasDistributorOrganization', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>

            {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
            {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="GasDistributorOrganization" />
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>}
        </Show>
    );
};

export default GasDistributorOrganizationShow;