import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReferenceArrayInput } from "react-admin";
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import QuickCreateButton from "../Buttons/QuickCreateButton";
import { GasMeterPointQuickCreateForm } from '../GasMeterPoint/GasMeterPointCreate';



const matchTrue = () => true;

const useStyles = makeStyles({
    container: {
        display: "inline-block",
        marginTop: 8,
        marginBottom: 4,
    }
});

const ReferenceArrayGasMeterPointInput = props => {
    const classes = useStyles();

    return (
        <>
            <ReferenceArrayInput {...props} classes={{container: classes.container}}>
                <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue}/>
            </ReferenceArrayInput>
            <QuickCreateButton {...props} isArray>
                <GasMeterPointQuickCreateForm />
            </QuickCreateButton>
        </>
    );
};

export default ReferenceArrayGasMeterPointInput;
