import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';
import useInfo from './useInfo';
import { useVersion } from 'react-admin';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';





import { PACKAGE_NAME, PACKAGE_VERSION } from '../../appsettings';

const Version = (props) => {

    const version = useVersion();
    const appQueries = useMemo(() => [
        {
            name: "Core",
            query: apolloCoreClient.query({ query: gql`query { Info { name, version } }`, fetchPolicy: 'network-only'}),
        }
    ], [version])
    const {data, loading, error} = useInfo(appQueries)

    const portal = { name: PACKAGE_NAME, version: PACKAGE_VERSION }
    const core = data && data[0] && data[0].data.Info && data[0].data.Info && {name: data[0].data.Info.name, version: data[0].data.Info.version}

    const infoApps = [portal, core]
    
    return (
        <div>
            {infoApps.map((info, index) => (
                info ?
                (<div key={index} >
                    <Typography variant="h5">{info.name}</Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>{info.version}</Typography>
                </div>) : null
            ))}
        </div> 
    );
};

export default Version;