import { cloneElement, useEffect } from 'react';
import isEmpty from "lodash/isEmpty";
import useColumnsPreferences from './useColumnsPreferences';
import { useResourceContext } from 'ra-core';



const useSelectedColumnsPreferences = (columns) => {

    const resource = useResourceContext();
    const [columnsFromPrefs, setColumnsFromPref, loaded] = useColumnsPreferences(resource, Object.keys(columns));

    useEffect(() => {
        if (loaded && !isEmpty(columns) && !columnsFromPrefs) {
            setColumnsFromPref(
                Object.keys(columns).filter(name => !columns[name].props.hideDefault)
            );
        }
    }, [loaded, columns, columnsFromPrefs, setColumnsFromPref]); // eslint-disable-line

    const columnList = columnsFromPrefs
        ? columnsFromPrefs.filter(name => !!columns[name])
        : Object.keys(columns).filter(name => !columns[name].props.hideDefault);

    const selectedColumns = columnList.map(name => {
        const field = columns[name]
        const { alwaysOn, hideDefault, column, ...props } = field.props
        const sanitizedField = { ...field, props }
        return cloneElement(sanitizedField, { key: column || props.source })
    })

    return selectedColumns;
};



export default useSelectedColumnsPreferences;
