import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment'
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { useDataProvider, useNotify, useRefresh, Button as RaButton, useUnselectAll, sanitizeEmptyValues, fetchStart, fetchEnd, } from 'react-admin';
import DisabledPeriodsArrayInput from './GasMeterPadDisabledPeriodsArrayInput';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import intersectionBy from 'lodash/intersectionBy'
import { groupBy } from 'lodash';
import updateManyDisabledPeriods from "../../dataProvider/Core/updateManyDisabledPeriods";


const GAS_METER_PAD_TEMPLATE = `{
    id
    disabled
    disabledPeriods {
        id
        dateFrom
        dateTo
        description
    }
}`

export const GET_MANY_GAS_METER_PADS = gql`
    query allGasMeterPads($filter: GasMeterPadFilter) {
    items: allGasMeterPads(filter: $filter) ${GAS_METER_PAD_TEMPLATE}
  }
`



const useStyles = makeStyles(theme => ({
    mt3: { marginTop: 16 },
}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const GasMeterPadBulkUpdateDisabledPeriodsButton = ({ selectedIds, basePath, record, resource, className, redirect: redirectTo, ...props  }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dispatch = useDispatch();   
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [gasMeterPads, setGasMeterPads] = useState([]);
    const [intersectionDisabledPeriods, setIntersectionDisabledPeriods] = useState([]);
    const dataProvider = useDataProvider();

    const handleOpen = useCallback(() => {
            setOpen(true);
            dispatch(fetchStart());
            apolloCoreClient.query({
                query: GET_MANY_GAS_METER_PADS,
                variables: { 
                    filter: {
                        id_in: selectedIds
                    }
                }
            })
            .then(result => {   

                const gasMeterPads = result.data.items;
                const gasMeterPadsWithIndexedDisabledPeriods = gasMeterPads.map(gasMeterPad => {
                    const indexedDisabledPeriods = gasMeterPad.disabledPeriods.map(period => {

                        return {
                            ...period,
                            index: `${period.dateFrom}-${period.dateTo}`
                        }
                    }) 

                    return {
                        ...gasMeterPad,
                        disabledPeriods: indexedDisabledPeriods,
                    }

                });

                const gasMeterPadsDisabledPeriods = gasMeterPadsWithIndexedDisabledPeriods.map(gasMeterPad => gasMeterPad.disabledPeriods)
                const intersectionDisabledPeriods = intersectionBy.apply(null, [...gasMeterPadsDisabledPeriods, "index"])
            
                setIntersectionDisabledPeriods(intersectionDisabledPeriods);
                setGasMeterPads(gasMeterPadsWithIndexedDisabledPeriods);
            })
            .catch((error) => {
                notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
        },
        [selectedIds, dispatch]
    );


    const handleClose = () => {
        setOpen(false);
        setGasMeterPads([])
        setIntersectionDisabledPeriods([])
    };

    const handleUpdateMany = (indexedDisablePeriods, newDisablePeriods) => Promise.all(gasMeterPads.map( pad => {
        const prevDisablePeriods = pad.disabledPeriods;
        const resultDisabledPeriods = [
            ...newDisablePeriods,
            ...prevDisablePeriods.map( period => {
                const target = indexedDisablePeriods.find(indexedPeriod => indexedPeriod.index === `${period.dateFrom}-${period.dateTo}`)
                const result = target ? target : !intersectionDisabledPeriods.some( intersectioned => intersectioned.index === `${period.dateFrom}-${period.dateTo}`) && period
                return result ? {
                    dateFrom: result.dateFrom,
                    dateTo: result.dateTo,
                    description: result.description,
                    id: result.id
                } : null;
            }).filter(el => el)
        ];
        return dataProvider.update('GasMeterPad', {
            id: pad.id,
            data: {
                disabledPeriods: resultDisabledPeriods
            },
        });
    }))


        .then( result => {
            const data = result.reduce(
                (acc, { data }) => [...acc, data.id],
                []
            );
            notify('ra.notification.updated', 'info', { smart_count: data.length})
            return { data };
        })
        .catch( error => {
            notify(error)
        });

    const handleUpdateManyInOneRequest = (indexedDisablePeriods, newDisablePeriods) => {
        const pads = gasMeterPads.map(pad => {
            const prevDisablePeriods = pad.disabledPeriods;
            const resultDisabledPeriods = [
                ...newDisablePeriods,
                ...prevDisablePeriods.map(period => {
                    const target = indexedDisablePeriods.find(indexedPeriod => indexedPeriod.index === `${period.dateFrom}-${period.dateTo}`)
                    const result = target ? target : !intersectionDisabledPeriods.some(intersectioned => intersectioned.index === `${period.dateFrom}-${period.dateTo}`) && period
                    return result ? {
                        dateFrom: result.dateFrom,
                        dateTo: result.dateTo,
                        description: result.description,
                        id: result.id
                    } : null;
                }).filter(el => el)
            ];
            return ({
                id: pad.id,
                data: {
                    disabledPeriods: resultDisabledPeriods
                }
            });
        });
        return updateManyDisabledPeriods(pads).then( result => {

            const data = Object.values(result.data).map(el => el.id)
            notify('ra.notification.updated', 'info', { smart_count: data.length})
            return { data };
        })
            .catch( error => {
                notify(error)
            });
    }
  
    const handleSubmit = values => {


        const indexedDisablePeriods = values.disabledPeriods.filter(p => p.index)
        const newDisablePeriods = values.disabledPeriods.filter(p => !p.index)

        dispatch(fetchStart())

        // handleUpdateMany(indexedDisablePeriods, newDisablePeriods)
        handleUpdateManyInOneRequest(indexedDisablePeriods, newDisablePeriods)
            .finally(()=> dispatch(fetchEnd()));

        setOpen(false);
        setGasMeterPads([])
        setIntersectionDisabledPeriods([])
    };


 
    




    return (
    <>
        <RaButton
            onClick={handleOpen}
            // disabled={loading}
            label='ra.action.disable'
            className={`ra-bulk-disable-button ${className}`}
            key="button"
        >
            <SaveIcon/>
        </RaButton>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
            <DialogTitle id="form-dialog-title">Обновление периодов отключения площадок</DialogTitle>
            <DialogContent>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Текущие периоды отключения обновятся на установленные для выбранных площадок
                </Typography>
            </DialogContent>
            <Form
                initialValues={{ disabledPeriods: intersectionDisabledPeriods.map(period => ({
                    dateFrom: period.dateFrom,
                    dateTo: period.dateTo,
                    description: "",
                    index: period.index,
                })) }}
                onSubmit={handleSubmit}
                mutators={{ ...arrayMutators }}
                subscription={defaultSubscription}
                // key={version} // support for refresh button
                keepDirtyOnReinitialize
                render={formProps => (
                <form>
                    <Box padding="0 24px">
                        <DisabledPeriodsArrayInput resource="GasMeterPad" source="disabledPeriods" />
                    </Box>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Отмена
                        </Button>
                        <Button onClick={formProps.handleSubmit} color="primary">
                            Обновить
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
        </Dialog>
    </>);
};

export default GasMeterPadBulkUpdateDisabledPeriodsButton;