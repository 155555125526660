import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Chip from '@material-ui/core/Chip';
import get from 'lodash/get';

const useStyles = makeStyles(theme => ({

    chip: ({ record }) => {
        const color = get(record, "color")
        const backgroundColor = color ? color : theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
        return {
            color: theme.palette.getContrastText(backgroundColor),
            backgroundColor: backgroundColor,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.08),
            }
        }
    }
}));

const SelectedItem = ({ className, classes: classesOverride, label, record, onDelete, ...props }) => {
    const classes = useStyles({ record, classes: classesOverride });
    if (!record) return null;
    const text = record.name;

    return (
        <Chip
            classes={{ root: classes.root }}
            className={classes.chip}
            label={text}
            onDelete={onDelete}
        />
    );
};

export default SelectedItem;
