import { Admin, ExportButton, Pagination, Resource } from 'react-admin';
import { BalanceRegionCreate, BalanceRegionEdit, BalanceRegionIcon, BalanceRegionList, BalanceRegionShow } from './components/BalanceRegion';
import { CounterpartyCreate, CounterpartyEdit, CounterpartyIcon, CounterpartyList, CounterpartyShow } from './components/Counterparty';
import { DashboardIcon, DashboardShow } from './components/Dashboard';
import { GasConsumerCreate, GasConsumerEdit, GasConsumerIcon, GasConsumerList, GasConsumerShow } from './components/GasConsumer';
import { GasDistributorOrganizationCreate, GasDistributorOrganizationEdit, GasDistributorOrganizationIcon, GasDistributorOrganizationList, GasDistributorOrganizationShow } from './components/GasDistributorOrganization';
import { GasDistributorStationCreate, GasDistributorStationEdit, GasDistributorStationIcon, GasDistributorStationList, GasDistributorStationShow } from './components/GasDistributorStation';
import { GasDistributorStationOutputCreate, GasDistributorStationOutputEdit, GasDistributorStationOutputIcon, GasDistributorStationOutputShow } from './components/GasDistributorStationOutput';
import { GasDistrictCreate, GasDistrictEdit, GasDistrictIcon, GasDistrictList, GasDistrictShow } from './components/GasDistrict';
import { GasMeterPadCreate, GasMeterPadEdit, GasMeterPadIcon, GasMeterPadList, GasMeterPadShow } from './components/GasMeterPad';
import { GasMeterPointEdit, GasMeterPointIcon, GasMeterPointShow } from './components/GasMeterPoint';
import { GasMeterUnitCreate, GasMeterUnitEdit, GasMeterUnitIcon, GasMeterUnitList, GasMeterUnitShow } from './components/GasMeterUnit';
import { GasQualityCertificateCreate, GasQualityCertificateEdit, GasQualityCertificateIcon, GasQualityCertificateList, GasQualityCertificateShow } from './components/GasQualityCertificate';
import { GasRegionCreate, GasRegionEdit, GasRegionIcon, GasRegionList, GasRegionShow } from './components/GasRegion';
import { HistoryIcon, HistoryList, HistoryShow } from './components/History';
import React, { useEffect, useState } from 'react';
import { ReportIcon, ReportShow } from './components/Report';
import { ReportDespatchIcon, ReportDespatchCreate, ReportDespatchList, ReportDespatchShow, ReportDespatchEdit } from './components/ReportDespatch';
import { TagCreate, TagEdit, TagIcon, TagList, TagShow } from './components/Tag';
import { TrashItemIcon, TrashItemList } from './components/TrashItem';
import { UserCreate, UserEdit, UserIcon, UserList, UserShow } from './components/User';

import LoginPage from './components/Auth/Login';
import Main from './components/Main';
import { MyLayout } from './components/Layout';
import { can } from "./abilityProvider";
import access from './redux/access/reducer';
import { authProvider } from './authPrivider';
import customRoutes from './customRoutes';
import { dataProviderFactory } from './dataProvider/dataProviderFactory';
import { i18nProvider } from './i18n/i18nProvider';
import onlineUsers from './redux/onlineUsers/reducer';
import { theme } from './defaultTheme';
import user from './redux/user/reducer';

ExportButton.defaultProps = {
  maxResults: 5000
}

Pagination.defaultProps = {
  rowsPerPageOptions: [10, 25, 50, 100]
}

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const onMount = () => {
      dataProviderFactory().then(dataProvider => {
        setDataProvider(() => dataProvider);
        setLoading(false);
      });
    }

    onMount();

    return () => {
      setDataProvider(null);
    };

  }, []);

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <Admin
      disableTelemetry
      theme={theme}
      layout={MyLayout}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      dashboard={Main}
      customReducers={{ user, onlineUsers, access }}
      customRoutes={customRoutes}
    >
      {permissions => [
        <Resource name="GasRegion"
          list={can('getList', 'GasRegion') && GasRegionList}
          create={can('create', 'GasRegion') && GasRegionCreate}
          edit={can('update', 'GasRegion') && GasRegionEdit}
          show={can('getOne', 'GasRegion') && GasRegionShow}
          icon={GasRegionIcon}
          options={{ menuGroup: "resources" }}
        />,
        <Resource name="GasDistrict"
          list={can('getList', 'GasDistrict') && GasDistrictList}
          create={can('create', 'GasDistrict') && GasDistrictCreate}
          edit={can('update', 'GasDistrict') && GasDistrictEdit}
          show={can('getOne', 'GasDistrict') && GasDistrictShow}
          icon={GasDistrictIcon}
          options={{ menuGroup: "resources" }}
        />,
        <Resource name="GasConsumer"
          list={can('getList', 'GasConsumer') && GasConsumerList}
          create={can('create', 'GasConsumer') && GasConsumerCreate}
          edit={can('update', 'GasRegion') && GasConsumerEdit}
          show={can('getOne', 'GasConsumer') && GasConsumerShow}
          icon={GasConsumerIcon}
          options={{ menuGroup: "resources" }}
        />,
        <Resource name="GasMeterPad"
          list={can('getList', 'GasMeterPad') && GasMeterPadList}
          create={can('create', 'GasMeterPad') && GasMeterPadCreate}
          edit={can('update', 'GasMeterPad') && GasMeterPadEdit}
          show={can('getOne', 'GasMeterPad') && GasMeterPadShow}
          icon={GasMeterPadIcon}
          options={{ menuGroup: "resources" }}
        />,
        <Resource name="GasMeterUnit"
          list={can('getList', 'GasMeterUnit') && GasMeterUnitList}
          create={can('create', 'GasMeterUnit') && GasMeterUnitCreate}
          edit={can('update', 'GasMeterUnit') && GasMeterUnitEdit}
          show={can('getOne', 'GasMeterUnit') && GasMeterUnitShow
          } icon={GasMeterUnitIcon}
          options={{ menuGroup: "resources" }}
        />,
        <Resource name="GasMeterPoint"
          edit={can('update', 'GasMeterPoint') && GasMeterPointEdit}
          show={can('getOne', 'GasMeterPoint') && GasMeterPointShow}
          icon={GasMeterPointIcon} />,

        <Resource name="GasDistributorOrganization"
          list={can('getList', 'GasDistributorOrganization') && GasDistributorOrganizationList}
          create={can('create', 'GasDistributorOrganization') && GasDistributorOrganizationCreate}
          edit={can('update', 'GasDistributorOrganization') && GasDistributorOrganizationEdit}
          show={can('getOne', 'GasDistributorOrganization') && GasDistributorOrganizationShow}
          icon={GasDistributorOrganizationIcon}
          options={{ menuGroup: "more" }}
        />,
        <Resource name="GasDistributorStation"
          list={can('getList', 'GasDistributorStation') && GasDistributorStationList}
          create={can('create', 'GasDistributorStation') && GasDistributorStationCreate}
          edit={can('update', 'GasDistributorStation') && GasDistributorStationEdit}
          show={can('getOne', 'GasDistributorStation') && GasDistributorStationShow}
          icon={GasDistributorStationIcon}
          options={{ menuGroup: "more" }}
        />,
        <Resource name="BalanceRegion"
          list={can('getList', 'BalanceRegion') && BalanceRegionList}
          create={can('create', 'BalanceRegion') && BalanceRegionCreate}
          edit={can('update', 'BalanceRegion') && BalanceRegionEdit}
          show={can('getOne', 'BalanceRegion') && BalanceRegionShow}
          icon={BalanceRegionIcon}
          options={{ menuGroup: "more" }}
        />,
        <Resource name="GasDistributorStationOutput"
          create={can('create', 'GasDistributorStationOutput') && GasDistributorStationOutputCreate}
          edit={can('update', 'GasDistributorStationOutput') && GasDistributorStationOutputEdit}
          show={can('getOne', 'GasDistributorStationOutput') && GasDistributorStationOutputShow}
          icon={GasDistributorStationOutputIcon}
          options={{ menuGroup: "more" }}
        />,
        <Resource name="GasQualityCertificate"
          list={can('getList', 'GasQualityCertificate') && GasQualityCertificateList}
          create={can('create', 'GasQualityCertificate') && GasQualityCertificateCreate}
          edit={can('update', 'GasQualityCertificate') && GasQualityCertificateEdit}
          show={can('getOne', 'GasQualityCertificate') && GasQualityCertificateShow}
          icon={GasQualityCertificateIcon}
          options={{ menuGroup: "more" }}
        />,
        <Resource name="Counterparty"
          icon={CounterpartyIcon}
          list={can('getList', 'Counterparty') && CounterpartyList}
          show={can('getOne', 'Counterparty') && CounterpartyShow}
          create={can('create', 'Counterparty') && CounterpartyCreate}
          edit={can('update', 'Counterparty') && CounterpartyEdit}
          options={{ menuGroup: "access" }}
        />,
        <Resource name="User"
          icon={UserIcon}
          list={can('getList', 'User') && UserList}
          show={can('getOne', 'User') && UserShow}
          create={can('create', 'User') && UserCreate}
          edit={can('update', 'User') && UserEdit}
          options={{ menuGroup: "access" }}
        />,
        <Resource name="AuthUser" />,
        <Resource name="Tag"
          icon={TagIcon}
          list={can('getList', 'Tag') && TagList}
          show={can('getOne', 'Tag') && TagShow}
          create={can('create', 'Tag') && TagCreate}
          edit={can('update', 'Tag') && TagEdit}
          options={{ menuGroup: "utils" }} />,
        <Resource name="ReportDespatch"
          icon={ReportDespatchIcon}
          list={can('getList', 'ReportDespatch') && ReportDespatchList}
          show={can('getOne', 'ReportDespatch') && ReportDespatchShow}
          create={can('create', 'ReportDespatch') && ReportDespatchCreate}
          edit={can('update', 'ReportDespatch') && ReportDespatchEdit}
          options={{ menuGroup: "utils" }} />,
        <Resource name="Contact" />,
        <Resource name="History"
          list={can('getList', 'History') && HistoryList}
          show={can('getOne', 'History') && HistoryShow}
          icon={HistoryIcon}
          options={{ menuGroup: "utils" }} />,
        <Resource name="TrashItem"
          list={can('getList', 'TrashItem') && TrashItemList}
          icon={TrashItemIcon}
          options={{ menuGroup: "utils" }} />,

        <Resource name="Profile" />,
        <Resource name="MetricGasMeter" />,
        <Resource name="DeviceStatus" />,
        <Resource name="ControllerConfiguration" />,
        <Resource name="GasMeterConfiguration" />,
        <Resource name="Report"
          show={can('getOne', 'Report') && ReportShow}
          icon={ReportIcon}
          options={{ menuGroup: "utils" }}
        />,
        <Resource name="Dashboard"
          show={can('getOne', 'Dashboard') && DashboardShow}
          icon={DashboardIcon}
          options={{ menuGroup: "utils" }}
        />,

      ]}
    </Admin>
  );
};

export default App;