import React from 'react';
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import useBalanceRegionExporter from './useBalanceRegionExporter';
import BalanceRegionBulkActionButtons from './BalanceRegionBulkActionButtons';
import BalanceRegionFilter from './BalanceRegionFilter';
import BalanceRegionDatagrid, { useDefaultDatagridColumns } from './BalanceRegionDatagrid';




const BalanceRegionList = (props) => {
    const exporter = useBalanceRegionExporter()
    const manualSubmitForm = React.useRef(null)
    const columns = useDefaultDatagridColumns()

    return (
        <List
            {...props}
            actions={<ListActions columns={columns} />}
            bulkActionButtons={<BalanceRegionBulkActionButtons />}
            exporter={exporter}
            filters={<BalanceRegionFilter formRef={manualSubmitForm} />}
        >
            <BalanceRegionDatagrid columns={columns} />
        </List>
    )
};

BalanceRegionList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default BalanceRegionList;