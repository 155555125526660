import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';


const useStyles = makeStyles(theme => ({

    online: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.getContrastText(theme.palette.success.main),
    },
}));


const OnlineChip = ({record, ...props}) => {
    const classes = useStyles();
    const onlineUsers = useSelector(state => state.onlineUsers, shallowEqual);
    if (!record) return null;
    const isOnline = onlineUsers.includes(record.id);
    const label = isOnline ? "Онлайн" : `Оффлайн${record.lastLogin ? `, был в сети ${moment(record.lastLogin).format('L LT')}` : ''}`

  return <Chip
        size="small"
        classes={{root: classes.root}}
        className={isOnline ? classes.online : ""}
        label={label}
    />;
}

OnlineChip.defaultProps = {
    addLabel: false,
};

export default OnlineChip;