import gql from 'graphql-tag'
import apolloClient from '../apolloClient';



export const GET_ENUMS = gql`
  query getEnums($name: String!) {
    data: __type(name: $name) {
      name
      enumValues{
        name
        description
      }                          
    }
  }
`

export default (args) => {
    return apolloClient.query({
        query: GET_ENUMS,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    })
};
