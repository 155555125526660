import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, SaveButton, BooleanInput, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import EnumInput from '../Inputs/EnumInput'
import CancelButton from '../Buttons/CancelButton';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import ReportDespatchSchedulerInput from './ReportDespatchSchedulerInput';
import ContactReferenceArrayInput from '../Contact/ContactReferenceArrayInput';
import { can } from "../../abilityProvider";



const validateName = [required()]
const validateTo = [required()]
const validateReportsIds = [required()]
const validateCron = [required()]

const ReportDespatchEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <CardBlankLayout >
                <SimpleForm >
                    {can('update', 'ReportDespatch', 'name') && <TextInput source="name" validate={validateName} placeholder="Будет использовано в качестве темы электронного письма" fullWidth />}
                    {can('update', 'ReportDespatch', 'description') && <TextInput source="description" placeholder="Будет использовано в качестве текста электронного письма" multiline fullWidth />}
                    {can('update', 'ReportDespatch', 'to') && <ContactReferenceArrayInput source="toIds" label="resources.ReportDespatch.fields.to" validate={validateTo} reference="Contact" sort={{ field: 'name', order: 'ASC' }} />}
                    {can('update', 'ReportDespatch', 'cc') && <ContactReferenceArrayInput source="ccIds" label="resources.ReportDespatch.fields.cc" reference="Contact" sort={{ field: 'name', order: 'ASC' }} />}
                    {can('update', 'ReportDespatch', 'bcc') && <ContactReferenceArrayInput source="bccIds" label="resources.ReportDespatch.fields.bcc" reference="Contact" sort={{ field: 'name', order: 'ASC' }} />}
                    {can('update', 'ReportDespatch', 'reportsIds', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ external: true }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.ReportDespatch.fields.reports" validate={validateReportsIds} fullWidth />}
                    {can('update', 'ReportDespatch', 'rangeOption') && <EnumInput source="rangeOption" name="RangeOption" validate={validateCron} />}
                    {can('update', 'ReportDespatch', 'cron') && <ReportDespatchSchedulerInput source="cron" label="resources.ReportDespatch.fields.scheduler" validate={validateCron} />}
                    {can('update', 'ReportDespatch', 'enabled') && <BooleanInput source="enabled" initialValue={true} />}
                </SimpleForm>
            </CardBlankLayout>

        </Edit>
    );
};

export default ReportDespatchEdit