import removeToTrash from './removeToTrash';


export default ({ ids, resource }) => {
    return Promise.all(
        ids.map(id =>
            removeToTrash({resource, where: {id}})
        )
    ).then(results => {
        const data = results.reduce(
            (acc, { data }) => [...acc, data.id],
            []
        );
        return { data };
    });
};