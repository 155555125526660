import { Form } from 'react-final-form';
import React, { useCallback, useEffect } from 'react';
// import { FilterFormInput } from 'react-admin';
import FilterFormInput from './FilterFormInput';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        form: {
            marginTop: -theme.spacing(2),
            paddingTop: 0,
            display: 'flex',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            minHeight: theme.spacing(10),
            pointerEvents: 'none',
        },
        clearFix: { clear: 'right' },
    }),
    { name: 'ManualSubmitFilterForm' }
);

const sanitizeRestProps = ({
    active,
    dirty,
    dirtyFields,
    dirtyFieldsSinceLastSubmit,
    dirtySinceLastSubmit,
    error,
    errors,
    filterValues,
    form,
    handleSubmit,
    hasSubmitErrors,
    hasValidationErrors,
    invalid,
    modified,
    modifiedSinceLastSubmit,
    pristine,
    setFilters,
    submitError,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting,
    touched,
    valid,
    validating,
    values,
    visited,
    ...props
}) => props;


export const ManualSubmitFilterForm = ({
    classes = {},
    className,
    resource,
    margin,
    variant,
    filters,
    displayedFilters = {},
    hideFilter,
    initialValues,
    handleSubmit,
    formRef,
    ...rest
}) => {
    useEffect(() => {
        filters.forEach((filter) => {
            if (filter.props.alwaysOn && filter.props.defaultValue) {
                throw new Error(
                    'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
                );
            }
        });
    }, [filters]);

    const getShownFilters = () =>
        filters.filter(
            (filterElement) =>
                filterElement.props.alwaysOn ||
                displayedFilters[filterElement.props.source] ||
                typeof lodashGet(initialValues, filterElement.props.source) !==
                'undefined'
        );

    const handleHide = useCallback(
        event => hideFilter(event.currentTarget.dataset.key),
        [hideFilter]
    );

    return (
        <form
            className={classnames(className, classes.form)}
            {...sanitizeRestProps(rest)}
            onSubmit={handleSubmit}
            ref={formRef}
        >

            {getShownFilters().map((filterElement) => (
                <FilterFormInput
                    key={filterElement.props.source}
                    filterElement={filterElement}
                    handleHide={handleHide}
                    resource={resource}
                    margin={margin}
                    variant={variant}
                />
            ))}
            <div className={classes.clearFix} />
            <button type="submit" style={{ display: "none" }}>Submit</button>
        </form>
    );
};



ManualSubmitFilterForm.propTypes = {
    resource: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object,
    hideFilter: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
};

export const mergeInitialValuesWithDefaultValues = ({
    initialValues,
    filters,
}) => ({
    ...filters
        .filter(
            (filterElement) =>
                filterElement.props.alwaysOn && filterElement.props.defaultValue
        )
        .reduce(
            (acc, filterElement) =>
                lodashSet(
                    { ...acc },
                    filterElement.props.source,
                    filterElement.props.defaultValue
                ),
            {}
        ),
    ...initialValues,
});

const EnhancedFilterForm = props => {
    const { classes: classesOverride, initialValues, setFilters, ...rest } = props;
    const classes = useStyles(props);

    const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(props);


    const handleSubmit = values => {
        setFilters(values)
    }


    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={mergedInitialValuesWithDefaultValues}
            mutators={{ ...arrayMutators }}
            render={formProps => (
                <ManualSubmitFilterForm classes={classes} {...formProps} {...rest} />
            )}
        />
    );
};

export default EnhancedFilterForm;
