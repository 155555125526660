import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TodayIcon from '@material-ui/icons/Today';
import WarningIcon from '@material-ui/icons/Warning';
import OtherIcon from '@material-ui/icons/Report';
import CertificateIcon from '../Icons/Certificate';
import { makeStyles } from '@material-ui/core/styles';


const getIcon = status => {
    switch (status) {
        case 'Hourly':
            return (<ScheduleIcon />)
        case 'Daily':
            return (<TodayIcon />)
        case 'Event':
            return (<WarningIcon />)
        case 'Other':
            return (<OtherIcon />)
        case 'Summary':
            return (<OtherIcon />)
        case 'GasQualityCertificate':
            return (<CertificateIcon />)
        default:
            return (<OtherIcon />)
    }
};

const useStyles = makeStyles(theme => ({
    root: { margin: ".125rem" },
    Hourly: { color: "#2e7d32" },
    Daily: { color: "#1565c0" },
    Event: { color: "#ef6c00" },
    Other: { color: "#4527a0" },
    Summary: { color: "#B71C1C" },
    GasQualityCertificate: { color: "#00796b" },
}));

const ReportIcon = ({ className, classes: classesOverride, record, ...props }) => {
    const classes = useStyles({ classes: classesOverride });

    if (!record) return null;
    const icon = getIcon(record.type)

    return React.cloneElement(icon, {
        className: `${className} ${classes[record.type]}`,
        ...props,
    });
};

export default ReportIcon;