import React, { cloneElement, useMemo } from 'react';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { can } from "../../abilityProvider";
import { sanitizeListRestProps, useListContext, useResourceContext, useResourceDefinition } from 'ra-core';
import { CreateButton, ExportButton, TopToolbar, useTranslate } from 'react-admin';
import SelectColumnsButton from '../Layout/preferences/SelectColumnsButton';
import ImportButton from "./GasQualityCertificateImportButton";
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import ListActionsMoreButton from '../Layout/list/ListActionsMoreButton';
import SelectColumnsMenu from '../Layout/preferences/SelectColumnsMenu';
import ExportButtonAsMenuItem from '../Layout/list/ExportButtonAsMenuItem';


const ListActions = (props) => {
    const { className, exporter, filters, columns, ...rest } = props;
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const {
        currentSort,
        displayedFilters,
        filterValues,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
    const resource = useResourceContext(rest);
    const { hasCreate } = useResourceDefinition(rest);
    const translate = useTranslate()

    // return useMemo(
    //     () => (
    //         <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    //             {filters &&
    //                 cloneElement(filters, {
    //                     resource,
    //                     showFilter,
    //                     displayedFilters,
    //                     filterValues,
    //                     context: 'button',
    //                 })}
    //             <SelectColumnsButton
    //                 preference={preferences}
    //                 columns={columns}
    //             />
    //             {hasCreate && <CreateButton basePath={basePath} />}
    //             {can('import', 'GasQualityCertificate') && <ImportButton />}
    //             {exporter !== false && (
    //                 <ExportButton
    //                     disabled={total === 0}
    //                     resource={resource}
    //                     sort={currentSort}
    //                     filterValues={filterValues}
    //                 />
    //             )}
    //         </TopToolbar>
    //     ),
    //     [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    // );

    return useMemo(
        () => isMobile ? (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                    cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
                {hasCreate && <CreateButton basePath={basePath} />}
                {can('import', 'GasQualityCertificate') && <ImportButton />}
                {exporter !== false && (
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filterValues={filterValues}
                    />
                )}
            </TopToolbar>
        ) : (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                    cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
                {hasCreate && <CreateButton basePath={basePath} />}
                {can('import', 'GasQualityCertificate') && <ImportButton />}
                <ListActionsMoreButton >
                    <ListSubheader style={{ lineHeight: 3 }}>{translate('ra-preferences.action.choose_columns', {
                        _: 'Choose columns',
                    })}</ListSubheader>
                    <SelectColumnsMenu columns={columns} />
                    <Divider />
                    <MenuList>
                        {exporter !== false && (
                            <ExportButtonAsMenuItem
                                disabled={total === 0}
                                resource={resource}
                                sort={currentSort}
                                filterValues={filterValues}
                            />
                        )}
                    </MenuList>
                </ListActionsMoreButton>
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total, isMobile] // eslint-disable-line react-hooks/exhaustive-deps
    );
};

ListActions.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    currentSort: PropTypes.any,
    displayedFilters: PropTypes.object,
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    hasCreate: PropTypes.bool,
    resource: PropTypes.string,
    onUnselectItems: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    showFilter: PropTypes.func,
    total: PropTypes.number,
    selectedColumns: PropTypes.exact({
        columns: PropTypes.objectOf(PropTypes.node),
        preferences: PropTypes.string
    }),
};

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};


export default ListActions;