import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';
import {GAS_METER_PAD_TEMPLATE} from "./typeDefs";



export default (pads) => {

    return apolloCoreClient.mutate({
        mutation: gql`
            mutation updateManyDisabledPeriods{
                ${ pads.map((pad, index) => {
                    return `
                        data${index}: updateGasMeterPad(where: { id: "${pad.id}"}, disabledPeriods: [${pad.data.disabledPeriods.map(period => {
                            return `{ dateFrom: "${period.dateFrom}", dateTo: ${period.dateTo ? '"' + period.dateTo + '"' : null}, description: ${period.description ? '"' + period.description + '"' : null} }`})}]) ${GAS_METER_PAD_TEMPLATE}`
                }).join(', ')}
            }
        `,
        variables: { },
        onError: (error) => {
            alert(error)
        }
    });
};
