import React, { useState } from 'react'
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, blueGrey, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
// import { InputBase } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import map from 'lodash/map'
import Swatch from './Swatch'
import { ColorWrap } from "react-color/lib/components/common";
import { isValidHex } from "react-color/lib/helpers/color"



const useStyles = makeStyles(theme => ({
    root: {
        // position: "relative"
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(9, 24px)",
        gridTemplateRows: 24,
        gridGap: 4,
    },
    swatch: {
        width: 30,
        height: 30,
        float: 'left',
        borderRadius: 4,
        margin: '0 6px 6px 0',
    },
    input: {
        width: 136
    }
}));

export const ColorPicker = ({
    onChange,
    onSwatchHover,
    colors,
    className,
    hex
}) => {
    const classes = useStyles()
    // const [value, setValue] = useState(hex)

    const handleSwatch = (hexcode, event) => {
        if (isValidHex(hexcode)) {
            onChange({
                hex: hexcode,
                source: 'hex',
            }, event)
            // setValue(hexcode)
        }
    }

    // const handleChange = (event) => {

    //     const hexcode = event.target.value
    //     setValue(hexcode)
    //     if (isValidHex(hexcode)) {
    //         onChange({
    //             hex: hexcode,
    //             source: 'hex',
    //         }, event)

    //     }
    // }

    return (
        <Paper className={`${classes.root} ${className}`}>
            <CardContent className={classes.grid}>
                {map(colors, (c, i) => {
                    return (
                        <Swatch
                            key={i}
                            color={c}
                            hex={c}
                            onClick={handleSwatch}
                            onHover={onSwatchHover}
                            focusStyle={{
                                boxShadow: `0 0 4px ${c}`,
                            }}
                        />
                    )
                })}
            </CardContent>
            {/* <CardContent style={{ paddingTop: 2, paddingBottom: 16 }}>
                <InputBase
                    id="component-outlined"
                    value={value}
                    onChange={handleChange}
                    margin="dense"
                    className={classes.input}
                />

            </CardContent> */}
        </Paper>
    )
}

const colorsGenerator = (colors) => {
    const colorsArray = colors.reduce((acc, color) => {
        const colorArray = [color['900'], color['800'], color['700'], color['600'], color['500'], color['400'], color['300'], color['200'], color['100']]

        return [...acc, ...colorArray]
    }, [])

    return colorsArray
}

ColorPicker.defaultProps = {
    colors: colorsGenerator([red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, blueGrey, grey]),
    // colors: [
    //     red['900'], red['700'], red['500'], red['300'], red['100'],
    //     pink['900'], pink['700'], pink['500'], pink['300'], pink['100'],
    //     purple['900'], purple['700'], purple['500'], purple['300'], purple['100'],
    //     deepPurple['900'], deepPurple['700'], deepPurple['500'], deepPurple['300'], deepPurple['100'],
    //     indigo['900'], indigo['700'], indigo['500'], indigo['300'], indigo['100'],
    //     blue['900'], blue['700'], blue['500'], blue['300'], blue['100'],
    //     lightBlue['900'], lightBlue['700'], lightBlue['500'], lightBlue['300'], lightBlue['100'],
    //     cyan['900'], cyan['700'], cyan['500'], cyan['300'], cyan['100'],
    //     teal['900'], teal['700'], teal['500'], teal['300'], teal['100'],
    //     '#194D33', green['700'], green['500'], green['300'], green['100'],
    //     lightGreen['900'], lightGreen['700'], lightGreen['500'], lightGreen['300'], lightGreen['100'],
    //     lime['900'], lime['700'], lime['500'], lime['300'], lime['100'],
    //     yellow['900'], yellow['700'], yellow['500'], yellow['300'], yellow['100'],
    //     amber['900'], amber['700'], amber['500'], amber['300'], amber['100'],
    //     orange['900'], orange['700'], orange['500'], orange['300'], orange['100'],
    //     deepOrange['900'], deepOrange['700'], deepOrange['500'], deepOrange['300'], deepOrange['100'],
    //     brown['900'], brown['700'], brown['500'], brown['300'], brown['100'],
    //     blueGrey['900'], blueGrey['700'], blueGrey['500'], blueGrey['300'], blueGrey['100'],
    //     '#000000', '#525252', '#969696', '#D9D9D9', '#FFFFFF'
    // ]
}

export default ColorWrap(ColorPicker)