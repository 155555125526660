import React from 'react';
import { TextField, DateField } from 'react-admin';
import { Show } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import TagBanner from './TagBanner';
// import { can } from "../../abilityProvider";
import NestedField from '../Fields/NestedField';
import EnumField from '../Fields/EnumField';
import ColorField from '../Fields/ColorField';
import { dateTimeOptions } from '../../utils';



const TagShow = props => {
    const showMoreButton = React.useRef(null);

    return (
        <Show title={<TitleForRecord source="name" {...props} />} {...props} >
            <TagBanner />

            <CardShowLayout>
                <ColorField source="color" />
                <EnumField source="type" />
                <EnumField source="resource" />
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    <TextField source="id" />
                    <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>
                    <DateField source="createdAt" options={dateTimeOptions} />
                    <DateField source="updatedAt" options={dateTimeOptions} />
                </CardShowLayout>
            </MoreContent>
        </Show>
    );
};

export default TagShow;