import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10.27 5a2 2 0 013.46 0H18v3h-4v1a2 2 0 012 2 6 6 0 016 6v3h-4v-3a2 2 0 00-2-2 2 2 0 01-2 2h-4a2 2 0 01-2-2 2 2 0 00-2 2v3H2v-3a6 6 0 016-6c0-1.1.9-2 2-2V8H6V5h4.27z" />
    </SvgIcon>
  );
}

export default Icon;