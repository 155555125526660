import React from 'react';
import get from 'lodash/get';
import moment from 'moment'
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';



const useStyles = makeStyles(theme => ({
    chip: {
        backgroundColor: "transparent",
        fontSize: theme.typography.body2.fontSize,
    },
    deprecated: {
        backgroundColor: red[200],
    },
}));

const MetricGasMeterDateField = ({ className, emptyText, locales, options, record, showTime = false, source, ...props }) => {
    const classes = useStyles();
    if (!record) return null;
    const value = get(record, source);
    const lastTimeUpdatedAt = moment(value).format('L')
    const isDeprecated = moment().subtract(2, 'days').isAfter(moment(value))

    return (
        <Chip label={lastTimeUpdatedAt} classes={{ root: classes.chip }} className={`${isDeprecated ? classes.deprecated : ""}`} size="small" />
    );
};

export default MetricGasMeterDateField;