import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SimpleForm, TextInput, Toolbar, SaveButton, DeleteButton, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import ResourceInput from './TagResourceInput';
// import EnumInput from '../Inputs/EnumInput';
import ColorInput from '../Inputs/ColorInput';
import { can } from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));

const TagToolbar = props => {
    const classes = useStyles();
    const isSystem = props.record.type === "SYSTEM"

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'Tag') && <SaveButton />}
            <ToolbarSpacer />
            {can('delete', 'Tag') && !isSystem && <DeleteButton undoable icon={<DeleteForeverIcon />} />}
        </Toolbar>
    );
}

const TagEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <CardBlankLayout >
                <SimpleForm toolbar={<TagToolbar />}>
                    {can('update', 'Tag', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'Tag', 'description') && <TextInput source="description" multiline fullWidth />}
                    {/* {can('create', 'Tag', 'type') && <EnumInput source="type" validate={required()} />} */}
                    {can('update', 'Tag', 'resource') && <ResourceInput source="resource" validate={required()} />}
                    {can('update', 'Tag', 'color') && <ColorInput source="color" />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default TagEdit