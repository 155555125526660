import gql from 'graphql-tag';

export const EMBEDDED_SIMPLE_TYPE = `{
    id
    name
    description
}`

export const EMBEDDED_ASKUG_TYPE = `{
    id
    name
    description
    aisId
}`

export const EMBEDDED_USER_TYPE = `{
    id
    name
    email
    phone
}`

export const EMBEDDED_DASHBOARD_TYPE = `{
    id
    name
    description
    resource
    executeApp
    uri
}`

export const EMBEDDED_REPORT_TYPE = `{
    id
    name
    description
    type
    template
    resource
    gasMeterType
}`

export const EMBEDDED_TAG_TYPE = `{
    id
    name
    description
    color
    resource
}`




const GAS_REGION_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasDistricts ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
}`

export const GET_LIST_GAS_REGION = gql`
    query allGasRegions($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasRegionFilter) {
    items: allGasRegions(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_REGION_TEMPLATE}
    total: _allGasRegionsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_REGIONS = gql`
    query allGasRegions($filter: GasRegionFilter) {
    items: allGasRegions(filter: $filter) ${GAS_REGION_TEMPLATE}
    total: _allGasRegionsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_REGION = gql`
    query GasRegion( $where: GasRegionWhereUniqueInput! ) {
        data: GasRegion( where: $where ) ${GAS_REGION_TEMPLATE}
    }
`

export const UPDATE_GAS_REGION = gql`
    mutation updateGasRegion( $where: GasRegionWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasDistricts: [GasDistrictWhereUniqueInput!], $reports: [ReportWhereUniqueInput!] ) {
        data: updateGasRegion( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasDistricts: $gasDistricts, reports: $reports ) ${GAS_REGION_TEMPLATE}
    }
`

export const DELETE_GAS_REGION = gql`
    mutation deleteGasRegion( $where: GasRegionWhereUniqueInput! ) {
        data: deleteGasRegion( where: $where ) { id }
    }
`

const GAS_DISTRICT_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasRegion ${EMBEDDED_ASKUG_TYPE}
    gasMeterPads ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
}`

export const GET_LIST_GAS_DISTRICT = gql`
    query allGasDistricts($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasDistrictFilter) {
    items: allGasDistricts(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_DISTRICT_TEMPLATE}
    total: _allGasDistrictsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_DISTRICTS = gql`
    query allGasDistricts($filter: GasDistrictFilter) {
    items: allGasDistricts(filter: $filter) ${GAS_DISTRICT_TEMPLATE}
    total: _allGasDistrictsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_DISTRICT = gql`
    query GasDistrict( $where: GasDistrictWhereUniqueInput! ) {
        data: GasDistrict( where: $where ) ${GAS_DISTRICT_TEMPLATE}
    }
`

export const UPDATE_GAS_DISTRICT = gql`
    mutation updateGasDistrict( $where: GasDistrictWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasRegion: GasRegionWhereUniqueInput, $gasMeterPads: [GasMeterPadWhereUniqueInput!], $reports: [ReportWhereUniqueInput!] ) {
        data: updateGasDistrict( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasRegion: $gasRegion, gasMeterPads: $gasMeterPads, reports: $reports ) ${GAS_DISTRICT_TEMPLATE}
    }
`

export const DELETE_GAS_DISTRICT = gql`
    mutation deleteGasDistrict( $where: GasDistrictWhereUniqueInput! ) {
        data: deleteGasDistrict( where: $where ) { id }
    }
`

const BALANCE_REGION_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasDistributorStations ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
}`

export const GET_LIST_BALANCE_REGION = gql`
    query allBalanceRegions($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: BalanceRegionFilter) {
    items: allBalanceRegions(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${BALANCE_REGION_TEMPLATE}
    total: _allBalanceRegionsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_BALANCE_REGIONS = gql`
    query allBalanceRegions($filter: BalanceRegionFilter) {
    items: allBalanceRegions(filter: $filter) ${BALANCE_REGION_TEMPLATE}
    total: _allBalanceRegionsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_BALANCE_REGION = gql`
    query BalanceRegion( $where: BalanceRegionWhereUniqueInput! ) {
        data: BalanceRegion( where: $where ) ${BALANCE_REGION_TEMPLATE}
    }
`

export const UPDATE_BALANCE_REGION = gql`
    mutation updateBalanceRegion( $where: BalanceRegionWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasDistributorStations: [GasDistributorStationWhereUniqueInput!], $reports: [ReportWhereUniqueInput!] ) {
        data: updateBalanceRegion( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasDistributorStations: $gasDistributorStations, reports: $reports ) ${BALANCE_REGION_TEMPLATE}
    }
`

export const DELETE_BALANCE_REGION = gql`
    mutation deleteBalanceRegion( $where: BalanceRegionWhereUniqueInput! ) {
        data: deleteBalanceRegion( where: $where ) { id }
    }
`

const GAS_DISTRIBUTOR_ORGANIZATION_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasMeterPads ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
    addrManual
}`

export const GET_LIST_GAS_DISTRIBUTOR_ORGANIZATION = gql`
    query allGasDistributorOrganizations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasDistributorOrganizationFilter) {
    items: allGasDistributorOrganizations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_DISTRIBUTOR_ORGANIZATION_TEMPLATE}
    total: _allGasDistributorOrganizationsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_DISTRIBUTOR_ORGANIZATIONS = gql`
    query allGasDistributorOrganizations($filter: GasDistributorOrganizationFilter) {
    items: allGasDistributorOrganizations(filter: $filter) ${GAS_DISTRIBUTOR_ORGANIZATION_TEMPLATE}
    total: _allGasDistributorOrganizationsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_DISTRIBUTOR_ORGANIZATION = gql`
    query GasDistributorOrganization( $where: GasDistributorOrganizationWhereUniqueInput! ) {
        data: GasDistributorOrganization( where: $where ) ${GAS_DISTRIBUTOR_ORGANIZATION_TEMPLATE}
    }
`

export const UPDATE_GAS_DISTRIBUTOR_ORGANIZATION = gql`
    mutation updateGasDistributorOrganization( $where: GasDistributorOrganizationWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasMeterPads: [GasMeterPadWhereUniqueInput!], $reports: [ReportWhereUniqueInput!], $addrManual: String ) {
        data: updateGasDistributorOrganization( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasMeterPads: $gasMeterPads, reports: $reports, addrManual: $addrManual ) ${GAS_DISTRIBUTOR_ORGANIZATION_TEMPLATE}
    }
`

export const DELETE_GAS_DISTRIBUTOR_ORGANIZATION = gql`
    mutation deleteGasDistributorOrganization( $where: GasDistributorOrganizationWhereUniqueInput! ) {
        data: deleteGasDistributorOrganization( where: $where ) { id }
    }
`

const GAS_DISTRIBUTOR_STATION_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    balanceRegion ${EMBEDDED_ASKUG_TYPE}
    gasDistributorStationOutputs ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
}`

export const GET_LIST_GAS_DISTRIBUTOR_STATION = gql`
    query allGasDistributorStations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasDistributorStationFilter) {
    items: allGasDistributorStations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_DISTRIBUTOR_STATION_TEMPLATE}
    total: _allGasDistributorStationsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_DISTRIBUTOR_STATIONS = gql`
    query allGasDistributorStations($filter: GasDistributorStationFilter) {
    items: allGasDistributorStations(filter: $filter) ${GAS_DISTRIBUTOR_STATION_TEMPLATE}
    total: _allGasDistributorStationsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_DISTRIBUTOR_STATION = gql`
    query GasDistributorStation( $where: GasDistributorStationWhereUniqueInput! ) {
        data: GasDistributorStation( where: $where ) ${GAS_DISTRIBUTOR_STATION_TEMPLATE}
    }
`

export const UPDATE_GAS_DISTRIBUTOR_STATION = gql`
    mutation updateGasDistributorStation( $where: GasDistributorStationWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $balanceRegion: BalanceRegionWhereUniqueInput, $gasDistributorStationOutputs: [GasDistributorStationOutputWhereUniqueInput!], $reports: [ReportWhereUniqueInput!]  ) {
        data: updateGasDistributorStation( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, balanceRegion: $balanceRegion, gasDistributorStationOutputs: $gasDistributorStationOutputs, reports: $reports ) ${GAS_DISTRIBUTOR_STATION_TEMPLATE}
    }
`

export const DELETE_GAS_DISTRIBUTOR_STATION = gql`
    mutation deleteGasDistributorStation( $where: GasDistributorStationWhereUniqueInput! ) {
        data: deleteGasDistributorStation( where: $where ) { id }
    }
`

const GAS_DISTRIBUTOR_STATION_OUTPUT_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasDistributorStation ${EMBEDDED_ASKUG_TYPE}
    gasMeterPads ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
}`

export const GET_LIST_GAS_DISTRIBUTOR_STATION_OUTPUT = gql`
    query allGasDistributorStationOutputs($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasDistributorStationOutputFilter) {
    items: allGasDistributorStationOutputs(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_DISTRIBUTOR_STATION_OUTPUT_TEMPLATE}
    total: _allGasDistributorStationOutputsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_DISTRIBUTOR_STATION_OUTPUTS = gql`
    query allGasDistributorStationOutputs($filter: GasDistributorStationOutputFilter) {
    items: allGasDistributorStationOutputs(filter: $filter) ${GAS_DISTRIBUTOR_STATION_OUTPUT_TEMPLATE}
    total: _allGasDistributorStationOutputsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_DISTRIBUTOR_STATION_OUTPUT = gql`
    query GasDistributorStationOutput( $where: GasDistributorStationOutputWhereUniqueInput! ) {
        data: GasDistributorStationOutput( where: $where ) ${GAS_DISTRIBUTOR_STATION_OUTPUT_TEMPLATE}
    }
`

export const UPDATE_GAS_DISTRIBUTOR_STATION_OUTPUT = gql`
    mutation updateGasDistributorStationOutput( $where: GasDistributorStationOutputWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasDistributorStation: GasDistributorStationWhereUniqueInput, $gasMeterPads: [GasMeterPadWhereUniqueInput!], $reports: [ReportWhereUniqueInput!]  ) {
        data: updateGasDistributorStationOutput( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasDistributorStation: $gasDistributorStation, gasMeterPads: $gasMeterPads, reports: $reports ) ${GAS_DISTRIBUTOR_STATION_OUTPUT_TEMPLATE}
    }
`

export const DELETE_GAS_DISTRIBUTOR_STATION_OUTPUT = gql`
    mutation deleteGasDistributorStationOutput( $where: GasDistributorStationOutputWhereUniqueInput! ) {
        data: deleteGasDistributorStationOutput( where: $where ) { id }
    }
`

const GAS_CONSUMER_TEMPLATE_LIST = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasMeterPads ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
    addrManual
}`

const GAS_CONSUMER_TEMPLATE_ONE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasMeterPads ${EMBEDDED_ASKUG_TYPE}
    ownershipGasMeterPads {gasMeterPad {id name description aisId tags ${EMBEDDED_TAG_TYPE}} dateFrom dateTo}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
    addrManual
}`

export const GET_LIST_GAS_CONSUMER = gql`
    query allGasConsumers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasConsumerFilter) {
    items: allGasConsumers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_CONSUMER_TEMPLATE_LIST}
    total: _allGasConsumersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_CONSUMERS = gql`
    query allGasConsumers($filter: GasConsumerFilter) {
    items: allGasConsumers(filter: $filter) ${GAS_CONSUMER_TEMPLATE_LIST}
    total: _allGasConsumersMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_CONSUMER = gql`
    query GasConsumer( $where: GasConsumerWhereUniqueInput! ) {
        data: GasConsumer( where: $where ) ${GAS_CONSUMER_TEMPLATE_ONE}
    }
`

export const UPDATE_GAS_CONSUMER = gql`
    mutation updateGasConsumer( $where: GasConsumerWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasMeterPads: [GasMeterPadWhereUniqueInput!], $addrManual: String, $reports: [ReportWhereUniqueInput!]  ) {
        data: updateGasConsumer( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasMeterPads: $gasMeterPads, addrManual: $addrManual, reports: $reports ) ${GAS_CONSUMER_TEMPLATE_ONE}
    }
`

export const DELETE_GAS_CONSUMER = gql`
    mutation deleteGasConsumer( $where: GasConsumerWhereUniqueInput! ) {
        data: deleteGasConsumer( where: $where ) { id }
    }
`

export const GAS_METER_PAD_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gasDistrict ${EMBEDDED_ASKUG_TYPE}
    gasConsumer ${EMBEDDED_ASKUG_TYPE}
    gasDistributorOrganization ${EMBEDDED_ASKUG_TYPE}
    gasDistributorStationOutput ${EMBEDDED_ASKUG_TYPE}
    gasMeterUnits ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    aisId
    addrManual
    disabled
    isProvider
    isGasQualityCertificateWritable
    disabledPeriods {
        id
        dateFrom
        dateTo
        description
    }
}`

export const GET_LIST_GAS_METER_PAD = gql`
    query allGasMeterPads($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterPadFilter) {
    items: allGasMeterPads(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_METER_PAD_TEMPLATE}
    total: _allGasMeterPadsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_METER_PADS = gql`
    query allGasMeterPads($filter: GasMeterPadFilter) {
    items: allGasMeterPads(filter: $filter) ${GAS_METER_PAD_TEMPLATE}
    total: _allGasMeterPadsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_METER_PAD = gql`
    query GasMeterPad( $where: GasMeterPadWhereUniqueInput! ) {
        data: GasMeterPad( where: $where ) {
            id
            name
            description
            createdAt
            createdBy ${EMBEDDED_USER_TYPE}
            updatedAt
            deleted
            gasDistrict ${EMBEDDED_ASKUG_TYPE}
            gasConsumer ${EMBEDDED_ASKUG_TYPE}
            gasDistributorOrganization ${EMBEDDED_ASKUG_TYPE}
            gasDistributorStationOutput {
                id
                name
                description
                aisId
                gasDistributorStation ${EMBEDDED_ASKUG_TYPE}
            }
            gasMeterUnits ${EMBEDDED_ASKUG_TYPE}
            reports ${EMBEDDED_REPORT_TYPE}
            tags ${EMBEDDED_TAG_TYPE}
            aisId
            addrManual
            disabled
            isProvider
            isGasQualityCertificateWritable
            disabledPeriods {
                id
                dateFrom
                dateTo
                description
            }
        }
    }
`

export const UPDATE_GAS_METER_PAD = gql`
    mutation updateGasMeterPad( $where: GasMeterPadWhereUniqueInput!, $name: String, $description: String, $aisId: String, $tags: [TagWhereUniqueInput!], $gasConsumer: GasConsumerWhereUniqueInput, $gasDistrict: GasDistrictWhereUniqueInput, $gasDistributorOrganization: GasDistributorOrganizationWhereUniqueInput, $gasDistributorStationOutput: GasDistributorStationOutputWhereUniqueInput, $gasMeterUnits: [GasMeterUnitWhereUniqueInput!], $addrManual: String, $reports: [ReportWhereUniqueInput!], $disabledPeriods: [DisabledPeriodInput!], $disabled: Boolean, $isProvider: Boolean ) {
        data: updateGasMeterPad( where: $where, name: $name, description: $description, aisId: $aisId, tags: $tags, gasConsumer: $gasConsumer, gasDistrict: $gasDistrict, gasDistributorOrganization: $gasDistributorOrganization, gasDistributorStationOutput: $gasDistributorStationOutput, gasMeterUnits: $gasMeterUnits, addrManual: $addrManual, reports: $reports, disabledPeriods: $disabledPeriods, disabled: $disabled, isProvider: $isProvider ) ${GAS_METER_PAD_TEMPLATE}
    }
`

export const DELETE_GAS_METER_PAD = gql`
    mutation deleteGasMeterPad( $where: GasMeterPadWhereUniqueInput! ) {
        data: deleteGasMeterPad( where: $where ) { id }
    }
`

const GAS_METER_UNIT_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    tags ${EMBEDDED_TAG_TYPE}
    inventoryNumber
    aisId
    gasMeterPad ${EMBEDDED_ASKUG_TYPE}
    gasMeterPoints ${EMBEDDED_ASKUG_TYPE}
    dashboards ${EMBEDDED_DASHBOARD_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    gasMeter { 
        id 
        dbId
        port
        serialNumber
        type
        commercialHour
    }
    controller {
        id
        url
        serialNumber
        type
        phoneNumber
    }
    enableWriteGasQualityCertificate
    fixedAsset
    linkToir
}`

export const GET_LIST_GAS_METER_UNIT = gql`
    query allGasMeterUnits($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterUnitFilter) {
    items: allGasMeterUnits(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_METER_UNIT_TEMPLATE}
    total: _allGasMeterUnitsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_METER_UNITS = gql`
    query allGasMeterUnits($filter: GasMeterUnitFilter) {
    items: allGasMeterUnits(filter: $filter) ${GAS_METER_UNIT_TEMPLATE}
    total: _allGasMeterUnitsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_METER_UNIT = gql`
    query GasMeterUnit( $where: GasMeterUnitWhereUniqueInput! ) {
        data: GasMeterUnit( where: $where ) {
            id
            name
            description
            createdAt
            createdBy ${EMBEDDED_USER_TYPE}
            updatedAt
            deleted
            tags ${EMBEDDED_TAG_TYPE}
            inventoryNumber
            aisId
            gasMeterPad ${EMBEDDED_ASKUG_TYPE}
            gasMeterPoints ${EMBEDDED_ASKUG_TYPE}
            dashboards ${EMBEDDED_DASHBOARD_TYPE}
            reports ${EMBEDDED_REPORT_TYPE}
            gasMeter { 
                id 
                dbId
                port
                type
                commercialHour
                configuration {
                    id
                    ns 
                    scadaSet
                }
            }
            controller {
                id
                url
                type
                phoneNumber
            }
            enableWriteGasQualityCertificate
            fixedAsset
            linkToir
        }
    }
`

export const UPDATE_GAS_METER_UNIT = gql`
    mutation updateGasMeterUnit( $where: GasMeterUnitWhereUniqueInput!, $name: String, $description: String, $inventoryNumber: String, $tags: [TagWhereUniqueInput!], $aisId: String, $gasMeterPad: GasMeterPadWhereUniqueInput, $gasMeterPoints: [GasMeterPointWhereUniqueInput!], $dashboards: [DashboardWhereUniqueInput!], $reports: [ReportWhereUniqueInput!] $enableWriteGasQualityCertificate: Boolean, $fixedAsset: String, $linkToir: String ) {
        data: updateGasMeterUnit( where: $where, name: $name, description: $description, inventoryNumber: $inventoryNumber tags: $tags, aisId: $aisId, gasMeterPad: $gasMeterPad, gasMeterPoints: $gasMeterPoints, dashboards: $dashboards, reports: $reports, enableWriteGasQualityCertificate: $enableWriteGasQualityCertificate, fixedAsset: $fixedAsset, linkToir: $linkToir ) ${GAS_METER_UNIT_TEMPLATE}
    }
`

export const DELETE_GAS_METER_UNIT = gql`
    mutation deleteGasMeterUnit( $where: GasMeterUnitWhereUniqueInput! ) {
        data: deleteGasMeterUnit( where: $where ) { id }
    }
`

const GAS_METER_POINT_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    tags ${EMBEDDED_TAG_TYPE}
    aisId
    gasMeterUnit ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    number
    disabled
}`

export const GET_ONE_GAS_METER_POINT = gql`
    query GasMeterPoint( $where: GasMeterPointWhereUniqueInput! ) {
        data: GasMeterPoint( where: $where ) ${GAS_METER_POINT_TEMPLATE}
    }
`

export const UPDATE_GAS_METER_POINT = gql`
    mutation updateGasMeterPoint( $where: GasMeterPointWhereUniqueInput!, $name: String, $description: String, $aisId: String, $number: Int, $disabled: Boolean, $gasMeterUnit: GasMeterUnitWhereUniqueInput, $reports: [ReportWhereUniqueInput!] ) {
        data: updateGasMeterPoint( where: $where, name: $name, description: $description, number: $number, disabled: $disabled, aisId: $aisId gasMeterUnit: $gasMeterUnit, reports: $reports ) ${GAS_METER_POINT_TEMPLATE}
    }
`

export const DELETE_GAS_METER_POINT = gql`
    mutation deleteGasMeterPoint( $where: GasMeterPointWhereUniqueInput! ) {
        data: deleteGasMeterPoint( where: $where ) { id }
    }
`

const GAS_METER_TEMPLATE = `{
    id
    name
    description
}`

export const GET_LIST_GAS_METER = gql`
    query allGasMeters($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterFilter) {
    items: allGasMeters(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_METER_TEMPLATE}
    total: _allGasMetersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_METERS = gql`
    query allGasMeters($filter: GasMeterFilter) {
    items: allGasMeters(filter: $filter) ${GAS_METER_TEMPLATE}
    total: _allGasMetersMeta(filter: $filter) { count }
  }
`

const GAS_QUALITY_SERTIFICATE_TEMPLATE = `{
    id
    name  
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    gqsId
    n2
    co2
    density
    validFrom
    validTo
    gasExtractionPoint
    gasDistributorStations ${EMBEDDED_ASKUG_TYPE}
    reports ${EMBEDDED_REPORT_TYPE}
    tags ${EMBEDDED_TAG_TYPE}
    writtenAt
}`

export const GET_LIST_GAS_QUALITY_SERTIFICATE = gql`
    query allGasQualityCertificates($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasQualityCertificateFilter) {
    items: allGasQualityCertificates(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_QUALITY_SERTIFICATE_TEMPLATE}
    total: _allGasQualityCertificatesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_QUALITY_SERTIFICATES = gql`
    query allGasQualityCertificates($filter: GasQualityCertificateFilter) {
    items: allGasQualityCertificates(filter: $filter) ${GAS_QUALITY_SERTIFICATE_TEMPLATE}
    total: _allGasQualityCertificatesMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_QUALITY_SERTIFICATE = gql`
    query GasQualityCertificate( $where: GasQualityCertificateWhereUniqueInput! ) {
        data: GasQualityCertificate( where: $where ) ${GAS_QUALITY_SERTIFICATE_TEMPLATE}
    }
`

export const UPDATE_GAS_QUALITY_SERTIFICATE = gql`
    mutation updateGasQualityCertificate( $where: GasQualityCertificateWhereUniqueInput!, $name: String, $description: String, $gqsId: String, $n2: Float, $co2: Float, $density: Float, $validFrom: DateTime, $validTo: DateTime, $gasExtractionPoint: String, $gasDistributorStations: [GasDistributorStationWhereUniqueInput!], $tags: [TagWhereUniqueInput!], $reports: [ReportWhereUniqueInput!] ) {
        data: updateGasQualityCertificate( where: $where, name: $name, description: $description, gqsId: $gqsId, n2: $n2, co2: $co2, density: $density, validFrom: $validFrom, validTo: $validTo, gasExtractionPoint: $gasExtractionPoint, gasDistributorStations: $gasDistributorStations, tags: $tags, reports: $reports ) ${GAS_QUALITY_SERTIFICATE_TEMPLATE}
    }
`

export const DELETE_GAS_QUALITY_SERTIFICATE = gql`
    mutation deleteGasQualityCertificate( $where: GasQualityCertificateWhereUniqueInput! ) {
        data: deleteGasQualityCertificate( where: $where ) { id }
    }
`

const COUNTERPARTY_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    type
    inn
    kpp
    okpo
    ogrn
    fio
    passport
    passdateissue
    parentId
    addressLegal
    addressActual
    addressPostal
    phone
    phoneMobile
    email
    users ${EMBEDDED_USER_TYPE}
    accessResource
    access {id resource}
}`

export const GET_LIST_COUNTERPARTY = gql`
    query allCounterparties($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: CounterpartyFilter) {
    items: allCounterparties(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${COUNTERPARTY_TEMPLATE}
    total: _allCounterpartiesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_COUNTERPARTIES = gql`
    query allCounterparties($filter: CounterpartyFilter) {
    items: allCounterparties(filter: $filter) ${COUNTERPARTY_TEMPLATE}
    total: _allCounterpartiesMeta(filter: $filter) { count }
  }
`

export const GET_ONE_COUNTERPARTY = gql`
    query Counterparty( $where: CounterpartyWhereUniqueInput! ) {
        data: Counterparty( where: $where ) ${COUNTERPARTY_TEMPLATE}
    }
`

export const UPDATE_COUNTERPARTY = gql`
    mutation updateCounterparty( $where: CounterpartyWhereUniqueInput!, $name: String, $description: String, $inn: String, $type: CounterpartyType, $kpp: String, $okpo: String, $ogrn: String, $fio: String, $passport: String, $passdateissue: DateTime, $parentId: ID, $addressLegal: String, $addressActual: String, $addressPostal: String, $phone: String, $phoneMobile: String, $email: String, $users: [UserWhereUniqueInput!], $accessResource: AccessResource, $access: [AccessInput!] ) {
        data: updateCounterparty( where: $where, name: $name, description: $description, inn: $inn, type: $type, kpp: $kpp, okpo: $okpo, ogrn: $ogrn, fio: $fio, passport: $passport, passdateissue: $passdateissue, parentId: $parentId, addressLegal: $addressLegal, addressActual: $addressActual, addressPostal: $addressPostal, phone: $phone, phoneMobile: $phoneMobile, email: $email, users: $users, accessResource: $accessResource, access: $access ) ${COUNTERPARTY_TEMPLATE}
    }
`

export const DELETE_COUNTERPARTY = gql`
    mutation deleteCounterparty( $where: CounterpartyWhereUniqueInput! ) {
        data: deleteCounterparty( where: $where ) { id }
    }
`

const USER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    authId
    banned
    login
    lastLogin
    email
    phone
    phoneContact
    role
    online
    counterparty ${EMBEDDED_SIMPLE_TYPE}
    position
    accessResource
    access {id resource}
}`

export const GET_LIST_USER = gql`
    query allUsers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilter) {
    items: allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${USER_TEMPLATE}
    total: _allUsersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_USERS = gql`
    query allUsers($filter: UserFilter) {
    items: allUsers(filter: $filter) ${USER_TEMPLATE}
    total: _allUsersMeta(filter: $filter) { count }
  }
`

export const GET_ONE_USER = gql`
    query User( $where: UserWhereUniqueInput! ) {
        data: User( where: $where ) ${USER_TEMPLATE}
    }
`

export const UPDATE_USER = gql`
    mutation updateUser( $where: UserWhereUniqueInput!, $name: String, $description: String, $authId: ID, $banned: Boolean, $login: String, $email: String, $phone: String, $phoneContact: String, $role: UserRole, $counterparty: CounterpartyWhereUniqueInput, $position: String, $accessResource: AccessResource, $access: [AccessInput!] ) {
        data: updateUser( where: $where, name: $name, description: $description, authId: $authId, banned: $banned, login: $login, email: $email, phone: $phone, phoneContact: $phoneContact, role: $role, counterparty: $counterparty, position: $position, accessResource: $accessResource, access: $access) ${USER_TEMPLATE}
    }
`

export const DELETE_USER = gql`
    mutation deleteUser( $where: UserWhereUniqueInput! ) {
        data: deleteUser( where: $where ) { id }
    }
`

export const UPDATE_PROFILE = gql`
    mutation updateProfile( $where: UserWhereUniqueInput!, $name: String, $description: String, $authId: ID, $login: String, $email: String, $phone: String, $phoneContact: String, $role: UserRole, $position: String) {
        data: updateProfile( where: $where, name: $name, description: $description, authId: $authId, login: $login, email: $email, phone: $phone, phoneContact: $phoneContact, role: $role, position: $position) ${USER_TEMPLATE}
    }
`

const HISTORY_TEMPLATE = `{
    id
    resource
    item
    name
    description
    action
    data
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
}`

export const GET_LIST_HISTORY = gql`
    query allHistories($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: HistoryFilter) {
    items: allHistories(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${HISTORY_TEMPLATE}
    total: _allHistoriesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_HISTORIES = gql`
    query allHistories($filter: HistoryFilter) {
    items: allHistories(filter: $filter) ${HISTORY_TEMPLATE}
    total: _allHistoriesMeta(filter: $filter) { count }
  }
`

export const GET_ONE_HISTORY = gql`
    query History( $id: ID! ) {
        data: History( id: $id ) ${HISTORY_TEMPLATE}
    }
`

const TRASHITEM_TEMPLATE = `{
    id
    resource
    item
    name
    description
    deletedAt
    deletedBy ${EMBEDDED_USER_TYPE}
}`

export const GET_LIST_TRASHITEM = gql`
    query allTrashItems($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: TrashItemFilter) {
    items: allTrashItems(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${TRASHITEM_TEMPLATE}
    total: _allTrashItemsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_TRASHITEMS = gql`
    query allTrashItems($filter: TrashItemFilter) {
    items: allTrashItems(filter: $filter) ${TRASHITEM_TEMPLATE}
    total: _allTrashItemsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_TRASHITEM = gql`
    query TrashItem( $id: ID! ) {
        data: TrashItem( id: $id ) ${TRASHITEM_TEMPLATE}
    }
`

const CONTROLLER_CONFIGURATION_TEMPLATE = `{
    id
    name
    description
    type
    mode
    status
    metaTemplate
}`

export const GET_LIST_CONTROLLER_CONFIGURATION = gql`
    query allControllerConfigurations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ControllerConfigurationFilter) {
    items: allControllerConfigurations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${CONTROLLER_CONFIGURATION_TEMPLATE}
    total: _allControllerConfigurationsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_CONTROLLER_CONFIGURATIONS = gql`
    query allControllerConfigurations($filter: ControllerConfigurationFilter) {
    items: allControllerConfigurations(filter: $filter) ${CONTROLLER_CONFIGURATION_TEMPLATE}
    total: _allControllerConfigurationsMeta(filter: $filter) { count }
  }
`

const GAS_METER_CONFIGURATION_TEMPLATE = `{
    id
    name
    description
    type
    status
    metaTemplate
}`

export const GET_LIST_GAS_METER_CONFIGURATION = gql`
    query allGasMeterConfigurations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterConfigurationFilter) {
    items: allGasMeterConfigurations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_METER_CONFIGURATION_TEMPLATE}
    total: _allGasMeterConfigurationsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_METER_CONFIGURATIONS = gql`
    query allGasMeterConfigurations($filter: GasMeterConfigurationFilter) {
    items: allGasMeterConfigurations(filter: $filter) ${GAS_METER_CONFIGURATION_TEMPLATE}
    total: _allGasMeterConfigurationsMeta(filter: $filter) { count }
  }
`

export const GET_PREFERENCES = gql`
    query getPreferences($key: String!) {
        preferences: getPreferences(key: $key) {
            id
            key
            value
        }
    }
`

export const SET_PREFERENCES = gql`
    mutation setPreferences($key: String!, $value: Json) {
        preferences: setPreferences(key: $key, value: $value) {
            id
            key
            value
        }
    }
`

export const GET_ENUMS = gql`
  query getEnums($name: String!) {
    data: __type(name: $name) {
      name
      enumValues{
        name
        description
      }                          
    }
  }
`