
import { useEffect, useCallback } from 'react';
import get from 'lodash/get';
import usePreferenceContext from './usePreferenceContext';



export const usePreferences = (
    path = '',
    defaultValue
) => {
    const { data, loading, loaded, readStorage, writeStorage } = usePreferenceContext();
    const [key] = path.split('.');


    useEffect(() => {
        if (data[key] === undefined && !loading) {
            readStorage(path)
        }
    }, [data, key, loaded]);

    const writeState = useCallback(value => {
        writeStorage(path, value);
    }, [key]);


    return [get(data, path, defaultValue), writeState, loaded, loading];
};
