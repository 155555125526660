import React, { cloneElement, Children } from 'react';
import { ListBase, ListToolbar, Pagination, ResourceContextProvider } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import ListFieldToolbar from './ListFieldToolbar';
import DefaultListFieldActions from './ListFieldActions';



const ListField = ({
    children,
    actions,
    filters,
    component: Content,
    resource,
    ...props
}) => {


    return (
        <ResourceContextProvider value={resource}>
            <ListBase
                {...props}
            >
                {(filters || actions) && (
                    <ListFieldToolbar
                        filters={filters}
                        actions={actions}
                    />
                )}
                <Content >
                    {cloneElement(Children.only(children), {
                        ...sanitizeRestProps(props),
                    })}
                    <Pagination />
                </Content>
            </ListBase>
        </ResourceContextProvider>




    );
};

ListField.defaultProps = {
    actions: <DefaultListFieldActions />,
    // classes: {},
    component: Paper,
    // bulkActionButtons: <DefaultBulkActionButtons />,
    // pagination: <DefaultPagination />,
    // empty: <Empty />,
    addLabel: true,
};


const sanitizeRestProps = (props) => ({
    basePath = null,
    currentSort = null,
    data = null,
    defaultTitle = null,
    displayedFilters = null,
    filterDefaultValues = null,
    filterValues = null,
    hasCreate = null,
    hasEdit = null,
    hasList = null,
    hasShow = null,
    hideFilter = null,
    history = null,
    ids = null,
    loading = null,
    loaded = null,
    location = null,
    match = null,
    onSelect = null,
    onToggleItem = null,
    onUnselectItems = null,
    options = null,
    page = null,
    permissions = null,
    perPage = null,
    resource = null,
    selectedIds = null,
    setFilters = null,
    setPage = null,
    setPerPage = null,
    setSort = null,
    showFilter = null,
    syncWithLocation = null,
    sort = null,
    total = null,
    ...rest
}) => rest;

export default ListField;