import { BulkExportButton } from 'react-admin';
import React from 'react';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import BulkUpdateTagsButton from "../Buttons/BulkUpdateTagsButton";
import BulkWriteButton from './GasQualityCertificateWriteBulkActionsButton';
import { can } from "../../abilityProvider";



const GasQualityCertificateBulkActionButtons = props => (
    <>
        {can('update', 'GasQualityCertificate', 'tags', 'Tag') && <BulkUpdateTagsButton {...props} />}
        {can('write', 'GasQualityCertificate') && <BulkWriteButton {...props} />}
        {can('getList', 'GasQualityCertificate') && <BulkExportButton {...props} />}
        {can('remove', 'GasQualityCertificate') && <BulkRemoveButton {...props} />}
    </>
)


export default GasQualityCertificateBulkActionButtons;