import Icon from '../Icons/District';
import GasDistrictList from './GasDistrictList';
import GasDistrictCreate from './GasDistrictCreate';
import GasDistrictEdit from './GasDistrictEdit';
import GasDistrictShow from './GasDistrictShow';

const GasDistrictIcon = Icon;


export {
    GasDistrictIcon,
    GasDistrictList,
    GasDistrictShow,
    GasDistrictCreate,
    GasDistrictEdit,
};
