import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, SaveButton, required, ReferenceInput } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import GasDistributorStationBanner from './GasDistributorStationBanner';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import ReportReferenceArrayInput from "../Report/ReportReferenceArrayInput";
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";
import ReferenceArrayGasDistributorStationOutput from "./ReferenceArrayGasDistributorStationOutput";



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));

const GasDistributorStationToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasDistributorStation') && <SaveButton />}
            {can("update", "GasDistributorStation") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasDistributorStation') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const GasDistributorStationEdit = props => {
    const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasDistributorStationBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasDistributorStationToolbar />}>
                    {can('update', 'GasDistributorStation', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'GasDistributorStation', 'description') && <TextInput source="description" multiline fullWidth />}
                    {can('update', 'GasDistributorStation', 'aisId') && <TextInput source="aisId" />}
                    {can('update', 'GasDistributorStation', 'balanceRegion', 'BalanceRegion') && <ReferenceInput source="balanceRegion.id" reference="BalanceRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStation.fields.balanceRegion" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    {can('update', 'GasDistributorStation', 'gasDistributorStationOutputs', 'GasDistributorStationOutput') && <ReferenceArrayGasDistributorStationOutput source="gasDistributorStationOutputsIds" reference="GasDistributorStationOutput" filter={{ gasDistributorStation: null }} label="resources.GasDistributorStation.fields.gasDistributorStationOutputs" fullWidth />}
                    {can('update', 'GasDistributorStation', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStation.fields.reports" formClassName={classes.flexReferenceArrayInput} fullWidth />}
                    {can('update', 'GasDistributorStation', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStation.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasDistributorStationEdit