import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';



export default (gasQualityCertificates) => {

    return apolloCoreClient.mutate({
        mutation: gql`
        mutation importGasQualityCertificates($gasQualityCertificates: [ManyGasQualityCertificateCreateInput!]!) {
            data: importGasQualityCertificates(gasQualityCertificates: $gasQualityCertificates) { count }
        }`,
        variables: {
            gasQualityCertificates
        },
        onError: (error) => {
            alert(error)
        }
    });
};