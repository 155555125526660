import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'


const useChipStyles = makeStyles(theme => ({

}));

const GasMeterPadDisabledPeriodChip = ({ className, classes: classesOverride, record, source, resource, ...props}) => {
    const classes = useChipStyles({ classes: classesOverride });
    const label = `${moment(record.dateFrom).format('L')}${record.dateTo ? ` - ${moment(record.dateTo).format('L')}`: ' - не указано'}${record.description ? ` | ${record.description}`: ''}`

    return (
        <Chip
            size="small"
            classes={{root:classes.root}}
            className={`${className}`}
            label={label}
        />
    );
};



export default GasMeterPadDisabledPeriodChip;