import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';



const ADD_HISTORY_MUTATION = gql`
    mutation addHistory( $resource: ResourceType!, $item: ID!, $name: String!, $description: String, $action: HistoryAction, $data: Json) {
        addHistory( resource: $resource, item: $item, name: $name, description: $description, action: $action, data: $data) {
            id
        }
    }
`;

export default (args) => {
    return apolloCoreClient.mutate({
        mutation: ADD_HISTORY_MUTATION,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};