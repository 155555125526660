import get from 'lodash/get';
import set from 'lodash/set';
import { LOCAL_STORAGE } from '../../appsettings';
import useDeepCompareEffect from 'use-deep-compare-effect';


export default (props, controllerProps) => {

    useDeepCompareEffect(() => {
        const { resource, reference } = props;
        const { filterValues, perPage } = controllerProps;
        const storageProps = JSON.parse(localStorage.getItem(LOCAL_STORAGE)) || {};
        const path = `${resource}.show.${reference}.props`;
        const storageResourceShowProps = get(storageProps, path, {});

        set(storageProps, path, { ...storageResourceShowProps, filter: filterValues, perPage });

        localStorage.setItem(LOCAL_STORAGE, JSON.stringify(storageProps));
    }, [controllerProps]);
}