import React from 'react';
import { BulkExportButton } from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import BulkUpdateTagsButton from "../Buttons/BulkUpdateTagsButton";
import { can } from "../../abilityProvider";



const GasDistributorStationBulkActionButtons = props => (
    <>
        {can('update', 'GasDistributorStation', 'tags', 'Tag') && <BulkUpdateTagsButton {...props} />}
        {can('getList', 'GasDistributorStation') && <BulkExportButton {...props} />}
        {can('remove', 'GasDistributorStation') && <BulkRemoveButton {...props} />}
    </>
)



export default GasDistributorStationBulkActionButtons;