import React from 'react';
import { can, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, TextInput, ReferenceInput, AutocompleteInput, required, minLength, TabbedForm, FormTab } from 'react-admin';
import PhoneInput from '../Inputs/PhoneInput';
import EnumInput from '../Inputs/EnumInput';
import PasswordInput from '../Inputs/PasswordInput';
import AccessResourceInput from './AccessResourceInput';
import AccessReferenceArrayInput from './AccessReferenceArrayInput';



const matchTrue = () => true;

const filterRoles = (value) => {
    const conditions = rulesToFields("create", "User")
    const role_in = (conditions.role && conditions.role.$in) || [];
    return role_in.includes(value.name);
}


const logined = (value, values) => {
    if (!values.email && !values.phone && !values.login) {
        return 'ra.action.one_of_fields_is_required';
    }
};
const validateLogin = [logined];
const validateName = [required(), minLength(2)];
const validateRole = [required()];
const validatePassword = [required()];

const UserCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <TabbedForm redirect="list" >
                <FormTab label="resources.User.tabs.general">
                    {can("create", "User", "name") && <TextInput source="name" validate={validateName} fullWidth />}
                    {can("create", "User", "description") && <TextInput source="description" fullWidth />}
                    {can("create", "User", "position") && <TextInput source="position" fullWidth />}
                    {can("create", "User", "role") && <EnumInput source="role" filter={filterRoles} validate={validateRole} />}
                    {can("create", "User", "login") && <TextInput source="login" validate={validateLogin} />}
                    {can("create", "User", "email") && <TextInput source="email" type="email" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                    {can("create", "User", "phone") && <PhoneInput source="phone" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                    {can("create", "User", "phoneContact") && <PhoneInput source="phoneContact" />}
                    {can("create", "User", "password") && <PasswordInput source="password" validate={validatePassword} />}
                </FormTab>
                <FormTab label="resources.Counterparty.tabs.access" path="access">
                    {can('create', 'User', 'counterparty') && <ReferenceInput source="counterparty.id" reference="Counterparty" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.User.fields.counterparty" allowEmpty emptyText="ra.message.no" >
                        <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
                    </ReferenceInput>}
                    <AccessResourceInput source="accessResource" name="AccessResource" option="resources.Access.enums.resource" allowEmpty emptyValue={null} emptyText="ra.message.no" />
                    <AccessReferenceArrayInput source="accessIds" label="resources.User.fields.access" />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default UserCreate