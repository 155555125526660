import React, { Children, isValidElement } from 'react';
import { Tooltip, IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslate } from 'react-admin';
import { Popover } from '@material-ui/core';
import SelectColumnsMenu from '../preferences/SelectColumnsMenu';
import ExportButtonAsMenuItem from './ExportButtonAsMenuItem';


const MoreButton = ({
    label = 'ra.action.more',
    children,
    cellClassName,
    preference,
    columns,
    ...rest }) => {
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <div style={{ marginRight: 16 }}>
            <Tooltip title={translate('ra.action.more', { _: 'More' })} enterDelay={700}>
                <IconButton
                    aria-label={translate('ra.action.more', { _: 'More' })}
                    aria-controls="more-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="small"
                    color="primary"
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id="more-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >

                {Children.map(children, field =>
                    field && isValidElement(field)
                        ? React.cloneElement(field, { ...rest })
                        : null
                )}
            </Popover>
            {/* <Menu
                id="more-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={open}
                onClose={handleClose}
            >
                
            </Menu> */}
        </div>
    );
}

export default MoreButton;