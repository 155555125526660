import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { REPORT_DESPATCH_FRAGMENT } from '../typeDefs';

export const GET_MANY_REFERENCE_REPORT_DESPATCHES = gql`
    query getManyReferenceReportDespatches($filter: ReportDespatchFilter, $sort: [ReportDespatchSort!] $page: Int, $perPage: Int) {
        data: listReportDespatches(filter: $filter, sort: $sort, page: $page, perPage: $perPage) {
            items {...ReportDespatchFragment}
            pageInfo { totalItems }
        }
    }
    ${REPORT_DESPATCH_FRAGMENT}
`

export default (args) => {
    return apolloClient.query({
        query: GET_MANY_REFERENCE_REPORT_DESPATCHES,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    })
};
