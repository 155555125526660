import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ArrayInput, FormDataConsumer, SimpleFormIterator, NumberInput, TextInput, required } from 'react-admin';
import BooleanInput from '../../Inputs/BooleanInput';


const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        alignItems: "center"
    },
    index: {
        width: 24,
        paddingTop: "1.25em",
    },
    flexInput: {
        paddingLeft: 8,
        paddingRight: 8,
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:last-child': {
            paddingRight: 0,
        },
    },
    flexGrow: {
        flexGrow: 1,
    },
    width128: {
        width: 128
    },
}));

const getIndex = (id) => {
    const regexp = /\[(\d+)\]/;
    const index = id.match(regexp)[1]
    return Number(index)
}

const DeviceGasMeterPointArrayInput = ({ className, ...props }) => {
    const classes = useStyles();

    return (
        <ArrayInput {...props} >
            <SimpleFormIterator classes={{ form: classes.form, index: classes.index }}>
                <FormDataConsumer label="resources.GasMeterPoint.fields.name" formClassName={classes.flexInput}>
                    {({ formData, scopedFormData, getSource, id, ...rest }) => (
                        <TextInput {...rest} id={getSource("name")} source={getSource("name")} validate={required()} defaultValue={`Линия ${getIndex(id) + 1}`} />
                    )}
                </FormDataConsumer>
                <TextInput source="description" label="resources.GasMeterPoint.fields.description" formClassName={`${classes.flexInput} ${classes.flexGrow}`} fullWidth />
                <TextInput source="aisId" label="resources.GasMeterPoint.fields.aisId" formClassName={`${classes.flexInput} ${classes.width128}`} fullWidth />
                <FormDataConsumer label="resources.GasMeterPoint.fields.number" formClassName={`${classes.flexInput} ${classes.width128}`}>
                    {({ formData, scopedFormData, getSource, id, className, ...rest }) => (
                        <NumberInput {...rest} id={getSource("number")} source={getSource("number")} validate={required()} defaultValue={getIndex(id) + 1} fullWidth />
                    )}
                </FormDataConsumer>
                <BooleanInput source="disabled" label="resources.GasMeterPoint.fields.disabled" formClassName={`${classes.flexInput} ${classes.width128}`} defaultValue={false} fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    );
}

export default DeviceGasMeterPointArrayInput;