import React, { cloneElement, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    sanitizeListRestProps,
    Identifier,
    SortPayload,
    Exporter,
    useListContext,
    useResourceContext,
    useResourceDefinition,
    TopToolbar,
} from 'react-admin';
import ReferenceListLinkButton2 from '../Buttons/ReferenceListLinkButton2';



const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 0,
    },
}));

const ListFieldActions = props => {
    const { className, exporter, filters, ...rest } = props;
    const classes = useStyles();
    const {
        currentSort,
        displayedFilters,
        filterValues,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
    const resource = useResourceContext(rest);

    return useMemo(
        () => (
            <TopToolbar
                className={className}
                classes={{ root: classes.root }}
                // variant="dense"
                {...sanitizeListRestProps(rest)}
            >
                {filters &&
                    cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}

                <ReferenceListLinkButton2 {...rest} />
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};



ListFieldActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default ListFieldActions;
