import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';



const useStyles = makeStyles(theme => ({

}));

const SelectedItem = ({className, label, record, onDelete, ...props}) => {
    const classes = useStyles();
    if (!record) return null;
    const text = record.aisId ? `${record.name} (${record.aisId})` : record.name
    return (
        <Chip 
            classes={{root: classes.root}}
            label={text} 
            className={className} 
            onDelete={onDelete} 
        />
    );
};

export default SelectedItem;
