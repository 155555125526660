import { Ability } from '@casl/ability'
import set from 'lodash/set'
import { createContext } from 'react'
import { createContextualCan } from '@casl/react'



export const AbilityContext = createContext();

export const ability = new Ability([]);

export const rulesToFields = (action, subject) => {
  const rules = ability.rulesFor(action, subject)

  return rules.filter(rule => !rule.inverted && rule.conditions).reduce((values, rule) => Object.keys(rule.conditions).reduce((fields, fieldName) => {
    const value = rule.conditions[fieldName];

    set(fields, fieldName, value);

    return fields;
  }, values), {});
}

export const defineAbilityFor = function (rules) {

  rules.filter(r => r.conditions).forEach(r => { for (let key in r.conditions) { if (r.conditions[key] === "undefined") { r.conditions[key] = undefined; } } });

  return rules;
};

export const abilitySubjectConditions = (resource, conditions) => ({ constructor: { name: resource }, ...conditions });

// export const Can = createContextualCan(AbilityContext.Consumer);

export const can = (action, subject, field, reference) => {
  return reference ? ability.can(action, subject, field) && ability.can("getList", reference) : ability.can(action, subject, field);
};