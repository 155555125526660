import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, TextField } from 'react-admin';
import BooleanField from '../Fields/BooleanField';
import ResourceLinkField from '../Fields/ResourceLinkField';
import { can } from "../../abilityProvider";
import useListStyles from '../Layout/list/useListStyles';



export const ReferenceDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {can('getList', 'GasMeterPoint', 'name') && <ResourceLinkField source="name" target="_blank" />}
            {can('getList', 'GasMeterPoint', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />}
            {can('getList', 'GasMeterPoint', 'number') && <TextField source="number" />}
            {can('getList', 'GasMeterPoint', 'disabled') && <BooleanField source="disabled" valueLabelTrue={'resources.GasMeterPoint.enums.disabled.true'} valueLabelFalse={'resources.GasMeterPoint.enums.disabled.false'} label={'resources.GasMeterPoint.fields.state'} />}
            {can('getList', 'GasMeterPoint', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'GasMeterPoint', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
        </Datagrid>
    )
}