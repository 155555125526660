import React, { cloneElement, Children } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslate} from 'react-admin';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    card: {}
}));

const CardBlankLayout = ({children, classes: classesOverride, className, label, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
            <Card className={classes.card}>
                {cloneElement(Children.only(children), {
                    ...props,
                    ...children.props,
                })}
            </Card>
        </div>
        
    );
};



export default CardBlankLayout;