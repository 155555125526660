import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, ReferenceField, TextField } from 'react-admin';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import EnabledWriteChip from "./GasMeterUnitEnabledWriteChip";
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import EnumField from '../Fields/EnumField';
import DeviceStatusField from './analitics/DeviceStatusField';
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import TagArrayEnhancedField from "../Tag/TagArrayEnhancedField";
import MetricGasMeterDateField from "./MetricGasMeterDateField";
import TagChip from "../Tag/TagChip";
import { can } from "../../abilityProvider";
import useListStyles from '../Layout/list/useListStyles';
import useColumns from '../../controller/preferences/useColumns';
import useSelectedColumnsPreferences from '../../controller/preferences/useSelectedColumnsPreferences';



export const useDefaultDatagridColumns = (props) => {
    const classes = useListStyles()

    const columns = useColumns([
        <ReferenceField source="gasMeter.dbId" reference="DeviceStatus" label="resources.GasMeterUnit.fields.deviceStatus" link={false} sortable={false} cellClassName={classes.cellMinWidth} column="deviceStatus">
            <DeviceStatusField source="status" />
        </ReferenceField>,
        can('getList', 'GasMeterUnit', 'name') && <ResourceLinkField source="name" target="_blank" alwaysOn />,
        can('getList', 'GasMeterUnit', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />,
        can('getList', 'GasMeterUnit', 'tags', 'Tag') && <TagArrayEnhancedField source="tags" sortable={false}>
            <TagChip />
            <EnabledWriteChip onlyDisabled />
        </TagArrayEnhancedField>,
        <ReferenceField source="gasMeter.dbId" reference="MetricGasMeter" label="resources.GasMeterUnit.fields.lastTimeUpdatedAt" textAlign="right" link={false} sortable={false} cellClassName={classes.cellMinWidth} column="lastTimeUpdatedAt">
            <MetricGasMeterDateField source="updatedAt" emptyText="Нет данных" />
        </ReferenceField>,
        can('getList', 'GasMeterUnit', 'controller.type') && <EnumField source="controller.type" label="resources.GasMeterUnit.fields.controller.type" option="resources.Controller.enums.type" sortable={false} />,
        can('getList', 'GasMeterUnit', 'gasMeter.type') && <EnumField source="gasMeter.type" label="resources.GasMeterUnit.fields.gasMeter.type" option="resources.GasMeter.enums.type" sortable={false} />,
        can('getList', 'GasMeterUnit', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
        can('getList', 'GasMeterUnit', 'createdAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
    ])

    return columns
}

export const GasMeterUnitReferenceDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            <ReferenceField source="gasMeter.dbId" reference="DeviceStatus" label="resources.GasMeterUnit.fields.deviceStatus" link={false} sortable={false} cellClassName={classes.cellMinWidth} column="deviceStatus">
                <DeviceStatusField source="status" />
            </ReferenceField>,
            {can('getList', 'GasMeterUnit', 'name') && <ResourceLinkField source="name" target="_blank" />}
            {can('getList', 'GasMeterUnit', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />}
            {can('getList', 'GasMeterUnit', 'gasMeter.type') && <EnumField source="gasMeter.type" label="resources.GasMeter.fields.type" option="resources.GasMeter.enums.type" />}
            {can('getList', 'GasMeterUnit', 'tags', 'Tag') && <TagArrayEnhancedField source="tags" sortable={false}>
                <TagChip />
                <EnabledWriteChip onlyDisabled />
            </TagArrayEnhancedField>}
            {can('getList', 'GasMeterUnit', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'GasMeterUnit', 'createdAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
        </Datagrid>
    )
};

const GasMeterUnitDatagrid = ({ columns, ...props }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();
    const SelectedColumns = useSelectedColumnsPreferences(columns);

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {SelectedColumns}
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {can('getOne', 'GasMeterUnit') && <ShowBlankItem />}
                {can('update', 'GasMeterUnit') && <EditMenuItem />}
                {can('remove', 'GasMeterUnit') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    )
};



export default GasMeterUnitDatagrid;