import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { Title } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useAuthenticated } from 'react-admin';


const styles = theme => ({
    root: {},
    main: {
        display: 'flex',
    },
    card: {
        flex: '1 1 auto',
    },
    cardWrapper: {
        flex: '1 1 auto',
    },
});


export const ShowView = withStyles(styles)(({
    title,
    aside,
    children,
    classes,
    className,
    ...props
}) => {
    useAuthenticated();
    if (!children) { return null; }

    return (
        <div className={classnames('about-page', classes.root, className)}>
            <Title title={title} />
            <div className={`${classes.main}`} >
                <div className={`${classes.cardWrapper}`} >
                { cloneElement(Children.only(children), {}) }
                </div>
                {aside && cloneElement(aside, {})}
            </div>
        </div>
    );
});


const Show = props => (
    <ShowView {...props} />
);

Show.propTypes = {
    aside: PropTypes.node,
    children: PropTypes.element,
    classes: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.any,
};

export default Show;