import React from 'react';
import { ChipField  } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    chipError: {
        backgroundColor: "#ef9a9a",
        color: "#c62828"
    },
   
}));

const GasDistributorStationChipField = (props) => {
    const classes = useStyles()
    return (
        <ChipField {...props} size="small" className={!props.record.id ? classes.chipError : ""}/>
    );
}

export default GasDistributorStationChipField