import React from 'react';
import { makeStyles, Paper, Popper } from '@material-ui/core';

const useStyles = makeStyles(
    {
        suggestionsContainer: {
            zIndex: 1500,
        },
        suggestionsPaper: {
            maxHeight: '50vh',
            overflowY: 'auto',
        },
    },
    { name: 'RaAutocompleteSuggestionList' }
);

const AutocompleteSuggestionList = ({
    children,
    isOpen,
    menuProps,
    inputEl,
    classes: classesOverride = undefined,
    suggestionsContainerProps,
}) => {
    const classes = useStyles({ classes: classesOverride });

    return (
        <Popper
            open={isOpen}
            anchorEl={inputEl}
            className={classes.suggestionsContainer}
            modifiers={{}}
            {...suggestionsContainerProps}
        >
            <div {...(isOpen ? menuProps : {})}>
                <Paper
                    style={{
                        marginTop: 8,
                        minWidth: inputEl ? inputEl.clientWidth : null,
                    }}
                    className={classes.suggestionsPaper}
                >
                    {children}
                </Paper>
            </div>
        </Popper>
    );
};

export default AutocompleteSuggestionList;
