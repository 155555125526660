import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle, InputProps } from 'ra-core';



const passwordValidation = (value, allValues, props) => {
  if (!value) return undefined
  
  const checkLength = value.length >= 8;
  const checkLowerCase = /[a-z]/u.test(value);
  const checkUpperCase = /[A-Z]/u.test(value);
  const checkSymbol = /[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,]/u.test(value);
  const checkNumber = /[0-9]/.test(value);
  const isValid = checkLength && checkLowerCase && checkUpperCase && checkSymbol && checkNumber

  return isValid ? undefined : "ra.validation.password_invalid";
};

const PasswordInput = ({
    initiallyVisible = false,
    validate=[],
    inputProps={},
    ...props
}) => {
    const [visible, setVisible] = useState(initiallyVisible);
    const translate = useTranslate();

    const handleClick = () => {
        setVisible(!visible);
    };

    return (
        <TextInput
            {...props}
            validate={[passwordValidation, ...validate]}
            type={visible ? 'text' : 'password'}
            inputProps={{ ...inputProps, autoComplete: 'new-password' }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={translate(
                                visible
                                    ? 'ra.input.password.toggle_visible'
                                    : 'ra.input.password.toggle_hidden'
                            )}
                            onClick={handleClick}
                        >
                            {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
          
        />
    );
};

export default PasswordInput;
