import Icon from '@material-ui/icons/Delete';
import TrashItemList from './TrashItemList';
import TrashItemShow from './TrashItemShow';

const TrashItemIcon = Icon;


export {
    TrashItemIcon,
    TrashItemList,
    TrashItemShow,
};


