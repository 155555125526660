import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { can } from '../../abilityProvider';
// import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, required, minLength } from 'react-admin';
import { Edit } from '../Layout/Edit';
import CardBlankLayout from '../Layout/CardBlankLayout';
import UserBanner from '../User/UserBanner';
import SaveProfileButton from '../Buttons/SaveProfileButton';
import PhoneInput from '../Inputs/PhoneInput';
import PasswordInput from '../Inputs/PasswordInput';



const validateName = [required(), minLength(2)];
const multiValidate = (value, values) => {
    if (!values.email && !values.phone && !values.login) {
        return 'ra.action.one_of_fields_is_required';
    }
};

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

const ProfileToolbar = props => {
    return (
        <Toolbar {...props} >
            {can("update", "Profile") && <SaveProfileButton />}
        </Toolbar>
    );
}

const ProfileEdit = ({ staticContext, ...props }) => {
    // const classes = useStyles();

    return (
        <Edit {...props} title="Профиль" resource="User" >
            <UserBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<ProfileToolbar />}>
                    {can('update', 'Profile', 'name') && <TextInput source="name" validate={validateName} fullWidth />}
                    {can('update', 'Profile', 'description') && <TextInput source="description" fullWidth />}
                    {can("update", "Profile", "position") && <TextInput source="position" fullWidth />}
                    {can('update', 'Profile', 'login') && <TextInput source="login" validate={[multiValidate]} />}
                    {can('update', 'Profile', 'email') && <TextInput source="email" type="email" validate={[multiValidate]} inputProps={{ autoComplete: "new-password" }} />}
                    {can('update', 'Profile', 'phone') && <PhoneInput source="phone" validate={[multiValidate]} inputProps={{ autoComplete: "new-password" }} />}
                    {can("update", "Profile", "phoneContact") && <PhoneInput source="phoneContact" />}
                    {can('update', 'Profile', 'password') && <PasswordInput source="password" />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

ProfileEdit.defaultProps = {
    resource: "Profile",
    basePath: "/Profile",
    redirect: false,

};


const ProfileHOC = (props) => {
    const user = useSelector(state => state.user, shallowEqual);
    if (!user) return null;

    return <ProfileEdit id={user.id} {...props} />
}


export default ProfileHOC;