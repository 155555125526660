import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useChoices } from 'ra-core';

const useStyles = makeStyles(
    {
        checkbox: {
            height: 32,
        },
    },
    { name: 'RaCheckboxGroupInputItem' }
);

const CheckboxGroupInputItem = ({
    id,
    onChange,
    choice = {id: "check_all", name: "ra.action.check_all"},
    choices,
    value,
    ...rest
}) => {
    const classes = useStyles({});
    const { getChoiceText, getChoiceValue } = useChoices({
        optionText: "name",
        optionValue: "id",
        translateChoice: true,
    });

    const choiceName = getChoiceText(choice);
    const choiceValue = getChoiceValue(choice);

    if (!(choices && choices.length>1)) return null

    return (

        <FormControlLabel
            htmlFor={`${id}_${choiceValue}`}
            key={choiceValue}
            onChange={onChange}
            control={
                <Checkbox
                    id={`${id}_${choiceValue}`}
                    color="primary"
                    className={classes.checkbox}
                    checked={value ? value.length === choices.length : false}
                    value={String(choiceValue)}
                    {...rest}
                />
            }
            label={choiceName}
        />

    );
};

export default CheckboxGroupInputItem;
