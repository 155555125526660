import React, { memo } from "react";
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { red, orange, yellow, green, grey } from '@material-ui/core/colors';
import LensIcon from '@material-ui/icons/Lens';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';


export const statusColors = {
    CRITICAL: {
        light: red[200],
        main: red[600],
        dark: red[900],
    },
    ERROR: {
        light: orange[200],
        main: orange[600],
        dark: orange[900],
    },
    WARNING: {
        light: yellow[200],
        main: yellow[600],
        dark: yellow[900],
    },
    OK: {
        light: green[200],
        main: green[300],
        dark: green[900],
    },
    NOTCHECKED: {
        light: grey[200],
        main: grey[300],
        dark: grey[900],
    }
}



const useStyles = makeStyles(theme => ({
    dot: (props) => ({
        color: get(statusColors, [props.record.status, 'main']),
    })
}), { name: 'DeviceStatusField' });

const DeviceStatusField = memo((props) => {
    const { className, resource, source, record = {}, emptyText, labeled, ...rest } = props
    const classes = useStyles(props);
    const translate = useTranslate();
    const status = get(record, source);
    if (!status) return null;
    const label = translate(`resources.${resource}.enums.${source}.${status}`, { _: status })

    return labeled
        ? (<Typography variant="body2" component="span" ><LensIcon className={classes.dot} fontSize="small" /> {label}</Typography>)
        : (
            <Tooltip title={label} enterDelay={700}>
                <LensIcon className={classes.dot} fontSize="small" />
            </Tooltip>
        );
});

DeviceStatusField.displayName = 'DeviceStatusField';

DeviceStatusField.defaultProps = {
    addLabel: true,
};

export default DeviceStatusField;
