import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, TextField } from 'react-admin';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import TagArrayField from "../Tag/TagArrayField";
import TagChip from "../Tag/TagChip";
import { can } from "../../abilityProvider";
import useListStyles from '../Layout/list/useListStyles';
import useSelectedColumnsPreferences from '../../controller/preferences/useSelectedColumnsPreferences';
import useColumns from '../../controller/preferences/useColumns';



export const useDefaultDatagridColumns = () => {
    const classes = useListStyles()
    const columns = useColumns([
        can('getList', 'GasDistrict', 'name') && <ResourceLinkField source="name" target="_blank" alwaysOn />,
        can('getList', 'GasDistrict', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />,
        can('getList', 'GasDistrict', 'tags', 'Tag') && <TagArrayField source="tags" sortable={false}>
            <TagChip />
        </TagArrayField>,
        can('getList', 'GasDistrict', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
        can('getList', 'GasDistrict', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
    ])

    return columns
}

export const GasDistrictReferenceDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {can('getList', 'GasDistrict', 'name') && <ResourceLinkField source="name" target="_blank" />}
            {can('getList', 'GasDistrict', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />}
            {can('getList', 'GasDistrict', 'tags', 'Tag') && <TagArrayField source="tags" sortable={false}>
                <TagChip />
            </TagArrayField>}
            {can('getList', 'GasDistrict', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'GasDistrict', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
        </Datagrid>
    );
}

const GasDistrictDatagrid = ({ columns, ...props }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles()
    const SelectedColumns = useSelectedColumnsPreferences(columns);

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }} >
            {SelectedColumns}
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {can('getOne', 'GasDistrict') && <ShowBlankItem />}
                {can('update', 'GasDistrict') && <EditMenuItem />}
                {can('remove', 'GasDistrict') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    )
}



export default GasDistrictDatagrid;