
  import React, { cloneElement, Children } from 'react';
  import get from 'lodash/get';
  import { useForm, useFormState } from "react-final-form";
  import { makeStyles } from '@material-ui/core/styles';

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: "column",
    },
  }));

const JsonVariableInputs = ({children, record, source, ...props}) => {
  const classes = useStyles();
  const { values } = useFormState();
  const jsonVariables = get(values, source)


  return jsonVariables && Array.isArray(jsonVariables) && jsonVariables.length > 0 ? (
    <div className={classes.root} >
    {jsonVariables.map((item, index) => {
             
      return cloneElement(Children.only(children), {
        ...props,
        key: `${source}[${index}]`,
        id: `${source}[${index}]`,
        record,
        source: `${source}[${index}]`,
      });
    })}
    </div>
  ) : null;
}
  
  
  export default JsonVariableInputs;
  