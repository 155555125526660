import List from '../Layout/list/List';
import React from 'react';
import useUserExporter from './useUserExporter';
import UserBulkActionButtons from './UserBulkActionButtons';
import UserFilter from './UserFilter';
import UserDatagrid from './UserDatagrid';



const UserList = (props) => {
    const exporter = useUserExporter()
    const manualSubmitForm = React.useRef(null)

    return (
        <List
            {...props}
            bulkActionButtons={<UserBulkActionButtons />}
            filters={<UserFilter formRef={manualSubmitForm} />}
            exporter={exporter}
        >
            <UserDatagrid />
        </List>
    )
};

UserList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};


export default UserList;