import Icon from '../Icons/ScaleBalance';
import BalanceRegionList from './BalanceRegionList';
import BalanceRegionCreate from './BalanceRegionCreate';
import BalanceRegionEdit from './BalanceRegionEdit';
import BalanceRegionShow from './BalanceRegionShow';

const BalanceRegionIcon = Icon;


export {
    BalanceRegionIcon,
    BalanceRegionList,
    BalanceRegionShow,
    BalanceRegionCreate,
    BalanceRegionEdit,
};
