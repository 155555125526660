import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useUnselectAll, useRefresh, fetchStart, fetchEnd, Button } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import RemoveIcon from '@material-ui/icons/Delete';
import removeManyToTrash from '../../dataProvider/Core/removeManyToTrash';

export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    removeButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}));

const BulkRemoveButton = ({ 
    basePath,
    resource,
    classes: classesOverride,
    className,
    filterValues,
    selectedIds,
    ...rest }) => {

    const dispatch = useDispatch();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);
    
    const handleClick = () => {

        setLoading(true);
        dispatch(fetchStart());

        removeManyToTrash({
            resource,
            ids: selectedIds,
        }).then(() => {
            refresh();
            notify( 'ra.notification.moved_to_trash', 'info', { smart_count: selectedIds.length } );
            unselectAll(resource)
            
        })
        .catch((error) => {
            notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
        })
        .finally(() => {
            setLoading(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    };

    return (
        <Button
            onClick={handleClick}
            disabled={loading}
            label='ra.action.remove'
            className={`ra-remove-button ${classes.removeButton} ${className}`}
            key="button"
            {...sanitizeRestProps(rest)}
        >
            <RemoveIcon />
        </Button>
    );
        
};

export default BulkRemoveButton;