import React, { useCallback } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
    fetchRelatedRecords,
    useDataProvider,
    useNotify,
    useListContext,
    useResourceContext,
    useTranslate,
} from 'ra-core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';



const stopPropagation = e => e.stopPropagation();

const ExportButtonAsMenuItem = props => {
    const {
        maxResults = 5000,
        onClick,
        label = 'ra.action.export',
        exporter: customExporter,
        sort, // deprecated, to be removed in v4
        ...rest
    } = props;
    const {
        filter,
        filterValues,
        currentSort,
        exporter: exporterFromContext,
        total,
    } = useListContext(props);
    const translate = useTranslate()
    const resource = useResourceContext(props);
    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleClick = useCallback(
        event => {
            dataProvider
                .getList(resource, {
                    sort: currentSort || sort,
                    filter: filter
                        ? { ...filterValues, ...filter }
                        : filterValues,
                    pagination: { page: 1, perPage: maxResults },
                })
                .then(
                    ({ data }) =>
                        exporter &&
                        exporter(
                            data,
                            fetchRelatedRecords(dataProvider),
                            dataProvider,
                            resource
                        )
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            currentSort,
            dataProvider,
            exporter,
            filter,
            filterValues,
            maxResults,
            notify,
            onClick,
            resource,
            sort,
        ]
    );

    return (
        <MenuItem
            onClick={handleClick}
            disabled={total === 0}
            {...sanitizeRestProps(rest)}
        >
            <ListItemIcon>
                <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{translate(label)}</Typography>
        </MenuItem>
    )
};


const sanitizeRestProps = ({
    basePath,
    filterValues,
    resource,
    ...rest
}) => rest;



export default ExportButtonAsMenuItem;
