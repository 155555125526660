import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { REPORT_DESPATCH_FRAGMENT } from '../typeDefs';

export const DELETE_REPORT_DESPATCH = gql`
    mutation deleteReportDespatch($where: ReportDespatchWhereUniqueInput!) {
        data: deleteReportDespatch(where: $where) {
            item {...ReportDespatchFragment}
        }
    }
    ${REPORT_DESPATCH_FRAGMENT}
`

export default (args) => {
    return apolloClient.mutate({
        mutation: DELETE_REPORT_DESPATCH,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    })
};
