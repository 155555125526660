import React from 'react';
import { ReferenceArrayInput } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from './TagAutocompleteSuggestionItem';
import SelectedItem from './TagAutocompleteSelectedItem';



const matchTrue = () => true;

const TagReferenceArrayInput = props => {
    return (
        <ReferenceArrayInput {...props}>
            <AutocompleteArrayInput selectedItem={<SelectedItem />} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>     
    );
};

export default TagReferenceArrayInput;