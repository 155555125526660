import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';


const GASREGION = `{ id name }`;
const GASDISTRICT = `{ id name gasRegion ${GASREGION}}`;
const GASCONSUMER = `{ id name }`;
const GASMETERPAD = `{ id name gasConsumer ${GASCONSUMER} gasDistrict ${GASDISTRICT}}`;
const GASMETERUNIT = `{ id name gasMeterPad ${GASMETERPAD}}`;
const GASMETERPOINT = `{ id name gasMeterUnit ${GASMETERUNIT}}`;
const GASDISTRIBUTORSORGANIZATION = `{ id name }`;
const GASDISTRIBUTORSTATION = `{ id name }`;
const GASDISTRIBUTORSTATIONOUTPUT = `{ id name gasDistributorStation ${GASDISTRIBUTORSTATION}}`;


const getInfo = (resource) => {
  switch (resource) {
    case "GasRegion": {
      return GASREGION
    }
    case "GasDistrict": {
      return GASDISTRICT
    }
    case "GasConsumer": {
        return GASCONSUMER
    }
    case "GasMeterPad": {
        return GASMETERPAD
    }
    case "GasMeterUnit": {
        return GASMETERUNIT
    }
    case "GasMeterPoint": {
      return GASMETERPOINT
    }
    case "GasDistributorOrganization": {
      return GASDISTRIBUTORSORGANIZATION
    }
    case "GasDistributorStation": {
        return GASDISTRIBUTORSTATION
    }
    case "GasDistributorStationOutput": {
        return GASDISTRIBUTORSTATIONOUTPUT
    }
    
  }     
};

const setData = (resource, data, state) => {

  const {id, name, ...nested} = data.data;

  const responce = {};

  switch (resource) {
    case "GasRegion": {
      responce.GasRegion = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasDistrict": {
      const gasRegion = nested.gasRegion
      responce.GasRegion = gasRegion ? { id: gasRegion.id, name: gasRegion.name, icon: state["GasRegion"].props.icon } : null
      responce.GasDistrict = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasConsumer": {
      const gasDistrict = nested.gasDistrict
      const gasRegion = gasDistrict && gasDistrict.gasRegion
      responce.GasRegion = gasRegion ? { id: gasRegion.id, name: gasRegion.name, icon: state["GasRegion"].props.icon } : null
      responce.GasDistrict = gasDistrict ? { id: gasDistrict.id, name: gasDistrict.name, icon: state["GasDistrict"].props.icon } : null
      responce.GasConsumer = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasMeterPad": {
      const gasDistrict = nested.gasDistrict
      const gasConsumer = nested.gasConsumer
      const gasRegion = gasDistrict && gasDistrict.gasRegion
      responce.GasRegion = gasRegion ? { id: gasRegion.id, name: gasRegion.name, icon: state["GasRegion"].props.icon } : null
      responce.GasDistrict = gasDistrict ? { id: gasDistrict.id, name: gasDistrict.name, icon: state["GasDistrict"].props.icon } : null
      responce.GasConsumer = gasConsumer ? { id: gasConsumer.id, name: gasConsumer.name, icon: state["GasConsumer"].props.icon } : null
      responce.GasMeterPad = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasMeterUnit": {
      const gasMeterPad = nested.gasMeterPad
      const gasConsumer = gasMeterPad && gasMeterPad.gasConsumer
      const gasDistrict = gasMeterPad && gasMeterPad.gasDistrict
      const gasRegion = gasDistrict && gasDistrict.gasRegion
      responce.GasRegion = gasRegion ? { id: gasRegion.id, name: gasRegion.name, icon: state["GasRegion"].props.icon } : null
      responce.GasDistrict = gasDistrict ? { id: gasDistrict.id, name: gasDistrict.name, icon: state["GasDistrict"].props.icon } : null
      responce.GasConsumer = gasConsumer ? { id: gasConsumer.id, name: gasConsumer.name, icon: state["GasConsumer"].props.icon } : null
      responce.GasMeterPad = gasMeterPad ? { id: gasMeterPad.id, name: gasMeterPad.name, icon: state["GasMeterPad"].props.icon } : null
      responce.GasMeterUnit = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasMeterPoint": {
      const gasMeterUnit = nested.gasMeterUnit
      const gasMeterPad = gasMeterUnit && gasMeterUnit.gasMeterPad
      const gasConsumer = gasMeterPad && gasMeterPad.gasConsumer
      const gasDistrict = gasMeterPad && gasMeterPad.gasDistrict
      const gasRegion = gasDistrict && gasDistrict.gasRegion
      responce.GasRegion = gasRegion ? { id: gasRegion.id, name: gasRegion.name, icon: state["GasRegion"].props.icon } : null
      responce.GasDistrict = gasDistrict ? { id: gasDistrict.id, name: gasDistrict.name, icon: state["GasDistrict"].props.icon } : null
      responce.GasConsumer = gasConsumer ? { id: gasConsumer.id, name: gasConsumer.name, icon: state["GasConsumer"].props.icon } : null
      responce.GasMeterPad = gasMeterPad ? { id: gasMeterPad.id, name: gasMeterPad.name, icon: state["GasMeterPad"].props.icon } : null
      responce.GasMeterUnit = gasMeterUnit ? { id: gasMeterUnit.id, name: gasMeterUnit.name, icon: state["GasMeterUnit"].props.icon } : null
      responce.GasMeterPoint = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasDistributorOrganization": {
      responce.GasDistributorOrganization = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasDistributorStation": {
      responce.GasDistributorStation = { id, name, icon: state[resource].props.icon }
      break;
    }
    case "GasDistributorStationOutput": {
      const gasDistributorStation = nested.gasDistributorStation
      responce.GasDistributorStation = gasDistributorStation ? { id: gasDistributorStation.id, name: gasDistributorStation.name, icon: state["GasDistributorStation"].props.icon } : null
      responce.GasDistributorStationOutput = { id, name, icon: state[resource].props.icon }
      break;
    }
  }
  
  return responce;
};

export default function(resource, id) {

  const resourcesState = useSelector((state) => state.admin.resources);

  const [state, setState] = useState({
    data: undefined,
    error: null,
    loading: true,
    loaded: false,
  })

  useEffect(() => {

    async function fetchData() {
  
      apolloCoreClient.query({
        query: gql`query ${resource}Hierarchy($where: ${resource}WhereUniqueInput!){
            data: ${resource}(where: $where) ${getInfo(resource)}
        }`,
        variables: { where: {id} },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        setState({
            data: setData(resource, data, resourcesState) ,
            loading: false,
            loaded: true,
        });
      })
      .catch(error => {
          setState({
              error,
              loading: false,
              loaded: false,
          });
      });
    }

    if (id) fetchData();

    
  }, [resource, id])

  return state;
}
