import React from 'react';
import get from 'lodash/get'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { NumberField } from 'react-admin';



const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        fontWeight: theme.typography.fontWeightBold
    },
    warning: {
        color: theme.palette.warning.dark,
        fontWeight: theme.typography.fontWeightBold
    },
}));

const checkOutOfRange = (value) => value < 0.6 || value > 0.9

export const validate = (value, record) => {
    const invalid = checkOutOfRange(value)
    const name = record.name
    const parameter = "P"
    const range = `0.6 < ${parameter} < 0.9`
    return invalid ? { message: 'resources.GasQualityCertificate.validation.out_of_range', args: { value, parameter, range, name } } : undefined
}

const DensityField = (props) => {
    const classes = useStyles()
    const { record, source } = props
    const value = get(record, source)
    const error = checkOutOfRange(value)

    return (<NumberField {...props} className={classNames({ [classes.error]: error })} />
    )
}

DensityField.defaultProps = {
    addLabel: true,
};

export default DensityField;

