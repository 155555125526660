import React from 'react';
import PropTypes from 'prop-types';
import { ShowContextProvider, useCheckMinimumRequiredProps, useShowController } from 'ra-core';


import { ShowView } from './ShowView';


export const Show = (props) => {
    useCheckMinimumRequiredProps('Show', ['children'], props);
    const controllerProps = useShowController(props);
    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView {...props} {...controllerProps} />
        </ShowContextProvider>
    );
};

Show.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    hasCreate: PropTypes.bool,
    hasEdit: PropTypes.bool,
    hasList: PropTypes.bool,
    hasShow: PropTypes.bool,
    id: PropTypes.any.isRequired,
    resource: PropTypes.string.isRequired,
    title: PropTypes.node,
};

export default Show