import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useForm } from "react-final-form";
import { ReferenceInput, AutocompleteInput } from 'react-admin';






const ContactUserReferenceInput = (props) => {
    const form = useForm();
    const users = useSelector(state => state.admin.resources.User.data, shallowEqual);

    const handleChangeUser = useCallback((id) => {
        const user = users[id]
        form.change("name", user.name)
        form.change("email", user.email)
    }, [users]);



    return (
        <ReferenceInput {...props} onChange={handleChangeUser}>
            <AutocompleteInput optionText="name" options={{ suggestionsContainerProps: { style: { zIndex: 10000000000000 } } }} />
        </ReferenceInput>
    );
}

export default ContactUserReferenceInput;