import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
// import EnumInput from '../Inputs/EnumInput';
import ColorInput from '../Inputs/ColorInput';
import ResourceInput from './TagResourceInput';
import { can } from "../../abilityProvider";



// const useStyles = makeStyles(theme => ({

// }));

const TagCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                {can('create', 'Tag', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'Tag', 'description') && <TextInput source="description" multiline fullWidth />}
                {/* {can('create', 'Tag', 'type') && <EnumInput source="type" validate={required()} />} */}
                {can('create', 'Tag', 'resource') && <ResourceInput source="resource" validate={required()} />}
                {can('create', 'Tag', 'color') && <ColorInput source="color" />}
            </SimpleForm>
        </Create>
    );
};

export default TagCreate;