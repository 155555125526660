import React, { Children, isValidElement, cloneElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Skeleton } from '@material-ui/lab';
import useResourceHierarchy from '../../dataProvider/Core/useResourceHierarchy';



const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: 40,
    alignItems: "flex-start",
    paddingTop: 24,
    paddingBottom: 8,
    justifyContent: "flex-end",
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  },
  skeleton: {
    margin: 2,
    borderRadius: 4
  },
  separator: {
    margin: 2,
  },
}));

const ResourceBreadcrumbs = ({ children, resource, ...props }) => {

  const classes = useStyles();
  const accessResourses = useSelector(state => state.access && state.access.accessResources, shallowEqual);


  const { data, loading, error } = useResourceHierarchy(resource, props.data && props.data.id)

  if (error) return null

  if (loading) {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
        {Children.map(children, (_, index) => (<Skeleton variant="rect" width={128} height={32} key={index} classes={{ root: classes.skeleton }} />))}
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root} classes={{ separator: classes.separator }}>
      {Children.map(children, breadcrumb => {
        const resource = breadcrumb.props.resource
        const record = data[resource]

        return record && accessResourses && isValidElement(breadcrumb)
          ? cloneElement(breadcrumb, {
            record,
            disabled: accessResourses[resource] === false
          })
          : null;
      })}
    </Breadcrumbs>
  );
}


export default ResourceBreadcrumbs;