import gql from 'graphql-tag'
import apolloCaslClient from './apolloCaslClient';

const GET_ABILITY_RULES = gql`
  query getAbility {
    rules: getAbility {
      actions
      subject
      conditions
      fields
      inverted
      reason
    }
  }
`

export default () => {
  return apolloCaslClient.query({
      query: GET_ABILITY_RULES,
      fetchPolicy: 'network-only',
      onError: (error) => {
          alert(error)
      }
  });
};