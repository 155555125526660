import React from 'react';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';


const useChipStyles = makeStyles(theme => ({

}));

const GasMeterPadDisabledChip = ({ className, classes: classesOverride, record, source, resource, hideWhen, ...props }) => {
    const classes = useChipStyles({ classes: classesOverride });
    if (!record) return null;
    const writable = get(record, source);
    if (writable === hideWhen) return null;

    const label = writable ? "Запись ПГ" : "Нет записи ПГ";

    return (
        <Chip
            size="small"
            classes={{ root: classes.root }}
            label={label}
        />
    );
};

export default GasMeterPadDisabledChip;