import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, ReferenceArrayInput, required } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import ReportReferenceArrayInputWithDefault from '../Report/ReportReferenceArrayInputWithDefault';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
// }));

const BalanceRegionCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'BalanceRegion', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'BalanceRegion', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'BalanceRegion', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'BalanceRegion', 'gasDistributorStations', 'GasDistributorStation') && <ReferenceArrayInput source="gasDistributorStationsIds" reference="GasDistributorStation" filter={{ balanceRegion: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.BalanceRegion.fields.gasDistributorStations" fullWidth>
                    <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                </ReferenceArrayInput>}
                {can('create', 'BalanceRegion', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.BalanceRegion.fields.reports" fullWidth />}
                {can('create', 'BalanceRegion', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.BalanceRegion.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default BalanceRegionCreate;