import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import backgroundImage from "../../files/MainBanner.svg";
import { can } from "../../abilityProvider";




const useStyles = makeStyles(theme => ({
    banner: {
        height: 460,
        position: "relative",
        overflow: "unset",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        flexGrow: 1,
        zIndex: 1,
    },
    title: {
        // fontWeight: 500,
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 16,
    },
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const WidgetBanner = forwardRef(({ ...props }, ref) => {
    const classes = useStyles();

    return (

        <Grid container >
            <Grid item xs={12} md={6} className={classes.container} >
                {can('getOne', 'Main')
                    ? (<div style={{ maxWidth: 480 }}>
                        <Typography variant="h3" color="primary" gutterBottom className={classes.title}>Хочешь узнать посуточный объем газопотребления в регионе?</Typography>
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom >Портал позволяет построить отчет любой сложности в два клика</Typography>
                        <Button
                            component={LinkRef}
                            ref={ref}
                            to="/GasRegion"
                            variant="contained"
                            color="primary"
                            disableElevation
                            style={{ marginTop: 24 }}>
                            Открыть Регионы
                    </Button>
                    </div>)
                    : (<div style={{ maxWidth: 480 }}>
                        <Typography variant="h3" color="primary" gutterBottom className={classes.title}>Добро пожаловать на портал АСКУГ Показатели!</Typography>
                    </div>)}
            </Grid>
            <Grid item xs={12} md={6} className={classes.container}>
                <img src={backgroundImage} alt="lorem" style={{ width: "100%", maxWidth: 734 }} />
            </Grid>
        </Grid>

    )

});


export default WidgetBanner;


