import gql from 'graphql-tag'
import { apolloAuthClientWithAuth } from './apolloAuthClient';



const CREATE_CLIENT = gql`
  mutation createUser($login: String, $email: String, $phone: String, $name: String!, $password: String!, $role: String) {
    createClient: createUser(login: $login, email: $email, phone: $phone, name: $name, password: $password, role: $role) {       
      id
      login
      email
      phone
      name
      role
      banned
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (args) => {
  return apolloAuthClientWithAuth.mutate({
    mutation: CREATE_CLIENT,
    variables: { ...args },
    onError: (error) => {
      alert(error)
    },
  });
};
