import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 4,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    menuGroupTitle: {
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(3),
    },

}));

const MenuGroup = ({ title, children, ...props }) => {
    const classes = useStyles();
    if (children.length === 0) return null;

    return (
        <div className={classes.root}>
            {(!!title) && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap className={classes.menuGroupTitle} >{title}</Typography>}
            {children}
        </div>
    )

};


export default MenuGroup;