import { useEffect, useState } from 'react';
import React from 'react';
import locationurl from 'url';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import { URI_SCADAJS } from '../../appsettings';
import Iframe from 'react-iframe'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';

const useStyles = makeStyles(theme => ({
    card: {},
    scadaheader: {
        display: "flex",
        marginBottom: theme.spacing.unit,
        justifyContent: "space-between"
    },
    switchedit: {
        marginLeft: "auto"
    },
    cardContent: {
        padding: 0
    }
}));

const DashboardPreview = (props) => {
    return (<div></div>);
}

DashboardPreview.propTypes = {
    icon: PropTypes.element,
    gridItemProps: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
    }),
}


const Dashboard = ({ record, ...props }) => {

    const target = locationurl.parse(props.location.search, true).query;

    // id:'ckaxom5wd01a96wt0259u24q8'
    // resource:'GasMeterUnit'

    const classes = useStyles();
    const [url, setUrl] = useState(null);

    const createUrl = (isEdit, dbid) => {
        if (record) {
            if (!dbid && url !== null) {
                const pu = locationurl.parse(url, true).query;
                dbid = pu.dbid;
            }

            const fix_url_scadajs = URI_SCADAJS[URI_SCADAJS.length - 1] == '/' ? URI_SCADAJS.substring(0, URI_SCADAJS.length - 1) : URI_SCADAJS;

            setUrl(`${fix_url_scadajs}/${isEdit ? 'editor' : 'player'}?saveas=false&uri=${encodeURIComponent(record.uri)}${dbid ? '&dbid=' + dbid : ''}`);

        }
    }

    useEffect(() => {

        if (target.resource === 'GasMeterUnit') {
            const query = gql`query GasMeterUnit($where: GasMeterUnitWhereUniqueInput!) { data: GasMeterUnit( where: $where ) { id, aisId, gasMeter { id, dbId } }}`;
            apolloCoreClient.query({
                query,
                variables: { where: { id: target.id } },
                fetchPolicy: 'network-only',
            }).then(({ data: { data } }) => {
                createUrl(false, (data.gasMeter && data.gasMeter.dbId) || data.aisId);
            })
        }

        return () => {
            setUrl(null);
        }
    }, [record]);

    const handleChange = (event) => {
        createUrl(event.target.checked);
    };

    if (url) {
        return <>
            <div className={classes.scadaheader}>
                <div>{record.name}</div>
                <FormControlLabel
                    className={classes.switchedit}
                    control={<Switch
                        onChange={handleChange}
                        name="EditView"
                        size="small"
                    />}
                    labelPlacement="start"
                />

            </div>
            <Card className={classes.card} >
                <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
                    <Iframe url={url} display="initial" overflow="auto" width="100%" height="750px" frameBorder="0" />
                </CardContent>
            </Card>

        </>
    } else {
        return <div>Loading...</div>
    }

    /*  
    createdAt:'2020-07-20T07:56:17.718Z'
    createdBy:null
    deleted:false
    description:null
    executeApp:'SCADAJS'
    gasMeterType:null
    id:'ckcu7t9dj001t0792ohz95rxn'
    name:'test1'
    resource:'GasMeterUnit'
    updatedAt:'2020-07-20T07:56:17.718Z'
    uri:'/ScadaJS/ckcu7t7j10000u8gvbkwlb
    */
}

const iconPreview = (prop) => {
    return (<DeveloperBoardIcon></DeveloperBoardIcon>);
}

export default {
    Dashboard,
    DashboardPreview,
    iconPreview,
    hasDashboard: true,
    hasDashboardPreview: false,
    error: undefined,
    loading: false,
    loaded: true
};