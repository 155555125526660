import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
// import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import QuickCreateButton from "../Buttons/QuickCreateButton";
import { ContactQuickCreateForm } from "./ContactCreate";
import { can } from "../../abilityProvider";



const matchTrue = () => true;

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "flex-start"
    },
    reference: {
        display: "inline-block",
        width: "90%"
    }
});

const optionRenderer = choice => choice ? `${choice.name} <${choice.email}>` : choice;

const ContactReferenceArrayInput = props => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <ReferenceArrayInput {...props} classes={{ container: classes.reference }} fullWidth >
                <AutocompleteArrayInput optionText={optionRenderer} matchSuggestion={matchTrue} />
            </ReferenceArrayInput>
            {can('create', 'Contact') && <QuickCreateButton {...props} isArray>
                <ContactQuickCreateForm />
            </QuickCreateButton>}
        </div>
    );
};

export default ContactReferenceArrayInput;
