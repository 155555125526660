import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '../authPrivider/getAccessToken';
import { PERMISSIONS_NAMESPACE, URI_CASL } from '../appsettings';

const httpLink = createHttpLink({
  uri: URI_CASL
})

const authLink = setContext(async (_, { headers }) => {

  const token = await getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      appName: PERMISSIONS_NAMESPACE,
    }
  }
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});
