import { ReferenceArrayInput } from 'react-admin';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import QuickFilter from "../Layout/filter/QuickFilter";
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import React from 'react';
import SearchInput from '../Inputs/SearchInput';
import { can } from "../../abilityProvider";
import { matchTrue } from '../../utils';



const CounterpartyFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
        {can('getList', 'Counterparty', 'users', 'User') && <ReferenceArrayInput source="users_and" label="resources.User.fields.users_and" reference="User" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'users', 'User') && <ReferenceArrayInput source="users_not_and" label="resources.User.fields.users_not_and" reference="User" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'access') && <ReferenceArrayInput source="accessGasRegions_and" label="resources.Counterparty.fields.accessGasRegion" reference="GasRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'access') && <ReferenceArrayInput source="accessGasDistricts_and" label="resources.Counterparty.fields.accessGasDistrict" reference="GasDistrict" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'access') && <ReferenceArrayInput source="accessGasConsumers_and" label="resources.Counterparty.fields.accessGasConsumer" reference="GasConsumer" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'access') && <ReferenceArrayInput source="accessGasMeterPads_and" label="resources.Counterparty.fields.accessGasMeterPad" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'access') && <ReferenceArrayInput source="accessGasMeterUnits_and" label="resources.Counterparty.fields.accessGasMeterUnit" reference="GasMeterUnit" sort={{ field: 'name', order: 'ASC' }} perPage={100} >
            <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'Counterparty', 'access') && <QuickFilter source="counterparty_not_access" label="resources.Counterparty.fields.access_is_null" defaultValue={true} />}

    </ManualSubmitFilter>
)



export default CounterpartyFilter;