import React, { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import DefaultMenuItem from './MenuItem';



const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
    },
}));

const Menu = ({
    className,
    menu,
    menuItem: ComponentMenuItem,
    ...props
}) => {
    const classes = useStyles();
    const depth = 0;

    return (
        <List component="nav" className={className} classes={{root: classes.root}}>
            {menu.map(menuItem => cloneElement(ComponentMenuItem, {
                key: menuItem.id,
                item: menuItem,
                depth,
            }))}
        </List>
    );
}

Menu.defaultProps = {
    menuItem: <DefaultMenuItem />,
}



export default Menu;