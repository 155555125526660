import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, SimpleShowLayout as RaSimpleShowLayout } from 'react-admin';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    content: {}
}));

const SimpleShowLayout = ({
    children,
    classes: classesOverride,
    className,
    component: Component,
    label,
    ...props
}) => {

    const classes = useStyles();
    const translate = useTranslate();
    if (!children) return null

    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label, { _: label })}</Typography>}
            <Component className={classes.content}>
                <RaSimpleShowLayout {...props}>
                    {children}
                </RaSimpleShowLayout>
            </Component>
        </div>
    );
};

SimpleShowLayout.defaultProps = {
    component: Card,
};



export default SimpleShowLayout;