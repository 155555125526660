import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 3l8 6v5l-1-1-2.828 2.828H14V14h2v-2h-2.268a2 2 0 00-3.464 0H8v2h2v2.268A2 2 0 009 18v3H4V9l8-6zm3 16.828v-2h4.5l-1.7-1.8 1.2-1.2 4 4-4 4-1.3-1.2 1.8-1.8H15z" />
    </SvgIcon>
  );
}

export default Icon;