import { buildQuery } from 'ra-data-graphql-simple';
import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import { GET_MANY_DEVICESTATUSES, GET_ONE_DEVICESTATUS, REFRESH_DEVICESTATUS } from './typeDefs';
import { GET_ENUMS } from './typeDefs';


const customBuildQuery = introspection => (fetchType, resource, params) => {

    const { id, data, previousData } = params;

    if (resource === 'DeviceStatus') {
        switch (fetchType) {
            case GET_ONE: {

                return {
                    query: GET_ONE_DEVICESTATUS,
                    variables: { where: { dbId: params.id } },
                    parseResponse: ({ data: { data, loading, networkStatus } }) => {
                        const { item } = data
                        return {
                            data: item
                        };
                    },
                };
                break;
            }
            case GET_MANY: {
                return {
                    query: GET_MANY_DEVICESTATUSES,
                    variables: {
                        filter: {
                            dbIds: params.ids
                        },
                    },
                    parseResponse: ({ data: { data, loading, networkStatus } }) => {
                        const { items, pageInfo } = data
                        return {
                            data: items,
                            total: pageInfo.totalItems,
                        };
                    },
                };
            }
            case GET_MANY_REFERENCE: {
                return {
                    query: GET_MANY_DEVICESTATUSES,
                    variables: {
                        ...params
                    },
                    parseResponse: ({ data: { data, loading, networkStatus } }) => {
                        const { items, pageInfo } = data
                        return {
                            data: items,
                            total: pageInfo.totalItems,
                        };
                    },
                };
            }

            default: break;
        }
    };

    if (fetchType === "getEnum") {
        return {
            query: GET_ENUMS,
            variables: { ...params },
            parseResponse: (({ data: { data } }) => {
                const { enumValues } = data;
                return {
                    data: enumValues,
                }
            })
        };
    }


    const builtQuery = buildQuery(introspection)(fetchType, resource, params);
    return builtQuery;
};

export default customBuildQuery;

