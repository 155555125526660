import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

const ONLINE_USERS = gql`
    query OnlineUsers {
        onlineUsers: allUsers(
            filter: {
                online: true
            }
        ){
            id
            authId
            online
        },
    }
`;

export default () => {
    return apolloCoreClient.query({
        query: ONLINE_USERS,
        onError: (error) => {
            alert(error)
        }
    });
};
