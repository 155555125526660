import React, { Fragment } from 'react';
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import GasConsumerBulkActionButtons from './GasConsumerBulkActionButtons';
import GasConsumerFilter from './GasConsumerFilter';
import GasConsumerDatagrid, { useDefaultDatagridColumns } from './GasConsumerDatagrid';
import useGasConsumerExporter from './useGasConsumerExporter';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';



const GasConsumerList = props => {
    const exporter = useGasConsumerExporter()
    const manualSubmitForm = React.useRef(null)
    const columns = useDefaultDatagridColumns(props)

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/4GasConsumer" target="_blank" />
            <List
                {...props}
                actions={<ListActions columns={columns} />}
                bulkActionButtons={<GasConsumerBulkActionButtons />}
                exporter={exporter}
                filters={<GasConsumerFilter formRef={manualSubmitForm} />}
            >
                <GasConsumerDatagrid columns={columns} />
            </List>
        </Fragment>
    );
};

GasConsumerList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};

export default GasConsumerList;
