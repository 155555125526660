import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';


export default ({resource, where}) => {
    return apolloCoreClient.mutate({
        mutation: gql`mutation remove${resource}($where: ${resource}WhereUniqueInput!) {
            data: remove${resource}(where: $where) {
                id
            }
        }`,
        variables: { where },
        onError: (error) => {
            alert(error)
        }
    });
};