import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useTranslate } from 'ra-core';


const parseGridValue = (theme, value) => {
  return (typeof value === 'string') ? value : theme.spacing(value) + "px";
}

const parseGridSize = (theme, size) => {
  return (Array.isArray(size))
    ? `minmax(${size.map(value => parseGridValue(theme, value)).join(", ")})`
    : parseGridValue(theme, size);
}

const generateGrid = (theme, breakpoint, props) => {
  const value = props[breakpoint];
  if (!value) return;
  const { count, size } = value;
  return {
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: `repeat(${count}, ${parseGridSize(theme, size)})`
    },
  };
}


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    '&:first-child': {
      marginTop: 0,
    },
  },
  grid: ({ spacing }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
    gridGap: theme.spacing(spacing),
  }),
  "grid-xs": (props) => generateGrid(theme, "xs", props),
  "grid-sm": (props) => generateGrid(theme, "sm", props),
  "grid-md": (props) => generateGrid(theme, "md", props),
  "grid-lg": (props) => generateGrid(theme, "lg", props),
  "grid-xl": (props) => generateGrid(theme, "xl", props),
}));



const GridLayoutSimple = ({
  children,
  classes: classesOverride,
  className: classNameOverride,
  lg = false,
  md = false,
  sm = false,
  xl = false,
  xs = false,
  spacing = 2,
  label,
  ...props
}) => {
  const classes = useStyles({ classes: classesOverride, lg, md, sm, xl, xs, spacing });
  const translate = useTranslate();
  if (!children) return null;
  const gridClassNames = classnames(
    classes.grid,
    {
      [classes[`grid-xs`]]: xs !== false,
      [classes[`grid-sm`]]: sm !== false,
      [classes[`grid-md`]]: md !== false,
      [classes[`grid-lg`]]: lg !== false,
      [classes[`grid-xl`]]: xl !== false,
    },
    classNameOverride,
  );

  return (
    <div className={classes.root} >
      {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
      <div className={gridClassNames}>
        {children}
      </div>
    </div>
  );
};



export default GridLayoutSimple;