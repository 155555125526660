import React, { memo, useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import sanitizeFieldRestProps from '../Fields/sanitizeFieldRestProps'
import { shallowEqual, useSelector } from 'react-redux';
import getOneDevice from '../../dataProvider/Core/getOneDevice';



const stopPropagation = e => e.stopPropagation();

const GasMeterUnitDeviceLinkField = memo(({ className, source, record, ...props }) => {

    const [device, setDevice] = useState()
    const serviceApp = useSelector(state => state.user.apps.find(app => app.id === "askug_service"), shallowEqual);
    useEffect(() => {
        if (!record) return
        getOneDevice({ where: { id: record.id } })
            .then(responce => {
                const device = responce.data?.data
                setDevice(device)
            })

        return () => {
            setDevice(undefined)
        }
    }, [record])

    const url = serviceApp && serviceApp.url
    if (!record || !device || !url) return null
    // const device =  getOneDevice({ where: { id: record.id } })
    const href = `${url}/#/Device/${device.id}/show`

    return (
        <Link
            className={className}
            href={href}
            onClick={stopPropagation}
            {...sanitizeFieldRestProps(props)}
            variant="body2"
        >
            {href}
        </Link>
    );
}
);

// what? TypeScript looses the displayName if we don't set it explicitly
GasMeterUnitDeviceLinkField.displayName = 'DeviceLinkField';

GasMeterUnitDeviceLinkField.defaultProps = {
    addLabel: true,
};

export default GasMeterUnitDeviceLinkField;