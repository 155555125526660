import gql from 'graphql-tag';
import { CREATE, GET_ONE, GET_LIST, GET_MANY, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import { NAME_APP } from '../../appsettings';
import createClient from '../../authPrivider/createClient';
import updateClient from '../../authPrivider/updateClient';



// eslint-disable-next-line import/no-anonymous-default-export
export default async (type, resource, params) => {

    const { data, previousData } = params;

    if (resource === 'GasRegion') {
        switch (type) {
            case CREATE: {
                if (data.gasDistrictsIds) { data.gasDistricts = data.gasDistrictsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasDistrictsIds) { data.gasDistricts = data.gasDistrictsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasDistrict') {
        switch (type) {
            case CREATE: {
                if (data.gasRegion) { data.gasRegion = (data.gasRegion.id) ? { id: data.gasRegion.id } : null }
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasRegion) { data.gasRegion = (data.gasRegion.id || previousData.gasRegion) ? { id: data.gasRegion.id } : null }
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasConsumer') {
        switch (type) {
            case CREATE: {
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasMeterPad') {
        switch (type) {
            case CREATE: {
                if (data.gasConsumer) { data.gasConsumer = (data.gasConsumer.id) ? { id: data.gasConsumer.id } : null }
                if (data.gasDistrict) { data.gasDistrict = (data.gasDistrict.id) ? { id: data.gasDistrict.id } : null }
                if (data.gasDistributorOrganization) { data.gasDistributorOrganization = (data.gasDistributorOrganization.id) ? { id: data.gasDistributorOrganization.id } : null }
                if (data.gasDistributorStationOutput) { data.gasDistributorStationOutput = (data.gasDistributorStationOutput.id) ? { id: data.gasDistributorStationOutput.id } : null }
                if (data.gasMeterUnitsIds) { data.gasMeterUnits = data.gasMeterUnitsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasConsumer) { data.gasConsumer = (data.gasConsumer.id || previousData.gasConsumer) ? { id: data.gasConsumer.id } : null }
                if (data.gasDistrict) { data.gasDistrict = (data.gasDistrict.id || previousData.gasDistrict) ? { id: data.gasDistrict.id } : null }
                if (data.gasDistributorOrganization) { data.gasDistributorOrganization = (data.gasDistributorOrganization.id || previousData.gasDistributorOrganization) ? { id: data.gasDistributorOrganization.id } : null }
                if (data.gasDistributorStationOutput) { data.gasDistributorStationOutput = (data.gasDistributorStationOutput.id || previousData.gasDistributorStationOutput) ? { id: data.gasDistributorStationOutput.id } : null }
                if (data.gasMeterUnitsIds) { data.gasMeterUnits = data.gasMeterUnitsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'BalanceRegion') {
        switch (type) {
            case CREATE: {
                if (data.gasDistributorStationsIds) { data.gasDistributorStations = data.gasDistributorStationsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasDistributorStationsIds) { data.gasDistributorStations = data.gasDistributorStationsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasDistributorOrganization') {
        switch (type) {
            case CREATE: {
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasDistributorStation') {
        switch (type) {
            case CREATE: {
                if (data.balanceRegion) { data.balanceRegion = (data.balanceRegion.id) ? { id: data.balanceRegion.id } : null }
                if (data.gasDistributorStationOutputsIds) { data.gasDistributorStationOutputs = data.gasDistributorStationOutputsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.balanceRegion) { data.balanceRegion = (data.balanceRegion.id || previousData.balanceRegion) ? { id: data.balanceRegion.id } : null }
                if (data.gasDistributorStationOutputsIds) { data.gasDistributorStationOutputs = data.gasDistributorStationOutputsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasDistributorStationOutput') {
        switch (type) {
            case CREATE: {
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.gasDistributorStation) { data.gasDistributorStation = (data.gasDistributorStation.id) ? { id: data.gasDistributorStation.id } : null }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterPadsIds) { data.gasMeterPads = data.gasMeterPadsIds.map(id => ({ id })) }
                if (data.gasDistributorStation) { data.gasDistributorStation = (data.gasDistributorStation.id || previousData.gasDistributorStation) ? { id: data.gasDistributorStation.id } : null }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasMeterUnit') {
        switch (type) {
            case CREATE: {
                if (data.gasMeterPad) { data.gasMeterPad = (data.gasMeterPad.id) ? { id: data.gasMeterPad.id } : null }
                if (data.gasMeterPointsIds) { data.gasMeterPoints = data.gasMeterPointsIds.map(id => ({ id })) }
                if (data.dashboardsIds) { data.dashboards = data.dashboardsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterPad) { data.gasMeterPad = (data.gasMeterPad.id || previousData.gasMeterPad) ? { id: data.gasMeterPad.id } : null }
                if (data.gasMeterPointsIds) { data.gasMeterPoints = data.gasMeterPointsIds.map(id => ({ id })) }
                if (data.dashboardsIds) { data.dashboards = data.dashboardsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasMeterPoint') {
        switch (type) {
            case CREATE: {
                if (data.gasMeterUnit) { data.gasMeterUnit = (data.gasMeterUnit.id) ? { id: data.gasMeterUnit.id } : null }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterUnit) { data.gasMeterUnit = (data.gasMeterUnit.id || previousData.gasMeterUnit) ? { id: data.gasMeterUnit.id } : null }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasQualityCertificate') {
        switch (type) {
            case CREATE: {
                if (data.gasDistributorStationsIds) { data.gasDistributorStations = data.gasDistributorStationsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasDistributorStationsIds) { data.gasDistributorStations = data.gasDistributorStationsIds.map(id => ({ id })) }
                if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'Counterparty') {
        switch (type) {
            case CREATE: {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                break;
            }
            case UPDATE: {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                break;
            }
            default: break;
        }
    }

    if (resource === 'User') {
        switch (type) {
            case CREATE: {

                if (data.counterparty) { data.counterparty = (data.counterparty.id) ? { id: data.counterparty.id } : null }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createClient({
                    login: data.login ? data.login : null,
                    email: data.email ? data.email : null,
                    phone: data.phone ? data.phone : null,
                    name: data.name,
                    password: data.password,
                    role: data.role,
                });

                params.data = {
                    ...data,
                    authId: auth.id,
                    login: auth.login ? auth.login : null,
                    email: auth.email ? auth.email : null,
                    phone: auth.phone ? auth.phone : null,
                    name: auth.name,
                    role: auth.role,
                }

                break;
            }
            case UPDATE: {

                if (data.counterparty) { data.counterparty = (data.counterparty.id || previousData.counterparty) ? { id: data.counterparty.id } : null }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                const authId = data.authId;
                const password = data.password;

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createClient({
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    params.data = {
                        ...data,
                        authId: auth.id,
                        login: auth.login ? auth.login : null,
                        email: auth.email ? auth.email : null,
                        phone: auth.phone ? auth.phone : null,
                        name: auth.name,
                        role: auth.role,
                    }
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateClient({
                        id: data.authId,
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                        banned: data.banned,
                    })

                    params.data = {
                        ...data,
                        authId: auth.id,
                        login: auth.login ? auth.login : null,
                        email: auth.email ? auth.email : null,
                        phone: auth.phone ? auth.phone : null,
                        name: auth.name,
                        role: auth.role,
                    }
                };



                break;
            }
            default: break;
        }
    }

    if (resource === 'Profile') {
        switch (type) {
            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createClient({
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateClient({
                        id: data.authId,
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                params.data = {
                    ...data,
                    authId: authUser.id,
                    login: authUser.login ? authUser.login : null,
                    email: authUser.email ? authUser.email : null,
                    phone: authUser.phone ? authUser.phone : null,
                    name: authUser.name,
                    role: authUser.role,
                }

                break;
            }
            default: break;
        }
    }

    return { type, resource, params };
};