import React, {cloneElement, Children} from "react";
import get from 'lodash/get';

const TagsField = ({children, classes: classesOverride, className, record, source, ...props}) => {
    const tags = get(record, source);

    if (!tags || !tags.length) return null;

    return (
        <div>
            {tags.map(tag => {
                return cloneElement(Children.only(children), {
                    key: tag.id,
                    record: tag,
                });
            })}
        </div>
    );
};

export default TagsField;