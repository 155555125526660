import { buildQuery } from 'ra-data-graphql-simple';
import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import apolloCoreClient from './apolloCoreClient';
import { GET_LIST_GAS_REGION, GET_MANY_GAS_REGIONS, GET_ONE_GAS_REGION, UPDATE_GAS_REGION, DELETE_GAS_REGION } from './typeDefs';
import { GET_LIST_GAS_DISTRICT, GET_MANY_GAS_DISTRICTS, GET_ONE_GAS_DISTRICT, UPDATE_GAS_DISTRICT, DELETE_GAS_DISTRICT } from './typeDefs';
import { GET_LIST_BALANCE_REGION, GET_MANY_BALANCE_REGIONS, GET_ONE_BALANCE_REGION, UPDATE_BALANCE_REGION, DELETE_BALANCE_REGION } from './typeDefs';
import { GET_LIST_GAS_DISTRIBUTOR_ORGANIZATION, GET_MANY_GAS_DISTRIBUTOR_ORGANIZATIONS, GET_ONE_GAS_DISTRIBUTOR_ORGANIZATION, UPDATE_GAS_DISTRIBUTOR_ORGANIZATION, DELETE_GAS_DISTRIBUTOR_ORGANIZATION } from './typeDefs';
import { GET_LIST_GAS_DISTRIBUTOR_STATION, GET_MANY_GAS_DISTRIBUTOR_STATIONS, GET_ONE_GAS_DISTRIBUTOR_STATION, UPDATE_GAS_DISTRIBUTOR_STATION, DELETE_GAS_DISTRIBUTOR_STATION } from './typeDefs';
import { GET_LIST_GAS_DISTRIBUTOR_STATION_OUTPUT, GET_MANY_GAS_DISTRIBUTOR_STATION_OUTPUTS, GET_ONE_GAS_DISTRIBUTOR_STATION_OUTPUT, UPDATE_GAS_DISTRIBUTOR_STATION_OUTPUT, DELETE_GAS_DISTRIBUTOR_STATION_OUTPUT } from './typeDefs';
import { GET_LIST_GAS_CONSUMER, GET_MANY_GAS_CONSUMERS, GET_ONE_GAS_CONSUMER, UPDATE_GAS_CONSUMER, DELETE_GAS_CONSUMER } from './typeDefs';
import { GET_LIST_GAS_METER_PAD, GET_MANY_GAS_METER_PADS, GET_ONE_GAS_METER_PAD, UPDATE_GAS_METER_PAD, DELETE_GAS_METER_PAD } from './typeDefs';
import { GET_LIST_GAS_METER_UNIT, GET_MANY_GAS_METER_UNITS, GET_ONE_GAS_METER_UNIT, UPDATE_GAS_METER_UNIT, DELETE_GAS_METER_UNIT } from './typeDefs';
import { GET_ONE_GAS_METER_POINT, UPDATE_GAS_METER_POINT, DELETE_GAS_METER_POINT } from './typeDefs';
import { GET_LIST_GAS_QUALITY_SERTIFICATE, GET_MANY_GAS_QUALITY_SERTIFICATES, GET_ONE_GAS_QUALITY_SERTIFICATE, UPDATE_GAS_QUALITY_SERTIFICATE, DELETE_GAS_QUALITY_SERTIFICATE } from './typeDefs';
import { GET_LIST_GAS_METER_CONFIGURATION, GET_MANY_GAS_METER_CONFIGURATIONS } from './typeDefs';
import { GET_LIST_CONTROLLER_CONFIGURATION, GET_MANY_CONTROLLER_CONFIGURATIONS } from './typeDefs';
import { GET_LIST_COUNTERPARTY, GET_MANY_COUNTERPARTIES, GET_ONE_COUNTERPARTY, UPDATE_COUNTERPARTY, DELETE_COUNTERPARTY } from './typeDefs';
import { GET_LIST_USER, GET_MANY_USERS, GET_ONE_USER, UPDATE_USER, DELETE_USER } from './typeDefs';
import { UPDATE_PROFILE } from './typeDefs';
import { GET_LIST_HISTORY, GET_MANY_HISTORIES, GET_ONE_HISTORY } from './typeDefs';
import { GET_LIST_TRASHITEM, GET_MANY_TRASHITEMS, GET_ONE_TRASHITEM } from './typeDefs';
import { GET_PREFERENCES, SET_PREFERENCES } from './typeDefs';
import { GET_ENUMS } from './typeDefs';





const customBuildQuery = introspection => (fetchType, resource, params) => {




    const { id, data, previousData } = params;

    if (resource === 'GasRegion') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_REGION,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_REGION,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_REGIONS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_REGION,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_REGION,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasDistrict') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_DISTRICT,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_DISTRICT,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_DISTRICTS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_DISTRICT,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_DISTRICT,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'BalanceRegion') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_BALANCE_REGION,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_BALANCE_REGION,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_BALANCE_REGIONS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_BALANCE_REGION,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_BALANCE_REGION,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasDistributorOrganization') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_DISTRIBUTOR_ORGANIZATION,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_DISTRIBUTOR_ORGANIZATION,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_DISTRIBUTOR_ORGANIZATIONS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_DISTRIBUTOR_ORGANIZATION,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_DISTRIBUTOR_ORGANIZATION,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasDistributorStation') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_DISTRIBUTOR_STATION,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_DISTRIBUTOR_STATION,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_DISTRIBUTOR_STATIONS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_DISTRIBUTOR_STATION,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_DISTRIBUTOR_STATION,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasQualityCertificateGasDistributorStation') {
        switch (fetchType) {
            case GET_MANY: {
                return {
                    query: GET_MANY_GAS_DISTRIBUTOR_STATIONS,
                    variables: {
                        filter: {
                            aisId_in: params.ids
                        },
                    },
                    parseResponse: (response) => {

                        const data = response.data.items.map(item => ({ ...item, id: item.aisId }))

                        return {
                            data,
                            total: response.data.total.count,
                        };
                    },
                };
                break;
            }
            default: break;
        }
    }

    if (resource === 'GasDistributorStationOutput') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_DISTRIBUTOR_STATION_OUTPUT,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_DISTRIBUTOR_STATION_OUTPUT,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_DISTRIBUTOR_STATION_OUTPUTS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_DISTRIBUTOR_STATION_OUTPUT,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_DISTRIBUTOR_STATION_OUTPUT,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasConsumer') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_CONSUMER,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_CONSUMER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_CONSUMERS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_CONSUMER,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_CONSUMER,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasMeterPad') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_METER_PAD,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_METER_PAD,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_METER_PADS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_METER_PAD,
                    variables: { where: { id: id || data.id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_METER_PAD,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasMeterUnit') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_METER_UNIT,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_METER_UNIT,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_METER_UNITS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_METER_UNIT,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_METER_UNIT,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasMeterPoint') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_METER_POINT,
                    variables: { where: { id } },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_METER_POINT,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_METER_POINT,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasQualityCertificate') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_QUALITY_SERTIFICATE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_QUALITY_SERTIFICATE,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_QUALITY_SERTIFICATES,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_QUALITY_SERTIFICATE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_QUALITY_SERTIFICATE,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Counterparty') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_COUNTERPARTY,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_COUNTERPARTY,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_COUNTERPARTIES,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_COUNTERPARTY,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_COUNTERPARTY,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Preference') {
        switch (fetchType) {
            case "getPreferences": {

                return {
                    query: GET_PREFERENCES,
                    variables: { ...params },
                    parseResponse: (({ data }) => {

                        return { data }
                    }),
                };
                break;
            }
            case "setPreferences": {
                return {
                    query: SET_PREFERENCES,
                    variables: { ...params },
                    parseResponse: (({ data }) => {
                        return { data }
                    }),
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'User') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_USER,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_USER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_USERS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_USER,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_USER,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Profile') {
        switch (fetchType) {
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_PROFILE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'History') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_HISTORY,
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_HISTORY,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_HISTORIES,
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'TrashItem') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_TRASHITEM,
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_TRASHITEM,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_TRASHITEMS,
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'ControllerConfiguration') {
        switch (fetchType) {
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_CONTROLLER_CONFIGURATION,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_CONTROLLER_CONFIGURATIONS,
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'GasMeterConfiguration') {
        switch (fetchType) {
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_METER_CONFIGURATION,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_METER_CONFIGURATIONS,
                };
                break;
            }
            default: break;
        }
    };

    if (fetchType === "getEnum") {
        return {
            query: GET_ENUMS,
            variables: { ...params },
            parseResponse: (({ data: { data } }) => {
                const { enumValues } = data;
                return {
                    data: enumValues,
                }
            })
        };
    }



    const builtQuery = buildQuery(introspection)(fetchType, resource, params);
    return builtQuery;
};

export default customBuildQuery;
