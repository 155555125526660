import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5c0-1.1.9-2 2-2h14a2 2 0 012 2v5h-2.35a3.99 3.99 0 00-5.3 0H10v4.8A6 6 0 007 20v1H5a2 2 0 01-2-2V5zm11.27 7a2 2 0 013.46 0H20v2h-2v1.08c.43.16.76.5.91.92H19a4 4 0 014 4v3h-3v-2a1 1 0 00-1-1h-.09c-.2.58-.76 1-1.41 1h-3a1.5 1.5 0 01-1.41-1H13a1 1 0 00-1 1v2H9v-3a4 4 0 014-4h.09c.15-.43.48-.76.91-.92V14h-2v-2h2.27z" />
    </SvgIcon>
  );
}

export default Icon;