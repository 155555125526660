import { ReferenceArrayInput, ReferenceInput, TextInput } from 'react-admin';
import React from 'react';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import AutocompleteInput from '../Inputs/AutoompleteInput';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import SearchInput from '../Inputs/SearchInput';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import { can } from "../../abilityProvider";
import { matchTrue } from '../../utils';



const GasQualityCertificateFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
        {can('getList', 'GasQualityCertificate', 'gqsId') && <TextInput source="gqsId" />}
        {can('getList', 'GasQualityCertificate', 'gasDistributorStation') && <ReferenceInput source="gasDistributorStation_some" reference="GasDistributorStation" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
            <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
        </ReferenceInput>}
        {can('getList', 'GasQualityCertificate', 'gasMeterUnit') && <ReferenceInput source="gasMeterUnit_some" reference="GasMeterUnit" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
            <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
        </ReferenceInput>}
        {/* {can('getList', 'GasQualityCertificate', 'tags', 'Tag') && <ReferenceInput source="tags_some.id" label="resources.Tag.fields.tag_some" reference="Tag" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceInput>}
        {can('getList', 'GasQualityCertificate', 'tags', 'Tag') && <ReferenceInput source="tags_none.id" label="resources.Tag.fields.tag_none" reference="Tag" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceInput>} */}
        {can('getList', 'GasQualityCertificate', 'tags', 'Tag') && <ReferenceArrayInput source="tags_and" label="resources.Tag.fields.tags_and" reference="Tag" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false}>
            <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'GasQualityCertificate', 'tags', 'Tag') && <ReferenceArrayInput source="tags_not_and" label="resources.Tag.fields.tags_not_and" reference="Tag" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
            <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceArrayInput>}
        {can('getList', 'GasQualityCertificate', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_and" label="resources.Report.fields.reports_and" reference="Report" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
        {can('getList', 'GasQualityCertificate', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_not_and" label="resources.Report.fields.reports_not_and" reference="Report" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
    </ManualSubmitFilter>
)



export default GasQualityCertificateFilter;