import React, { Fragment } from 'react';
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import useGasDistributorStationExporter from './useGasDistributorStationExporter';
import GasDistributorStationBulkActionButtons from './GasDistributorStationBulkActionButtons';
import GasDistributorStationFilter from './GasDistributorStationFilter';
import GasDistributorStationDatagrid, { useDefaultDatagridColumns } from './GasDistributorStationDatagrid';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';



const GasDistributorStationList = (props) => {
    const exporter = useGasDistributorStationExporter()
    const manualSubmitForm = React.useRef(null);
    const columns = useDefaultDatagridColumns()

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/9GasDistributorStation" target="_blank" />
            <List
                {...props}
                actions={<ListActions columns={columns} />}
                bulkActionButtons={<GasDistributorStationBulkActionButtons />}
                exporter={exporter}
                filters={<GasDistributorStationFilter formRef={manualSubmitForm} />}
            >
                <GasDistributorStationDatagrid columns={columns} />
            </List>
        </Fragment>
    )
};

GasDistributorStationList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' }
};



export default GasDistributorStationList;