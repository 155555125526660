import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';



const useStyles = makeStyles(theme => ({
    root: { 
        width: 240
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const Sidebar = ({
    routes,
    sidebar,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Menu menu={sidebar} className={classes.root} />
    );
}


export default Sidebar;