import { useTranslate, useResourceContext } from 'react-admin';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';



const useExporter = () => {
    const translate = useTranslate()
    const resource = useResourceContext()
    const fileName = translate(`resources.${resource}.name`, { smart_count: 2 })
    const modelExport = {
        id: true,
        name: true,
        description: true,
        createdBy: {
            id: true,
            name: true,
            __resource: "User",
        },
        createdAt: dateFormatter,
        updatedAt: dateFormatter,
        type: enumFormatter,
        inn: true,
        kpp: true,
        okpo: true,
        ogrn: true,
        fio: true,
        passport: true,
        passdateissue: true,
        parentId: true,
        addressLegal: true,
        addressActual: true,
        addressPostal: true,
        phone: true,
        phoneMobile: true,
        email: true,
        users: {
            id: true,
            name: true,
            __resource: "User",
        },
        access: {
            id: true,
            resource: enumFormatter,
            __resource: "Access",
        },
        __resource: "Counterparty",
    }
    const exporter = customExporter(fileName, modelExport, { translate })

    return exporter
}



export default useExporter;