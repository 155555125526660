import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import TagArrayField from "../Tag/TagArrayField";
import TagChip from "../Tag/TagChip";
import AisChip from "../Fields/AisChip";
import { can } from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },

    chipWarning: {
        backgroundColor: "#ffecb3",
    },
}));

const GasDistrictBanner = ({ classes: classesOverride, className, ...props }) => {
    const classes = useStyles({ classes: classesOverride });
    const { record } = props;
    if (!record) { return null }
    const name = record.name;
    const description = record.description;

    return (
        <Card className={`${classes.root} ${className}`}>
            <div style={{ padding: 16 }}>
                <Grid container spacing={4}>
                    <Grid item xs >
                        {can('getOne', 'GasDistrict', 'name') && <Typography variant="h6">{name}</Typography>}
                        {can('getOne', 'GasDistrict', 'description') && <Typography variant="subtitle1" gutterBottom>{description}</Typography>}
                        {can('getOne', 'GasDistrict', 'tags', 'Tag') && <TagArrayField record={record} source="tags">
                            <TagChip />
                        </TagArrayField>}
                    </Grid>
                    <Grid item >
                        {can('getOne', 'GasDistrict', 'aisId') && <AisChip {...props} />}
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};



export default GasDistrictBanner;