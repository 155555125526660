import React from 'react';
import { useForm } from 'react-final-form';
import EnumInput from '../../Inputs/EnumInput'


const StatusInput = (props) => {
    const form = useForm();

    const handleChangeStatus = (event) => {
        const value = event.target.value
        form.change('gasMeter.status', value)
    }

    return (
        <EnumInput {...props} onChange={handleChangeStatus} />
    );
};

export default StatusInput;