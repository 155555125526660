import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { USER_FRAGMENT } from '../typeDefs';


export const GET_ONE_USER = gql`
    query getOneUser( $where: UserWhereUniqueInput! ) {
        item: User( where: $where ) {...UserFragment}
    }
    ${USER_FRAGMENT}
`

export default (args) => {
    return apolloClient.query({
        query: GET_ONE_USER,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    })
};
