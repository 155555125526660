import Icon from '@material-ui/icons/History';
import ReportList from './ReportList';
import ReportShow from './ReportShow';

const ReportIcon = Icon;


export {
    ReportIcon,
    ReportList,
    ReportShow,
};
