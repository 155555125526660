import differenceBy from 'lodash/differenceBy';
import difference from 'lodash/difference';


export const buildNestedArrayInput = (current = [], previous = []) => {

    const connect = current.filter(item => item.id).map(({ id }) => ({ id }))
    const create = current.filter(item => !item.id).map(({ id, ...input }) => ({ ...input }))
    const disconnect = differenceBy(previous, current, 'id').map(({ id }) => ({ id }))

    return {
        connect: connect.length > 0 ? connect : undefined,
        create: create.length > 0 ? create : undefined,
        disconnect: disconnect.length > 0 ? disconnect : undefined,
    }

}

export const buildArrayInput = (current = [], previous = []) => {

    const connect = difference(current, previous).map(id => ({ id }))
    const disconnect = difference(previous, current).map(id => ({ id }))

    return {
        connect: connect.length > 0 ? connect : undefined,
        disconnect: disconnect.length > 0 ? disconnect : undefined,
    }

}

export const sanitizeResource = data => {
    const result = Object.keys(data).reduce((acc, key) => {
        if (key.startsWith('_')) {
            return acc;
        }

        const dataKey = data[key];

        if (dataKey === null || dataKey === undefined) {
            return acc;
        }

        if (Array.isArray(dataKey)) {
            if (typeof dataKey[0] === 'object' && dataKey[0] !== null) {
                return {
                    ...acc,
                    [key]: dataKey.map(sanitizeResource),
                    [`${key}Ids`]: dataKey.map(d => d.id),
                };
            } else {
                return { ...acc, [key]: dataKey };
            }
        }

        if (typeof dataKey === 'object' && dataKey !== null) {
            return {
                ...acc,
                ...(dataKey &&
                    dataKey.id && {
                    [`${key}.id`]: dataKey.id,
                }),
                [key]: sanitizeResource(dataKey),
            };
        }

        return { ...acc, [key]: dataKey };
    }, {});

    return result;
};

export const omitDeepArrayWalk = (arr, key) => {
    return arr.map(val => {
        if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
        else if (typeof val === "object") return omitDeep(val, key);
        return val;
    });
};

export const omitDeep = (obj, key) => {
    const keys = Object.keys(obj);
    const newObj = {};
    keys.forEach(i => {
        if (i !== key) {
            const val = obj[i];
            if (val instanceof Date) newObj[i] = val;
            else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
            else if (typeof val === "object" && val !== null)
                newObj[i] = omitDeep(val, key);
            else newObj[i] = val;
        }
    });
    return newObj;
};