import Icon from '../Icons/GRO';
import GasDistributorOrganizationList from './GasDistributorOrganizationList';
import GasDistributorOrganizationCreate from './GasDistributorOrganizationCreate';
import GasDistributorOrganizationEdit from './GasDistributorOrganizationEdit';
import GasDistributorOrganizationShow from './GasDistributorOrganizationShow';

const GasDistributorOrganizationIcon = Icon;


export {
    GasDistributorOrganizationIcon,
    GasDistributorOrganizationList,
    GasDistributorOrganizationShow,
    GasDistributorOrganizationCreate,
    GasDistributorOrganizationEdit,
};
