import Icon from '@material-ui/icons/Label';
import TagList from './TagList';
import TagCreate from './TagCreate';
import TagEdit from './TagEdit';
import TagShow from './TagShow';

const TagIcon = Icon;


export {
    TagIcon,
    TagList,
    TagShow,
    TagCreate,
    TagEdit,
};
