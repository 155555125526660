import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import WarningIcon from '@material-ui/icons/Warning';



const GasMeterWarning = props => {
    return (
        <Alert severity="warning" icon={<WarningIcon />} style={{ marginBottom: 24 }} >
            <AlertTitle>Внимание</AlertTitle>
            <div>После сохранения будет создана системная запись в журнал УУГ "Владение Корректорами". Удаление навсегда созданного УУГ или Корректора станет <strong>невозможно</strong>.</div>

        </Alert>
    );
};

export default GasMeterWarning