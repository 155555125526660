import React, { useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { AutocompleteInput, Datagrid, SimpleList, DateInput, DateField, ListContextProvider, ListView, TextField, ReferenceInput, useListController, useRecordContext, useTranslate } from 'react-admin';
import EnumInput from '../Inputs/EnumInput'
import EnumField from '../Fields/EnumField';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import SearchInput from '../Inputs/SearchInput'
import ResourceLinkField from '../Fields/ResourceLinkField';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';

const knownResources = [
    "GasRegion",
    "GasDistrict",
    "BalanceRegion",
    "GasDistributorOrganization",
    "GasDistributorStation",
    "GasDistributorStationOutput",
    "GasConsumer",
    "GasMeterPad",
    "GasMeterUnit",
    "GasMeterPoint",
    "GasQualityCertificate",
    "Counterparty",
    "User"
]


const filterResources = (value) => {
    const resources = knownResources.filter((resource) => can('getList', resource))
    return resources.includes(value.name);
}

const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const HistoryFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
        <EnumInput source="action" alwaysOn />
        <EnumInput source="resource" name="ResourceType" filter={filterResources} alwaysOn />
        <ReferenceInput source="createdBy.id" label="resources.History.fields.createdBy" reference="User" sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty emptyText="ra.message.no">
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceInput>
        <DateInput source="createdAt_gte" />
        <DateInput source="createdAt_lte" />
    </ManualSubmitFilter>
);

export const DefaultDatagrid = (props) => {
    const classes = useStyles();
    return (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            <EnumField source="action" noWrap />
            <EnumField source="resource" />
            <ResourceLinkField source="name" target="_blank" />
            <TextField source="createdBy.name" label="resources.History.fields.createdBy" sortable={false} />
            <DateField source="createdAt" textAlign="right" options={dateTimeOptions} />
        </Datagrid>
    );
}

export const ReferenceDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const translate = useTranslate();
    const classes = useStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => translate(`resources.History.enums.action.${record.action}`)}
            tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            <EnumField source="action" noWrap />
            <EnumField source="resource" />
            <ResourceLinkField source="name" target="_blank" />
            <TextField source="createdBy.name" label="resources.History.fields.createdBy" sortable={false} />
            <DateField source="createdAt" textAlign="right" options={dateTimeOptions} />
        </Datagrid>
    );
}




export const ReferenceFieldDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const translate = useTranslate();
    const classes = useStyles();
    const { record: { id } } = useRecordContext()

    const historyRowStyle = useCallback((record, index) => {
        if (record.id === id) return { backgroundColor: 'rgba(0, 0, 0, 0.08)' }
    }, [id]);

    return isMobile ? (
        <SimpleList
            primaryText={record => translate(`resources.History.enums.action.${record.action}`)}
            tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
            linkType="show"
        />
    ) : (
        <Datagrid
            {...props}
            rowClick="show"
            classes={{ row: classes.row }}
            rowStyle={historyRowStyle}
        >
            <EnumField source="action" noWrap />
            <EnumField source="resource" />
            <ResourceLinkField source="name" target="_blank" />
            <TextField source="createdBy.name" label="resources.History.fields.createdBy" sortable={false} />
            <DateField source="createdAt" textAlign="right" options={dateTimeOptions} />
        </Datagrid>
    );
}





const HistoryList = props => {
    const filter = { resource_in: knownResources.filter((resource) => can('getList', resource)) };
    const controllerProps = useListController({ ...props, filter });
    const manualSubmitForm = React.useRef(null);
    const classes = useStyles();

    return (
        <ListContextProvider value={controllerProps}>
            <ListView
                filters={<HistoryFilter formRef={manualSubmitForm} />}
                exporter={false}
                filter={filter}
                {...props}
            >
                <DefaultDatagrid />
            </ListView>
        </ListContextProvider>
    )
};

HistoryList.defaultProps = {
    perPage: 50,
    sort: { field: 'createdAt', order: 'DESC' },
    bulkActionButtons: false,
};


export default HistoryList;