import Icon from '../Icons/Consumer';
import GasConsumerList from './GasConsumerList';
import GasConsumerCreate from './GasConsumerCreate';
import GasConsumerEdit from './GasConsumerEdit';
import GasConsumerShow from './GasConsumerShow';

const GasConsumerIcon = Icon;


export {
    GasConsumerIcon,
    GasConsumerList,
    GasConsumerShow,
    GasConsumerCreate,
    GasConsumerEdit,
};
