import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import sanitizeFieldRestProps from './sanitizeFieldRestProps'

const getInitials = name => {
    var initials = name.split(" ") || [];
    var first = initials.shift()
    var second = initials.pop()
    initials = (first.charAt(0) + (second ? second.charAt(0) : first.charAt(1))).toUpperCase();
    return initials;
}

const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: "1rem",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));


const AvatarField = ({ className, record, size, ...props }) => {
    const classes = useStyles();
    if (!record) return null;
    const { name, fullname, avatar } = record;
    const text = fullname || name;

    return (<Avatar alt={text} src={avatar} className={`${className} ${classes[size]}`} {...sanitizeFieldRestProps(props)}>{getInitials(text)}</Avatar>);
};

export default AvatarField;