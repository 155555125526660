import { useTranslate, useResourceContext } from 'react-admin';
import customExporter, { dateFormatter } from '../../exporter';



const useExporter = () => {
    const translate = useTranslate()
    const resource = useResourceContext()
    const fileName = translate(`resources.${resource}.name`, { smart_count: 2 })
    const modelExport = {
        id: true,
        aisId: true,
        name: true,
        description: true,
        createdBy: {
            id: true,
            name: true,
            __resource: "User",
        },
        createdAt: dateFormatter,
        updatedAt: dateFormatter,
        gasDistricts: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasDistrict",
        },
        reports: {
            id: true,
            name: true,
            __resource: "Report",
        },
        tags: {
            id: true,
            name: true,
            __resource: "Tag",
        },
        __resource: "GasRegion",
    }
    const exporter = customExporter(fileName, modelExport, { translate })

    return exporter
}



export default useExporter;