import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { USER_FRAGMENT } from '../typeDefs';

export const GET_MANY_REFERENCE_CONTACTS = gql`
    query getManyReferenceUsers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilter) {
        items: allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {...UserFragment}
        total: _allUsersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
    ${USER_FRAGMENT}
`

export default (args) => {
    return apolloClient.query({
        query: GET_MANY_REFERENCE_CONTACTS,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    })
};
