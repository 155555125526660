import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, TextField, } from 'react-admin';
import DisabledChip from "./GasMeterPadDisabledChip";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import ProviderChip from "./GasMeterPadProviderChip";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import TagArrayEnhancedField from "../Tag/TagArrayEnhancedField";
import TagChip from "../Tag/TagChip";
import { can } from "../../abilityProvider";
import GasQualityCertificateWritableChip from '../Fields/GasQualityCertificateWritableChip';
import useListStyles from '../Layout/list/useListStyles';
import useSelectedColumnsPreferences from '../../controller/preferences/useSelectedColumnsPreferences';
import useColumns from '../../controller/preferences/useColumns';


export const useDefaultDatagridColumns = (props) => {
    const classes = useListStyles()

    const columns = useColumns([
        can('getList', 'GasMeterPad', 'name') && <ResourceLinkField source="name" target="_blank" alwaysOn />,
        can('getList', 'GasMeterPad', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />,
        can('getList', 'GasMeterPad', 'tags', 'Tag') && <TagArrayEnhancedField source="tags" sortable={false}>
            <TagChip />
            <DisabledChip source="disabled" hideWhen={false} />
            <ProviderChip source="isProvider" hideWhen={false} />
            <GasQualityCertificateWritableChip source="isGasQualityCertificateWritable" hideWhen={false} />
        </TagArrayEnhancedField>,
        can('getList', 'GasMeterPad', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
        can('getList', 'GasMeterPad', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
    ])

    return columns
}

export const GasMeterPadReferenceDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {can('getList', 'GasMeterPad', 'name') && <ResourceLinkField source="name" target="_blank" />}
            {can('getList', 'GasMeterPad', 'aisId') && <TextField source="aisId" headerClassName={classes.noWrap} />}
            {can('getList', 'GasMeterPad', 'tags', 'Tag') && <TagArrayEnhancedField source="tags" sortable={false}>
                <TagChip />
                <DisabledChip source="disabled" hideWhen={false} />
                <ProviderChip source="isProvider" hideWhen={false} />
                <GasQualityCertificateWritableChip source="isGasQualityCertificateWritable" hideWhen={false} />
            </TagArrayEnhancedField>}
            {can('getList', 'GasMeterPad', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'GasMeterPad', 'createdAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
        </Datagrid>
    );
};


const GasMeterPadDatagrid = ({ columns, ...props }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();
    const SelectedColumns = useSelectedColumnsPreferences(columns);

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.aisId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {SelectedColumns}
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {can('getOne', 'GasMeterPad') && <ShowBlankItem />}
                {can('update', 'GasMeterPad') && <EditMenuItem />}
                {can('remove', 'GasMeterPad') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    )
};



export default GasMeterPadDatagrid;