import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Labeled } from 'react-admin';
import JsonField from '../Fields/JsonField';



const useStyles = makeStyles(theme => {
    return {
        root: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            [theme.breakpoints.down('xs')]: {
                gridTemplateColumns: "1fr",

            },
        },
        labeled: {
            width: "100%"
        },
        jsonField: {
            display: "grid",
        }
    }
});


const HistoryField = props => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Labeled {...props} source="data.data" className={classes.labeled}>
                <JsonField {...props} source="data.data" className={classes.jsonField} />
            </Labeled>
            <Labeled {...props} source="data.previous" className={classes.labeled}>
                <JsonField {...props} source="data.previous" className={classes.jsonField} />
            </Labeled>
        </div>
    );
};

export default HistoryField;