import React from 'react';
import get from 'lodash/get'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { NumberField } from 'react-admin';



const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        fontWeight: theme.typography.fontWeightBold
    },
    warning: {
        color: theme.palette.warning.dark,
        fontWeight: theme.typography.fontWeightBold
    },
}));


const checkOutOfRange = (value) => value < 0.1 || value > 3.8

export const validate = (value, record) => {
    const invalid = checkOutOfRange(value)
    const name = record.name
    const parameter = "N2"
    const range = `0.1 < ${parameter} < 3.8`
    return invalid ? { message: 'resources.GasQualityCertificate.validation.out_of_range', args: { value, parameter, range, name } } : undefined
}

const N2Field = (props) => {
    const classes = useStyles()
    const { record, source } = props
    const value = get(record, source)
    const warning = checkOutOfRange(value)

    return (<NumberField {...props} className={classNames({ [classes.warning]: warning })} />
    )
}

N2Field.defaultProps = {
    addLabel: true,
};

export default N2Field;

