import React, { useState, useCallback } from 'react';
import DeviceCreateWizardDialog from './DeviceCreateWizardDialog';
import DeviceCreateWizardCard from './DeviceCreateWizardCard';



const DeviceCreateWizardCardButton = ({ className, classes: classesOverride, ...props }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <DeviceCreateWizardCard onClick={handleOpen} />
            <DeviceCreateWizardDialog open={open} onClose={handleClose} {...props} />
        </>

    );
};

export default DeviceCreateWizardCardButton;