import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';



const CREATE_GAS_METER_UNIT = gql`
    mutation createGasMeterUnit( $name: String!, $description: String, $inventoryNumber: String, $aisId: String, $gasMeterPad: GasMeterPadWhereUniqueInput, $dashboards: [DashboardWhereUniqueInput!], $reports: [ReportWhereUniqueInput!] $enableWriteGasQualityCertificate: Boolean ) {
        data: createGasMeterUnit( name: $name, description: $description, inventoryNumber: $inventoryNumber, aisId: $aisId, gasMeterPad: $gasMeterPad, dashboards: $dashboards, reports: $reports, enableWriteGasQualityCertificate: $enableWriteGasQualityCertificate ) {
            id
        }
    }
`

const CREATE_GAS_METER_POINT = gql`
    mutation createGasMeterPoint( $name: String!, $description: String, $aisId: String, $number: Int!, $disabled: Boolean, $gasMeterUnit: GasMeterUnitWhereUniqueInput ) {
        data: createGasMeterPoint( name: $name, description: $description, number: $number, disabled: $disabled, aisId: $aisId gasMeterUnit: $gasMeterUnit ) {
            id
        }
    }
`

const CREATE_CONTROLLER = gql`
    mutation createController( $name: String!, $description: String, $status: ControllerStatus, $type: ControllerType!, $serialNumber: String, $phoneNumber: String, $url: String, $configuration: ControllerConfigurationWhereUniqueInput, $meta: Json ) {
        data: createController( name: $name, description: $description, status: $status, type: $type, serialNumber: $serialNumber, phoneNumber: $phoneNumber, url: $url, configuration: $configuration, meta: $meta ) {
            id
        }
    }
`

const CREATE_GAS_METER = gql`
    mutation createGasMeter( $name: String!, $description: String, $dbId: Int, $serialNumber: String, $port: Int, $meta: Json, $type: GasMeterType!, $status: GasMeterStatus, $configuration: GasMeterConfigurationWhereUniqueInput, $timeZone: Int, $commercialHour: Int, $dst: Boolean ) {
        data: createGasMeter( name: $name, description: $description, dbId: $dbId, serialNumber:  $serialNumber, port: $port, meta: $meta, type: $type, status: $status, configuration: $configuration, timeZone: $timeZone, commercialHour:  $commercialHour, dst: $dst ) {
            id
        }
    }
`

const CREATE_DEVICE = gql`
    mutation createDevice( $id: ID!, $gasMeterId: ID!, $controllerId: ID! ) {
        data: createDevice(id: $id, gasMeterId: $gasMeterId, controllerId: $controllerId) { 
            id
        }
    }
`


export const createGasMeterUnit = (data) => {

    if (data.gasMeterPad) { data.gasMeterPad = (data.gasMeterPad.id) ? { id: data.gasMeterPad.id } : null }
    if (data.gasMeterPointsIds) { data.gasMeterPoints = data.gasMeterPointsIds.map(id => ({ id })) }
    if (data.dashboardsIds) { data.dashboards = data.dashboardsIds.map(id => ({ id })) }
    if (data.reportsIds) { data.reports = data.reportsIds.map(id => ({ id })) }

    return apolloCoreClient.mutate({
        mutation: CREATE_GAS_METER_UNIT,
        variables: { ...data },
        onError: (error) => {
            alert(error)
        }
    }).then(response => response.data?.data);
};

export const createGasMeterPoints = (gasMeterPoints, gasMeterUnitId) => {

    const requests = gasMeterPoints.map(gasMeterPoint => {
        return apolloCoreClient.mutate({
            mutation: CREATE_GAS_METER_POINT,
            variables: {
                ...gasMeterPoint,
                gasMeterUnit: { id: gasMeterUnitId }
            },
            onError: (error) => {
                alert(error)
            }
        }).then(response => response.data?.data);
    })

    return Promise.all(requests)
        .then(responses => {
            return responses.length
        });

};

export const createController = (args) => {

    return apolloCoreClient.mutate({
        mutation: CREATE_CONTROLLER,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    }).then(response => response.data?.data);
};

export const createGasMeter = (args) => {

    return apolloCoreClient.mutate({
        mutation: CREATE_GAS_METER,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    }).then(response => response.data?.data);
};

export const createDevice = ({ gasMeterUnitId, controllerId, gasMeterId }) => {

    return apolloCoreClient.mutate({
        mutation: CREATE_DEVICE,
        variables: {
            id: gasMeterUnitId,
            controllerId,
            gasMeterId,
        },
        onError: (error) => {
            alert(error)
        }
    }).then(response => {


        return response.data?.data
    });
};