import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, TextField } from 'react-admin';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import PhoneField from '../Fields/PhoneField';
import React from 'react';
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import { can } from "../../abilityProvider";
import useListStyles from '../Layout/list/useListStyles';



const CounterpartyDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }} >
            {can('getList', 'Counterparty', 'name') && <ResourceLinkField source="name" target="_blank" />}
            {can('getList', 'Counterparty', 'description') && <TextField source="description" />}
            {can('getList', 'Counterparty', 'phone') && <PhoneField source="phone" />}
            {can('getList', 'Counterparty', 'email') && <TextField source="email" />}
            {can('getList', 'Counterparty', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'Counterparty', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {can('getOne', 'Counterparty') && <ShowBlankItem />}
                {can('update', 'Counterparty') && <EditMenuItem />}
                {can('remove', 'Counterparty') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    )
}



export default CounterpartyDatagrid;