import { useEffect, useState } from 'react';


export default function (path) {

    const [module, setModule] = useState({
        Dashboard: undefined,
        DashboardPreview: undefined,
        iconPreview: undefined,
        hasDashboard: false,
        hasDashboardPreview: false,
        error: undefined,
        loading: true,
        loaded: false,
    })

    useEffect(() => {

        const onModuleLoad = (path) => {

            setModule(prevState => ({ ...prevState, loading: true }));
            import(`../../components/Dashboard${path.replace('/Dashboard', '')}`) //прикол вебпак https://stackoverflow.com/questions/54254553/dynamic-import-in-react-not-working-when-trying-to-import-a-component-in-another
                .then(module => {
                    setModule({
                        Dashboard: module.default,
                        DashboardPreview: module.DashboardPreview,
                        iconPreview: module.DashboardPreview.defaultProps.icon,
                        hasDashboard: Boolean(module.default),
                        hasDashboardPreview: Boolean(module.DashboardPreview),
                        loading: false,
                        loaded: true,
                    });
                })
                .catch(error => {
                    setModule({
                        error,
                        loading: false,
                        loaded: false,
                    });
                });
        }

        if (path) {
            onModuleLoad(path)
        };

        return () => {
            setModule({
                Dashboard: undefined,
                DashboardPreview: undefined,
                iconPreview: undefined,
                hasDashboard: false,
                hasDashboardPreview: false,
                error: undefined,
                loading: true,
                loaded: false,
            });
        }

    }, [path]);

    return module;
}
