import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { DateTimeInput, FormDataConsumer, SimpleForm, TextInput, Toolbar, SaveButton, NumberInput, ReferenceArrayInput, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import GasQualityCertificateBanner from './GasQualityCertificateBanner';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

}));

const GasQualityCertificateToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasQualityCertificate') && <SaveButton />}
            {can("update", "GasQualityCertificate") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasQualityCertificate') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}



const GasQualityCertificateEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasQualityCertificateBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasQualityCertificateToolbar />}>
                    {can('update', 'GasQualityCertificate', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'GasQualityCertificate', 'description') && <TextInput source="description" multiline fullWidth />}
                    {can('update', 'GasQualityCertificate', 'gqsId') && <TextInput source="gqsId" validate={required()} />}
                    {can('update', 'GasQualityCertificate', 'n2') && <NumberInput source="n2" />}
                    {can('update', 'GasQualityCertificate', 'co2') && <NumberInput source="co2" />}
                    {can('update', 'GasQualityCertificate', 'density') && <NumberInput source="density" />}
                    {can('update', 'GasQualityCertificate', 'validFrom') && <FormDataConsumer source="validFrom">
                        {({ formData, ...rest }) => (
                            <DateTimeInput {...rest} source="validFrom" inputProps={{ max: formData && formData.validTo ? moment(formData.validTo).format("YYYY-MM-DD") : null }} />
                        )}
                    </FormDataConsumer>}
                    {can('update', 'GasQualityCertificate', 'validTo') && <FormDataConsumer source="validTo">
                        {({ formData, ...rest }) => (
                            <DateTimeInput {...rest} source="validTo" inputProps={{ min: formData && formData.validFrom ? moment(formData.validFrom).format("YYYY-MM-DD") : null }} />
                        )}
                    </FormDataConsumer>}
                    {can('update', 'GasQualityCertificate', 'gasExtractionPoint') && <TextInput source="gasExtractionPoint" />}
                    {can('update', 'GasQualityCertificate', 'gasDistributorStations', 'GasDistributorStation') && <ReferenceArrayInput source="gasDistributorStationsIds" reference="GasDistributorStation" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasQualityCertificate.fields.gasDistributorStations" fullWidth>
                        <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                    </ReferenceArrayInput>}
                    {can('update', 'GasQualityCertificate', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasQualityCertificate.fields.reports" fullWidth />}
                    {can('update', 'GasQualityCertificate', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasQualityCertificate.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasQualityCertificateEdit