import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EditContextProvider, useEditController, SimpleForm, TextInput, Toolbar, SaveButton, ReferenceInput, required } from 'react-admin';
import { EditView } from '../Layout/EditView';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import GasMeterUnitBanner from './GasMeterUnitBanner';
import DashboardReferenceArrayInput from '../Dashboard/DashboardReferenceArrayInput';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import ReferenceArrayGasMeterPointInput from './ReferenceArrayGasMeterPointInput';
import BooleanInput from '../Inputs/BooleanInput';
import { can } from "../../abilityProvider";
import TextField from '@material-ui/core/TextField';



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    flexReferenceArrayInput: {
        display: "flex",
        alignItems: "flex-end",
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const GasMeterUnitToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasMeterUnit') && <SaveButton />}
            {can("update", "GasMeterUnit") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasMeterUnit') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}



const GasMeterUnitEdit = props => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const gasMeterType = controllerProps.record?.gasMeter?.type;

    return (
        <EditContextProvider value={controllerProps}>
            <EditView title={<TitleForRecord source="name" {...props} />} {...props}  {...controllerProps}  >
                <GasMeterUnitBanner />
                <CardBlankLayout >
                    <SimpleForm toolbar={<GasMeterUnitToolbar />}>
                        {can('update', 'GasMeterUnit', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                        {can('update', 'GasMeterUnit', 'description') && <TextInput source="description" multiline fullWidth />}
                        {can('update', 'GasMeterUnit', 'inventoryNumber') && <TextInput source="inventoryNumber" />}
                        {can('update', 'GasMeterUnit', 'aisId') && <TextInput source="aisId" />}
                        {can('update', 'GasMeterUnit', 'enableWriteGasQualityCertificate') && <BooleanInput source="enableWriteGasQualityCertificate" />}
                        {can('update', 'GasMeterUnit', 'gasMeterPad', 'GasMeterPad') && <ReferenceInput source="gasMeterPad.id" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.gasMeterPad" allowEmpty emptyText="ra.message.no" >
                            <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                        </ReferenceInput>}
                        {can('update', 'GasMeterUnit', 'gasMeterPoints', 'GasMeterPoint') && <ReferenceArrayGasMeterPointInput source="gasMeterPointsIds" reference="GasMeterPoint" filter={{ gasMeterUnit: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.gasMeterPoints" formClassName={classes.flexReferenceArrayInput} fullWidth />}
                        {can('update', 'GasMeterUnit', 'reports', 'Report') && (Boolean(gasMeterType)
                            ? <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasMeterUnit", gasMeterType }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.reports" fullWidth />
                            : <TextField label="Отчеты" disabled placeholder="Для добавления отчетов необходимо Устройство" className={classes.textField} InputLabelProps={{ shrink: true }} variant="filled" fullWidth />
                        )}
                        {can('update', 'GasMeterUnit', 'dashboards', 'Dashboard') && (Boolean(gasMeterType)
                            ? <DashboardReferenceArrayInput source="dashboardsIds" reference="Dashboard" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.dashboards" fullWidth />
                            : <TextField label="Информационные панели" disabled placeholder="Для добавления информационных панелей необходимо Устройство" className={classes.textField} InputLabelProps={{ shrink: true }} variant="filled" fullWidth />
                        )}
                        {can('update', 'GasMeterUnit', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.tags" fullWidth />}
                        {can('update', 'GasMeterUnit', 'fixedAsset') && <TextInput source="fixedAsset" fullWidth />}
                        {can('update', 'GasMeterUnit', 'linkToir') && <TextInput source="linkToir" fullWidth />}
                    </SimpleForm>
                </CardBlankLayout>
            </EditView>
        </EditContextProvider>
    );
};

export default GasMeterUnitEdit