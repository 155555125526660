import React from 'react';
import moment from 'moment'
// import { makeStyles } from '@material-ui/core/styles';
import { Create, DateTimeInput, FormDataConsumer, NumberInput, SimpleForm, TextInput, ReferenceArrayInput, required } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import ReportReferenceArrayInputWithDefault from '../Report/ReportReferenceArrayInputWithDefault';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

const GasQualityCertificateCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'GasQualityCertificate', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasQualityCertificate', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasQualityCertificate', 'gqsId') && <TextInput source="gqsId" validate={required()} />}
                {can('create', 'GasQualityCertificate', 'n2') && <NumberInput source="n2" />}
                {can('create', 'GasQualityCertificate', 'co2') && <NumberInput source="co2" />}
                {can('create', 'GasQualityCertificate', 'density') && <NumberInput source="density" />}
                {can('create', 'GasQualityCertificate', 'validFrom') && <FormDataConsumer source="validFrom">
                    {({ formData, ...rest }) => (
                        <DateTimeInput {...rest} source="validFrom" inputProps={{ max: formData && formData.validTo ? moment(formData.validTo).format("YYYY-MM-DD") : null }} />
                    )}
                </FormDataConsumer>}
                {can('create', 'GasQualityCertificate', 'validTo') && <FormDataConsumer source="validTo">
                    {({ formData, ...rest }) => (
                        <DateTimeInput {...rest} source="validTo" inputProps={{ min: formData && formData.validFrom ? moment(formData.validFrom).format("YYYY-MM-DD") : null }} />
                    )}
                </FormDataConsumer>}
                {can('create', 'GasQualityCertificate', 'gasExtractionPoint') && <TextInput source="gasExtractionPoint" />}
                {can('create', 'GasQualityCertificate', 'gasDistributorStations', 'GasDistributorStation') && <ReferenceArrayInput source="gasDistributorStationsIds" reference="GasDistributorStation" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasQualityCertificate.fields.gasDistributorStations" fullWidth>
                    <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                </ReferenceArrayInput>}
                {can('create', 'GasQualityCertificate', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasQualityCertificate.fields.reports" fullWidth />}
                {can('create', 'GasQualityCertificate', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasQualityCertificate" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasQualityCertificate.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasQualityCertificateCreate;