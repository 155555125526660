import React from 'react';
import { NullableBooleanInput, ReferenceArrayInput, ReferenceInput, TextInput } from 'react-admin';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import AutocompleteInput from '../Inputs/AutoompleteInput';
import EnumInput from '../Inputs/EnumInput'
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import IPInput from '../Inputs/IPInput';
import { PhoneFilterInput } from '../Inputs/PhoneInput';
import SearchInput from '../Inputs/SearchInput';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import QuickFilter from "../Layout/filter/QuickFilter";
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import { can } from "../../abilityProvider";
import useAnaliticsEnum from '../../dataProvider/Analitics/useEnum';
import DeviceStatusBackReferenceInput from './analitics/DeviceStatusBackReferenceInput';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}));

const matchTrue = () => true;

const GasMeterUnitFilter = (props) => {
    const classes = useStyles()

    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn />
            {can('getList', 'GasMeterUnit', 'aisId') && <TextInput source="aisId" alwaysOn resettable className={classes.mediumWidth} />}
            {can('getList', 'GasMeterUnit', 'controller.ip') && <IPInput source="controller.ip" label="resources.Controller.fields.ip" alwaysOn resettable className={classes.mediumWidth} />}
            {can('getList', 'GasMeterUnit', 'gasMeter.dbId') && <TextInput source="gasMeter.dbId" label="resources.GasMeter.fields.dbId" alwaysOn resettable className={classes.mediumWidth} />}
            {can('getList', 'GasMeterUnit', 'enableWriteGasQualityCertificate') && <NullableBooleanInput source="enableWriteGasQualityCertificate" />}
            {can('getList', 'GasMeterUnit', 'gasMeterPad', 'GasMeterPad') && <ReferenceInput source="gasMeterPadReference" label="resources.GasMeterUnit.fields.gasMeterPad" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterUnit', 'gasMeterPoints', 'GasMeterPoint') && <QuickFilter source="gasMeterPoints_some.aisId_is_null" label="Линии (ID АИС отсутствует)" defaultValue={true} />}
            {/* {can('getList', 'GasMeterUnit', 'tags', 'Tag') && <ReferenceInput source="tags_some.id" label="resources.Tag.fields.tag_some" reference="Tag" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasMeterUnit', 'tags', 'Tag') && <ReferenceInput source="tags_none.id" label="resources.Tag.fields.tag_none" reference="Tag" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>} */}
            {can('getList', 'GasMeterUnit', 'tags', 'Tag') && <ReferenceArrayInput source="tags_and" label="resources.Tag.fields.tags_and" reference="Tag" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasMeterUnit', 'tags', 'Tag') && <ReferenceArrayInput source="tags_not_and" label="resources.Tag.fields.tags_not_and" reference="Tag" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasMeterUnit', 'dashboards', 'Dashboard') && <ReferenceArrayInput source="dashboards_and" label="resources.Dashboard.fields.dashboards_and" reference="Dashboard" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasMeterUnit', 'dashboards', 'Dashboard') && <ReferenceArrayInput source="dashboards_not_and" label="resources.Dashboard.fields.dashboards_not_and" reference="Dashboard" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasMeterUnit', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_and" label="resources.Report.fields.reports_and" reference="Report" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
            {can('getList', 'GasMeterUnit', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_not_and" label="resources.Report.fields.reports_not_and" reference="Report" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
            {can('getList', 'GasMeterUnit', 'inventoryNumber') && <TextInput source="inventoryNumber" className={classes.mediumWidth} />}
            {can('getList', 'GasMeterUnit', 'controller.phoneNumber') && <PhoneFilterInput source="controller.phoneNumber_contains" label="resources.Controller.fields.phoneNumber" className={classes.mediumWidth} />}
            {can('getList', 'GasMeterUnit', 'controller.phoneNumber') && <QuickFilter source="controller.phoneNumber_is_null" label="resources.Controller.fields.phoneNumber_is_null" defaultValue={true} />}
            {can('getList', 'GasMeterUnit', 'controller.ip') && <QuickFilter source="controller.ip_is_null" label="resources.Controller.fields.ip_is_null" defaultValue={true} />}
            {can('getList', 'GasMeterUnit', 'controller.type') && <EnumInput name="ControllerType" source="controller.type" option="resources.Controller.enums.type" emptyText="ra.message.no" label="resources.GasMeterUnit.fields.controller.type" />}
            {can('getList', 'GasMeterUnit', 'gasMeter.type') && <EnumInput name="GasMeterType" source="gasMeter.type" label="resources.GasMeter.fields.type" option="resources.GasMeter.enums.type" label="resources.GasMeterUnit.fields.gasMeter.type" />}
            {can('getList', 'GasMeterUnit', 'fixedAsset') && <TextInput source="fixedAsset" fullWidth label="resources.GasMeterUnit.fields.fixedAssetShort" />}
            <DeviceStatusBackReferenceInput source="gasMeter.dbId_in" reference="DeviceStatus" target="dbId" label="resources.GasMeterUnit.fields.deviceStatus">
                <EnumInput source="status" name="Status" />
            </DeviceStatusBackReferenceInput>
        </ManualSubmitFilter>
    )
}


export default GasMeterUnitFilter;