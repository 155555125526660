import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'
import 'moment/locale/ru';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import { useTranslate } from 'react-admin'
import EnumField from '../Fields/EnumField';
import ReportAvatar from './ReportAvatar';
import { can } from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    cardActionArea: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "unset",
        justifyContent: "space-between",
    },
    root: {
        alignItems: "start"
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    ml2: { marginLeft: 8 },
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const ReportCard = forwardRef(({ className, classes: classesOverride, record, resource, source, linkType, referenceRecord, lastUpdate, ...props }, ref) => {
    const translate = useTranslate()
    const classes = useStyles({ classes: classesOverride });
    if (!record) return null;
    const title = `${record.name} ${translate(`resources.${record.resource}.menu`, { smart_count: 1 })}${record.gasMeterType ? ` ${translate(`resources.GasMeter.enums.type.${record.gasMeterType}`)}` : ""}`
    const timestamp = lastUpdate && lastUpdate.data && lastUpdate.data[0] && moment.unix(Number(lastUpdate.data[0].value[1])).format('L')


    return (
        <Card className={className} >
            <CardActionArea
                component={LinkRef}
                ref={ref}
                to={{
                    pathname: `/Report/${record.id}/show`,
                    search: `?resource=${resource}&id=${referenceRecord.id}`,
                }}
                className={classes.cardActionArea}
            >
                <CardHeader
                    avatar={<ReportAvatar record={record} />}
                    title={title}
                    titleTypographyProps={{ variant: "subtitle1" }}
                    classes={{ title: classes.title, root: classes.root }}
                />
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="baseline" className={classes.secondaryContent}>
                        {can('getOne', 'Report', 'type') && <EnumField resource="Report" source="type" record={record} variant="body2" color="textSecondary" />}
                        {timestamp && <Typography variant="body2" color="textSecondary" className={classes.ml2} >{timestamp}</Typography>}
                    </Box>
                </CardContent >
            </CardActionArea>
        </Card>
    );
});

// FileCard.defaultProps = {
//     resourse: "File",
//     linkType: "show",
// };

export default ReportCard;