import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import classnames from 'classnames';

import { useAuthenticated, useTranslate, Title } from 'react-admin';


const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                height: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                height: '100vh',
                marginTop: '-3em',
            },
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        message: {
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            opacity: 0.5,
            margin: '0 1em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
        ml3: {
            marginLeft: 16
        }
    }),
    { name: 'RemoveLayout' }
);

const goBack = () => {
    window.history.go(-1);
}

const stopPropagation = e => {
    e.stopPropagation()
};

const Remove = props => {
    const { className, classes: classesOverride, resource, basePath, record, title, defaultTitle, ...rest } = props;
    const classes = useStyles(props);
    const translate = useTranslate();
    useAuthenticated();
    return (
        <div
            className={classnames(classes.container, className)}
            {...sanitizeRestProps(rest)}
        >
            <Title title={title} defaultTitle={defaultTitle} record={record}/>
            <div className={classes.message}>
                <DeleteIcon className={classes.icon} />
                <h1>{translate('ra.page.removed')}</h1>
                <div>{translate('ra.message.removed')}.</div>
            </div>
            <div className={classes.toolbar}>
                <Button 
                    variant="contained" 
                    component={Link}
                    to={basePath}
                    className={classes.ml3}
                >
                    {translate('ra.action.back')}
                </Button>
                <Button 
                    variant="contained" 
                    component={Link}
                    to={`/TrashItem`}
                    className={classes.ml3}
                >
                    {translate('ra.action.trash_open')}
                </Button>
            </div>
        </div>
    );
};

const sanitizeRestProps = ({
    staticContext,
    history,
    location,
    match,
    ...rest
}) => rest;

Remove.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,

    location: PropTypes.object,
};

export default Remove;
