import React, { Fragment } from 'react';
import List from '../Layout/list/List';
import ListActions from '../Layout/list/ListActionsWithSelectColumnsButton';
import useGasMeterPadExporter from './useGasMeterPadExporter';
import GasMeterPadFilter from './GasMeterPadFilter';
import GasMeterPadBulkActionButtons from './GasMeterPadBulkActionButtons';
import GasMeterPadDatagrid, { useDefaultDatagridColumns } from './GasMeterPadDatagrid';
import DocumentationLinkButton from '../Layout/DocumentationLinkButton';



const GasMeterPadList = (props) => {
    const exporter = useGasMeterPadExporter()
    const manualSubmitForm = React.useRef(null)
    const columns = useDefaultDatagridColumns(props)

    return (
        <Fragment>
            <DocumentationLinkButton to="/docs/askug-main-portal/5GasMeterPad" target="_blank" />
            <List
                {...props}
                actions={<ListActions columns={columns} />}
                bulkActionButtons={<GasMeterPadBulkActionButtons />}
                exporter={exporter}
                filters={<GasMeterPadFilter formRef={manualSubmitForm} />}
            >
                <GasMeterPadDatagrid columns={columns} />
            </List>
        </Fragment>
    );
};

GasMeterPadList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default GasMeterPadList;