import { useMemo } from 'react';
import compact from 'lodash/compact';

const useColumns = (fields) => {

    const columns = useMemo(() => {
        return compact(fields).reduce((acc, field) => {
            const column = field.props.column || field.props.source

            acc[column] = field;
            return acc
        }, {})
    }, []);

    return columns
}

export default useColumns