import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, TabbedForm, FormTab, TextInput, DateInput, ReferenceArrayInput, AutocompleteArrayInput, required, FormDataConsumer, email } from 'react-admin';
import EnumInput from '../Inputs/EnumInput';
import PhoneInput from '../Inputs/PhoneInput';
import AccessResourceInput from './AccessResourceInput';
import AccessReferenceArrayInput from './AccessReferenceArrayInput';
import GridField from '../Fields/GridField';



const validateEmail = [email()];

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

const CounterpartyCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >

            <TabbedForm redirect="show" >
                <FormTab label="resources.Counterparty.tabs.general">
                    <TextInput source="name" validate={required()} fullWidth />
                    <TextInput source="description" multiline fullWidth />
                    <TextInput source="inn" validate={required()} />
                    <EnumInput source="type" validate={required()} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.type === "LEGALENTITY" || formData.type === "GOVERNMENTAGENCY") &&
                            <GridField>

                                <TextInput source="kpp" xs {...rest} />
                                <TextInput source="okpo" xs {...rest} />
                                <TextInput source="ogrn" xs {...rest} />
                            </GridField>
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.type === "INDIVIDUAL" &&
                            <GridField>
                                <TextInput source="fio" xs={6} {...rest} />
                                <TextInput source="passport" xs {...rest} />
                                <DateInput source="passdateissue" xs  {...rest} />
                            </GridField>
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.type === "SEPARATEDIVISION" &&
                            <>
                                <TextInput source="parentId" {...rest} />
                            </>
                        }
                    </FormDataConsumer>
                    <TextInput source="addressLegal" fullWidth />
                    <TextInput source="addressActual" fullWidth />
                    <TextInput source="addressPostal" fullWidth />
                    <PhoneInput source="phone" />
                    <PhoneInput source="phoneMobile" />
                    <TextInput source="email" type="email" validate={validateEmail} />
                </FormTab>
                <FormTab label="resources.Counterparty.tabs.access" path="access">
                    <AccessResourceInput source="accessResource" name="AccessResource" option="resources.Access.enums.resource" allowEmpty emptyValue={null} emptyText="ra.message.no" />
                    <AccessReferenceArrayInput source="accessIds" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.Counterparty.fields.access" />
                    <ReferenceArrayInput source="usersIds" reference="User" filter={{ counterparty: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.Counterparty.fields.users" fullWidth>
                        <AutocompleteArrayInput />
                    </ReferenceArrayInput>
                </FormTab>
            </TabbedForm>

        </Create>
    );
};

export default CounterpartyCreate;



