import List from '../Layout/list/List';
import React from 'react';
import useCounterpartyExporter from './useCounterpartyExporter';
import CounterpartyBulkActionButtons from './CounterpartyBulkActionButtons';
import CounterpartyFilter from './CounterpartyFilter';
import CounterpartyDatagrid from './CounterpartyDatagrid';




const CounterpartyList = (props) => {
    const exporter = useCounterpartyExporter()
    const manualSubmitForm = React.useRef(null);

    return (
        <List
            {...props}
            bulkActionButtons={<CounterpartyBulkActionButtons />}
            filters={<CounterpartyFilter formRef={manualSubmitForm} />}
            exporter={exporter}
        >
            <CounterpartyDatagrid />
        </List>
    )
};

CounterpartyList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default CounterpartyList;