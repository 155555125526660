import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    },
}),
    { name: 'RaReferenceManyActions' }
);

const ReferenceManyActions = props => {
    const { className, children, ...rest } = props;
    const classes = useStyles(props);
    return (
        <div className={classnames(classes.root, className)}>
            {children}
        </div>
    );
};


export default ReferenceManyActions;