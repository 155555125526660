import React, { cloneElement, isValidElement, Children } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useQueryWithStore } from 'react-admin';
import Error from '../../Layout/Error';
import Loading from '../../Layout/Loading';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    card: {}
}));

const DeviceStatusLayout = ({ children, classes: classesOverride, className, label, ...props }) => {
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    const resource = 'DeviceStatus'
    const { loading, loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource,
        payload: { id: props.record?.gasMeter?.dbId }
    });

    if (loading) { return <Loading />; }
    if (error) { return <Error caption='ra.message.analitics_not_found' />; }

    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
            <Card className={classes.card}>

                {Children.map(children, field =>
                    field && isValidElement(field)
                        ? cloneElement(field, {
                            ...props,
                            record: data,
                            resource,
                            referenceRecord: props.record
                        })
                        : null
                )}
                {/* {cloneElement(Children.only(children), {
                    ...props,
                    ...children.props,
                    record: data,
                    referenceRecord: props.record
                })} */}
            </Card>
        </div>

    );
};



export default DeviceStatusLayout;