import { DateField, Pagination, ReferenceField, ReferenceManyField as RaReferenceManyField, SearchInput, TextField, UrlField, useShowController, ShowContextProvider, SimpleShowLayout } from 'react-admin';
import DividerField from '../Fields/DividerField';
import Breadcrumb from '../Layout/Breadcrumb';
import Breadcrumbs from '../Layout/Breadcrumbs';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import DashboardNestedGridField from '../Dashboard/DashboardNestedGridField';
import DashboardPreview from '../Dashboard/DashboardCard';
import EnumField from '../Fields/EnumField';
import { ReferenceDatagrid as GasMeterPointReferenceDatagrid } from '../GasMeterPoint/GasMeterPointList';
import { GasQualityCertificateReferenceDatagrid } from '../GasQualityCertificate/GasQualityCertificateDatagrid';
import GasMeterUnitBanner from './GasMeterUnitBanner';
import MoreContent from '../Layout/MoreContent';
import NestedField from '../Fields/NestedField';
import PhoneField from '../Fields/PhoneField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import ReferenceManyField from '../Fields/referenceManyField/ReferenceManyField';
import ReferenceManyFilter from '../Fields/referenceManyField/ReferenceManyFilter';
import ReferenceManyActions from '../Fields/referenceManyField/ReferenceManyActions';
import ReferenceListLinkButton from '../Buttons/ReferenceListLinkButton';
import ReportCard from '../Report/ReportCard';
import ReportNestedGridField from '../Report/ReportNestedGridField';
import DeviceLinkField from './GasMeterUnitDeviceLinkField';
import DeviceStatusLayout from './analitics/DeviceStatusLayout';
import DeviceStatusField from './analitics/DeviceStatusField';
import DeviceStatusDatailsField from './analitics/DeviceStatusDatailsField';
import DeviceStatusRefreshButton from './analitics/DeviceStatusRefreshButton'
import Separator from '../Layout/Separator';
import { ShowView } from '../Layout/ShowView';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';

const GasMeterUnitBreadcrumbs = (props) => {
    return (
        <Breadcrumbs {...props}>
            <Breadcrumb resource={"GasRegion"} />
            <Breadcrumb resource={"GasDistrict"} />
            <Breadcrumb resource={"GasConsumer"} />
            <Breadcrumb resource={"GasMeterPad"} />
        </Breadcrumbs>
    );
}

const ReferenceManyFilters = (props) => {
    return (
        <ReferenceManyFilter {...props}>
            <SearchInput source="q" id={`${props.reference}.q`} alwaysOn />
        </ReferenceManyFilter>
    )
}

const ReferenceManyActionButtons = (props) => {
    return (
        <ReferenceManyActions >
            <ReferenceListLinkButton {...props} />
        </ReferenceManyActions>
    )
}

const GasMeterUnitShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showAnaliticsButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const dbId = controllerProps.record?.gasMeter?.dbId;
    const enableWriteGasQualityCertificate = controllerProps.record?.enableWriteGasQualityCertificate;


    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView breadcrumbs={<GasMeterUnitBreadcrumbs />} title={<TitleForRecord source="name" {...props} />} {...props} {...controllerProps} >
                <GasMeterUnitBanner />
                {can('getOne', 'GasMeterUnit', 'reports', 'Report') && <ReportNestedGridField source="reports" filter={{ dbIds: [dbId] }} label="resources.GasMeterUnit.fields.reports" >
                    <ReportCard />
                </ReportNestedGridField>}
                {can('getOne', 'GasMeterUnit', 'dashboards', 'Dashboard') && <DashboardNestedGridField source="dashboards" label="resources.GasMeterUnit.fields.dashboards" >
                    <DashboardPreview />
                </DashboardNestedGridField>}
                {can('getOne', 'GasMeterUnit', 'gasMeterPoints', 'GasMeterPoint') &&
                    <ReferenceManyField
                        reference="GasMeterPoint"
                        target="gasMeterUnitReference"
                        label="resources.GasMeterUnit.fields.gasMeterPoints"
                        filters={<ReferenceManyFilters />}
                        sort={{ field: 'name', order: 'ASC' }} perPage={100}
                        perPage={25}
                        pagination={<Pagination />}
                    >
                        <GasMeterPointReferenceDatagrid />
                    </ReferenceManyField>}

                {can('getList', 'GasQualityCertificate') && enableWriteGasQualityCertificate === true &&
                    <ReferenceManyField
                        reference="GasQualityCertificate"
                        target="gasMeterUnit_some"
                        label="resources.GasMeterUnit.fields.gasQualityCertificates"
                        filters={<ReferenceManyFilters />}
                        actions={<ReferenceManyActionButtons />}
                        sort={{ field: 'validTo', order: 'DESC' }}
                        perPage={1}
                        pagination={<Pagination rowsPerPageOptions={[1, 5, 10, 25, 50, 100]} />}
                    >
                        <GasQualityCertificateReferenceDatagrid />
                    </ReferenceManyField>}

                <Separator action={(<div ref={showMoreButton} ></div>)} />
                <MoreContent showMoreButton={showMoreButton}>
                    <CardShowLayout>
                        {can('getOne', 'GasMeterUnit', 'aisId') && <TextField source="aisId" />}
                        {can('getOne', 'GasMeterUnit', 'inventoryNumber') && <TextField source="inventoryNumber" />}
                        {can('getOne', 'GasMeterUnit', 'controller.serialNumber') && <TextField source="controller.serialNumber" label="resources.Controller.fields.serialNumber" />}
                        {can('getOne', 'GasMeterUnit', 'controller.type') && <EnumField source="controller.type" label="resources.GasMeterUnit.fields.controller.type" option="resources.Controller.enums.type" />}
                        {can('getOne', 'GasMeterUnit', 'controller.url') && <TextField source="controller.url" label="resources.Controller.fields.url" />}
                        {can('getOne', 'GasMeterUnit', 'controller.phoneNumber') && <PhoneField source="controller.phoneNumber" label="resources.Controller.fields.phoneNumber" />}
                        {can('getOne', 'GasMeterUnit', 'gasMeter.serialNumber') && <TextField source="gasMeter.serialNumber" label="resources.GasMeter.fields.serialNumber" />}
                        {can('getOne', 'GasMeterUnit', 'gasMeter.dbId') && <TextField source="gasMeter.dbId" label="resources.GasMeter.fields.dbId" />}
                        {can('getOne', 'GasMeterUnit', 'gasMeter.type') && <EnumField source="gasMeter.type" label="resources.GasMeterUnit.fields.gasMeter.type" option="resources.GasMeter.enums.type" />}
                        {can('getOne', 'GasMeterUnit', 'gasMeter.port') && <TextField source="gasMeter.port" label="resources.GasMeter.fields.port" />}
                        {can('getOne', 'GasMeterUnit', 'gasMeter.commercialHour') && <TextField source="gasMeter.commercialHour" label="resources.GasMeter.fields.commercialHour" />}
                        {can('getOne', 'gasMeterPad', 'gasMeterPad.aisId', 'GasMeterPad') && <TextField source="gasMeterPad.aisId" label="resources.GasMeterUnit.fields.dasMeterPadAisId" />}
                        {can('getOne', 'GasMeterUnit', 'fixedAsset') && <TextField source="fixedAsset" />}
                        {can('getOne', 'GasMeterUnit', 'linkToir') && <UrlField source="linkToir" target="_blank" noWrap style={{ display: "inline-block", maxWidth: 400 }} variant="body2" />}
                        {can('getOne', 'Device') && <DeviceLinkField source="linkToDevice" target="_blank" noWrap style={{ display: "inline-block", maxWidth: 400 }} />}


                        <DividerField />
                        {can('getOne', 'GasMeterUnit', 'id') && <TextField source="id" />}
                        {can('getOne', 'GasMeterUnit', 'createdBy', 'User') && <NestedField source="createdBy" reference="User" >
                            <TextField source="name" />
                        </NestedField>}
                        {can('getOne', 'GasMeterUnit', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                        {can('getOne', 'GasMeterUnit', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                    </CardShowLayout>
                </MoreContent>

                <Separator action={(<div ref={showAnaliticsButton} ></div>)} />
                <MoreContent showMoreButton={showAnaliticsButton} label="ra.action.show_analitics">
                    <DeviceStatusLayout>
                        <SimpleShowLayout>
                            <DeviceStatusField source="status" labeled />
                            <DateField source="date" options={dateTimeOptions} />
                            <DeviceStatusDatailsField source="details" />
                            <DeviceStatusRefreshButton />
                        </SimpleShowLayout>
                    </DeviceStatusLayout>
                </MoreContent>

                {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
                {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                    <CardBlankLayout>
                        <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                            <ReferenceHistoryDatagrid source="GasMeterUnit" />
                        </RaReferenceManyField>
                    </CardBlankLayout>
                </MoreContent>}
            </ShowView>
        </ShowContextProvider>

    );
};

export default GasMeterUnitShow;