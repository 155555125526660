import { useTranslate, useResourceContext } from 'react-admin';
import customExporter, { booleanFormatter, dateFormatter } from '../../exporter';



const useExporter = () => {
    const translate = useTranslate()
    const resource = useResourceContext()
    const fileName = translate(`resources.${resource}.name`, { smart_count: 2 })
    const modelExport = {
        id: true,
        aisId: true,
        name: true,
        description: true,
        createdBy: {
            id: true,
            name: true,
            __resource: "User",
        },
        createdAt: dateFormatter,
        updatedAt: dateFormatter,
        gasConsumer: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasConsumer",
        },
        gasDistributorOrganization: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasDistributorOrganization",
        },
        gasDistributorStationOutput: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasDistributorStationOutput",
        },
        gasDistrict: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasDistrict",
        },
        gasMeterUnits: {
            id: true,
            name: true,
            aisId: true,
            __resource: "GasMeterUnit",
        },
        addrManual: true,
        disabled: booleanFormatter,
        isProvider: booleanFormatter,
        disabledPeriods: {
            id: true,
            dateFrom: dateFormatter,
            dateTo: dateFormatter,
            description: true,
            __resource: "DisabledPeriod",
        },
        reports: {
            id: true,
            name: true,
            __resource: "Report",
        },
        tags: {
            id: true,
            name: true,
            __resource: "Tag",
        },
        __resource: "GasMeterPad",
    }
    const exporter = customExporter(fileName, modelExport, { translate })

    return exporter
}



export default useExporter;