import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

export const GET_ONE_DEVICE = gql`
    query Device( $where: DeviceWhereUniqueInput! ) {
        data: Device( where: $where ) {id}
    }
`

export default (args) => {

    return apolloCoreClient.query({
        query: GET_ONE_DEVICE,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};
