import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, ReferenceArrayInput, required } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import ReportReferenceArrayInputWithDefault from '../Report/ReportReferenceArrayInputWithDefault';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
//     flexReferenceArrayInput: {
//         display: "flex",
//         alignItems: " flex-end",
//     },
// }));

const GasRegionCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'GasRegion', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasRegion', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasRegion', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'GasRegion', 'gasDistricts', 'GasDistrict') && <ReferenceArrayInput source="gasDistrictsIds" reference="GasDistrict" filter={{ gasRegion: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.gasDistricts" fullWidth>
                    <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                </ReferenceArrayInput>}
                {can('create', 'GasRegion', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "GasRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.reports" fullWidth />}
                {can('create', 'GasRegion', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasRegionCreate;