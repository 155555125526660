import { useRef, useEffect } from 'react';
import lodashMemoize from 'lodash/memoize';
import dataProvider from '../../dataProvider';
import getOneGasMeter from '../../dataProvider/Core/getOneGasMeter';

const memoize = (fn) =>
    lodashMemoize(fn, (...args) => JSON.stringify(args));

const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

export const asyncUnique = memoize((resource) => async (value, values, props) => {
    if (value === undefined) return undefined;
    const { data } = await dataProvider("getOne", resource, { [props.name]: value })
    return (data === null) ? undefined : { message: 'ra.validation.unique', args: { value } }
});


export const asyncUniqueGasMeter = memoize((resource, source) => async (value, values, props) => {
    if (value === undefined) return undefined;
    const { data: { data } } = await getOneGasMeter({ where: { [source]: value } })
    return (data === null) ? undefined : { message: 'ra.validation.unique', args: { value } }
});
