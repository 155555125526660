import dataProviderCore from './Core/graphqlProvider'
import dataProviderAuth from './Auth/dataProvider'
import dataProviderPrometheus from './Prometheus/prometheusProvider'
import dataProviderAnalitics from './Analitics/graphqlProvider'
import dataProviderReportDespatch from './ReportDespatch/dataProvider'

const dataProviders = [dataProviderCore, dataProviderAuth, dataProviderPrometheus, dataProviderAnalitics, dataProviderReportDespatch];

export const dataProviderFactory = () => {
  return Promise.resolve((type, resource, params) => {

    const findDataProvider = dataProviders.find(dp => dp.knownResources.includes(resource));

    if (!findDataProvider) throw new Error('Provider is not loaded')
    return findDataProvider.dataProvider(type, resource, params);
  });
};