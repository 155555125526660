import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, ReferenceInput, ReferenceArrayInput, required } from 'react-admin';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import BooleanInput from '../Inputs/BooleanInput';
import ReportReferenceArrayInputWithDefault from '../Report/ReportReferenceArrayInputWithDefault';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import DisabledPeriodsArrayInput from './GasMeterPadDisabledPeriodsArrayInput';
import GasDistributorStationOutputSuggestionItem, { gasDistributorStationOutputOptionText } from './GasDistributorStationOutputSuggestionItem';
import { can } from "../../abilityProvider";
import { matchTrue } from '../../utils';



const gasDistributorStationOutputFilterToQuery = searchText => ({ searchWithGDS: searchText });

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

const GasMeterPadCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show" initialValues={{ disabled: false, isProvider: false }}>
                {can('create', 'GasMeterPad', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasMeterPad', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasMeterPad', 'addrManual') && <TextInput source="addrManual" fullWidth />}
                {can('create', 'GasMeterPad', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'GasMeterPad', 'gasDistrict', 'GasDistrict') && <ReferenceInput source="gasDistrict.id" reference="GasDistrict" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasDistrict" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasMeterPad', 'gasConsumer', 'GasConsumer') && <ReferenceInput source="gasConsumer.id" reference="GasConsumer" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasConsumer" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasMeterPad', 'gasDistributorOrganization', 'GasDistributorOrganization') && <ReferenceInput source="gasDistributorOrganization.id" sort={{ field: 'name', order: 'ASC' }} perPage={100} reference="GasDistributorOrganization" label="resources.GasMeterPad.fields.gasDistributorOrganization" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasMeterPad', 'gasDistributorStationOutput', 'GasDistributorStationOutput') && <ReferenceInput
                    source="gasDistributorStationOutput.id"
                    filterToQuery={gasDistributorStationOutputFilterToQuery}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={100}
                    reference="GasDistributorStationOutput"
                    label="resources.GasMeterPad.fields.gasDistributorStationOutput"
                    allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={gasDistributorStationOutputOptionText} suggestionItem={<GasDistributorStationOutputSuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasMeterPad', 'gasMeterUnits', 'GasMeterUnit') && <ReferenceArrayInput source="gasMeterUnitsIds" reference="GasMeterUnit" filter={{ gasMeterPad: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.gasMeterUnits" fullWidth>
                    <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                </ReferenceArrayInput>}
                {can('create', 'GasMeterPad', 'disabled') && <BooleanInput source="disabled" />}
                {can('create', 'GasMeterPad', 'isProvider') && <BooleanInput source="isProvider" />}
                {can('create', 'GasMeterPad', 'disabledPeriods') && <DisabledPeriodsArrayInput source="disabledPeriods" />}
                {can('create', 'GasMeterPad', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.reports" fullWidth />}
                {can('create', 'GasMeterPad', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasMeterPad" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterPad.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasMeterPadCreate;