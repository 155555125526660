import React from 'react';
import { BulkExportButton } from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import BulkUpdateTagsButton from "../Buttons/BulkUpdateTagsButton";
import { can } from "../../abilityProvider";



const GasRegionBulkActionButtons = props => (
    <>
        {can('update', 'GasRegion', 'tags', 'Tag') && <BulkUpdateTagsButton {...props} />}
        {can('getList', 'GasRegion') && <BulkExportButton {...props} />}
        {can('remove', 'GasRegion') && <BulkRemoveButton {...props} />}
    </>
)



export default GasRegionBulkActionButtons;