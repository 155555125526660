import { GET_LIST, GET_ONE, CREATE, UPDATE, UPDATE_MANY, DELETE, DELETE_MANY, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';
import Contact from './Contact';
import ReportDespatch from './ReportDespatch';

const mappingType = {
    [GET_LIST]: 'getList',
    [GET_ONE]: 'getOne',
    [GET_MANY]: 'getMany',
    [GET_MANY_REFERENCE]: 'getManyReference',
    [CREATE]: 'create',
    [UPDATE]: 'update',
    [UPDATE_MANY]: 'updateMany',
    [DELETE]: 'delete',
    [DELETE_MANY]: 'deleteMany',
};

const getMethod = (fetchType) => {
    const method = mappingType[fetchType] || fetchType
    return method
}

const buildQuery = {
    Contact,
    ReportDespatch
}

export const knownResources = Object.keys(buildQuery);

export const dataProvider = (fetchType, resource, params) => {

    return buildQuery[resource][getMethod(fetchType)](params)
};

export default {
    dataProvider,
    knownResources,
};
