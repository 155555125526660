import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import { getCookie } from '../../utils';
import { PACKAGE_VERSION, PACKAGE_VERSION_COOKIE } from '../../appsettings';



const useStyles = makeStyles(theme => ({
    cardActionArea: {
        height: "100%"
    },
    avatar: {
        color: deepOrange[500],
        backgroundColor: deepOrange[100],
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const NewReleaseCard = forwardRef((props, ref) => {
    const classes = useStyles();
    const version = getCookie(PACKAGE_VERSION_COOKIE);

    if (version === PACKAGE_VERSION) return null

    return (
        <Card  >
            <CardActionArea
                component={LinkRef}
                ref={ref}
                to="/About/Сhangelog"
                className={classes.cardActionArea}
            >
                <CardHeader
                    avatar={<Avatar aria-label="newRelease" variant="rounded" className={classes.avatar}>
                        <WhatshotIcon />
                    </Avatar>}
                    title="Портал обновился!"
                    titleTypographyProps={{ variant: "subtitle1" }}
                    classes={{ title: classes.title }}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Новая версия уже здесь! Узнайте что нового в Журнале изменений.
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
});

export default NewReleaseCard;