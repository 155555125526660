import React from 'react';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import { useInput, FieldTitle, InputHelperText, TextInput, regex } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';


const regexp = /^(\+7|7|8)?[\s\-]?([489][0-9]{2})?[\s\-]?([0-9]{3})[\s\-]?([0-9]{4})$/;
const validatePhone = [regex(regexp, 'ra.validation.phone_invalid')];
const phoneParse = value => value.replace(/[^+\w\d]/g, '');


const PhoneMaskInput = ({ ...props }) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null) }}
      mask={['+', '7', ' ', '(', /[489]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      keepCharPositions={true}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
};




const PhoneInput = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = phoneParse,
  validate = validatePhone,
  resource,
  source,
  variant = 'filled',
  inputProps,
  className,
  ...rest
}) => {

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  return (
    <TextField
      id={id}
      {...input}
      className={className}
      variant={variant}
      error={!!(touched && error)}
      helperText={
        (touched && error) || helperText ? (
          <InputHelperText
            touched={touched}
            error={error}
            helperText={helperText}
          />
        ) : null
      }
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      }
      margin={margin}
      InputProps={{
        ...inputProps,
        inputComponent: PhoneMaskInput,
      }}
      {...options}
      {...sanitizeRestProps(rest)}
    />

  )
};

export const PhoneFilterInput = (props) => {


  return (<TextInput {...props} parse={phoneParse} />);
};

export default PhoneInput;
