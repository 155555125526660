import React from 'react';
import { setCookie } from '../../utils';
import { PACKAGE_VERSION, PACKAGE_VERSION_COOKIE } from '../../appsettings';


const Changelog = ({children, ...props}) => {
    const version = PACKAGE_VERSION
    setCookie(PACKAGE_VERSION_COOKIE, version);
    
    return (<>{children}</>);
}

export default Changelog;