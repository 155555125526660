import omit from 'lodash/omit';
import create from './create';
import getList from './getList';
import getMany from './getMany';
import getManyReference from './getManyReference';
import getOne from './getOne';
import update from './update';
import deleteMutation from './delete';
import getEnum from './getEnum';
import { buildArrayInput, sanitizeResource } from '../utils';



const ReportDespatch = {
    create: (params) => {

        const { data } = params

        const to = data.toIds ? buildArrayInput(data.toIds) : undefined
        const cc = data.ccIds ? buildArrayInput(data.ccIds) : undefined
        const bcc = data.bccIds ? buildArrayInput(data.bccIds) : undefined

        const input = omit(data, ['toIds', 'ccIds', 'bccIds', 'lastExecutionAt', 'nextExecutionAt'])

        return create({
            input: {
                ...input,
                to,
                cc,
                bcc,
            }
        }).then(({ data: { data: { item } } }) => {
            return {
                data: sanitizeResource(item),
            }
        });
    },
    getList: (params) => {
        const { filter, pagination: { page, perPage }, sort: { field, order } } = params;

        return getList({
            filter,
            sort: [{ [field]: order.toLowerCase() }],
            page,
            perPage,
        }).then(({ data: { data: { items, pageInfo } } }) => {
            return {
                data: items.map(sanitizeResource),
                total: pageInfo.totalItems,
            }
        });
    },
    getMany: (params) => {
        const { ids } = params;

        return getMany({
            filter: {
                ids
            },
            perPage: ids.length
        }).then(({ data: { data: { items } } }) => {
            return {
                data: items.map(sanitizeResource),
            }
        });
    },
    getManyReference: (params) => {
        const { filter, pagination: { page, perPage }, sort: { field, order } } = params;

        return getManyReference({
            filter,
            sort: [{ [field]: order.toLowerCase() }],
            page,
            perPage,
        }).then(({ data: { data: { items, pageInfo } } }) => {
            return {
                data: items.map(sanitizeResource),
                total: pageInfo.totalItems,
            }
        });
    },
    getOne: (params) => {
        const { id } = params

        return getOne({
            where: { id },
        }).then(({ data: { data: { item } } }) => {
            return {
                data: sanitizeResource(item),
            }
        });
    },
    update: (params) => {

        const { id, data, previousData } = params
        const input = omit(data, ['id', 'createdAt', 'updatedAt', 'createdById', 'toIds', 'ccIds', 'bccIds', 'lastExecutionAt', 'nextExecutionAt'])

        const to = data.toIds ? buildArrayInput(data.toIds, previousData.toIds) : undefined
        const cc = data.ccIds ? buildArrayInput(data.ccIds, previousData.ccIds) : undefined
        const bcc = data.bccIds ? buildArrayInput(data.bccIds, previousData.bccIds) : undefined

        return update({
            where: { id },
            input: {
                ...input,
                to,
                cc,
                bcc,
            }
        }).then(({ data: { data: { item } } }) => {
            return {
                data: sanitizeResource(item),
            }
        });
    },
    delete: (params) => {
        const { id } = params

        return deleteMutation({
            where: { id },
        }).then(({ data: { data: { item } } }) => {
            return {
                data: sanitizeResource(item),
            }
        });
    },
    deleteMany: (params) => {
        const { ids } = params

        return Promise.all(
            ids.map(id =>
                deleteMutation({ where: { id } })
            )
        ).then(results => {
            const data = results.reduce((acc, { data: { data: { item } } }) => [...acc, item.id], []);
            return { data };
        });
    },
    getEnum: (params) => {
        const { name } = params

        return getEnum({ name }).then(({ data: { data } }) => {
            const { enumValues } = data;
            return {
                data: enumValues,
            }
        });
    },
};

export default ReportDespatch