const raPreferencesLanguageRussian = {
    'ra-preferences': {
        action: {
            choose_columns: 'Выбрать столбцы',
            toggle_theme: 'Переключить тему',
        },
        saved_queries: {
            label: "Сохраненные запросы",
            query_name: 'Имя запроса',
            new_label: 'Сохранить текущий запрос ...',
            new_dialog_title: 'Сохранить текущий запрос как',
            remove_label: 'Удалить сохраненный запрос',
            remove_label_with_name: 'Удалить запрос "%{name}"',
            remove_dialog_title: 'Удалить сохраненный запрос?',
            remove_message: 'Вы действительно хотите удалить этот элемент из списка сохраненных запросов?',
            help: 'Отфильтровать список и сохранить этот запрос для дальнейшего использования',
        },
    },
};

export default raPreferencesLanguageRussian;