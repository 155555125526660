import React, { cloneElement } from 'react';
import { useRouteMatch, Route, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DefaultAside from './Aside';
import getDocumentationPath from './getDocumentationPath';


const useStyles = makeStyles(theme => ({
    root: { 
        display: "flex",
        flexGrow: 1,
        flexBasis: 0,
    },
    content: { 
        padding: theme.spacing(4, 6),
        flexGrow: 1,
        flexBasis: 0,
    },
}));

const Content = ({
    aside,
    routes,
    ...props
}) => {

    const classes = useStyles();
    const match = useRouteMatch();

    return (
        <div className={classes.root}>
            <Paper className={classes.content}>
                {routes.reduce((result, { id, path, component }, index) => {

                    if (index === 0) {
                        result.push(
                            <Route exact path={match.url} key={`Redirect_to_${id}`}>
                                <Redirect to={getDocumentationPath(path, match.url)} />
                            </Route>
                        );
                    };
                    result.push(
                        <Route exact path={getDocumentationPath(path, match.url)} key={id}>
                            {cloneElement(component, {})}
                        
                        </Route>
                    );

                    return result;
                }, [])}
            </Paper>
            {cloneElement(aside, {})}
        </div>
    );
}

Content.defaultProps = {
    aside: <DefaultAside />,
}


export default Content;