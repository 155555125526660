import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, DateField, Filter, ShowContextProvider, useShowController } from 'react-admin';
import { ShowView } from '../Layout/ShowView';
import CardShowLayout from '../Layout/CardShowLayout';
import BlankShowLayout from '../Layout/BlankShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import EnumInput from '../Inputs/EnumInput'
import EnumField from '../Fields/EnumField';
import JsonField from '../Fields/JsonField';
import LinkField from './LinkField';
import NestedField from '../Fields/NestedField';
import ListField from '../Fields/ListField';
import { ReferenceFieldDatagrid as ReferenceHistoryDatagrid } from './HistoryList';
import HistoryField from './HistoryField';
import { dateTimeOptions } from '../../utils';



const useStyles = makeStyles(theme => {
    return {
        jsonField: {
            display: "grid",
        }
    }
});

const HistoryFilter = (props) => (
    <Filter {...props}>
        <EnumInput source="action" alwaysOn />
    </Filter>
);


const HistoryShow = props => {
    const controllerProps = useShowController(props);
    const classes = useStyles()
    const showMoreButton = React.useRef(null);

    if (!controllerProps.record) return null

    const { item, name } = controllerProps.record

    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView
                {...props}
                {...controllerProps}
                title={<TitleForRecord source="name" {...props} />}
            >
                <CardShowLayout>
                    <EnumField source="action" noWrap />
                    <EnumField source="resource" />
                    <LinkField source="name" />
                    {/* <JsonField source="data.data" className={classes.jsonField} />
                    <JsonField source="data.previous" className={classes.jsonField} /> */}
                    <HistoryField source="data" />
                    <DateField source="createdAt" options={dateTimeOptions} />
                </CardShowLayout>

                <BlankShowLayout label={`История ${name}`}>
                    <ListField
                        resource="History"
                        basePath="/History"
                        actions={false}
                        filters={<HistoryFilter />}
                        filter={{
                            item,
                        }}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        perPage={10}
                    >
                        <ReferenceHistoryDatagrid />
                    </ListField>
                </BlankShowLayout>


                <Separator action={(<div ref={showMoreButton} ></div>)} />

                <MoreContent showMoreButton={showMoreButton}>
                    <CardShowLayout>
                        <TextField source="id" />
                        <NestedField source="createdBy" reference="User" >
                            <TextField source="name" />
                        </NestedField>
                    </CardShowLayout>
                </MoreContent>
            </ShowView>
        </ShowContextProvider>
    );
};

export default HistoryShow;