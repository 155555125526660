import { BulkDeleteButton, BulkExportButton, Datagrid, DateField, ListContextProvider, ListView, TextField, useListController, useTranslate } from 'react-admin';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';

import ResourceInput from './TagResourceInput';
import EnumInput from '../Inputs/EnumInput';
import ColorField from '../Fields/ColorField';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import EnumField from '../Fields/EnumField';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import React from 'react';
import SearchInput from '../Inputs/SearchInput'
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import { can } from "../../abilityProvider";
import useListStyles from '../Layout/list/useListStyles';



const TagBulkActionButtons = props => (
    <>
        {can('remove', 'Tag',) && <BulkDeleteButton {...props} />}
        {can('getList', 'Tag',) && <BulkExportButton {...props} />}
    </>
);

const TagFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
        <ResourceInput source="resource" />
        <EnumInput source="type" />
    </ManualSubmitFilter>
);

const modelExport = {
    id: true,
    name: true,
    description: true,
    createdBy: {
        id: true,
        name: true,
        __resource: "User",
    },
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    color: true,
    type: enumFormatter,
    resource: true,
    __resource: "Tag",
};

const TagList = props => {
    const classes = useListStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContextProvider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<TagBulkActionButtons />}
                filters={<TagFilter formRef={manualSubmitForm} />}
            >
                <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
                    <ResourceLinkField source="name" target="_blank" />
                    <TextField source="description" />
                    {can('getList', 'Tag', 'type') && <EnumField source="type" />}
                    {can('getList', 'Tag', 'resource') && <EnumField source="resource" />}
                    {can('getList', 'Tag', 'color') && <ColorField source="color" hex />}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {can('getOne', 'Tag') && <ShowBlankItem />}
                        {can('update', 'Tag') && <EditMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContextProvider>
    )
};

TagList.defaultProps = {
    filter: { resource_in: ['GasRegion', 'GasDistrict', 'GasDistributorOrganization', 'GasDistributorStation', 'GasDistributorStationOutput', 'GasConsumer', 'GasMeterPad', 'GasMeterUnit', 'GasQualityCertificate'] },
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default TagList;