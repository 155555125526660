import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { union } from 'lodash/array';
import classnames from 'classnames';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { FieldTitle, useInput } from 'ra-core';
import { InputHelperText } from 'react-admin';
import defaultSanitizeRestProps from './sanitizeRestProps';
import CheckboxCheckAllInputItem from './CheckboxCheckAllInputItem';
import CheckboxGroupInputItem from './CheckboxGroupInputItem';



const sanitizeRestProps = ({
    setFilter,
    setPagination,
    setSort,
    loaded,
    isRequired,
    limitChoicesToValue,
    ...rest
}) => defaultSanitizeRestProps(rest);

const useStyles = makeStyles(
    theme => ({
        root: {},
        label: {
            transform: 'translate(0, 8px) scale(0.75)',
            transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'
                }`,
        },
    }),
    { name: 'RaCheckboxGroupInput' }
);


const CheckboxGroupInput = ({
    choices = [],
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    optionText,
    optionValue,
    options,
    parse,
    resource,
    row,
    source,
    translate,
    translateChoice,
    validate,
    ...rest
}) => {
    const classes = useStyles({});

    const {
        id,
        input: { onChange: finalFormOnChange, onBlur: finalFormOnBlur, value },
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const handleCheckAll = useCallback(
        (event, isChecked) => {
            if (isChecked) {
                finalFormOnChange(union((value || []), choices.map(choice => get(choice, optionValue))));
            } else {
                finalFormOnChange([]);
            }
            finalFormOnBlur();
        },
        [finalFormOnChange, finalFormOnBlur, choices, value, optionValue]
    );

    const handleCheck = useCallback(
        (event, isChecked) => {
            let newValue;
            try {
                // try to convert string value to number, e.g. '123'
                newValue = JSON.parse(event.target.value);
            } catch (e) {
                // impossible to convert value, e.g. 'abc'
                newValue = event.target.value;
            }
            if (isChecked) {
                finalFormOnChange([...(value || []), ...[newValue]]);
            } else {
                finalFormOnChange(value.filter(v => v != newValue)); // eslint-disable-line eqeqeq
            }
            finalFormOnBlur(); // HACK: See https://github.com/final-form/react-final-form/issues/365#issuecomment-515045503
        },
        [finalFormOnChange, finalFormOnBlur, value]
    );

    choices.sort((a, b) => {

        if (value.includes(a.id)) return -1
        return 0
    });

    return (
        <FormControl
            component="fieldset"
            margin={margin}
            error={touched && !!error}
            className={classnames(classes.root, className)}
            {...sanitizeRestProps(rest)}
        >
            <FormLabel component="legend" className={classes.label}>
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            </FormLabel>
            <FormGroup row={row}>
                <CheckboxCheckAllInputItem
                    key={`${source}_checkAll`}
                    id={id}
                    onChange={handleCheckAll}
                    choices={choices}
                    value={value}
                />
                {choices.map(choice => (
                    <CheckboxGroupInputItem
                        key={get(choice, optionValue)}
                        choice={choice}
                        id={id}
                        onChange={handleCheck}
                        options={options}
                        optionText={optionText}
                        optionValue={optionValue}
                        translateChoice={translateChoice}
                        value={value}
                    />
                ))}
            </FormGroup>
            <FormHelperText>
                <InputHelperText
                    touched={touched}
                    error={error || submitError}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormControl>
    );
};

CheckboxGroupInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]),
    optionValue: PropTypes.string,
    row: PropTypes.bool,
    resource: PropTypes.string,
    translateChoice: PropTypes.bool,
};

CheckboxGroupInput.defaultProps = {
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    fullWidth: true,
    row: true,
};

export default CheckboxGroupInput;
