import React from 'react';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { useNotify, useTranslate, useRefresh, fetchStart, fetchEnd, Button } from 'react-admin';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient'
import getWriteValues from './getGasQualityCertificateWriteValues'
import writeGasQualityCertificate from '../../dataProvider/Databus/writeGasQualityCertificate'
import addHistory from '../../dataProvider/Core/addHistory';
import MButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const GAS_QUALITY_SERTIFICATE_TEMPLATE = `{
    id
    name
    description
    gqsId
    n2
    co2
    density
    validFrom
    validTo
    gasDistributorStations {
        id
    }
}`

const GET_MANY_GAS_QUALITY_SERTIFICATES = gql`
    query allGasQualityCertificates($page: Int, $perPage: Int, $filter: GasQualityCertificateFilter) {
    items: allGasQualityCertificates(page: $page, perPage: $perPage, filter: $filter) ${GAS_QUALITY_SERTIFICATE_TEMPLATE}
  }
`




const useStyles = makeStyles(theme => ({

}));



const GasQualityCertificateWriteBulkActionsButton = ({ selectedIds, basePath, className, record, resource, filterValues, ...props }) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();

    // if (!record) return null;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleWrite = async () => {
        setOpen(false);
        dispatch(fetchStart());


        const allGasQualityCertificates = await apolloCoreClient.query({
            query: GET_MANY_GAS_QUALITY_SERTIFICATES,
            variables: {
                perPage: selectedIds.length,
                filter: {
                    id_in: selectedIds
                }
            },
            onError: (error) => {
                alert(error)
            }
        })

        const gasQualityCertificateItems = allGasQualityCertificates.data && allGasQualityCertificates.data.items || [];

        const { writeTags, gasQualityCertificates } = await getWriteValues(gasQualityCertificateItems);

        if (writeTags && Array.isArray(writeTags) && Boolean(writeTags.length)) {
            writeGasQualityCertificate({ writeTags })
                .then(({ data }) => {

                    notify('ra.notification.gas_quality_certificate_written', 'info', { smart_count: selectedIds.length });
                    gasQualityCertificates.forEach(record => {
                        addHistory({
                            resource: resource,
                            item: record.id,
                            name: record.name,
                            description: record.description,
                            action: "WRITTEN",
                            data: {
                                data: {
                                    writeValues: record.writeTags
                                },
                            }
                        });
                    })

                })
                .catch((error) => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
                })
                .finally(() => {
                    dispatch(fetchEnd());
                });
        } else {
            notify('ra.notification.gas_quality_certificate_write_error', 'warning', { smart_count: 2 })
            dispatch(fetchEnd())
        }


    };

    return (
        <>
            <Button
                onClick={handleClickOpen}
                label='ra.action.write_GasQualityCertificate'
                {...props}
            >
                <SendIcon />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="writeGasQualityCertificate-dialog-title"
                aria-describedby="writeGasQualityCertificate-dialog-description"
            >
                <DialogTitle id="writeGasQualityCertificate-dialog-title">
                    {translate("resources.GasQualityCertificate.writeDialogTitle", { smart_count: selectedIds.length })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="writeGasQualityCertificate-dialog-description">
                        {translate("resources.GasQualityCertificate.writeDialogContent", { smart_count: selectedIds.length })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MButton onClick={handleClose} >{translate('ra.action.cancel')}</MButton>
                    <MButton onClick={handleWrite} color="primary" autoFocus>{translate('ra.action.write')}</MButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GasQualityCertificateWriteBulkActionsButton;

