import React from 'react';
import PropTypes from 'prop-types';
import { useListController, ListContextProvider, ListView } from 'react-admin';
import ListProrsPreferences from './ListProrsPreferences';



const List = props => {
    const controllerProps = useListController(props)

    return (
        <ListContextProvider value={controllerProps}>
            <ListProrsPreferences />
            <ListView {...props} {...controllerProps} />
        </ListContextProvider>
    );
};

List.propTypes = {
    actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    aside: PropTypes.element,
    bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    filter: PropTypes.object,
    filterDefaultValues: PropTypes.object,
    filters: PropTypes.element,
    pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    perPage: PropTypes.number.isRequired,
    sort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    authProvider: PropTypes.func,
    hasCreate: PropTypes.bool.isRequired,
    hasEdit: PropTypes.bool.isRequired,
    hasList: PropTypes.bool.isRequired,
    hasShow: PropTypes.bool.isRequired,
    location: PropTypes.any,
    match: PropTypes.any,
    path: PropTypes.string,
    resource: PropTypes.string.isRequired,
};

List.defaultProps = {
    filter: {},
    perPage: 10,
};

export default List;
