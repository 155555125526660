import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslate } from 'ra-core';
import IconButton from '@material-ui/core/IconButton';
import DocumentationIcon from '../Icons/DocumentationIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { shallowEqual, useSelector } from 'react-redux';


const DocumentationLinkButton = (props) => {
    const { to, target } = props
    const translate = useTranslate();
    const documentationApp = useSelector(state => state?.user?.apps.find(app => app.id === "askug_documentation"), shallowEqual);
    const url = documentationApp?.url
    const container =
        typeof document !== 'undefined'
            ? document.getElementById('askug-documentation')
            : null;
    if (!container || !to || !url) return null

    const href = `${url}${to}`

    return createPortal(
        <Tooltip title={translate('ra.navigation.open_documentation', { _: 'Open documentation' })} >
            <IconButton aria-controls="askug-documentation" color="inherit" href={href} target={target}>
                <DocumentationIcon />
            </IconButton>
        </Tooltip>, container);
};





export default DocumentationLinkButton;
