import gql from 'graphql-tag'
import { apolloAuthClient } from './apolloAuthClient';



const LOGIN_MUTATION = gql`
     mutation login($login: String!, $password: String!) {
        login(login: $login, password: $password) {
            token,
            refreshToken,
            user {
                id,
                email,
                phone,
                name,
                role
            }
        }
    }
`;

export default (args) => {
    return apolloAuthClient.mutate({
        mutation: LOGIN_MUTATION,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
