import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { fetchStart, fetchEnd } from 'react-admin';


export default function (args) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        data: undefined,
        error: undefined,
        loading: true,
    })

    useEffect(() => {
        dispatch(fetchStart());
        Promise.all(args.map(app => app.query))
        .then( data => { 
            const result = args.map((app, index) => ({
                name: app.name,
                data: data[index].data
            })) 
            setState({
                data: result,
                loading: false,
                loaded: true,
            });
        })
        .catch(error => {
            setState({
            error,
            loading: false,
            loaded: false,
            });
        }).finally(() => {
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    }, [dispatch, args])

    return state;
}