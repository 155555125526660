import React from 'react';
import ReferenceManyFilterForm from './ReferenceManyFilterForm';
import { sanitizeListRestProps, useListContext } from 'ra-core';



const ReferenceManyFilter = (props) => {

    const { classes: classesOverride, children, ...rest } = props;
    const { resource, setFilters, filterValues } = useListContext(props);

    return (
        <ReferenceManyFilterForm 
            resource={resource}
            filters={React.Children.toArray(children)}
            initialValues={filterValues}
            setFilters={setFilters}
            {...sanitizeListRestProps(rest)}
        />   
    );
};

export default ReferenceManyFilter;
