import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'


const useChipStyles = makeStyles(theme => ({

}));

const GasMeterUnitEnabledWriteChip = ({ className, classes: classesOverride, record, source, resource, onlyDisabled, ...props}) => {
    const classes = useChipStyles({ classes: classesOverride });

    if (!record) return null;

    const enable = record.enableWriteGasQualityCertificate

    if (enable === true && onlyDisabled) return null;

    const label = enable ? "Запись ПГ включена" : "Запись ПГ отключена";

    return (
        <Chip
            size="small"
            classes={{root:classes.root}}
            // className={disabled ? classes.disabled : ""}
            label={label}
        />
    );
};

export default GasMeterUnitEnabledWriteChip;