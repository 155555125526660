import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';



const preventDefault = (event) => event.preventDefault();

const useStyles = makeStyles((theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
            marginBottom: 8,
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        actions: {
            display: 'flex',
            flexDirection: "column",
            padding: '0 1em 1em 1em',
        },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        variant="filled"
        size="small"
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = values => {
        setLoading(true);
        login(values, redirectTo)
        .then(() => {
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);

            const graphQLError = error && error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0]
            const extensions = graphQLError && graphQLError.extensions
            const code = extensions && extensions.code
            const countAttempt = extensions && extensions.countAttempt
            const message = (graphQLError && graphQLError.message) || error.message || 'sign_in_error'

            switch (code) {
                case "NOT_AUTHORIZED_USER": {
                    notify('ra.auth.not_authorized_user', 'warning', { smart_count: countAttempt });
                } break;
                case "BANNED_USER": {
                    notify('ra.auth.banned_user', 'warning');
                } break;
                default: {
                    notify( message, 'warning');
                }
            }
        });
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="username"
                                name="username"
                                component={Input}
                                label={translate('ra.auth.username')}
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                id="password"
                                name="password"
                                component={Input}
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                autoComplete="current-password"
                            />
                        </div>
                    </div>
                    <CardActions classes={{root: classes.actions}}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disableElevation
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                            {/*<Typography variant="caption" color="textSecondary">*/}
                            {/*    Нет аккаунта? <Link href="#" onClick={preventDefault}>Зарегистрируйтесь</Link>*/}
                            {/*</Typography>*/}
                    </CardActions>
                </form>
            )}
        />
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;
