import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Sidebar from './Sidebar';
import Menu from './Menu';
import Notification from './Notification';
import PreferenceContextProvider from '../../controller/preferences/PreferenceContextProvider';

export const MyLayout = props => {
    return (
        <PreferenceContextProvider>
            <Layout
                {...props}
                appBar={AppBar}
                menu={Menu}
                notification={Notification}
                sidebar={Sidebar}
            />
        </PreferenceContextProvider>

    );
};

