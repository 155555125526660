import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import apolloCoreClient from './apolloCoreClient';



const GET_ONE_DASHBOARD = gql`
    query Dashboard( $id: ID! ) {
        data: Dashboard( id: $id ) {
            id
            name
            description
            createdAt
            createdBy {
                id
                name
            }
            updatedAt
            deleted
            resource
            gasMeterType
            executeApp
            uri
        }
    }
`;

export default function(resource, id, version) {

    const [state, setState] = useState({
        data: undefined,
        error: null,
        loading: true,
        loaded: false,
    })

    useEffect(() => {

        if (!id) return;

        setState(prevState => ({ ...prevState, loading: true }));

        apolloCoreClient.query({
            query: GET_ONE_DASHBOARD,
            variables: { id },
            fetchPolicy: 'network-only',
        })
            .then(({ data: {data} }) => {
                setState({
                    data,
                    loading: false,
                    loaded: true,
                });
            })
            .catch(error => {
                setState({
                    error,
                    loading: false,
                    loaded: false,
                });
            });
    }, [resource, id, version]);

    return state;
}
