import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { CONTACT_FRAGMENT } from '../typeDefs';

export const CREATE_CONTACT = gql`
    mutation createContact($input: ContactCreateInput!) {
        data: createContact(input: $input) {
            item {...ContactFragment}
        }
    }
    ${CONTACT_FRAGMENT}
`

export default (args) => {
    return apolloClient.mutate({
        mutation: CREATE_CONTACT,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    })
};
