import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M20 9l-8-6-8 6v12h5v-3a2 2 0 011-1.732V14H8v-2h2.268a2 2 0 013.464 0H16v2h-2v2.268A2 2 0 0115 18v3h5V9z" />
    </SvgIcon>
  );
}

export default Icon;