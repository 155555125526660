import React from 'react';
import { ReferenceArrayInput } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from './ReportAutocompleteSuggestionItem';
import ReportChip from './ReportChip';



const matchTrue = () => true;

const ReportReferenceArrayInput = props => {

    return (
        <ReferenceArrayInput {...props}>
            <AutocompleteArrayInput selectedItem={<ReportChip />} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
        </ReferenceArrayInput>
    );
};

export default ReportReferenceArrayInput;