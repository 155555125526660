import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { useForm, useFormState } from "react-final-form";
import { ArrayInput, FormDataConsumer, SimpleFormIterator, DateInput, TextInput, required } from 'react-admin';



const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
    },
    index: {
        width: 24,
        paddingTop: "1.25em",
    },
    dateToInput: {
        marginLeft: 16,
    },
    reasonInput: {
        flexGrow: 1,
        marginLeft: 16,
        marginRight: 16,
        minWidth: 256,
    },
}));




const GasMeterPadDisabledPeriodsArrayInput = ({ className, ...props }) => {
    const classes = useStyles();
    const { values } = useFormState();

    return (
        <ArrayInput {...props}>
            <SimpleFormIterator classes={{ form: classes.form, index: classes.index }}>
                <FormDataConsumer label="resources.GasMeterPad.fields.dateFrom">
                    {({ formData, scopedFormData, getSource, id, ...rest }) => (
                        <DateInput {...rest} id={`${id}dateFrom`} source={getSource("dateFrom")} validate={required()} inputProps={{ max: scopedFormData && scopedFormData.dateTo ? moment(scopedFormData.dateTo).format("YYYY-MM-DD") : null }} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer label="resources.GasMeterPad.fields.dateTo" formClassName={classes.dateToInput}>
                    {({ formData, scopedFormData, getSource, id, ...rest }) => (
                        <DateInput {...rest} id={`${id}dateTo`} source={getSource("dateTo")} inputProps={{ min: scopedFormData && scopedFormData.dateFrom ? moment(scopedFormData.dateFrom).format("YYYY-MM-DD") : null }} />
                    )}
                </FormDataConsumer>
                <TextInput source="description" formClassName={classes.reasonInput} fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    );
}

export default GasMeterPadDisabledPeriodsArrayInput;