import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import get from 'lodash/get';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/ru';

import { useInput, FieldTitle, InputProps } from 'ra-core';

import { InputHelperText } from 'react-admin';
import sanitizeFieldRestProps from './sanitizeFieldRestProps';
// import { Field } from 'react-final-form';
// import { Labeled } from 'react-admin';

const useStyles = makeStyles(theme => {
  const light = theme.palette.type === 'light';
  const backgroundColor = light ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.09)';

  return {
    root: {
      position: 'relative',
      backgroundColor,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
      '&:hover': {
        backgroundColor: light ? 'rgba(0, 0, 0, 0.13)' : 'rgba(255, 255, 255, 0.13)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor,
        },
      },
      '&$focused': {
        backgroundColor: light ? 'rgba(0, 0, 0, 0.09)' : 'rgba(255, 255, 255, 0.09)',
      },
      '&$disabled': {
        backgroundColor: light ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
      },
    },
    label: {
      paddingLeft: 12,
    }
  };
});





const githubTheme = {
  default: 'rgba(0, 0, 0, 0.87)',
  background: 'rgba(0, 0, 0, 0.04)',
  background_warning: '#fdd',
  string: '#d14',
  number: '#008080',
  colon: '#49B8F7',
  keys: 'rgba(0, 0, 0, 0.87)',
  keys_whiteSpace: '#835FB6',
  primitive: '#386FA4',
  error: "#e45649"
};


const JsonField = ({ record, source, theme, ...props }) => {
  const json = get(record, source);
  if (!json) return null;
  const jsObject = (typeof json === 'string') ? JSON.parse(json) : json;

  return (

    <JSONInput

      placeholder={jsObject}
      viewOnly
      confirmGood={false}
      colors={{ ...theme, background: "none" }}
      locale={locale}
      style={{
        body: {
          fontSize: '1rem',
        },
        contentBox: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      }}
      height="360px"
      width="100%"
      {...sanitizeFieldRestProps(props)}
    />


  )
}

JsonField.defaultProps = {
  theme: githubTheme,
  addLabel: true,
};

export default JsonField;
