import React, { Fragment, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Error from '../Layout/Error';
import Loading from '../Layout/Loading';



const useStyles = makeStyles(theme => ({
    card: {},
    cardContent: {
        paddingTop: 0,
    },
    cardActionArea: {},
    header: {},
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const DashboardCard = forwardRef(({ className, classes: classesOverride, record, basePath, resource, source, referenceRecord, version, dashboardModuleProps, ...props}, ref) => {
    const classes = useStyles({ classes: classesOverride });

    if (!record) return null;
    const { DashboardPreview, iconPreview, hasDashboard, error, loading } = dashboardModuleProps;
    const resultIcon = (typeof iconPreview == 'function' ? iconPreview(props) : iconPreview) || <DashboardIcon />;
    const linkprops = hasDashboard ? { component: LinkRef, ref, to: { pathname: `/Dashboard/${record.id}/show`, search: `?resource=${resource}&id=${referenceRecord.id}`}} : {};
    const context = {
        basePath,
        resource,
        record,
        referenceRecord,
        version,
    }
    
    const CardInner = (
        <Fragment>
            <CardHeader
                avatar={<Avatar aria-label="dashboard" variant="rounded" classes={{colorDefault: classes.avatar}}>
                    {React.cloneElement(resultIcon)}
                </Avatar>}
                title={record.name}
                titleTypographyProps={{variant:"subtitle1"}}
                classes={{root: classes.header, title: classes.title}}
            />
            <CardContent classes={{root: classes.cardContent}}>
                { error && <Error />}
                { loading && <Loading />}
                { DashboardPreview && React.isValidElement(<DashboardPreview />) && <DashboardPreview context={context}/>}
            </CardContent>
        </Fragment>
    );

    if (hasDashboard) {
        return (
            <Card className={classes.card} >
                <CardActionArea {...linkprops} classes={{root: classes.cardActionArea}} >
                    {React.cloneElement(CardInner)}
                </CardActionArea>
            </Card>
        );
    }
    
    return (
        <Card className={classes.card} >
            {React.cloneElement(CardInner)}
        </Card>
    );
    
});





export default DashboardCard;