import get from 'lodash/get';
import { LOCAL_STORAGE } from '../../appsettings';



export default (props) => {
    const { resource, reference } = props;
    const storageProps = JSON.parse(localStorage.getItem(LOCAL_STORAGE)) || {};
    const storageResourceShowProps = get(storageProps, `${resource}.show.${reference}.props`, {});
    return storageResourceShowProps;
}
