import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        // type: "dark",
        primary: {
            main: '#316FC4',
        },
        background: {
            default: "#f4f6f9"
        },
        link: {
            main: "#673ab7",
        }
    },
    sidebar: {
        width: 256,
        closedWidth: 72,
    },
    menu: {
        width: 240,
        closedWidth: 72,
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiAvatar: {
            rounded: {
                borderRadius: 8,
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: "0px 3px 5px -1px rgba(49, 111, 196, 0.06), 0px 6px 10px 0px rgba(49, 111, 196, 0.042), 0px 1px 18px 0px rgba(49, 111, 196, 0.036)",
            }
        },
        MuiTableCell: {
            sizeSmall: {
                padding: '6px 8px',
                '&:first-child': {
                    paddingLeft: 16,
                },
                '&:last-child': {
                    paddingRight: 16,
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: "unset",
                marginRight: 12,
            },
        },
        MuiChip: {
            root: {
                margin: 2,
                borderRadius: 8,
            },
        },
        MuiFormHelperText: {
            root: {
                whiteSpace: "break-spaces",
            },
        },
        RaLayout: {
            content: {
                backgroundColor: "#f4f6f9",
                zIndex: 2,
            }
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
});
