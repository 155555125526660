import { AutocompleteInput, FormTab, ReferenceInput, SaveButton, TabbedForm, TextInput, Toolbar, minLength, required, EditContextProvider, useEditController } from 'react-admin';
import { can, rulesToFields } from '../../abilityProvider';
import { shallowEqual, useSelector } from 'react-redux';
import get from 'lodash/get';
import AccessReferenceArrayInput from './AccessReferenceArrayInput';
import AccessResourceInput from './AccessResourceInput';
import BanButton from '../Buttons/BanButton';
import CancelButton from '../Buttons/CancelButton';
import CardBlankLayout from '../Layout/CardBlankLayout';
import { EditView } from '../Layout/EditView';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import EnumInput from '../Inputs/EnumInput';
import PasswordInput from '../Inputs/PasswordInput';
import PhoneInput from '../Inputs/PhoneInput';
import React from 'react';
import RemoveButton from '../Buttons/RemoveButton';
import TitleForRecord from '../Layout/TitleForRecord';
import UserBanner from '../User/UserBanner';
import { makeStyles } from '@material-ui/core/styles';



const matchTrue = () => true;

const filterRoles = (value) => {
    const conditions = rulesToFields("update", "User")
    const role_in = (conditions.role && conditions.role.$in) || [];
    return role_in.includes(value.name);
}

const logined = (value, values) => {
    if (!values.email && !values.phone && !values.login) {
        return 'ra.action.one_of_fields_is_required';
    }
};
const validateLogin = [logined];
const validateName = [required(), minLength(2)];
const validateRole = [required()];

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    accessReferenceArrayInput: {
        display: "flex",
        flexDirection: "column",
    },
}));



const UserToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can("update", "User") && <SaveButton />}
            {can("update", "User") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can("ban", "User") && <BanButton redirect="show" />}
            {can("remove", "User") && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const UserEdit = props => {
    const controllerProps = useEditController(props);
    const classes = useStyles();
    const user = useSelector(state => state.user, shallowEqual);
    const itsMe = get(controllerProps, ["record", "id"]) === get(user, ["id"])

    return (
        <EditContextProvider value={controllerProps}>
            <EditView
                title={<TitleForRecord source="name" {...props} />}
                {...props}
                {...controllerProps}
            >
                <UserBanner />
                <CardBlankLayout >
                    <TabbedForm toolbar={<UserToolbar />}>
                        <FormTab label="resources.User.tabs.general">
                            {can("update", "User", "name") && <TextInput source="name" validate={validateName} fullWidth />}
                            {can("update", "User", "description") && <TextInput source="description" fullWidth />}
                            {can("update", "User", "position") && <TextInput source="position" fullWidth />}
                            {can("update", "User", "role") && !itsMe && <EnumInput source="role" filter={filterRoles} validate={validateRole} />}
                            {can("update", "User", "login") && <TextInput source="login" validate={validateLogin} />}
                            {can("update", "User", "email") && <TextInput source="email" type="email" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                            {can("update", "User", "phone") && <PhoneInput source="phone" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                            {can("update", "User", "phoneContact") && <PhoneInput source="phoneContact" />}
                            {can("update", "User", "password") && <PasswordInput source="password" />}
                        </FormTab>
                        <FormTab label="resources.User.tabs.access" path="access">
                            {can('update', 'User', 'counterparty') && <ReferenceInput source="counterparty.id" reference="Counterparty" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.User.fields.counterparty" allowEmpty emptyText="ra.message.no" >
                                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
                            </ReferenceInput>}
                            <AccessResourceInput source="accessResource" name="AccessResource" option="resources.Access.enums.resource" allowEmpty emptyValue={null} emptyText="ra.message.no" />
                            <AccessReferenceArrayInput source="accessIds" label="resources.User.fields.access" validate={required()} formClassName={classes.accessReferenceArrayInput} />
                        </FormTab>
                    </TabbedForm>
                </CardBlankLayout>
            </EditView>
        </EditContextProvider>

    );
};

export default UserEdit