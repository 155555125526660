import { DateField, Pagination, ReferenceManyField as RaReferenceManyField, SearchInput, TextField } from 'react-admin';
import DividerField from '../Fields/DividerField';
import Breadcrumb from '../Layout/Breadcrumb';
import Breadcrumbs from '../Layout/Breadcrumbs';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import GasMeterPadBanner from './GasMeterPadBanner';
import GasMeterPadDisabledPeriodChip from './GasMeterPadDisabledPeriodChip';
import { GasMeterUnitReferenceDatagrid } from '../GasMeterUnit/GasMeterUnitDatagrid';
import MoreContent from '../Layout/MoreContent';
import NestedArrayField from '../Fields/NestedArrayField';
import NestedField from '../Fields/NestedField';
import NestedGridField from '../Fields/NestedGridField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import ReferenceListLinkButton from '../Buttons/ReferenceListLinkButton';
import ReferenceManyActions from '../Fields/referenceManyField/ReferenceManyActions';
import ReferenceManyField from '../Fields/referenceManyField/ReferenceManyField';
import ReferenceManyFilter from '../Fields/referenceManyField/ReferenceManyFilter';
import ReportCard from '../Report/ReportCard';
import Separator from '../Layout/Separator';
import { Show } from '../Layout/Show';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';

const GasMeterPadBreadcrumbs = (props) => {
    return (
        <Breadcrumbs {...props}>
            <Breadcrumb resource={"GasRegion"} />
            <Breadcrumb resource={"GasDistrict"} />
            <Breadcrumb resource={"GasConsumer"} />
        </Breadcrumbs>
    );
}

const ReferenceManyFilters = (props) => {
    return (
        <ReferenceManyFilter {...props}>
            <SearchInput source="q" id={`${props.reference}.q`} alwaysOn />
        </ReferenceManyFilter>
    )
}

const ReferenceManyActionButtons = (props) => {
    return (
        <ReferenceManyActions >
            <ReferenceListLinkButton {...props} />
        </ReferenceManyActions>
    )
}

const GasMeterPadShow = props => {
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show breadcrumbs={<GasMeterPadBreadcrumbs />} title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasMeterPadBanner />

            <CardShowLayout>
                {can('getOne', 'GasMeterPad', 'addrManual') && <TextField source="addrManual" />}
                {can('getOne', 'GasMeterPad', 'gasConsumer', 'GasConsumer') && <NestedField source="gasConsumer" reference="GasConsumer" >
                    <TextField source="name" />
                </NestedField>}
                {can('getOne', 'GasMeterPad', 'gasDistributorOrganization', 'GasDistributorOrganization') && <NestedField source="gasDistributorOrganization" reference="GasDistributorOrganization" >
                    <TextField source="name" />
                </NestedField>}
                {can('getOne', 'GasMeterPad', 'gasDistributorStation', 'GasDistributorStation') && <NestedField source="gasDistributorStationOutput.gasDistributorStation" reference="GasDistributorStation" label="resources.GasMeterPad.fields.gasDistributorStation">
                    <TextField source="name" />
                </NestedField>}
                {can('getOne', 'GasMeterPad', 'gasDistributorStationOutput', 'GasDistributorStationOutput') && <NestedField source="gasDistributorStationOutput" reference="GasDistributorStationOutput" >
                    <TextField source="name" />
                </NestedField>}
                {can('getOne', 'GasMeterPad', 'disabledPeriods') && <NestedArrayField source="disabledPeriods" linkType={false}>
                    <GasMeterPadDisabledPeriodChip />
                </NestedArrayField>}
            </CardShowLayout>

            {can('getOne', 'GasMeterPad', 'reports', 'Report') && <NestedGridField source="reports" label="resources.GasMeterPad.fields.reports" >
                <ReportCard />
            </NestedGridField>}

            {can('getOne', 'GasMeterPad', 'gasMeterUnits', 'GasMeterUnit') &&
                <ReferenceManyField
                    reference="GasMeterUnit"
                    target="gasMeterPadReference"
                    label="resources.GasMeterPad.fields.gasMeterUnits"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }} perPage={100}
                    perPage={25}
                    pagination={<Pagination />}
                >
                    <GasMeterUnitReferenceDatagrid />
                </ReferenceManyField>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {can('getOne', 'GasMeterPad', 'aisId') && <TextField source="aisId" />}
                    <DividerField />
                    {can('getOne', 'GasMeterPad', 'id') && <TextField source="id" />}
                    {can('getOne', 'GasMeterPad', 'createdBy', 'User') && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {can('getOne', 'GasMeterPad', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'GasMeterPad', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>

            {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
            {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="GasMeterPad" />
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>}
        </Show>
    );
};

export default GasMeterPadShow;