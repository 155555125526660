import React, { useState, useEffect } from 'react';
import url from 'url';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { useRouteMatch, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import getDocumentationPath from './getDocumentationPath';
import 'github-markdown-css'



// const importAll = (r, cache) => r.keys().forEach(key => cache[key] = r(key));

// const imageFiles = {};

// importAll(require.context('../docs/media', false, /\.(png|jpe?g|svg)$/), imageFiles);

// const transformImageUri = (uri) => {
//     return uri.startsWith("http") ? uri : imageFiles[uri.replace('media', '.')];
// }


function RouterLink(props) {
    const match = useRouteMatch();

    const f = props.href.match(/^\//)
    return (<Link to={getDocumentationPath(props.href, match.url)} >{props.children}</Link>);
    return (
      props.href.match(/^\//)
        ? <Link to={getDocumentationPath(props.href, match.url)} >{props.children}</Link>
        : <a href={props.href}>{props.children}</a>
    );
  }

  function RouterHashLink(props) {
    const match = useRouteMatch();

    const f = props.href.match(/^\//)
    return (<HashLink to={getDocumentationPath(props.href, match.url)} >{props.children}</HashLink>);
    return (
      props.href.match(/^\//)
        ? <Link to={getDocumentationPath(props.href, match.url)} >{props.children}</Link>
        : <a href={props.href}>{props.children}</a>
    );
  }

  function Heading(props) {
    const uniqueSlug = "fff";

    // eslint-disable-next-line react/no-children-prop
    return React.createElement(`h${props.level}`, {
      id: uniqueSlug,
      className: 'headline-primary',
      children: <a href={ `#${uniqueSlug}` }>
        { props.children }
      </a>
    });
  }

const MarkdownView = ({ path, ...props }) => {
    const [markdownText, setMarkdownText] = useState("");
    useEffect(() => {
        fetch(path)
        .then(res => res.text())
        .then(text => {
            setMarkdownText(text)
        })
    }, []);

    return (
        <div className='markdown-body'>
            <Markdown
                source={markdownText}
                escapeHtml={false}
                // transformImageUri={transformImageUri}
                // renderers={{
                //   heading: Heading,
                //   link: RouterHashLink,
                // }}
            />
        </div>
    );

};

MarkdownView.propTypes = {
  path: PropTypes.string.isRequired,
};

export default MarkdownView;