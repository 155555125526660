import React, { useState, useCallback } from 'react';
import { LinearProgress, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dropzone from '../Layout/Dropzone';
import GasQualityCertificateImportDatagrid from './GasQualityCertificateImportDatagrid';



const useStyles = makeStyles(theme => ({
    fullWidth: { width: "100%" },
}));

const GasQualityCertificateImportDialog = ({
    open,
    errors,
    warnings,
    data,
    loading,
    loaded,
    onImport,
    onDrop,
    onClose,
    onExited,
    ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [confirm, setConfirm] = useState(false);


    const handleConfirm = useCallback((event) => {
        setConfirm(event.target.checked)
    }, [setConfirm])

    const handleClose = useCallback(() => {
        onClose()
    }, [onClose])

    const handleExited = useCallback(() => {
        onExited()
    }, [onExited])

    const handleImport = useCallback(() => {
        onImport()
    }, [onImport])


    const handleDrop = useCallback(acceptedFiles => {
        onDrop(acceptedFiles)
    }, [onDrop])


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onExited={handleExited}
            aria-labelledby="import-dialog-title"
            aria-describedby="import-dialog-description"
            maxWidth="lg"
            scroll="body"
        >
            <DialogTitle id="import-dialog-title">{translate('resources.GasQualityCertificate.import.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="import-dialog-description">{translate('resources.GasQualityCertificate.import.content')}</DialogContentText>
                <Dropzone onDrop={handleDrop} />
            </DialogContent>
            {loading && (<LinearProgress className={classes.fullWidth} />)}
            {errors && (
                <DialogContent>
                    <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
                        <AlertTitle>{translate('resources.GasQualityCertificate.import.errors')}</AlertTitle>
                        {errors.map(({ message, args }, index) => (
                            <Typography key={index} variant="body2">{translate(message, args)}</Typography>
                        ))}
                    </Alert>
                </DialogContent>
            )}
            {warnings && (
                <DialogContent>
                    <Alert icon={<WarningIcon fontSize="inherit" />} severity="warning">
                        <AlertTitle>{translate('resources.GasQualityCertificate.import.warnings')}</AlertTitle>
                        {warnings.map(({ message, args }, index) => (
                            <Typography key={index} variant="body2">{translate(message, args)}</Typography>
                        ))}
                    </Alert>
                </DialogContent>
            )}
            {data && (
                <GasQualityCertificateImportDatagrid data={data} />
            )}
            <DialogContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={confirm}
                            onChange={handleConfirm}
                            disabled={Boolean(errors) || !(data?.length > 0)}
                            name="checkedConfirm"
                            color="primary"
                        />
                    }
                    label={translate("ra.input.confirm.label")}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} >
                    {translate(`ra.action.cancel`)}
                </Button>
                <Button
                    onClick={handleImport}
                    disabled={!loaded || !data.length || Boolean(errors) || !confirm}
                    color="primary"
                    autoFocus
                >
                    {translate(`ra.action.import`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GasQualityCertificateImportDialog;

