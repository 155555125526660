import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { TitleForRecord } from 'react-admin';
import useDashboardModule from '../../controller/dashboard/useDashboardModule';
import dashboardScadaModule from './DashboardScadaModule';
import Remove from './Remove';


const useStyles = makeStyles({
    root: {},
    main: {
        display: 'flex',
    },
    noActions: {
        marginTop: '1em',
    },
    content: {
        flex: '1 1 auto',
    },
    toolbar: {
        display: 'flex',
        justifyContent: "space-between",
    },
});

const sanitizeRestProps = ({
    actions,
    aside,
    title,
    children,
    className,
    id,
    data,
    loading,
    loaded,
    resource,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    hasDashboard,
    version,
    match,
    location,
    history,
    options,
    locale,
    permissions,
    ...rest
}) => rest;

export const DashboardView = ({
    breadcrumbs,
    actions,
    aside,
    basePath,
    children,
    classes: classesOverride,
    className,
    defaultTitle,
    hasEdit,
    hasList,
    record,
    resource,
    title,
    version,
    ...rest
}) => {
    const classes = useStyles({ classes: classesOverride });
    const path = record && record.uri;
    const executeApp = record && record.executeApp;
    const dashboardModuleProps = useDashboardModule(path);

    if (record && record.deleted) {
        return (
            <Remove
                resource={resource}
                basePath={basePath}
                record={record}
                title={title}
                defaultTitle={defaultTitle}
                hasList={hasList}
                hasEdit={hasEdit}
            />
        );
    }

    const { Dashboard, error, loading, loaded } = executeApp == 'SCADAJS' ? dashboardScadaModule : dashboardModuleProps;

    return (
        <div
            className={classnames('show-page', classes.root, className)}
            {...sanitizeRestProps(rest)}
        >
            <TitleForRecord
                title={title}
                record={record}
                defaultTitle={defaultTitle}
            />
            <div className={classes.toolbar}>
                {breadcrumbs && React.cloneElement(breadcrumbs, {
                    basePath,
                    data: record,
                    hasList,
                    hasEdit,
                    resource,
                })}
                {actions && React.cloneElement(actions, {
                    basePath,
                    data: record,
                    hasList,
                    hasEdit,
                    resource,
                    style: { marginLeft: "auto" },
                    ...actions.props,
                })}
            </div>

            <div
                className={classnames(classes.main, {
                    [classes.noActions]: !actions,
                })}
            >
                <div className={`${classes.content}`} >
                    {loading && "Загрузка..."}
                    {Dashboard && React.isValidElement(<Dashboard />) && (executeApp == 'SCADAJS' ? <Dashboard record={record} {...rest} /> : <Dashboard />)}
                </div>
            </div>
        </div>
    );
};

DashboardView.propTypes = {
    actions: PropTypes.element,
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    defaultTitle: PropTypes.any,
    hasEdit: PropTypes.bool,
    hasList: PropTypes.bool,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    record: PropTypes.object,
    resource: PropTypes.string,
    title: PropTypes.any,
    version: PropTypes.node,
};

DashboardView.defaultProps = {
    classes: {},
};