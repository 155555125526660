import React, { cloneElement, useCallback } from 'react';
import { useDataProvider, useNotify, useListContext } from 'react-admin';
import { Form, FormSpy } from 'react-final-form';
import { useField } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import set from 'lodash/set';


const sanitizeInputRestProps = ({
    afterSubmit,
    allowEmpty,
    allowNull,
    alwaysOn,
    basePath,
    beforeSubmit,
    component,
    data,
    defaultValue,
    format,
    formatOnBlur,
    formClassName,
    initialValue,
    initializeForm,
    input,
    isEqual,
    isRequired,
    label,
    limitChoicesToValue,
    locale,
    meta,
    multiple,
    name,
    options,
    optionText,
    optionValue,
    parse,
    record,
    ref,
    render,
    resource,
    source,
    subscription,
    textAlign,
    translate,
    translateChoice,
    type,
    validate,
    validateFields,
    value,
    ...rest
}) => rest;

const sanitizeRestProps = ({
    dataStatus = null,
    filter = null,
    filterToQuery = null,
    onChange = null,
    perPage = null,
    reference = null,
    referenceRecord = null,
    referenceSource = null,
    sort = null,
    validation = null,
    ...rest
}) => sanitizeInputRestProps(rest);

const handleSubmit = event => {
    event.preventDefault();
    return false;
};

const handleFinalFormSubmit = () => { };

const FormSpySubscription = { values: true };


const DeviceStatusBackReferenceInput = ({
    children,
    source,
    reference,
    target = "id",
    ...rest
}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { input: { value } } = useField();
    const { filterValues, setFilters } = useListContext();


    const handleChange = useCallback(({ values }) => {
        const filterField = children.props.source
        const filterValue = get(values, filterField)
        const referenceFilter = filterValue ? { [filterField]: filterValue } : undefined

        const setBackFilter = (referenceFilter) => {
            dataProvider.getManyReference(reference, {
                filter: referenceFilter,
                perPage: 1000
            })
                .then(({ data, total }) => {
                    const sourceFilter = set({}, source, data.length ? data.map(item => item[target]) : [0])
                    setFilters({
                        ...filterValues,
                        ...sourceFilter
                    })

                })
                .catch((error) => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
                })
        }

        if (referenceFilter) {
            setBackFilter(referenceFilter)
        }

    }, [filterValues]);

    return (
        <Form
            onSubmit={handleFinalFormSubmit}
            mutators={{ ...arrayMutators }}
            render={formProps => (
                <>
                    <FormSpy
                        subscription={FormSpySubscription}
                        onChange={handleChange}
                    />
                    {/* https://www.gitmemory.com/issue/jaredpalmer/formik/826/494517126 */}
                    <div onSubmit={handleSubmit} >
                        {cloneElement(children, {
                            resource: reference,
                            ...sanitizeRestProps(rest),
                        })}
                    </div>
                </>
            )}
        />
    );
};

export default DeviceStatusBackReferenceInput