import { BulkExportButton } from 'react-admin';
import { can } from '../../abilityProvider';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import React from 'react';



const UserBulkActionButtons = props => (
    <>
        {can('getList', 'User') && <BulkExportButton {...props} />}
        {can('remove', 'User') && <BulkRemoveButton {...props} />}
    </>
)



export default UserBulkActionButtons;