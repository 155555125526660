import { Form, FormSpy } from 'react-final-form';

import { FilterFormInput } from 'react-admin';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import useDebouncedFunction from '../../../hooks/useDebouncedFunction';

const sanitizeFieldRestProps = ({
    anyTouched,
    asyncValidate,
    asyncValidating,
    autofill,
    blur,
    change,
    clearAsyncError,
    clearFields,
    clearSubmit,
    clearSubmitErrors,
    destroy,
    dirty,
    dirtyFields,
    dirtyFieldsSinceLastSubmit,
    dirtySinceLastSubmit,
    dispatch,
    displayedFilters,
    errors,
    filters,
    filterValues,
    form,
    handleSubmit,
    hasSubmitErrors,
    hasValidationErrors,
    hideFilter,
    initialize,
    initialized,
    initialValues,
    invalid,
    modified,
    modifiedSinceLastSubmit,
    pristine,
    pure,
    reset,
    resetSection,
    save,
    setFilter,
    setFilters,
    submit,
    submitAsSideEffect,
    submitError,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting,
    touch,
    touched,
    triggerSubmit,
    untouch,
    valid,
    validate,
    validating,
    values,
    visited,
    __versions,
    ...props
}) => props;

const handleSubmit = event => {
    event.preventDefault();
    return false;
};

const handleFinalFormSubmit = () => { };

const FormSpySubscription = { values: true, pristine: true };


const useStyles = makeStyles(theme => ({
    form: {
        marginTop: -theme.spacing(4),
        paddingTop: 0,
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        minHeight: theme.spacing(10),
        pointerEvents: "none",
    },
    clearFix: { clear: 'right' },
}),
    { name: 'RaReferenceManyFilterForm' }
);

const ReferenceManyFilterForm = props => {
    const { className, classes: classesOverride, filters, resource, addLabel, initialValues, ...rest } = props;
    const classes = useStyles(props);
    const { setFilters } = rest;

    const debouncedSetFilters = useDebouncedFunction(
        values => setFilters(values),
        1000,
        true
    );

    const handleChange = ({ pristine, values }) => {
        if (pristine) return;
        debouncedSetFilters(values);

    };

    return (
        <Form
            onSubmit={handleFinalFormSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={formProps => (
                <>
                    <FormSpy
                        subscription={FormSpySubscription}
                        onChange={handleChange}
                    />
                    <form
                        className={classnames(className, classes.form)}
                        {...sanitizeFieldRestProps(rest)}
                        onSubmit={handleSubmit}
                    >
                        {filters.map(filterElement => (
                            <FilterFormInput
                                key={filterElement.props.source}
                                filterElement={filterElement}
                                resource={resource}
                            />
                        ))}
                        <div className={classes.clearFix} />
                    </form>
                </>
            )}
        />
    );
};



export default ReferenceManyFilterForm;
