import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useNotify, fetchStart, fetchEnd, useTranslate, TextInput, ReferenceInput, NumberInput, required, FormDataConsumer, linkToRecord } from 'react-admin';
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import BooleanInput from '../Inputs/BooleanInput';
import ReportReferenceArrayInputWithDefault from '../Report/ReportReferenceArrayInputWithDefault';
import DashboardReferenceArrayInputWithDefault from '../Dashboard/DashboardReferenceArrayInputWithDefault';
import DeviceGasMeterPointArrayInput from "./inputs/DeviceGasMeterPointArrayInput";
import ControllerTypeInput from "./inputs/ControllerTypeInput";
import GasMeterTypeInput from "./inputs/GasMeterTypeInput";
import StatusInput from "./inputs/StatusInput";
import ReferenceControllerConfigurationInput from "./inputs/ReferenceControllerConfigurationInput";
import ReferenceGasMeterConfigurationInput from "./inputs/ReferenceGasMeterConfigurationInput";
import PhoneInput from "../Inputs/PhoneInput";
import JsonVariableInputs from "../Inputs/JsonVariableInputs";
import JsonVariableInput from "../Inputs/JsonVariableInput";
import ControllerWarning from "./ControllerWarning";
import GasMeterWarning from "./GasMeterWarning";
import { can } from "../../abilityProvider";
import { createGasMeterUnit, createGasMeterPoints, createController, createGasMeter, createDevice } from '../../dataProvider/Core/createDeviceWizard'
import { asyncUniqueGasMeter } from '../Form/validate'



const dbIdValidation = [asyncUniqueGasMeter('GasMeter', 'dbId')]

const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const defaultValues = {
    gasMeterUnit: {
        enableWriteGasQualityCertificate: false,
    },
    gasMeterPoints: [{ name: "Линия 1", number: 1, disabled: false }],
    controller: {
        status: 'ACTIVE',
    },
    gasMeter: {
        status: 'ACTIVE',
        timeZone: 3,
        commercialHour: 10,
    }
}

const CreateDialog = ({
    children,
    open,
    onClose,
    ...props
}) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const classes = useStyles(props);
    const translate = useTranslate();
    const [loading, setLoading] = useState(false)

    const handleSave = useCallback((values) => {

        const { gasMeterUnit, gasMeterPoints, controller, gasMeter } = values

        dispatch(fetchStart());
        setLoading(true)

        /// Создание УУГ
        createGasMeterUnit(gasMeterUnit)
            .then(result => {
                const gasMeterUnitId = result.id

                /// Создание Линий
                createGasMeterPoints(gasMeterPoints, gasMeterUnitId)
                    .then(result => {
                        console.log(result)
                    })

                return { gasMeterUnitId }
            })
            .then(({ gasMeterUnitId }) => {
                /// Создание Контроллера

                return createController(controller)
                    .then(result => {
                        const controllerId = result.id
                        return { gasMeterUnitId, controllerId }
                    })
            })
            .then(({ gasMeterUnitId, controllerId }) => {
                /// Создание Корректора

                return createGasMeter(gasMeter)
                    .then(result => {
                        const gasMeterId = result.id
                        return { gasMeterUnitId, controllerId, gasMeterId }
                    })
            })
            .then(({ gasMeterUnitId, controllerId, gasMeterId }) => {

                return createDevice({ gasMeterUnitId, controllerId, gasMeterId })
            })
            .then((result) => {

                const deviceId = result.id
                notify('ra.notification.device_wizard_created', 'info', {
                    action: <Button
                        color="primary"
                        component={Link}
                        to={`${linkToRecord("/GasMeterUnit", deviceId)}/show`} size="small">
                        {translate('ra.action.show')}
                    </Button>
                });
            })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setLoading(false)
                dispatch(fetchEnd());
                onClose()
            });
    }, []);




    return (<Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth="lg"
        scroll="body"
        open={open}
        aria-labelledby="createWizard-dialog-title"
        onClose={onClose}
        {...sanitizeRestProps(props)}
    >
        <DialogTitle id="createWizard-dialog-title">
            Мастер создания Устройства
            <IconButton
                aria-label={translate('ra.action.close')}
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <WizardForm save={handleSave} saving={loading} initialValues={defaultValues} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <WizardFormStep label="УУГ" resource="GasMeterUnit">
                {can('create', 'GasMeterUnit', 'name') && <TextInput source="gasMeterUnit.name" validate={required()} fullWidth label="resources.GasMeterUnit.fields.name" />}
                {can('create', 'GasMeterUnit', 'description') && <TextInput source="gasMeterUnit.description" fullWidth label="resources.GasMeterUnit.fields.description" />}
                {can('create', 'GasMeterUnit', 'aisId') && <TextInput source="gasMeterUnit.aisId" label="resources.GasMeterUnit.fields.aisId" />}
                {can('create', 'GasMeterUnit', 'enableWriteGasQualityCertificate') && <BooleanInput source="gasMeterUnit.enableWriteGasQualityCertificate" label="resources.GasMeterUnit.fields.enableWriteGasQualityCertificate" />}
                {can('create', 'GasMeterUnit', 'gasMeterPad', 'GasMeterPad') && <ReferenceInput source="gasMeterUnit.gasMeterPad.id" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} validate={required()} label="resources.GasMeterUnit.fields.gasMeterPad" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasMeterUnit', 'gasMeterPoints', 'GasMeterPoint') && <DeviceGasMeterPointArrayInput source="gasMeterPoints" label="resources.GasMeterUnit.fields.gasMeterPoints" />}
            </WizardFormStep>
            <WizardFormStep label="Контроллер" resource="Controller">
                <ControllerWarning />
                {can('create', 'Controller', 'name') && <FormDataConsumer formClassName={classes.flexInput}>
                    {({ formData, id, ...rest }) => (
                        <TextInput source="controller.name" validate={required()} fullWidth label="resources.Controller.fields.name" defaultValue={formData?.gasMeterUnit?.name} />
                    )}
                </FormDataConsumer>}
                {can('create', 'Controller', 'description') && <TextInput source="controller.description" fullWidth label="resources.Controller.fields.description" />}
                {can('create', 'Controller', 'type') && <ControllerTypeInput source="controller.type" name="ControllerType" resource="GasMeterUnit" option="resources.Controller.enums.type" validate={required()} label="resources.Controller.fields.type" />}
                {can('create', 'Controller', 'configuration', 'ControllerConfiguration') && <ReferenceControllerConfigurationInput source="controller.configuration.id" reference="ControllerConfiguration" validate={required()} label="resources.Controller.fields.configuration" allowEmpty emptyText="ra.message.no" />}
                {can('create', 'Controller', 'serialNumber') && <TextInput source="controller.serialNumber" label="resources.Controller.fields.serialNumber" />}
                {can('create', 'Controller', 'url') && <TextInput source="controller.url" label="resources.Controller.fields.url" validate={required()} />}
                {can('create', 'Controller', 'phoneNumber') && <PhoneInput source="controller.phoneNumber" label="resources.Controller.fields.phoneNumber" />}
                {can('create', 'Controller', 'meta') && <JsonVariableInputs source="controller.meta.metaVariable">
                    <JsonVariableInput />
                </JsonVariableInputs>}
            </WizardFormStep>
            <WizardFormStep label="Корректор" resource="GasMeter">
                <GasMeterWarning />
                {can('create', 'GasMeter', 'name') && <FormDataConsumer formClassName={classes.flexInput}>
                    {({ formData, id, ...rest }) => (
                        <TextInput source="gasMeter.name" validate={required()} fullWidth label="resources.GasMeter.fields.name" defaultValue={formData?.gasMeterUnit?.name} />
                    )}
                </FormDataConsumer>}
                {can('create', 'GasMeter', 'description') && <TextInput source="gasMeter.description" multiline fullWidth label="resources.GasMeter.fields.description" />}
                {can('create', 'GasMeter', 'type') && <GasMeterTypeInput source="gasMeter.type" name="GasMeterType" resource="GasMeterUnit" option="resources.GasMeter.enums.type" validate={required()} label="resources.GasMeter.fields.type" />}
                {can('create', 'GasMeter', 'configuration', 'GasMeterConfiguration') && <ReferenceGasMeterConfigurationInput source="gasMeter.configuration.id" reference="GasMeterConfiguration" validate={required()} label="resources.GasMeter.fields.configuration" allowEmpty emptyText="ra.message.no" />}
                {can('create', 'GasMeter', 'dbId') && <NumberInput source="gasMeter.dbId" label="resources.GasMeter.fields.dbId" validate={dbIdValidation} helperText="Если не указан, будет сгенерирован автоматически" />}
                {can('create', 'GasMeter', 'serialNumber') && <TextInput source="gasMeter.serialNumber" label="resources.GasMeter.fields.serialNumber" />}
                {can('create', 'GasMeter', 'port') && <NumberInput source="gasMeter.port" label="resources.GasMeter.fields.port" />}
                {can('create', 'GasMeter', 'timeZone') && <NumberInput source="gasMeter.timeZone" step={1} validate={required()} label="resources.GasMeter.fields.timeZone" />}
                {can('create', 'GasMeter', 'commercialHour') && <NumberInput source="gasMeter.commercialHour" step={1} validate={required()} label="resources.GasMeter.fields.commercialHour" />}
                {can('create', 'GasMeter', 'meta') && <JsonVariableInputs source="gasMeter.meta.metaVariable" >
                    <JsonVariableInput />
                </JsonVariableInputs>}
            </WizardFormStep>
            <WizardFormStep label="Дополнительно">
                {can('update', 'Controller', 'status') && <StatusInput source="controller.status" name="ControllerStatus" resource="GasMeterUnit" option="resources.Controller.enums.status" label="resources.Controller.fields.status" helperText="Значение будет установлено как статус Контроллера и Корректора" />}
                {can('create', 'GasMeterUnit', 'reports', 'Report') && <FormDataConsumer formClassName={classes.flexInput}>
                    {({ formData, id, ...rest }) => (
                        <ReportReferenceArrayInputWithDefault source="gasMeterUnit.reportsIds" reference="Report" filter={{ resource: "GasMeterUnit", gasMeterType: formData.gasMeter?.type }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.reports" fullWidth />
                    )}
                </FormDataConsumer>}
                {can('create', 'GasMeterUnit', 'dashboards', 'Dashboard') && <DashboardReferenceArrayInputWithDefault source="gasMeterUnit.dashboardsIds" reference="Dashboard" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.dashboards" fullWidth />}
            </WizardFormStep>
        </WizardForm>
    </Dialog >
    );
};


const sanitizeRestProps = ({
    basePath = null,
    hasCreate = null,
    hasEdit = null,
    hasList = null,
    hasShow = null,
    history = null,
    loaded = null,
    loading = null,
    location = null,
    match = null,
    onFailure = null,
    onSuccess = null,
    options = null,
    permissions = null,
    transform = null,
    ...rest
}) => rest;


export default CreateDialog;
