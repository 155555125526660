import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleForm, TextInput, Toolbar, SaveButton, ReferenceArrayInput, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import GasRegionBanner from './GasRegionBanner';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    referenceArrayInput: {
        marginTop: 8,
        marginBottom: 4,
    },
}));

const GasRegionToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {can('update', 'GasRegion') && <SaveButton />}
            {can("update", "GasRegion") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {can('remove', 'GasRegion') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const GasRegionEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasRegionBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasRegionToolbar />}>
                    {can('update', 'GasRegion', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {can('update', 'GasRegion', 'description') && <TextInput source="description" multiline fullWidth />}
                    {can('update', 'GasRegion', 'aisId') && <TextInput source="aisId" />}
                    {can('create', 'GasRegion', 'gasDistricts', 'GasDistrict') && <ReferenceArrayInput source="gasDistrictsIds" reference="GasDistrict" filter={{ gasRegion: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.gasDistricts" fullWidth>
                        <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                    </ReferenceArrayInput>}
                    {can('update', 'GasRegion', 'reports', 'Report') && <ReportReferenceArrayInput source="reportsIds" reference="Report" filter={{ resource: "GasRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.reports" fullWidth />}
                    {can('update', 'GasRegion', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasRegion.fields.tags" fullWidth />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasRegionEdit