import { DateField, Pagination, ReferenceManyField as RaReferenceManyField, SearchInput, TextField } from 'react-admin';
import DividerField from '../Fields/DividerField';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import GasConsumerBanner from './GasConsumerBanner';
import { GasDistrictReferenceDatagrid } from '../GasDistrict/GasDistrictDatagrid';
import { GasMeterPadReferenceDatagrid } from '../GasMeterPad/GasMeterPadDatagrid';
import MoreContent from '../Layout/MoreContent';
import NestedField from '../Fields/NestedField';
import NestedGridField from '../Fields/NestedGridField';
import OwnershipGasMeterPadsDatagrid from './OwnershipGasMeterPadsDatagrid';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import ReferenceListLinkButton from '../Buttons/ReferenceListLinkButton';
import ReferenceManyActions from '../Fields/referenceManyField/ReferenceManyActions';
import ReferenceManyField from '../Fields/referenceManyField/ReferenceManyField';
import ReferenceManyFilter from '../Fields/referenceManyField/ReferenceManyFilter';
import ReportCard from '../Report/ReportCard';
import Separator from '../Layout/Separator';
import { Show } from '../Layout/Show';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';
// import Breadcrumbs from '../Layout/Breadcrumbs';
// import Breadcrumb from '../Layout/Breadcrumb';



const ReferenceManyFilters = (props) => {
    return (
        <ReferenceManyFilter {...props}>
            <SearchInput source="q" id={`${props.reference}.q`} alwaysOn />
        </ReferenceManyFilter>
    )
}

const ReferenceManyActionButtons = (props) => {
    return (
        <ReferenceManyActions >
            <ReferenceListLinkButton {...props} />
        </ReferenceManyActions>
    )
}


const GasConsumerShow = props => {
    const showOwnershipButton = React.useRef(null);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasConsumerBanner />
            {can('getOne', 'GasConsumer', 'addrManual') && <CardShowLayout>
                <TextField source="addrManual" />
            </CardShowLayout>}
            {can('getOne', 'GasConsumer', 'reports', 'Report') && <NestedGridField source="reports" label="resources.GasConsumer.fields.reports" >
                <ReportCard />
            </NestedGridField>}
            {can('getOne', 'GasConsumer', 'gasDistricts', 'GasDistrict') &&
                <ReferenceManyField
                    reference="GasDistrict"
                    target="gasMeterPadsSomeGasConsumerReference"
                    label="resources.GasConsumer.fields.gasDistricts"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={100}
                    pagination={<Pagination />}
                >
                    <GasDistrictReferenceDatagrid />
                </ReferenceManyField>}

            {can('getOne', 'GasConsumer', 'gasMeterPads', 'GasMeterPad') &&
                <ReferenceManyField
                    reference="GasMeterPad"
                    target="gasConsumerReference"
                    label="resources.GasConsumer.fields.gasMeterPads"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={100}
                    pagination={<Pagination />}
                >
                    <GasMeterPadReferenceDatagrid />
                </ReferenceManyField>}

            {can('getOne', 'GasConsumer', 'ownershipGasMeterPads') && <Separator action={(<div ref={showOwnershipButton} ></div>)} />}
            {can('getOne', 'GasConsumer', 'ownershipGasMeterPads') && <MoreContent showMoreButton={showOwnershipButton} label="ra.action.show_ownership">
                <CardBlankLayout>
                    <OwnershipGasMeterPadsDatagrid source="ownershipGasMeterPads" />
                </CardBlankLayout>
            </MoreContent>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton} >
                <CardShowLayout>
                    {can('getOne', 'GasConsumer', 'aisId') && <TextField source="aisId" />}
                    <DividerField />
                    {can('getOne', 'GasConsumer', 'id') && <TextField source="id" />}
                    {can('getOne', 'GasConsumer', 'createdBy', 'User') && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {can('getOne', 'GasConsumer', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'GasConsumer', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>

            {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
            {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid />
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>}
        </Show>
    );
};

export default GasConsumerShow;