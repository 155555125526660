import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'


const useChipStyles = makeStyles(theme => ({

}));

const GasMeterPadDisabledChip = ({ className, classes: classesOverride, record, source, resource, hideWhen, ...props}) => {
    const classes = useChipStyles({ classes: classesOverride });

    if (!record) return null;

    const disabled = record.disabled

    if (disabled === hideWhen) return null;

    const label = disabled ? "Отключена" : "Включена";

    return (
        <Chip
            size="small"
            classes={{root:classes.root}}
            // className={disabled ? classes.disabled : ""}
            label={label}
        />
    );
};

export default GasMeterPadDisabledChip;