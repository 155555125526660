import Icon from '../Icons/ValvePad';
import GasMeterPadList from './GasMeterPadList';
import GasMeterPadCreate from './GasMeterPadCreate';
import GasMeterPadEdit from './GasMeterPadEdit';
import GasMeterPadShow from './GasMeterPadShow';

const GasMeterPadIcon = Icon;


export {
    GasMeterPadIcon,
    GasMeterPadList,
    GasMeterPadShow,
    GasMeterPadCreate,
    GasMeterPadEdit,
};
