import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, TextField } from 'react-admin';
import React from 'react';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import N2Field from "./GasQualityCertificateN2Field";
import CO2Field from "./GasQualityCertificateCO2Field";
import DensityField from "./GasQualityCertificateDensityField";
import useColumns from '../Layout/preferences/useColumns';
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import TagArrayField from "../Tag/TagArrayField";
import TagChip from "../Tag/TagChip";
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';
import useSelectedColumnsPreferences from '../../controller/preferences/useSelectedColumnsPreferences';
import useListStyles from '../Layout/list/useListStyles';



export const useDefaultDatagridColumns = () => {
    const classes = useListStyles()
    const columns = useColumns([
        can('getList', 'GasQualityCertificate', 'name') && <ResourceLinkField source="name" target="_blank" alwaysOn />,
        can('getList', 'GasQualityCertificate', 'gqsId') && <TextField source="gqsId" />,
        can('getList', 'GasQualityCertificate', 'n2') && <N2Field source="n2" label="resources.GasQualityCertificate.fields.n2_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} />,
        can('getList', 'GasQualityCertificate', 'co2') && <CO2Field source="co2" label="resources.GasQualityCertificate.fields.co2_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} />,
        can('getList', 'GasQualityCertificate', 'density') && <DensityField source="density" label="resources.GasQualityCertificate.fields.density_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} />,
        can('getList', 'GasQualityCertificate', 'validFrom') && <DateField source="validFrom" options={dateTimeOptions} textAlign="right" headerClassName={classes.noWrap} />,
        can('getList', 'GasQualityCertificate', 'validTo') && <DateField source="validTo" options={dateTimeOptions} textAlign="right" headerClassName={classes.noWrap} />,
        can('getList', 'GasQualityCertificate', 'gasExtractionPoint') && <TextField source="gasExtractionPoint" />,
        can('getList', 'GasQualityCertificate', 'tags', 'Tag') && <TagArrayField source="tags" sortable={false}>
            <TagChip />
        </TagArrayField>,
        can('getList', 'GasQualityCertificate', 'writtenAt') && <DateField source="writtenAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
        can('getList', 'GasQualityCertificate', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
        can('getList', 'GasQualityCertificate', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />,
    ])

    return columns
}

export const GasQualityCertificateReferenceDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.gqsId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {can('getList', 'GasQualityCertificate', 'name') && <ResourceLinkField source="name" target="_blank" />}
            {can('getList', 'GasQualityCertificate', 'gqsId') && <TextField source="gqsId" />}
            {can('getList', 'GasQualityCertificate', 'n2') && <N2Field source="n2" label="resources.GasQualityCertificate.fields.n2_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} />}
            {can('getList', 'GasQualityCertificate', 'co2') && <CO2Field source="co2" label="resources.GasQualityCertificate.fields.co2_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} />}
            {can('getList', 'GasQualityCertificate', 'density') && <DensityField source="density" label="resources.GasQualityCertificate.fields.density_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} />}
            {can('getList', 'GasQualityCertificate', 'validFrom') && <DateField source="validFrom" options={dateTimeOptions} textAlign="right" headerClassName={classes.noWrap} />}
            {can('getList', 'GasQualityCertificate', 'validTo') && <DateField source="validTo" options={dateTimeOptions} textAlign="right" headerClassName={classes.noWrap} />}
            {can('getList', 'GasQualityCertificate', 'gasExtractionPoint') && <TextField source="gasExtractionPoint" />}
            {can('getList', 'GasQualityCertificate', 'tags', 'Tag') && <TagArrayField source="tags" sortable={false}>
                <TagChip />
            </TagArrayField>}
            {can('getList', 'GasQualityCertificate', 'writtenAt') && <DateField source="writtenAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'GasQualityCertificate', 'createdAt') && <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
            {can('getList', 'GasQualityCertificate', 'updatedAt') && <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />}
        </Datagrid>
    )
};

const GasQualityCertificateDatagrid = ({ columns, ...props }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles()
    const SelectedColumns = useSelectedColumnsPreferences(columns);

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            tertiaryText={record => record.gqsId}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            {SelectedColumns}
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {can('getOne', 'GasQualityCertificate') && <ShowBlankItem />}
                {can('update', 'GasQualityCertificate') && <EditMenuItem />}
                {can('remove', 'GasQualityCertificate') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    )
}



export default GasQualityCertificateDatagrid;