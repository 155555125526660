import React from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useListContext } from 'react-admin';


const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.text.secondary,
    },
}));

export const ReferenceListLinkButton = ({ className, target, reference, record, ...props }) => {

    const classes = useStyles();
    const { basePath, filter, filterValues, ...fgsdf } = useListContext();

    return (
        <Button
            component={Link}
            to={{
                pathname: basePath,
                search: stringify({
                    filter: JSON.stringify({
                        ...filter,
                        ...filterValues
                    }),
                })
            }}
            size="small"
            disableElevation
            className={classes.button}
        >
            Открыть список
        </Button>
    );
};


export default ReferenceListLinkButton;