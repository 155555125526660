import { DateField, Pagination, ReferenceManyField as RaReferenceManyField, SearchInput, TextField } from 'react-admin';
import DividerField from '../Fields/DividerField';
import CardBlankLayout from "../Layout/CardBlankLayout";
import CardShowLayout from '../Layout/CardShowLayout';
import { GasConsumerReferenceDatagrid } from '../GasConsumer/GasConsumerDatagrid';
import GasDistributorStationBanner from './GasDistributorStationBanner';
import { GasDistributorStationOutputReferenceDatagrid } from '../GasDistributorStationOutput/GasDistributorStationOutputDatagrid';
import { GasMeterPadReferenceDatagrid } from '../GasMeterPad/GasMeterPadDatagrid';
import { GasQualityCertificateReferenceDatagrid } from '../GasQualityCertificate/GasQualityCertificateDatagrid';
import MoreContent from '../Layout/MoreContent';
import NestedField from '../Fields/NestedField';
import NestedGridField from '../Fields/NestedGridField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import ReferenceListLinkButton from '../Buttons/ReferenceListLinkButton';
import ReferenceManyActions from '../Fields/referenceManyField/ReferenceManyActions';
import ReferenceManyField from '../Fields/referenceManyField/ReferenceManyField';
import ReferenceManyFilter from '../Fields/referenceManyField/ReferenceManyFilter';
import ReportCard from "../Report/ReportCard";
import Separator from '../Layout/Separator';
import { Show } from '../Layout/Show';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';

const ReferenceManyFilters = (props) => {
    return (
        <ReferenceManyFilter {...props}>
            <SearchInput source="q" id={`${props.reference}.q`} alwaysOn />
        </ReferenceManyFilter>
    )
}

const ReferenceManyActionButtons = (props) => {
    return (
        <ReferenceManyActions >
            <ReferenceListLinkButton {...props} />
        </ReferenceManyActions>
    )
}

const GasDistributorStationShow = props => {
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasDistributorStationBanner />

            {can('getOne', 'GasDistributorStation', 'reports', 'Report') && <NestedGridField source="reports" label="resources.GasDistributorStation.fields.reports" >
                <ReportCard />
            </NestedGridField>}

            {can('getOne', 'GasDistributorStation', 'gasDistributorStationOutputs', 'GasDistributorStationOutput') &&
                <ReferenceManyField
                    reference="GasDistributorStationOutput"
                    target="gasDistributorStationReference"
                    label="resources.GasDistributorStation.fields.gasDistributorStationOutputs"
                    filters={<ReferenceManyFilters />}
                    // actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }} perPage={100}
                    perPage={10}
                    pagination={<Pagination />}
                >
                    <GasDistributorStationOutputReferenceDatagrid />
                </ReferenceManyField>}

            {can('getOne', 'GasDistributorStation', 'gasConsumers', 'GasConsumer') &&
                <ReferenceManyField
                    reference="GasConsumer"
                    target="gasDistributorStationByGasMeterPadReference"
                    label="resources.GasDistributorStation.fields.gasConsumers"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }} perPage={100}
                    perPage={10}
                    pagination={<Pagination />}
                >
                    <GasConsumerReferenceDatagrid />
                </ReferenceManyField>}

            {can('getOne', 'GasDistributorStation', 'gasMeterPads', 'GasMeterPad') &&
                <ReferenceManyField
                    reference="GasMeterPad"
                    target="gasDistributorStationByGasDistributorStationOutputReference"
                    label="resources.GasDistributorStation.fields.gasMeterPads"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }} perPage={100}
                    perPage={25}
                    pagination={<Pagination />}
                >
                    <GasMeterPadReferenceDatagrid />
                </ReferenceManyField>}

            {can('getOne', 'GasDistributorStation', 'gasQualityCertificates', 'GasQualityCertificate') &&
                <ReferenceManyField
                    reference="GasQualityCertificate"
                    target="gasDistributorStation_some"
                    label="resources.GasDistributorStation.fields.gasQualityCertificates"
                    filters={<ReferenceManyFilters />}
                    actions={<ReferenceManyActionButtons />}
                    sort={{ field: 'name', order: 'ASC' }} perPage={100}
                    perPage={25}
                    pagination={<Pagination />}
                >
                    <GasQualityCertificateReferenceDatagrid />
                </ReferenceManyField>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {can('getOne', 'GasDistributorStation', 'aisId') && <TextField source="aisId" />}
                    {can('getOne', 'GasDistributorStation', 'balanceRegion', 'BalanceRegion') && <NestedField source="balanceRegion" reference="BalanceRegion" >
                        <TextField source="name" />
                    </NestedField>}
                    <DividerField />
                    {can('getOne', 'GasDistributorStation', 'id') && <TextField source="id" />}
                    {can('getOne', 'GasDistributorStation', 'createdBy', 'User') && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {can('getOne', 'GasDistributorStation', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'GasDistributorStation', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>

            {can('getList', 'History') && <Separator action={(<div ref={showHistoryButton} ></div>)} />}
            {can('getList', 'History') && <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="GasDistributorStation" />
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>}
        </Show>
    );
};

export default GasDistributorStationShow;