import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 4.5C9 2.6 7.4 1 5.5 1S2 2.6 2 4.5C2 7.1 5.5 11 5.5 11S9 7.1 9 4.5zM5.5 5.8c.7 0 1.3-.6 1.2-1.2 0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2zM11 4.5c0-.52-.07-1.02-.21-1.5h7.73a.5.5 0 01.35.85L3.85 18.87a.5.5 0 01-.85-.35V11.1a26.75 26.75 0 00.83 1.03L5.5 14l1.67-1.87A23.86 23.86 0 008.9 9.84c.47-.7.98-1.54 1.37-2.4.38-.85.72-1.88.72-2.94zM5.4 20.15a.5.5 0 00.36.85h12.62a.5.5 0 00.35-.85l-5.25-5.25a2 2 0 00-2.82 0L5.4 20.15zM21 18.38a.5.5 0 01-.85.35l-5.25-5.25a2 2 0 010-2.82l5.25-5.25a.5.5 0 01.85.35v12.62z" />
    </SvgIcon>
  );
}

export default Icon;