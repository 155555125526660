import React, { Children, cloneElement } from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
import useDashboardModule from '../../controller/dashboard/useDashboardModule';
import dashboardScadaProps from '../Layout/DashboardScadaModule';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },

}));

const DashboardNestedGridField = ({ children, classes: classesOverride, record, source, resource, basePath, version, className, label, ...props }) => {
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    const choices = get(record, source);
    const referenceRecord = record
    if (!record || !choices.length) return null;

    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
            <Grid container spacing={2} >
                {choices.map(choice => (
                    <DashboardNestedGridItem record={choice} key={choice.id}>
                        {cloneElement(Children.only(children), {
                            record: choice,
                            resource,
                            basePath,
                            version,
                            referenceRecord,
                        })}
                    </DashboardNestedGridItem>
                ))}
            </Grid>
        </div>
    );
};

const DashboardNestedGridItem = ({ children, record, ...props }) => {
    const path = record && record.uri;

    const useDashboardModuleProps = useDashboardModule(path);
    const dashboardModuleProps = record && record.executeApp && record.executeApp == 'SCADAJS' ? dashboardScadaProps : useDashboardModuleProps;

    const { DashboardPreview } = dashboardModuleProps;
    const gridItemProps = DashboardPreview && DashboardPreview.defaultProps && DashboardPreview.defaultProps.gridItemProps;

    const resultGridItemProps = gridItemProps || {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
    };

    return (
        <Grid item {...resultGridItemProps}>
            {cloneElement(Children.only(children), {
                dashboardModuleProps,
            })}
        </Grid>
    );
};

export default DashboardNestedGridField;