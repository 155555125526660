import React, { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DefaultSidebar from './Sidebar';
import DefaultContent from './Content';



const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexGrow: 1,
        marginTop: theme.spacing(2),
    },
}));

const Layout = ({
    sidebar: SidebarComponent,
    content: ContentComponent,
    configuration,
    ...props
}) => {
    const classes = useStyles();
    const { routes, sidebar } = configuration;

    return (
        <div className={classes.root}>
            {cloneElement(SidebarComponent, {
                sidebar,
            })}
            {cloneElement(ContentComponent, {
                routes,
            })}
        </div>
    );
}

Layout.defaultProps = {
    sidebar: <DefaultSidebar />,
    content: <DefaultContent />,
}


export default Layout;