import Icon from '../Icons/GRS';
import GasDistributorStationList from './GasDistributorStationList';
import GasDistributorStationCreate from './GasDistributorStationCreate';
import GasDistributorStationEdit from './GasDistributorStationEdit';
import GasDistributorStationShow from './GasDistributorStationShow';

const GasDistributorStationIcon = Icon;


export {
    GasDistributorStationIcon,
    GasDistributorStationList,
    GasDistributorStationShow,
    GasDistributorStationCreate,
    GasDistributorStationEdit,
};
