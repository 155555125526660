import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { REPORT_DESPATCH_FRAGMENT } from '../typeDefs';

export const UPDATE_REPORT_DESPATCH = gql`
    mutation updateReportDespatch($where: ReportDespatchWhereUniqueInput!, $input: ReportDespatchUpdateInput!) {
        data: updateReportDespatch(where: $where, input: $input) {
            item {...ReportDespatchFragment}
        }
    }
    ${REPORT_DESPATCH_FRAGMENT}
`

export default (args) => {
    return apolloClient.mutate({
        mutation: UPDATE_REPORT_DESPATCH,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        }
    })
};
