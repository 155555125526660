import React from 'react';
import { useTranslate } from 'react-admin';
import { get } from 'lodash';



const TitleForRecord = ({ resource, source, record, ...props }) => {
    const translate = useTranslate();
    
    if (!record) return null;
    const resourceText = translate(`resources.${resource}.name`, { smart_count: 1 });
    const sourceText = get(record, source);

    return `${resourceText}${(sourceText) ? ` ${sourceText}` : ` #${record.id}`}`;
};



export default TitleForRecord;