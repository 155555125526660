import React, { memo } from 'react';
import get from 'lodash/get';
import sanitizeFieldRestProps from './sanitizeFieldRestProps';
import { linkToRecord } from 'react-admin';
import Link from './Link';

const stopPropagation = e => e.stopPropagation();

const ResourceLinkField = memo(props => {
    const { className, emptyText, source, linkTo = 'show', basePath, record, ...rest } = props;
    if (!record) return null;
    const value = get(record, source);
    const link = linkToRecord(basePath, record.id, linkTo)

    return (
        <Link
            className={className}
            to={link}
            onClick={stopPropagation}
            {...sanitizeFieldRestProps(rest)}
        >
            {value}
        </Link>
    );
});

ResourceLinkField.defaultProps = {
    addLabel: true,
};

ResourceLinkField.displayName = 'ResourceLinkField';


export default ResourceLinkField;
