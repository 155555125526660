import buildGraphQLProvider from 'ra-data-graphql-simple';
import apolloCoreClient from './apolloClient';
import decorate from './decorate';
import buildQuery from './builtQuery';

const buildCoreProvider = () => buildGraphQLProvider({
    client: apolloCoreClient,
    buildQuery: buildQuery,
    introspection: false
});


export const knownResources = ['DeviceStatus'];

export const dataProvider = (type, resource, params) => {
    return buildCoreProvider().then(async (provider) => {

        const { params: paramsD } = await decorate(type, resource, params);

        return provider(type, resource, paramsD)
    }
    )
};

export default {
    dataProvider,
    knownResources,
};