import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReferenceArrayInput, ReferenceInput, TextInput } from 'react-admin';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import SearchInput from '../Inputs/SearchInput';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import AutocompleteInput from '../Inputs/AutoompleteInput';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import { can } from "../../abilityProvider";
import { matchTrue } from '../../utils';



const useStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}));

const Filter = (props) => {
    const classes = useStyles()
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn />
            {can('getList', 'GasConsumer', 'aisId') && <TextInput source="aisId" alwaysOn resettable className={classes.mediumWidth} />}
            {can('getList', 'GasConsumer', 'gasMeterPads', "GasMeterPad") && <TextInput source="gasMeterPads_some.aisId" label="resources.GasConsumer.fields.gasMeterPadAisId" />}
            {can('getList', 'GasConsumer', 'gasMeterPads', "GasMeterPad") && <ReferenceInput source="gasMeterPads_some.id" label="resources.GasConsumer.fields.gasMeterPad" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasConsumer', 'gasDistrict', 'GasDistrict') && <ReferenceInput source="gasDistrictByGasMeterPadReference" label="resources.GasConsumer.fields.gasDistrict" reference="GasDistrict" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasConsumer', 'gasDistributorOrganization', "GasDistributorOrganization") && <ReferenceInput source="gasDistributorOrganizationByGasMeterPadReference" label="resources.GasConsumer.fields.gasDistributorOrganization" reference="GasDistributorOrganization" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasConsumer', 'gasDistributorStation', "GasDistributorStation") && <ReferenceInput source="gasDistributorStationByGasMeterPadReference" label="resources.GasConsumer.fields.gasDistributorStation" reference="GasDistributorStation" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasConsumer', 'tags', "Tag") && <ReferenceArrayInput source="tags_and" label="resources.Tag.fields.tags_and" reference="Tag" filter={{ resource: "GasConsumer" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false}>
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasConsumer', 'tags', 'Tag') && <ReferenceArrayInput source="tags_not_and" label="resources.Tag.fields.tags_not_and" reference="Tag" filter={{ resource: "GasConsumer" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasConsumer', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_and" label="resources.Report.fields.reports_and" reference="Report" filter={{ resource: "GasConsumer" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
            {can('getList', 'GasConsumer', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_not_and" label="resources.Report.fields.reports_not_and" reference="Report" filter={{ resource: "GasConsumer" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
        </ManualSubmitFilter>
    )
};



export default Filter;