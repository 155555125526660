import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import decodeJwt from 'jwt-decode'
import { AUTH_TOKEN_COOKIE } from '../../appsettings';
import { getCookie } from '../../utils';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import UserApp from './UserApp';



const USER_APPS = gql`
    query getUser($authId: ID) {
        data: User(
            where: {
                authId: $authId
            }
        ){id apps {id name description url avatar}}
    }
`;

const UserApps = (props) => {
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
      
    };

    const handleClose = event => {
        setAnchorEl(null);
        
    };

    const { userId } = decodeJwt(getCookie(AUTH_TOKEN_COOKIE));

    const { data } = useQuery(USER_APPS, {
        client: apolloCoreClient,
        variables: { authId: userId },
    });

    const apps = data && data.data && data.data.apps.length > 0 && sortBy(data.data.apps, (app) => app.name)

    if (!apps) return null;

    return (
        <>
            <Tooltip title={translate('ra.navigation.user_apps', { _: 'Apps' })} >
                <IconButton aria-controls="user_apps" color="inherit" onClick={handleClick}>
                    <AppsIcon />
                </IconButton>
            </Tooltip>
                <Menu
                    id="user_apps"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                {apps.map(app => (
                    <UserApp
                        key={app.id}
                        onClick={handleClose}
                        {...app}
                    />
                 ))}
            </Menu>
        </>
    )
};

export default UserApps;


