import { useTranslate, useResourceContext } from 'react-admin';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';



const useExporter = () => {
    const translate = useTranslate()
    const resource = useResourceContext()
    const fileName = translate(`resources.${resource}.name`, { smart_count: 2 })
    const modelExport = {
        id: true,
        name: true,
        description: true,
        createdBy: {
            id: true,
            name: true,
            __resource: "User",
        },
        createdAt: dateFormatter,
        updatedAt: dateFormatter,
        email: true,
        login: true,
        phone: true,
        phoneContact: true,
        position: true,
        role: enumFormatter,
        access: {
            id: true,
            resource: enumFormatter,
            __resource: "Access",
        },
        counterparty: {
            id: true,
            name: true,
            __resource: "Counterparty",
        },
        __resource: "User",
    }
    const exporter = customExporter(fileName, modelExport, { translate })

    return exporter
}



export default useExporter;