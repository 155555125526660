import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReportIcon from '@material-ui/icons/Report';
import { useTranslate } from 'ra-core';



const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(2),
        opacity: 0.5,
    },
    icon: {
        marginBottom: 4,
    },
}));

const Error = ({className, title, caption, ...props}) => {
    const classes = useStyles(props);
    const translate = useTranslate();
    return (
        <div className={classnames(classes.root, className)}>
            <ReportIcon className={classes.icon} fontSize="large" />
            {title && <Typography variant="body1" >{translate(title, {_: title})}</Typography>}
            {caption && <Typography variant="caption" >{translate(caption, {_: caption})}</Typography>}
        </div>
    );
};

Error.defaultProps = {
    title: 'ra.page.error',
    caption: 'ra.message.error',
};


export default Error;