import React from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton } from 'react-admin';
import { useUpdate, useNotify } from 'react-admin';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setUser } from '../../redux/user/actions';


export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const SaveProfileButton = ({saving, record, className, ...props}) => {
    const user = useSelector(state => state.user, shallowEqual);
    const [update, { loading }] = useUpdate('Profile');
    const notify = useNotify();
    const formState = useFormState();
    const dispatch = useDispatch();


    const handleClick = () => update({
        type: 'update',
        resource: 'Profile',
        payload: {
            id: record.id,
            data: {...formState.values},
            previousData: {...record},
        },
    },
    {
        onSuccess: ({ data }) => {
            
            const editFields = {
                name: data.name, 
                description: data.description, 
                authId: data.authId,
                email: data.email,
                phone: data.phone, 
                role: data.role, 
                login: data.login
            }

            notify('ra.notification.profile_saved');
            dispatch(setUser({
                ...user,
                ...editFields,
            }));

        },
     
    });

    return <SaveButton
        onMouseDown={handleClick}
        disabled={loading}
    />;
}

export default SaveProfileButton;

