import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, ReferenceInput, required } from 'react-admin';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import ReferenceArrayGasMeterPointInput from './ReferenceArrayGasMeterPointInput';
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import BooleanInput from '../Inputs/BooleanInput';
import { can } from "../../abilityProvider";
import TextField from '@material-ui/core/TextField';



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    flexReferenceArrayInput: {
        display: "flex",
        alignItems: "flex-end",
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const GasMeterUnitCreate = props => {
    const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show" initialValues={{ enableWriteGasQualityCertificate: false }}>
                {can('create', 'GasMeterUnit', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasMeterUnit', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasMeterUnit', 'inventoryNumber') && <TextInput source="inventoryNumber" />}
                {can('create', 'GasMeterUnit', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'GasMeterUnit', 'enableWriteGasQualityCertificate') && <BooleanInput source="enableWriteGasQualityCertificate" />}
                {can('create', 'GasMeterUnit', 'gasMeterPad', 'GasMeterPad') && <ReferenceInput source="gasMeterPad.id" reference="GasMeterPad" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.gasMeterPad" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasMeterUnit', 'gasMeterPoints', 'GasMeterPoint') && <ReferenceArrayGasMeterPointInput source="gasMeterPointsIds" reference="GasMeterPoint" filter={{ gasMeterUnit: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.gasMeterPoints" formClassName={classes.flexReferenceArrayInput} fullWidth />}
                {can('create', 'GasMeterUnit', 'reports', 'Report') && <TextField label="Отчеты" disabled placeholder="Для добавления отчетов необходимо Устройство" className={classes.textField} InputLabelProps={{ shrink: true }} variant="filled" fullWidth />}
                {can('create', 'GasMeterUnit', 'dashboards', 'Dashboard') && <TextField label="Информационные панели" disabled placeholder="Для добавления информационных панелей необходимо Устройство" className={classes.textField} InputLabelProps={{ shrink: true }} variant="filled" fullWidth />}
                {can('create', 'GasMeterUnit', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasMeterUnit" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasMeterUnit.fields.tags" fullWidth />}
                {can('create', 'GasMeterUnit', 'fixedAsset') && <TextInput source="fixedAsset" fullWidth />}
                {can('create', 'GasMeterUnit', 'linkToir') && <TextInput source="linkToir" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasMeterUnitCreate;