import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';




const useStyles = makeStyles(theme => ({

    chipWarning: {
        backgroundColor: "#ffecb3",
    }, 
}));

const AisChip = ({classes: classesOverride, className, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const { record } = props;
    if (!record)  {return null}
    const aisId = record.aisId;
    const label = aisId ? aisId : "ИД АИС не назначен";

    return (
        <Chip className={classNames(classes.chip, className, {[classes.chipWarning]: !aisId})} label={label} size="small" />
    );
};



export default AisChip;