import React from 'react';
import Divider from '@material-ui/core/Divider';
import sanitizeFieldRestProps from './sanitizeFieldRestProps';


const DividerField = (props) => {

    return (<Divider {...sanitizeFieldRestProps(props)} />);
}

export default DividerField;