import React from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';



const useStyles = makeStyles(theme => ({
    badge: ({banned, online}) => ({
        backgroundColor: banned ? theme.palette.error.main : theme.palette.success.main,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    }),
    dot: {
        height: 8,
        padding: 0,
        minWidth: 8,
    },
}));


const OnlineBadge = ({children, record, ...props}) => {
    const onlineUsers = useSelector(state => state.onlineUsers, shallowEqual);
    const online = onlineUsers.includes(record.id);
    const banned = record.banned;
    const classes = useStyles({banned, online});
    
    if (!record) return null;
 
    
  return (
    <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
        classes={{badge: classes.badge, dot: classes.dot}}
        invisible={(!online && !banned)}
        variant="dot"
    >
        { React.cloneElement(children, {
            record,
            ...props,
        })}
    </Badge>
      
  );
}

OnlineBadge.defaultProps = {
    addLabel: false,
};

export default OnlineBadge;