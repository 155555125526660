import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import AisChip from "../Fields/AisChip";


const useStyles = makeStyles(theme => ({
    root: {},
    selected: { fontWeight: 500 },
    highlight: {
        fontWeight: 500,
        color: "#000"
    },
    suggestionText: {
        flex: '1 1 auto',
    },
    suggestionChip: {
        marginLeft: 8,
        margin: 0,
    },
}),
    { name: 'RaAutocompleteSuggestionItem' }
);

export const gasDistributorStationOutputOptionText = choice => {
    const { aisId, name, gasDistributorStation } = choice
    const gdsName = gasDistributorStation?.name
    const gdso = aisId ? `${name} (${aisId})` : name;
    const text = gdsName ? `${gdsName}: ${gdso}` : gdso
    return text;
};

const Suggestion = ({
    suggestion,
    index,
    highlightedIndex,
    isSelected,
    filterValue,
    classes: classesOverride,
    getSuggestionText,
    ...props
}) => {
    const classes = useStyles({ classes: classesOverride });
    const gasDistributorStationName = suggestion.gasDistributorStation?.name;
    const suggestionText = `${gasDistributorStationName ? `${gasDistributorStationName}: ` : ''}${suggestion.name}`;
    if (!suggestion.id) return <MenuItem key={suggestion.id} {...props}>Нет</MenuItem>;
    const isHighlighted = highlightedIndex === index;
    const matches = match(suggestionText, filterValue);
    const parts = parse(suggestionText, matches);


    return (
        <MenuItem
            key={suggestion.id}
            selected={isHighlighted}
            className={classnames(classes.root, {
                [classes.selected]: isSelected,
            })}
            {...props}
        >
            <Typography variant="body1" className={classes.suggestionText}>
                {parts.map((part, index) => (<span key={index} className={(part.highlight) ? classes.highlight : ""}>{part.text}</span>))}
            </Typography>
            <AisChip {...props} record={suggestion} className={classes.suggestionChip} />
        </MenuItem>
    );
};

export default Suggestion;
