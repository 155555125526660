import React from "react";
import PreferenceContext from "./PreferenceContext";
import usePreferenceController from "./usePreferenceController";

const PreferenceContextProvider = ({ children }) => {

    const сonntrollerProps = usePreferenceController()

    return (
        <PreferenceContext.Provider value={сonntrollerProps}>
            {children}
        </PreferenceContext.Provider>
    );
};

export default PreferenceContextProvider