import React, {cloneElement, Children, isValidElement} from "react";
import get from 'lodash/get';

const TagArrayEnhancedField = ({children, classes: classesOverride, className, record, resource, source, basePath, ...props}) => {
    const tags = get(record, source, []);

    const [tagComponent, ...enhancedComponents] = Children.toArray(children);
    
    if (!tags.length && !enhancedComponents.length ) return null;

    return (
        <div>
            {tags.map(tag => {
                return cloneElement(Children.only(tagComponent), {
                    key: tag.id,
                    record: tag,
                });
            })}
            { Children.map(enhancedComponents, (field, index) =>
                field && isValidElement(field)
                ? cloneElement(field, {
                    key: index,
                    basePath,
                    record,
                    resource,
                    source,
                    ...field.props,
                })
                : null
            )}
        </div>
    );
};

export default TagArrayEnhancedField;