import React from 'react';
import MarkdownView from './lib/MarkdownView';
import Changelog from './Changelog';
import ChangelogIcon from './ChangelogIcon';

import Introduction from './docs/Ведение.md';
import Login from './docs/Вход в систему.md';
import Uidescription from './docs/Описание интерфейса.md';
import VERSION from '../AboutApp/Version';
import CHANGELOG from './docs/CHANGELOG.md';


const routes = [
  // {
  //   id: 'introduction',
  //   path: '/Introduction',
  //   component: <MarkdownView path={Introduction} />,
  // },
  // {
  //   id: 'login',
  //   path: '/Login',
  //   component: <MarkdownView path={Login} />,
  // },
  // {
  //   id: 'uidescription',
  //   path: '/Uidescription',
  //   component: <MarkdownView path={Uidescription} />,
  // },
  {
    id: 'changelog',
    path: '/Сhangelog',
    component: <Changelog><MarkdownView path={CHANGELOG} /></Changelog>,
  },
  {
    id: 'version',
    path: '/Version',
    component: <VERSION />,
  },
]


const sidebar = [
  // {
  //   id: 'documentation',
  //   name: 'Документация',
  //   open: true,
  //   children: [
  //     {
  //       id: 'introduction',
  //       name: 'Введение',
  //       route: routes.find(route => route.id === 'introduction'),
  //     },
  //     {
  //       id: 'login',
  //       name: 'Вход в систему',
  //       route: routes.find(route => route.id === 'login'),
  //     },
  //     {
  //       id: 'uidescription',
  //       name: 'Описание интерфейса',
  //       route: routes.find(route => route.id === 'uidescription'),
  //     },
  //   ],
  // },
  {
    id: 'changelog',
    name: 'Журнал изменений',
    icon: <ChangelogIcon menuItemIconProps={{ style: { color: "inherit", marginRight: 0 } }} />,
    route: routes.find(route => route.id === 'changelog'),
  },
  {
    id: 'version',
    name: 'Версия',
    route: routes.find(route => route.id === 'version'),
  },
];



export {
  routes,
  sidebar,
};



