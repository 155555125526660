import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import GoogleIcon from '../Icons/Google';
import LogoIcon from '../Icons/Logo'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import { Notification } from 'react-admin';
import { theme as defaultTheme } from '../../defaultTheme';
import LoginForm from './LoginForm';



const preventDefault = (event) => event.preventDefault();

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: "#316FC4",
        // backgroundImage:
        //     'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
        //backgroundImage: 'url(https://source.unsplash.com/random/1600x900)',
    },
    content: {
        marginTop: '6em',
        minWidth: 300,
    },
    welcome: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    logo: {
        //filter: `drop-shadow(${theme.shadows[4]})`,
        boxShadow: theme.shadows[4],
        borderRadius: "0.5rem",
        fontSize: 56,
        marginBottom: 16
    },
    welcomeText: {
        color: theme.palette.getContrastText(theme.palette.text.primary),
        maxWidth: 300,
        textAlign: "center",

    },
    card: {
        marginTop: '3em',
        borderRadius: 10,
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
    block: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 1em 1em 1em',

    },
    actions: {
        display: 'flex',
        padding: '0 1em 1em 1em',
    },
    button: {
        width: '100%',
    },

}),
    { name: 'RaLogin' }
);


const Login = props => {
    const {
        theme,
        classes: classesOverride,
        className,
        children,
        staticContext,
        backgroundImage,
        title,
        ...rest
    } = props;

    const classes = useStyles(props);
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
            >
                <div className={classes.content}>
                    <div className={classes.welcome}>
                        <LogoIcon className={classes.logo} />
                        <Typography variant="h6" className={classes.welcomeText}>Добро пожаловать на портал АСКУГ Показатели</Typography>
                    </div>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        {children}
                        {/*<CardContent classes={{root: classes.block}}>*/}

                        {/*    <Typography variant="h6">*/}
                        {/*        ИЛИ*/}
                        {/*    </Typography>*/}
                        {/*</CardContent>*/}
                        {/*<CardActions classes={{root: classes.actions}}>*/}
                        {/*    <Button*/}
                        {/*        variant="contained"*/}
                        {/*        type="submit"*/}
                        {/*        disabled*/}
                        {/*        disableElevation*/}
                        {/*        //color="primary"*/}
                        {/*        //startIcon={<GoogleIcon />}*/}
                        {/*        className={classes.button}*/}
                        {/*    >Google*/}
                        {/*    </Button>*/}
                        {/*    <Button*/}
                        {/*        variant="contained"*/}
                        {/*        type="submit"*/}
                        {/*        //color="primary"*/}
                        {/*        //startIcon={<AppleIcon />}*/}
                        {/*        disabled*/}
                        {/*        disableElevation*/}
                        {/*        className={classes.button}*/}
                        {/*    >Apple*/}
                        {/*    </Button>*/}

                        {/*</CardActions>*/}
                    </Card>
                </div>


                <Notification />
            </div>
        </ThemeProvider>
    );
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

Login.defaultProps = {
    theme: defaultTheme,
    children: <LoginForm />,
};

export default Login;
