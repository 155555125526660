
import { makeStyles } from '@material-ui/core/styles';

const useListStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

export default useListStyles