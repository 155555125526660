import Icon from '@material-ui/icons/Public';
import GasRegionList from './GasRegionList';
import GasRegionCreate from './GasRegionCreate';
import GasRegionEdit from './GasRegionEdit';
import GasRegionShow from './GasRegionShow';

const GasRegionIcon = Icon;


export {
    GasRegionIcon,
    GasRegionList,
    GasRegionShow,
    GasRegionCreate,
    GasRegionEdit,
};
