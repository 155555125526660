import { orderBy } from 'lodash';
import moment from 'moment'
import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';



const customBuildQuery = (fetchType, resource, params) => {

    const { id, data, previousData } = params;

    const start = moment().subtract(30, "days").unix();
    const end = moment().unix();

    if (resource === 'MetricGasMeter') {
        switch (fetchType) {
            case GET_MANY: {
                return {
                    query: `query_range?query=askug_convertItem_convert_lastupdate{dbid=~"${params.ids.join('|')}"}&start=${start}&end=${end}&step=4838`,
                    //query: 'query?query=askug_convertItem_convert_lastupdate',
                    //variables: `dbid=~"${params.ids.join('|')}"`,
                    parseResponse: response => {
                        const { result } = response;
                                                          
                        const items = result.map(item => ({
                            id: item.metric.dbid,
                            dbid: item.metric.dbid,
                            updatedAt: moment.unix((orderBy(item.values, [0], "desc")[0] || [])[1]).format()
                        }));
                        
                        /*const items = result.map(item => ({
                            id: item.metric.dbid,
                            dbid: item.metric.dbid,
                            updatedAt: moment.unix(Number(item.value[1])).format()
                        }));*/

                        return {
                            data: items
                        };
                    },
                };
                break;
            }
            default: break;
        }
    };

};

export default customBuildQuery;
