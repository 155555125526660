import Icon from '@material-ui/icons/Info';
import AboutAppShow from './AboutAppShow';

const AboutAppIcon = Icon;


export {
    AboutAppIcon,
    AboutAppShow,
};
