import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="m12 13 8-5V6l-8 5-8-5v2l8 5ZM4 4h16a2 2 0 0 1 2 2v6.1A7 7 0 1 1 10.68 20H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Zm17.77 14.5a5 5 0 1 1-9.55-3 5 5 0 0 1 9.55 3Zm-4.27-1.68 2.44 1.41-.75 1.3L16 17.69V14h1.5v2.82Z" />
    </SvgIcon>
  );
}

export default Icon;