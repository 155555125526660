import React from 'react';
import url from 'url';
import useDashboardController from '../../controller/dashboard/useDashboardController';
import { DashboardView } from '../Layout/Dashboard';
import TitleForRecord from '../Layout/TitleForRecord';
import Breadcrumbs from '../Layout/Breadcrumbs';
import Breadcrumb from '../Layout/Breadcrumb';




const DashboardBreadcrumbs = ({ target, ...props }) => {
    return (
        <Breadcrumbs resource={target.resource} data={{ id: target.id }}>
            <Breadcrumb resource={"GasRegion"} />
            <Breadcrumb resource={"GasDistrict"} />
            <Breadcrumb resource={"GasConsumer"} />
            <Breadcrumb resource={"GasMeterPad"} />
            <Breadcrumb resource={"GasMeterUnit"} />
        </Breadcrumbs>
    );
}

const DashboardShow = props => {

    const controllerProps = useDashboardController(props);
    const target = url.parse(props.location.search, true).query;

    return (
        <DashboardView
            breadcrumbs={<DashboardBreadcrumbs target={target} />}
            title={<TitleForRecord source="name" {...props} />}
            {...props}
            {...controllerProps}
        />
    );
};

export default DashboardShow;