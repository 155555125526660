import React from 'react';
import MaskedInput from 'react-text-mask';
import { regex, TextInput } from 'react-admin';


const validateIP = [regex(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/, 'ra.validation.ip_invalid')];

const IPMaskInput = ({ ...props }) => {
  const { inputRef, ...other } = props;
  const mask = value => Array(value.length).fill(/[\d.]/);
  const pipe = value => {
    if (value === '.' || value.endsWith('..')) return false;

    const parts = value.split('.');

    if (
      parts.length > 4 ||
      parts.some(part => part === '00' || part < 0 || part > 255)
    ) {
      return false;
    }

    return value;
  };

  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null) }}
      //   mask={[/[0-2]/,/\d/, /\d/, '.', /[0-2]/, /\d/, /\d/, '.', /[0-2]/, /\d/, /\d/, '.', /[0-2]/, /\d/, /\d/]}
      mask={mask}
      pipe={pipe}
      keepCharPositions={true}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
};


const IPInput = (props) => {
  return (
    <TextInput
      {...props}
      validate={validateIP}
      InputProps={{
        inputComponent: IPMaskInput,
      }}
    />
  )
};

export default IPInput;
