import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useInput, FieldTitle, InputHelperText, regex } from 'react-admin';
import cronstrue from 'cronstrue/i18n';



const croneValidation = (value, allValues) => {
  if (!value) return undefined;
  try {
    cronstrue.toString(value);
  } catch (error) {
    return error.replace("Error: ", "");
  }
  return undefined;
};

const getCronHumanizeLabel = (value) => {
  if (!value) return undefined
  try {

    const cronHumanize = cronstrue.toString(value, {
      // throwExceptionOnParseError: false,
      use24HourTimeFormat: true,
      verbose: true,
      locale: "ru",
    });

    return cronHumanize

  } catch (err) {

    const a = err
  }
};

const filter = createFilterOptions();

const CronInput = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  resource,
  source,
  validate = [],
  variant = 'filled',
  inputProps,
  className,
  optionValue = "id",
  optionText = "name",
  options = [],
  ...rest
}) => {

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    validate: [...validate, croneValidation],
    ...rest,
  });

  // let cronHumanize;
  // let cronError;

  // try {

  //   cronHumanize = cronstrue.toString(input.value, {
  //     // throwExceptionOnParseError: false,
  //     use24HourTimeFormat: true,
  //     verbose: true,
  //     locale: "ru",
  //   });

  // } catch (err) {

  //   const a = err
  // }


  const handleChange = useCallback((event, newValue) => {
    if (typeof newValue === 'string') {
      input.onChange(newValue)
    } else {
      input.onChange(newValue ? newValue[optionValue] : newValue)
    }
  }, [])

  const filterOptions = useCallback((options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        id: "OTHER",
        name: params.inputValue,
        cron: params.inputValue
      });
    }

    return filtered;
  }, [])

  const getOptionLabel = useCallback((option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[optionText];
  }, [])

  const renderOption = useCallback((option) => option[optionText], [])

  return (
    <Autocomplete
      {...input}
      onChange={handleChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      style={{ width: 300 }}
      // freeSolo
      renderInput={(params) => {

        const cronHumanize = getCronHumanizeLabel(params.inputProps.value)

        return (
          <TextField
            {...params}
            className={className}
            variant={variant}
            error={!!(touched && error)}
            helperText={
              (touched && error) || helperText || cronHumanize ? (
                <InputHelperText
                  touched={touched}
                  error={error}
                  helperText={helperText || cronHumanize}
                />
              ) : null
            }
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            margin={margin}

          />
        )
      }}
    />
  );
};


export default CronInput;
