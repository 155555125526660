import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';



export default ({records, resource, ...props}) => {
    console.log(records)
    return apolloCoreClient.mutate({
        mutation: gql`
            mutation updateManyTags{
                ${ records.map((record, index) => `data${index}: update${resource}(where: { id: "${record.id}"}, tags: [${record.tags && record.tags.length ? record.tags.map(tag => `{ id: "${tag.id}" }` ) : ''}]){ id name tags{ id name } }`).join(', ')}
            }
        `,
        variables: { },
        onError: (error) => {
            alert(error)
        }
    });
};
