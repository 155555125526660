import { ResourceContextProvider, AutocompleteInput, Datagrid, DateInput, DateField, ListContextProvider, ListView, TextField, ReferenceInput, useListController } from 'react-admin';
import { can, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
import RowToolbar from "../Layout/RowToolbar";
import BulkDeleteButton from "../Buttons/BulkDeleteButton";
import BulkRestoreButton from '../Buttons/BulkRestoreButton';
import { DeleteButton } from 'react-admin';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EnumField from '../Fields/EnumField';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import React from 'react';
import RestoreButton from '../Buttons/RestoreButton';
import SearchInput from '../Inputs/SearchInput'
import ResourceLinkField from '../Fields/ResourceLinkField';
import { dateTimeOptions } from '../../utils';
import useListStyles from '../Layout/list/useListStyles';


const matchTrue = () => true;

const TrashItemBulkActionButtons = props => (
    <>
        {/*<BulkExportButton {...props} />*/}
        {can("restore", "TrashItem") && <BulkRestoreButton {...props} />}
        {can("delete", "TrashItem") && <BulkDeleteButton {...props} icon={<DeleteForeverIcon />} />}

    </>
);

const TrashItemFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput source="deletedBy.id" label="resources.TrashItem.fields.deletedBy" reference="User" sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty emptyText="ra.message.no">
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
        </ReferenceInput>
        <DateInput source="deletedAt_gte" />
        <DateInput source="deletedAt_lte" />
    </ManualSubmitFilter>
);

// const redirectToItem = (id, basePath, record) => {

//     return `/${record.resource}/${record.item}/show`
// };


const EmbededRestoreButton = (props) => {
    return (
        <ResourceContextProvider value={props.record.resource}>
            <RestoreButton {...props} resource={props.record.resource} record={{ id: props.record.item }} variant="text" />
        </ResourceContextProvider>
    );
};

const EmbededDeleteButton = (props) => {
    return (
        <ResourceContextProvider value={props.record.resource}>
            <DeleteButton {...props} resource={props.record.resource} record={{ id: props.record.item }} icon={<DeleteForeverIcon />} variant="text" />
        </ResourceContextProvider>
    );
};


const TrashItemList = props => {
    const filter = { resource_in: ["GasRegion", "GasDistrict", "BalanceRegion", "GasDistributorOrganization", "GasDistributorStation", "GasDistributorStationOutput", "GasConsumer", "GasMeterPad", "GasMeterUnit", "GasMeterPoint", "GasQualityCertificate", "Counterparty", "User"].filter((resource) => can('getList', resource)) };
    const controllerProps = useListController({ ...props, filter });
    const classes = useListStyles();
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContextProvider value={controllerProps}>
            <ListView
                filters={<TrashItemFilter formRef={manualSubmitForm} />}
                bulkActionButtons={<TrashItemBulkActionButtons />}
                exporter={false}
                filter={filter}
                {...props}
            >
                <Datagrid /*rowClick={redirectToItem}*/ classes={{ row: classes.row }}>
                    <EnumField source="resource" />
                    <ResourceLinkField source="name" target="_blank" />
                    <TextField source="description" />
                    <TextField source="deletedBy.name" label="resources.TrashItem.fields.deletedBy" sortable={false} />
                    <DateField source="deletedAt" options={dateTimeOptions} />
                    <RowToolbar textAlign="right">
                        <EmbededRestoreButton />
                        <EmbededDeleteButton />
                    </RowToolbar>
                </Datagrid>
            </ListView>
        </ListContextProvider>
    )
};

TrashItemList.defaultProps = {
    perPage: 50,
    sort: { field: 'deletedAt', order: 'DESC' },
};

export default TrashItemList;