import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 3l5.35 4.013a4.947 4.947 0 00-3.911 1.51A5.17 5.17 0 0012 12.11c0 1.186.404 2.34 1.148 3.26-.974.323-1.9.79-2.635 1.432C9.703 17.51 9 18.554 9 19.889V21H4V9l8-6zm5 6c.796 0 1.559.328 2.121.911a3.17 3.17 0 01.879 2.2 3.17 3.17 0 01-.879 2.2 2.947 2.947 0 01-2.121.911 2.947 2.947 0 01-2.121-.911 3.17 3.17 0 01-.879-2.2c0-.825.316-1.616.879-2.2A2.947 2.947 0 0117 9zm3 8.198c0 .824-.21 2.745-1.642 4.892l-.608-3.757.705-1.462A12.506 12.506 0 0017 16.778c-.503 0-.99.039-1.455.093l.705 1.462-.607 3.757C14.21 19.943 14 18.022 14 17.198c-1.793.544-3 1.524-3 2.69V23h12v-3.111c0-1.167-1.2-2.147-3-2.691z" />
    </SvgIcon>
  );
}

export default Icon;