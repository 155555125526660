import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { useNotify, useTranslate, useRefresh, fetchStart, fetchEnd, Button } from 'react-admin';
import getWriteValues from './getGasQualityCertificateWriteValues';
import writeGasQualityCertificate from '../../dataProvider/Databus/writeGasQualityCertificate';
import addHistory from '../../dataProvider/Core/addHistory';
import MButton from '@material-ui/core/Button';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";




const useStyles = makeStyles(theme => ({
}));



const GasQualityCertificateWriteButton = ({ basePath, className, record, resource, ...props }) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    if (!record) return null;


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleWrite = async () => {
        setOpen(false);
        // dispatch(fetchStart());

        const { writeTags } = await getWriteValues(record)

        if (writeTags && Array.isArray(writeTags) && Boolean(writeTags.length)) {
            writeGasQualityCertificate({ writeTags })
                .then(({ data }) => {

                    notify('ra.notification.gas_quality_certificate_written', 'info', { smart_count: 1 });
                    addHistory({
                        resource: resource,
                        item: record.id,
                        name: record.name,
                        description: record.description,
                        action: "WRITTEN",
                        data: {
                            data: {
                                writeTags: data.data
                            },
                        }
                    });
                })
                .catch((error) => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
                })
                .finally(() => {
                    dispatch(fetchEnd());
                });
        } else {
            notify('ra.notification.gas_quality_certificate_write_error', 'warning', { smart_count: 1 });
            dispatch(fetchEnd());
        }

    };

    return (
        <>
            <Button
                onClick={handleClickOpen}
                label='ra.action.write_GasQualityCertificate'
                {...props}
            >
                <SendIcon />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="writeGasQualityCertificate-dialog-title"
                aria-describedby="writeGasQualityCertificate-dialog-description"
            >
                <DialogTitle id="writeGasQualityCertificate-dialog-title">
                    {translate("resources.GasQualityCertificate.writeDialogTitle", { smart_count: 1 })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="writeGasQualityCertificate-dialog-description">
                        {translate("resources.GasQualityCertificate.writeDialogContent", { smart_count: 1 })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MButton onClick={handleClose} >{translate('ra.action.cancel')}</MButton>
                    <MButton onClick={handleWrite} color="primary" autoFocus>{translate('ra.action.write')}</MButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GasQualityCertificateWriteButton;

