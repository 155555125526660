import get from 'lodash/get';
import apolloClient from './apolloClient';
import { REFRESH_DEVICESTATUS } from './typeDefs';



export default ({ dbId }) => {
    return apolloClient.mutate({
        mutation: REFRESH_DEVICESTATUS,
        variables: { dbIds: [dbId] },
        onError: (error) => {
            alert(error)
        }
    }).then(response => {
        const item = get(response, ["data", "data", "items", 0])
        return {
            data: item
        }
    });
};