import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MagicWand from '../Icons/MagicWand';



const useStyles = makeStyles(theme => ({
    cardActionArea: {
        height: "100%"
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: theme.palette.text.primary,
        backgroundColor: "#ffcd38",
    },
}));

const DeviceCreateWizardCard = ({ className, classes: classesOverride, onClick, ...props }) => {
    const classes = useStyles({ classes: classesOverride });

    return (
        <Card className={className} >
            <CardActionArea
                onClick={onClick}
                className={classes.cardActionArea}
            >
                <CardHeader
                    avatar={<Avatar aria-label="wizard" variant="rounded" className={classes.avatar}>
                        <MagicWand />
                    </Avatar>}
                    title="Мастер создания Устройства"
                    titleTypographyProps={{ variant: "subtitle1" }}
                    classes={{ title: classes.title }}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Создайте УУГ, Контроллер, Корректор и связи между ними в одном месте!
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default DeviceCreateWizardCard;