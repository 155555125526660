import React from 'react';
import { ReferenceArrayInput, ReferenceInput, TextField, TextInput, useTranslate } from 'react-admin';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import AutocompleteInput from '../Inputs/AutoompleteInput';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import SearchInput from '../Inputs/SearchInput';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import { can } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';
import { matchTrue } from '../../utils';



const useStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}))

const GasDistributorStationFilter = (props) => {
    const classes = useStyles()
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn />
            {can('getList', 'GasDistributorStation', 'aisId') && <TextInput source="aisId" alwaysOn resettable className={classes.mediumWidth} />}
            {can('getList', 'GasDistributorStation', 'balanceRegion', 'BalanceRegion') && <ReferenceInput source="balanceRegionReference" label="resources.GasDistributorStation.fields.balanceRegion" reference="BalanceRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasDistributorStation', 'gasQualityCertificate', 'GasQualityCertificate') && <ReferenceInput source="gasQualityCertificate_some" label="resources.GasDistributorStation.fields.gasQualityCertificate" reference="GasQualityCertificate" sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'GasDistributorStation', 'tags', 'Tag') && <ReferenceArrayInput source="tags_and" label="resources.Tag.fields.tags_and" reference="Tag" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false}>
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasDistributorStation', 'tags', 'Tag') && <ReferenceArrayInput source="tags_not_and" label="resources.Tag.fields.tags_not_and" reference="Tag" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'GasDistributorStation', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_and" label="resources.Report.fields.reports_and" reference="Report" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
            {can('getList', 'GasDistributorStation', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_not_and" label="resources.Report.fields.reports_not_and" reference="Report" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
        </ManualSubmitFilter>
    )
}


export default GasDistributorStationFilter;