import React, { Fragment } from 'react';
import { BulkExportButton } from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import BulkUpdateTagsButton from "../Buttons/BulkUpdateTagsButton";
import { can } from "../../abilityProvider";



const GasMeterUnitBulkActionButtons = props => (
    <Fragment>
        {can('update', 'GasMeterUnit', 'tags', 'Tag') && <BulkUpdateTagsButton {...props} />}
        {can('getList', 'GasMeterUnit') && <BulkExportButton {...props} />}
        {can('remove', 'GasMeterUnit') && <BulkRemoveButton {...props} />}
    </Fragment>
);


export default GasMeterUnitBulkActionButtons;