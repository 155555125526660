import { useMediaQuery } from '@material-ui/core';
import { Datagrid, SimpleList, DateField, TextField } from 'react-admin';
import { can } from '../../abilityProvider';
import AvatarField from '../Fields/AvatarField';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import EnumField from '../Fields/EnumField';
import MoreButton from "../Buttons/MoreButton";
import ResourceLinkField from '../Fields/ResourceLinkField';
import OnlineBadge from '../Fields/OnlineBadge';
import PhoneField from '../Fields/PhoneField';
import React from 'react';
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import { dateTimeOptions } from '../../utils';
import useListStyles from '../Layout/list/useListStyles';




const UserDatagrid = (props) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useListStyles();

    return isMobile ? (
        <SimpleList
            primaryText={record => record.name}
            linkType="show"
        />
    ) : (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            <OnlineBadge>
                <AvatarField size="small" />
            </OnlineBadge>
            <ResourceLinkField source="name" target="_blank" />
            <TextField source="description" />
            {can('getList', 'User', 'role') && <EnumField source="role" />}
            {can('getList', 'User', 'login') && <TextField source="login" />}
            {can('getList', 'User', 'phone') && <PhoneField source="phone" />}
            {can('getList', 'User', 'email') && <TextField source="email" />}
            <DateField source="lastLogin" textAlign="right" cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} options={dateTimeOptions} />
            <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
            <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {can('getOne', 'User') && <ShowBlankItem />}
                {can('update', 'User') && <EditMenuItem />}
                {can('remove', 'User') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    )
}



export default UserDatagrid;