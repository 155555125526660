import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';


const useStyles = makeStyles(theme => ({
    
}));

const MenuItemIcon = ({
    icon,
    ...props
}) => {
    return (<ListItemIcon {...props}>{icon}</ListItemIcon>)
}


export default MenuItemIcon;