import { useVersion, useTranslate, useNotify } from 'react-admin';
import useDashboard from '../../dataProvider/Core/useDashboard';



const useDashboardController = (props) => {

  const { basePath, id, resource } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const version = useVersion();
  const { data: record, loading, loaded, error } = useDashboard(resource, id, version);

  if (error) notify('ra.notification.item_doesnt_exist', 'warning');

  const defaultTitle = translate('resources.Dashboard.name', { smart_count: 1 });

  return {
    loading,
    loaded,
    defaultTitle,
    resource,
    basePath,
    record,
    version,
  };
};

export default useDashboardController;
