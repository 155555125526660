import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <rect width="24" height="24" rx="4" ry="4" fill="#316fc4"></rect>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.88 20.52A7.23 7.23 0 0 0 20 14.65C20 10.55 12 3 12 3s-8 7.55-8 11.65c0 2.39 1.22 4.52 3.12 5.87a5.2 5.2 0 0 1-.83-2.81c0-.99.42-1.91.79-2.56.4-.7.9-1.4 1.37-1.98l1.29-1.48L12 9.45l2.26 2.24a26.3 26.3 0 0 1 2.1 2.58c.2.28.4.58.56.88.37.65.8 1.57.8 2.56a5.2 5.2 0 0 1-.84 2.81ZM12 21c1.9 0 3.43-1.47 3.43-3.3 0-1.81-3.43-5.17-3.43-5.17s-3.43 3.36-3.43 5.18C8.57 19.53 10.11 21 12 21Z" fill="#fff" />
    </SvgIcon>
  );
}

export default Icon;