import React from 'react';
import Chip from '@material-ui/core/Chip';
import ReportIcon from './ReportIcon';
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    root: {},
}));

const ReportChip = ({ className, classes: classesOverride, record, onDelete, ...props }) => {
    const translate = useTranslate()
    const classes = useStyles({ classes: classesOverride });
    if (!record) return null;
    const { name, resource, gasMeterType } = record
    const label = `${name} ${translate(`resources.${resource}.menu`, { smart_count: 1 })}${gasMeterType ? ` ${translate(`resources.GasMeter.enums.type.${gasMeterType}`)}` : ""}`

    return (
        <Chip
            classes={{ root: classes.root }}
            icon={<ReportIcon record={record} />}
            label={label}
            className={className}
            onDelete={onDelete}
        />
    );
};

export default ReportChip;