import { ReferenceArrayInput, TextInput } from 'react-admin';
import AutocompleteArrayInput from "../Inputs/AutocompleteArrayInput";
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import React from 'react';
import SearchInput from '../Inputs/SearchInput';
import ReportReferenceArrayInput from '../Report/ReportReferenceArrayInput';
import { can } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';
import { matchTrue } from '../../utils';



const useStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}))

const BalanceRegionFilter = (props) => {
    const classes = useStyles()
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn />
            {can('getList', 'BalanceRegion', 'aisId') && <TextInput source="aisId" alwaysOn resettable className={classes.mediumWidth} />}
            {/* {can('getList', 'BalanceRegion', 'tags', 'Tag') && <ReferenceInput source="tags_some.id" label="resources.Tag.fields.tag_some" reference="Tag" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {can('getList', 'BalanceRegion', 'tags', 'Tag') && <ReferenceInput source="tags_none.id" label="resources.Tag.fields.tag_none" reference="Tag" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>} */}
            {can('getList', 'BalanceRegion', 'tags', 'Tag') && <ReferenceArrayInput source="tags_and" label="resources.Tag.fields.tags_and" reference="Tag" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false}>
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {can('getList', 'BalanceRegion', 'tags', 'Tag') && <ReferenceArrayInput source="tags_not_and" label="resources.Tag.fields.tags_not_and" reference="Tag" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} >
                <AutocompleteArrayInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}

            {can('getList', 'BalanceRegion', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_and" label="resources.Report.fields.reports_and" reference="Report" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}
            {can('getList', 'BalanceRegion', 'reports', 'Report') && <ReportReferenceArrayInput source="reports_not_and" label="resources.Report.fields.reports_not_and" reference="Report" filter={{ resource: "BalanceRegion" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} allowEmpty={false} />}

        </ManualSubmitFilter>
    )
}



export default BalanceRegionFilter