import { DateField, ArrayField, SingleFieldList, ChipField, ReferenceField, ReferenceArrayField, TextField, TopToolbar, EditButton } from 'react-admin';
import CardShowLayout from '../Layout/CardShowLayout';
import MoreContent from '../Layout/MoreContent';
import BooleanField from '../Fields/BooleanField';
import CronField from '../Fields/CronField';
import EnumField from '../Fields/EnumField';
import React from 'react';
import Separator from '../Layout/Separator';
import { Show } from '../Layout/Show';
import TitleForRecord from '../Layout/TitleForRecord';
import { can } from "../../abilityProvider";
import { dateTimeOptions } from '../../utils';
import ContactChip from '../Contact/ContactChip';
import ReportChip from '../Report/ReportChip';
import RunNowReportDespatchButton from '../Buttons/RunNowReportDespatchButton';




const ReportDespatchShowActions = ({ basePath, className, data, hasEdit, hasList, resource, ...props }) => {


    return (
        <TopToolbar {...props}>
            {can('update', 'ReportDespatch') && <RunNowReportDespatchButton resource={resource} basePath={basePath} record={data} />}
            {can('update', 'ReportDespatch') && <EditButton resource={resource} basePath={basePath} record={data} />}
        </TopToolbar>
    )
};

const ReportDespatchShow = props => {
    const showMoreButton = React.useRef(null);

    return (
        <Show
            actions={<ReportDespatchShowActions />}
            title={<TitleForRecord source="name" {...props} />}
            {...props}
        >
            <CardShowLayout>
                {can('getOne', 'ReportDespatch', 'name') && <TextField source="name" />}
                {can('getOne', 'ReportDespatch', 'description') && <TextField source="description" />}
                {can('getOne', 'ReportDespatch', 'to') && <ArrayField source="to">
                    <SingleFieldList linkType={false}>
                        <ContactChip source="email" />
                    </SingleFieldList>
                </ArrayField>}
                {can('getOne', 'ReportDespatch', 'cc') && <ArrayField source="cc">
                    <SingleFieldList linkType={false} >
                        <ContactChip source="email" />
                    </SingleFieldList>
                </ArrayField>}
                {can('getOne', 'ReportDespatch', 'bcc') && <ArrayField source="bcc">
                    <SingleFieldList linkType={false}>
                        <ContactChip source="email" />
                    </SingleFieldList>
                </ArrayField>}
                {can('getOne', 'ReportDespatch', 'reportsIds', "Report") && <ReferenceArrayField source="reportsIds" reference="Report" label="resources.ReportDespatch.fields.reports">
                    <SingleFieldList linkType={false}>
                        <ReportChip source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>}
                {can('getOne', 'ReportDespatch', 'rangeOption') && <EnumField source="rangeOption" />}
                {can('getOne', 'ReportDespatch', 'cron') && <CronField source="cron" label="resources.ReportDespatch.fields.scheduler" />}
                {can('getOne', 'ReportDespatch', 'lastExecutionAt') && <DateField source="lastExecutionAt" options={dateTimeOptions} />}
                {can('getOne', 'ReportDespatch', 'nextExecutionAt') && <DateField source="nextExecutionAt" options={dateTimeOptions} />}
                {can('getOne', 'ReportDespatch', 'enabled') && <BooleanField source="enabled" valueLabelTrue={'resources.ReportDespatch.enums.enabled.true'} valueLabelFalse={'resources.ReportDespatch.enums.enabled.false'} label={'resources.ReportDespatch.fields.state'} />}
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {can('getOne', 'ReportDespatch', 'id') && <TextField source="id" />}
                    {can('getOne', 'ReportDespatch', 'createdById', 'User') && <ReferenceField source="createdById" reference="AuthUser" label="resources.ReportDespatch.fields.createdBy" link={false}>
                        <TextField source="name" />
                    </ReferenceField>}
                    {can('getOne', 'ReportDespatch', 'createdAt') && <DateField source="createdAt" options={dateTimeOptions} />}
                    {can('getOne', 'ReportDespatch', 'updatedAt') && <DateField source="updatedAt" options={dateTimeOptions} />}
                </CardShowLayout>
            </MoreContent>
        </Show>
    );
};



export default ReportDespatchShow;