import React, { Children, cloneElement } from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
import GridLayoutSimple from '../Layout/GridLayoutSimple';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    gridItem: {
        display: "flex",
    },
}));

const NestedGridField = ({ children, classes: classesOverride, record, source, resource, basePath, version, className, label, ...props }) => {
    const choices = get(record, source);
    const referenceRecord = record
    if (!record || !choices.length) return null;

    return (
        <GridLayoutSimple xs={{ count: "auto-fill", size: [32, "1fr"] }} label={label} style={{ padding: 16 }}>
            {choices.map(choice =>
                cloneElement(Children.only(children), {
                    key: choice.id,
                    record: choice,
                    resource,
                    basePath,
                    version,
                    referenceRecord,
                })
            )}
        </GridLayoutSimple>
    )
};


export default NestedGridField;