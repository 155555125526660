import russianMessages from 'ra-language-russian'
import raFormLayoutLanguageRussian from './raFormLayoutLanguageRussian'
import raPreferencesLanguageRussian from './raPreferencesLanguageRussian'

const resourcesLanguageRussian = {
    resources: {
        GasRegion: {
            name: 'Регион |||| Регионы',
            menu: 'Регион |||| Регионы',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasDistricts: 'Участки',
                reports: 'Отчеты',
                tags: 'Метки',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
            },
            empty: 'Регионы отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasDistrict: {
            name: 'Абонентский участок |||| Абонентские участки',
            menu: 'Участок |||| Участки',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasRegion: 'Регион',
                gasConsumer: 'Потребитель',
                gasMeterPads: 'Площадки',
                gasConsumers: 'Потребители',
                reports: 'Отчеты',
                tags: 'Метки',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
            },
            empty: 'Абонентские участки отсутствуют',
            invite: 'Вы хотите создать?',
        },
        BalanceRegion: {
            name: 'Балансовая зона |||| Балансовые зоны',
            menu: 'Балансовая зона |||| Балансовые зоны',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                aisId: 'ID АИС',
                gasDistributorStations: 'ГРС',
                reports: 'Отчеты',
                tags: 'Метки',
            },
            empty: ' Балансовые зоны отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasDistributorOrganization: {
            name: 'Газораспределительная организация |||| Газораспределительные организации',
            menu: 'ГРО |||| ГРО',
            fields: {
                id: 'ID',
                name: 'Название',
                addrManual: 'Адрес',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasConsumers: 'Потребители',
                gasMeterPads: 'Площадки',
                reports: 'Отчеты',
                tags: 'Метки',
                gasDistrict: 'Участки',
            },
            empty: 'Газораспределительные организации отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasDistributorStation: {
            name: 'Газораспределительная станция |||| Газораспределительные станции',
            menu: 'ГРС |||| ГРС',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                addrManual: 'Адрес',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
                balanceRegion: 'Балансовая зона',
                gasConsumers: 'Потребители',
                gasMeterPads: 'Площадки',
                gasDistributorStationOutputs: 'Выходы ГРС',
                gasQualityCertificate: 'Паспорт качества газа',
                gasQualityCertificates: 'Паспорта качества газа',
                reports: 'Отчеты',
                tags: 'Метки',
            },
            empty: 'Газораспределительные станции отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasDistributorStationOutput: {
            name: 'Выход газораспределительной станции |||| Выходы газораспределительных станций',
            menu: 'Выход ГРС |||| Выходы ГРС',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                gasDistributorStation: 'ГРС',
                gasMeterPads: 'Площадки',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
                reports: 'Отчеты',
                tags: 'Метки',
            },
            empty: 'Выходы газораспределительных станций отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasConsumer: {
            name: 'Потребитель |||| Потребители',
            menu: 'Потребитель |||| Потребители',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasMeterPads: 'Площадки',
                gasDistrict: 'Участок',
                gasDistricts: 'Участки',
                gasDistributorOrganization: 'ГРО',
                gasDistributorStation: 'ГРС',
                reports: 'Отчеты',
                tags: 'Метки',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
                addrManual: 'Адрес',
                gasMeterPad: 'Площадка',
                gasMeterPadAisId: 'ID АИС Площадки',
            },
            empty: 'Потребители отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasMeterPad: {
            name: 'Площадка газопотребления |||| Площадки газопотребления',
            menu: 'Площадка |||| Площадки',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasConsumer: 'Потребитель',
                gasDistrict: 'Участок',
                gasDistributorOrganization: 'ГРО',
                gasDistributorStation: 'ГРС',
                gasDistributorStationOutput: 'Выход ГРС',
                gasMeterUnits: 'УУГ',
                reports: 'Отчеты',
                tags: 'Метки',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
                addrManual: 'Адрес',
                dateFrom: 'Дата с',
                dateTo: 'Дата по',
                disabled: 'Отключена',
                disabledPeriods: 'Периоды отключения площадки',
                isProvider: "Является поставщиком"
            },
            empty: 'Площадки газопотребления отсутствуют',
            invite: 'Вы хотите создать?',
        },
        DisabledPeriod: {
            fields: {
                id: 'ID',
                dateFrom: 'Дата с',
                dateTo: 'Дата по',
                description: 'Описание',
            },
        },
        GasMeterUnit: {
            name: 'Узел учета газа |||| Узлы учета газа',
            menu: 'УУГ |||| УУГ',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                dashboards: 'Информационные панели',
                reports: 'Отчеты',
                tags: 'Метки',
                gasMeterPad: 'Площадка',
                gasMeterPoints: 'Линии',
                aisId: 'ID АИС',
                id_ais_regiongaz: 'ID АИС Регионгаз',
                lastTimeUpdatedAt: 'Данные',
                current_variable: 'Текущие значения',
                gas_quality_certificate: 'Паспорт газа',
                number: 'Номер линии',
                disabled: 'Отключена',
                inventoryNumber: 'Инвентарный номер',
                enableWriteGasQualityCertificate: 'Запись паспорта газа',
                gasQualityCertificates: 'Паспорта качества газа',
                dasMeterPadAisId: 'ID АИС Площадки',
                fixedAsset: 'Основное средство',
                linkToir: 'Ссылка на объект ТОИР',
                controller: 'Контроллер',
                "controller.type": 'Тип контроллера',
                gasMeter: 'Корректор',
                "gasMeter.type": 'Тип корректора',
                fixedAssetShort: 'Основное средство',
                linkToDevice: 'Ссылка на Устройство',
                deviceStatus: 'Статус',
                metrics: "Метрики"
            },
            empty: 'Узлы учета газа отсутствуют',
            invite: 'Вы хотите создать?',
        },
        Controller: {
            fields: {
                id: 'ID',
                alias_contains: 'Псевдоним',
                name: 'Название',
                description: 'Описание',
                serialNumber: 'S/N, Серийный номер',
                sn: 'S/N',
                phoneNumber: 'Номер телефона',
                phoneNumber_is_null: 'Номер телефона отсутствует',
                ip: 'IP адрес',
                ip_is_null: 'IP адрес отсутствует',
                url: 'URL адрес',
                type: 'Тип',
                configuration: 'Конфигурация',
                status: 'Статус'
            },
            enums: {
                type: {
                    TELEOFIS: 'ТЕЛЕОФИС',
                    AXI1: 'АКСИ-1',
                    AXI2: 'АКСИ-2',
                    AXITEL: 'КАМ25А',
                    AXI1MR: 'АКСИ-1МР',
                    AXIS: 'АКСИС',
                    KAM200: 'КАМ-200',
                    KAM25: 'КАМ25',
                    KAM214: 'КАМ214',
                    FX: 'FX',
                    WS: 'WS',
                    BECKHOFF: 'Beckhoff',
                    AXITELJSONRPC: 'АКСИТЕЛ JSON-RPC',
                },
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    ACTIVE: 'Активен',
                    SWAP: 'Заменен',
                },
            },
        },
        ControllerConfiguration: {
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    RELEASE: 'Релиз',
                },
            },
        },
        GasMeter: {
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                serialNumber: 'S/N, Серийный номер',
                dbId: 'ID Шины',
                ip: 'IP адрес',
                port: 'Порт',
                commercialHour: 'Коммерческий час',
                dst: 'Переход на летнее время',
                meta: 'meta',
                timeZone: 'Часовой пояс',
                type: 'Тип',
                configuration: 'Конфигурация',
                status: 'Статус',
            },
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    ACTIVE: 'Активен',
                    SWAP: 'Заменен',
                },
                type: {
                    BK: 'БК',
                    EK260: 'EK-260',
                    EK270: 'EK-270',
                    EK280: 'EK-280',
                    EK290: 'EK-290',
                    ELSTERSERVICE: 'Сервис Elster',
                    IRVISRS4ULTRA: 'ИРВИС-РС4-УЛЬТРА',
                    IRVISRSK300: 'ИРВИС-K-300',
                    VKG2: 'ВКГ-2',
                    VKG3T: 'ВКГ-3Т',
                    VKG3D: 'ВКГ-3Д',
                    SPG741: 'СПГ-741',
                    SPG742: 'СПГ-742',
                    SPG761: 'СПГ-761',
                    GASDEVICESERVICE: 'Сервис Газдевайс',
                    GASDEVICEOMEGAEK: 'Газдевайс ОМЕГА ЭК',
                    GIPERFLOW3PM: 'ГиперФлоу-ЗПм',
                    GIPERFLOWUS: 'ГиперФлоу-УС',
                    GRANDSPI: 'Гранд SPI',
                    TECHNOMER: 'Техномер',
                    TECHNOMERSERVICE: 'Сервис Техномер',
                    TURBODONSERVICE: 'Сервис Турбулентность-ДОН',
                    SUPERFLOW21V: 'СуперФлоу-21В',
                    SUPERFLOW2E: 'СуперФлоу-2Е',
                    SUPERFLOW2ET: 'СуперФлоу-2ЕТ',
                    FLOBOSS: 'FloBoss',
                    FLOWGAS: 'ФЛОУГАЗ',
                    TC220: 'ТС220',
                    TURBOFLOWUFG: 'Turbo Flow UFG',
                    TURBOFLOWGFG: 'Turbo Flow GFG',
                    TURBOFLOWTFG: 'Turbo Flow TFG',
                    TELEFLOWEGM3530: 'TeleFlow EGM 3530',
                    FLOWSIC600: 'FLOWSIC600',
                    ROSTELECOM: 'Ростелеком',
                    SGT16E: 'СГТ16Э',
                    VYMPEL500: 'Вымпел-500',
                    JSONRPC: 'JSON-RPC',
                    ERZ2000DI: 'ERZ 2000-DI',
                },
            },
        },
        GasMeterConfiguration: {
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    RELEASE: 'Релиз',
                },
            },
        },
        GasMeterPoint: {
            name: 'Линия |||| Линии',
            menu: 'Линия |||| Линии',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                reports: 'Отчеты',
                tags: 'Метки',
                aisId: 'ID АИС',
                number: 'Номер линии',
                season: 'Сезон',
                disabled: 'Отключена',
                state: 'Состояние',
                gasMeterUnit: 'УУГ',

            },
            enums: {
                disabled: {
                    true: 'Отключена',
                    false: 'Включена',
                },
            },
            empty: 'Линии отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasQualityCertificate: {
            name: 'Паспорт качества газа |||| Паспорта качества газа',
            menu: 'Паспорт газа |||| Паспорта газа',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                index: '#',
                number: '№',
                gqsId: 'ID Паспорта газа',
                n2: 'Объемная доля N2',
                n2_short: 'N2',
                co2: 'Объемная доля CO2',
                co2_short: 'CO2',
                density: 'Плотность газа P',
                density_short: 'P',
                validFrom: 'Действует с',
                validTo: 'Действует по',
                gasExtractionPoint: 'Точка отбора проб',
                gasDistributorStations: 'ГРС',
                gasDistributorStation_some: 'ГРС',
                gasMeterUnit_some: 'УУГ',
                reports: 'Отчеты',
                tags: 'Метки',
                writtenAt: 'Отправлен на запись',
            },
            validation: {
                out_of_range: "Значение параметра %{parameter} (%{value}) выходит за пределы допустимого диапазона (%{range}). Паспорт газа %{name}",
            },
            import: {
                title: 'Импорт паспортов качества газа',
                content: 'Утилита создаст Паспорта качества газа из Excel файла. Повторный импорт одного и того же файла приведет к обновлению Паспортов.',
                errors: 'Ошибки',
                warnings: 'Предупреждения',
            },
            empty: 'Паспорта качества газа отсутствуют',
            invite: 'Вы хотите создать или импортировать?',
            writeDialogTitle: 'Запись паспорта качества газа |||| Запись паспортов качества газа |||| Запись паспортов качества газа',
            writeDialogContent: 'Отправить Паспорт качества газа на запись? |||| Отправить %{smart_count} паспорта качества газа на запись? |||| Отправить %{smart_count} паспортов качества газа на запись?',
        },
        Dashboard: {
            name: 'Информационня панель |||| Информационные панели',
            menu: 'Инфопанель |||| Инфопанели',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasDistricts: 'Участки',
                reports: 'Отчеты',
                tags: 'Метки',
                aisId: 'АИС ID',
                dashboards_and: 'Инф. панели',
                dashboards_not_and: 'Инф. панели (НЕ)',
            },
            enums: {
                type: {
                    Hourly: 'Часовой',
                    Daily: 'Суточный',
                    Event: 'События',
                    Summary: 'Сводный',
                    GasQualityCertificate: 'Паспорт качества газа',
                    Other: 'Специальный',
                },
            },
            empty: 'Отчеты отсутствуют',
            invite: 'Вы хотите создать?',
        },
        Report: {
            name: 'Отчет |||| Отчеты',
            menu: 'Отчет |||| Отчеты',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                gasDistricts: 'Участки',
                reports: 'Отчеты',
                reports_and: 'Отчеты',
                reports_not_and: 'Отчеты (НЕ)',
                tags: 'Метки',
                aisId: 'АИС ID',
            },
            enums: {
                type: {
                    Hourly: 'Часовой',
                    Daily: 'Суточный',
                    Event: 'События',
                    Summary: 'Сводный',
                    GasQualityCertificate: 'Паспорт качества газа',
                    Other: 'Специальный',
                },
            },
            empty: 'Отчеты отсутствуют',
            invite: 'Вы хотите создать?',
        },
        ReportDespatch: {
            name: 'Рассылка отчетов |||| Рассылки отчетов',
            menu: 'Рассылка |||| Рассылки',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                from: 'От кого',
                to: 'Кому',
                cc: 'Копия',
                bcc: 'Скрытая копия',
                reports: 'Отчеты',
                scheduler: 'Расписание',
                cron: 'Cron',
                rangeOption: 'Глубина',
                enabled: 'Включена',
                state: 'Состояние',
                lastExecutionAt: 'Последняя отправка',
                nextExecutionAt: 'Следующая отправка',
                reports_not_and: 'Отчеты (НЕ)',
                tags: 'Метки',
                aisId: 'АИС ID',
            },
            enums: {
                type: {
                    Hourly: 'Часовой',
                    Daily: 'Суточный',
                    Event: 'События',
                    Summary: 'Сводный',
                    GasQualityCertificate: 'Паспорт качества газа',
                    Other: 'Специальный',
                },
                enabled: {
                    true: 'Включена',
                    false: 'Отключена',
                },
                rangeOption: {
                    TODAY: 'Сегодня',
                    TODAYSOFAR: 'Сегодня (по текущее время)',
                    THISWEEK: 'Эта неделя',
                    THISWEEKSOFAR: 'Эта неделя (по текущее время)',
                    THISMONTH: 'Этот месяц',
                    THISMONTHSOFAR: 'Этот месяц (по текущее время)',
                    THISYEAR: 'Этот год',
                    THISYEARSOFAR: 'Этот год (по текущее время)',
                    YESTERDAY: 'Вчера',
                    DAYBEFOREYESTERDAY: 'Позавчера',
                    THISDAYLASTWEEK: 'Этот день на прошлой неделе',
                    PREVIOUSWEEK: 'Предыдущая неделя',
                    PREVIOUSMONTH: 'Предыдущий месяц',
                    PREVIOUSQUARTER: 'Предыдущий квартал',
                    PREVIOUSYEAR: 'Предыдущий год',
                    LASTDAY: 'Последний день',
                    LAST2DAYS: 'Последние 2 дня',
                    LAST7DAYS: 'Последние 7 дней',
                    LASTWEEK: 'Последняя неделя',
                    LASTMONTH: 'Последний месяц',
                    LASTQUARTER: 'Последний квартал',
                    LASTYEAR: 'Последний год',
                    LASTCOMMERCIALDAY: 'Последний коммерческий день',
                    LASTCOMMERCIALDAYSOFAR: 'Последний коммерческий день (по текущее время)',
                }
            },
            empty: 'Рассылки отчетов отсутствуют',
            invite: 'Вы хотите создать?',
        },
        Contact: {
            name: 'Контакт |||| Контакты',
            fields: {
                id: 'ID',
                name: 'Наименование',
                email: 'Email',
                user: 'Пользователь',
                userId: 'ID Пользователя',
            },
        },
        Tag: {
            name: 'Метка |||| Метки',
            menu: 'Метка |||| Метки',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                type: 'Тип',
                resource: 'Ресурс',
                color: 'Цвет',
                createdAt: 'Создана',
                createdBy: 'Создал',
                updatedAt: 'Обновлена',
                tag_some: 'Метка',
                tag_none: 'Метка (НЕ)',
                tags_and: 'Метки',
                tags_not_and: 'Метки (НЕ)',
            },
            enums: {
                type: {
                    USER: 'Пользовательская',
                    SYSTEM: 'Системная',
                },
                resource: {
                    GasRegion: 'Регион',
                    GasDistrict: 'Участок',
                    GasDistributorOrganization: 'ГРО',
                    GasDistributorStation: 'ГРС',
                    GasDistributorStationOutput: 'Выход ГРС',
                    GasConsumer: 'Потребитель',
                    GasMeterPad: 'Площадка',
                    GasMeterUnit: 'УУГ',
                    GasQualityCertificate: 'Паспорт газа',
                    User: 'Пользователь',
                },
            },
            empty: 'Метки отсутствуют',
            invite: 'Вы хотите создать?',
        },
        TrashItem: {
            name: 'Корзина',
            menu: 'Корзина',
            fields: {
                id: 'ID',
                resource: 'Ресурс',
                name: 'Название',
                description: 'Описание',
                deletedBy: 'Удалил',
                deletedAt: 'Удален',
                deletedAt_gte: 'C даты',
                deletedAt_lte: 'По дату',
            },
            enums: {
                resource: {
                    GasRegion: 'Регион',
                    GasDistrict: 'Участок',
                    BalanceRegion: 'Балансовая зона',
                    GasDistributorOrganization: 'ГРО',
                    GasDistributorStation: 'ГРС',
                    GasDistributorStationOutput: 'Выход ГРС',
                    GasConsumer: 'Потребитель',
                    GasMeterPad: 'Площадка',
                    GasMeterUnit: 'УУГ',
                    GasMeterPoint: 'Линия',
                    GasQualityCertificate: 'Паспорт газа',
                    Counterparty: 'Контрагент',
                    User: 'Пользователь',
                },
            }
        },
        History: {
            name: 'История',
            menu: 'История',
            fields: {
                id: 'ID',
                resource: 'Ресурс',
                name: 'Название',
                action: 'Действие',
                description: 'Описание',
                'data.data': 'Текущее состояние',
                'data.previous': 'Предыдущее состояние',
                createdAt: 'Дата',
                createdAt_gte: 'C даты',
                createdAt_lte: 'По дату',
                createdBy: 'Пользователь',
            },
            enums: {
                action: {
                    CREATED: 'Создана',
                    UPDATED: 'Обновлена',
                    DELETED: 'Удалена навсегда',
                    REMOVED: 'Удалена',
                    RESTORED: 'Восстановлена',
                    IMPORTED: 'Импортирован',
                    SWAPPED: 'Заменен',
                    WRITTEN: 'Значение записано',
                    LOGIN: 'Вход пользователя',
                    LOGOUT: 'Выход пользователя',
                    INSTALLED: 'Смонтирован',
                    UNINSTALLED: 'Демонтирован',
                },
                resource: {
                    GasRegion: 'Регион',
                    GasDistrict: 'Участок',
                    BalanceRegion: 'Балансовая зона',
                    GasDistributorOrganization: 'ГРО',
                    GasDistributorStation: 'ГРС',
                    GasDistributorStationOutput: 'Выход ГРС',
                    GasConsumer: 'Потребитель',
                    GasMeterPad: 'Площадка',
                    GasMeterUnit: 'УУГ',
                    GasMeterPoint: 'Линия',
                    GasQualityCertificate: 'Паспорт газа',
                    Counterparty: 'Контрагент',
                    User: 'Пользователь',
                },
            },
        },
        Counterparty: {
            name: 'Контрагент |||| Контрагенты',
            menu: 'Контрагент |||| Контрагенты',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                type: 'Тип',
                inn: 'ИНН',
                kpp: 'КПП',
                okpo: 'ОКПО',
                ogrn: 'ОГРН',
                fio: 'Наименование',
                passport: 'Паспорт',
                passdateissue: 'Дата выдачи',
                parentId: 'Родитель',
                addressLegal: 'Юридический адрес',
                addressActual: 'Фактический адрес',
                addressPostal: 'Почтовый адрес',
                phone: 'Телефон',
                phoneMobile: 'Мобильный',
                email: 'Email',
                users: 'Пользователи',
                accessResource: 'Ресурс',
                access: 'Доступ',
                access_is_null: 'Нет доступа',
                filterGasRegion: 'Фильтр по Регионам',
                filterGasDistrict: 'Фильтр по Участкам',
                filterGasConsumer: 'Фильтр по Потребителям',
                filterGasMeterPad: 'Фильтр по Площадкам',
                accessGasRegion: 'Доступ по Регионам',
                accessGasDistrict: 'Доступ по Участкам',
                accessGasConsumer: 'Доступ по Потребителям',
                accessGasMeterPad: 'Доступ по Площадкам',
                accessGasMeterUnit: 'Доступ по УУГ',
                q: 'Поиск',
            },
            enums: {
                type: {
                    LEGALENTITY: 'Юридическое лицо',
                    INDIVIDUAL: 'Физическое лицо',
                    SEPARATEDIVISION: 'Обособленное подразделение',
                    GOVERNMENTAGENCY: 'Гос.орган',
                },
            },
            tabs: {
                general: 'Основное',
                access: 'Доступ',
            },
            empty: 'Контрагенты отсутствуют',
            invite: 'Вы хотите создать?',
        },
        User: {
            name: 'Пользователь |||| Пользователи',
            menu: 'Пользователь |||| Пользователи',
            fields: {
                id: 'ID',
                name: 'Наименование',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                email: 'Email',
                phone: 'Телефон',
                banned: 'Заблокирован',
                phoneContact: 'Телефон для связи',
                role: 'Роль',
                login: 'Логин',
                lastLogin: 'Был в сети',
                regions: 'Регионы',
                password: 'Пароль',
                counterparty: "Контрагент",
                position: "Должность",
                accessResource: 'Ресурс',
                access: 'Доступ',
                access_is_null: 'Нет доступа',
                filterGasRegion: 'Фильтр по Регионам',
                filterGasDistrict: 'Фильтр по Участкам',
                filterGasConsumer: 'Фильтр по Потребителям',
                filterGasMeterPad: 'Фильтр по Площадкам',
                accessCounterparty: 'Доступ по Контрагенту',
                accessGasRegion: 'Доступ по Регионам',
                accessGasDistrict: 'Доступ по Участкам',
                accessGasConsumer: 'Доступ по Потребителям',
                accessGasMeterPad: 'Доступ по Площадкам',
                accessGasMeterUnit: 'Доступ по УУГ',
                q: 'Поиск',
                users_and: 'Пользователи',
                users_not_and: 'Пользователи (НЕ)',
            },
            enums: {
                role: {
                    GUEST: 'Гость',
                    SERVICE: 'Сервис',
                    SUPERADMIN: 'Суперадмин',
                    ADMIN: 'Администратор',
                    ENGINEER: 'Инженер',
                    CONSUMER: 'Потребитель',
                    DISPATCHER: 'Диспетчер'
                },
            },
            tabs: {
                general: 'Основное',
                access: 'Доступ',
            },
            empty: 'Пользователи отсутствуют',
            invite: 'Вы хотите создать?',
        },
        Access: {
            fields: {
                id: 'ID',
                resource: 'Ресурс',
            },
            enums: {
                resource: {
                    GasRegion: 'Регион',
                    GasDistrict: 'Участок',
                    GasConsumer: 'Потребитель',
                    GasMeterPad: 'Площадка',
                    GasMeterUnit: 'УУГ',
                },
            },
        },
        About: {
            name: 'О приложении',
            menu: 'О приложении',
        },
        Main: {
            fields: {
                scope: "Возможности"
            }
        },
        DeviceStatus: {
            fields: {
                id: 'ID',
                dbId: 'ID Шины',
                status: 'Статус',
                date: 'Дата',
                details: 'Детали'
            },
            enums: {
                status: {
                    CRITICAL: 'Критический',
                    ERROR: 'Ошибка',
                    WARNING: 'Внимание',
                    OK: 'ОК',
                    NOTCHECKED: 'Не проверено',
                },
            },
        },
        MetricGasMeter: {
            fields: {
                updatedAt: 'Время последнего приема данных',
            },
        },
    },
}

const menuLanguageRussian = {
    menu: {
        groups: {
            default: "",
            resources: "Ресурсы",
            more: "Дополнительно",
            access: "Доступ",
            directories: "Справочники",
            utils: "Утилиты",
        }
    },
}

const raLanguageRussian = {
    ra: {
        action: {
            ...russianMessages.ra.action,
            show_blank: "Просмотр в новом окне",
            bulk_actions: "%{smart_count} выбрано |||| %{smart_count} выбрано |||| %{smart_count} выбрано",
            create_and_continue: "Coздать и продолжить",
            save_and_continue: "Сохранить и продолжить",
            show_ownership: "Показать владение",
            show_history: "Показать историю",
            show_analitics: "Аналитика",
            delete: "Удалить навсегда",
            restore: "Восстановить",
            check_all: "Выбрать все",
            trash_open: "Корзина",
            more: "Дополнительно",
            import: "Импорт",
            print: "Печать",
            upload: "Загрузка",
            exportexcel: "Excel",
            exportpdf: "PDF",
            exportexcelnovokubansk: "Выгрузка в новокубанскую программу",
            one_of_fields_is_required: "Требуется хотя бы одно из полей: Логин, Телефон или Email",
            exportword: "Word",
            ban: "Заблокировать",
            unban: "Разблокировать",
            disable: "Периоды отключения",
            write_GasQualityCertificate: "Отправить на запись",
            tags_update: "метки",
            write: "Отправить",
            apply_filter: "Применить фильтр",
            despatch_run: "Запустить рассылку",
        },
        boolean: {
            ...russianMessages.ra.boolean,
            null: ' ',
        },
        page: {
            ...russianMessages.ra.page,
            no_data: 'Н\\Д',
            empty: 'Здесь ничего нет',
            invite: 'Вы хотите создать?',
            removed: "Удалено",
        },
        input: {
            ...russianMessages.ra.input,
            confirm: {
                label: "Я подтверждаю правильность импортируемых данных"
            }
        },
        message: {
            ...russianMessages.ra.message,
            no_data: 'Обновите страницу позднее',
            copy: "Копия от %{from}",
            removed: "Ресурс перемещен в Корзину",
            analitics_not_found: "Данных аналитики для этого ресурса не найдено",
        },
        navigation: {
            ...russianMessages.ra.navigation,
            user_apps: "Приложения",
            skip_nav: 'Перейти к содержимому',
            open_documentation: 'Открыть документацию',
        },
        auth: {
            ...russianMessages.ra.auth,
            permissions_error: 'Нет доступа',
            access_error: 'Нет доступа',
            User_is_not_access: 'Пользователь не имеет прав доступа. Обратитесь к администратору',

            role_SERVICE_not_allowed: 'Роль пользователя "Сервис" не подходит',
            ['User with such login does not exist.']: 'Пользователь с таким логином не существует',
            ['User with such login and password does not exist.']: 'Пользователь с таким логином и паролем не существует',
            ['User with such login banned.']: 'Пользователь с таким логином заблокирован. Обратитесь к администратору',
            ['Аuthorization error!']: 'Ошибка авторизации!',
            ['Not authenticated']: 'Ошибка аутентификации!',
            not_authorized_user: 'Пользователь с таким логином и паролем не существует. Осталась последняя попытка |||| Пользователь с таким логином и паролем не существует. Осталось %{smart_count} попытки |||| Пользователь с таким логином и паролем не существует.  Осталось %{smart_count} попыток',
            banned_user: 'Пользователь с таким логином заблокирован. Обратитесь к администратору',
        },
        notification: {
            ...russianMessages.ra.notification,
            profile_saved: 'Профиль сохранен',
            selected_disabled_periods_updated: 'Периоды отключения площадки обновленны |||| Периоды отключения успешно обновлены у %{smart_count} площадок |||| Периоды отключения успешно обновлены у %{smart_count} площадок',
            moved_to_trash: '1 элемент перемещен в корзину |||| %{smart_count} элемента перемещено в корзину |||| %{smart_count} элементов перемещено в корзину',
            restored_from_trash: '1 элемент восстановлен из корзины |||| %{smart_count} элемента восстановлено из корзины |||| %{smart_count} элементов восстановлено из корзины',
            deleted_from_trash: '1 элемент удален из корзины |||| %{smart_count} элементов(а) удалено из корзины |||| %{smart_count} элементов(а) удалено из корзины',
            gas_quality_certificate_imported: 'Паспорт газа импортирован |||| %{smart_count} паспорта газа импортировано |||| %{smart_count} паспортов газа импортировано',
            gas_quality_certificate_written: 'Паспорт газа отправлен на запись |||| %{smart_count} паспорта газа отправлено на запись |||| %{smart_count} паспортов газа отправлено на запись',
            gas_quality_certificate_write_error: 'Паспорт газа не отправлен на запись. Нет УУГ, на которые может быть записан Паспорт газа, или запись Паспорта газа отключена.  |||| Паспорта газа не отправлены на запись. Нет УУГ, на которые могут быть записаны Паспорта газа, или запись Паспорта газа отключена. |||| Паспорта газа не отправлены на запись. Нет УУГ, на которые могут быть записаны Паспорта газа, или запись Паспорта газа отключена.',
            user_banned: 'Пользователь заблокирован',
            user_unbanned: 'Пользователь разблокирован',
            device_wizard_created: 'Устройство создано',
            status_refreshed: 'Статус устройства обновлен',
            despatch_runned: 'Рассылка отчетов запущена |||| %{smart_count} рассылки отчетов запущено |||| %{smart_count} рассылок отчетов запущено',
        },
        validation: {
            ...russianMessages.ra.validation,
            unique: "Значение %{value} уже используется",
            ip_invalid: 'Должен быть в формате 255.255.255.255',
            phone_invalid: 'Должен быть в формате +7 (999) 999-9999',
            password_invalid: 'Пароль должен содержать: \n - минимум 8 латинских символов \n - минимум одну заглавную букву \n - минимум одну строчную букву \n - минимум одну цифру \n - минимум один спецсимвол \n',
        },
    },
}

const reporttemplatesLanguageRussian = {
    reporttemplates: {
        button: {
            close: "Закрыть",
            runExport: "Выгрузить"
        },
        fields: {
            from: "C",
            to: "по",
            districts: 'Участки',
            modeline: 'Построчный',
            modecolumns: 'C датами в столбцах'
        },
        messages: {
            maxintervalall: 'Задан большой интервал времени. При выборе "Все участки" максивальный интервал 3 месяца',
            maxinterval: 'Задан большой интервал времени. Максивальный интервал 1 год',
            query: 'Запрос на выгрузку...',
            filesuccess: 'Файл выгружен'
        },
        helpertext: {
            districts_error: 'Не задан участок'
        }
    }
}

const ru = {
    ...resourcesLanguageRussian,
    ...menuLanguageRussian,
    ...raLanguageRussian,
    ...reporttemplatesLanguageRussian,
    ...raFormLayoutLanguageRussian,
    ...raPreferencesLanguageRussian,
}

export default ru