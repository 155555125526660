import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';


const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,    
        maxWidth: 256,
        position: "relative",
        backgroundColor: "transparent",
        borderRadius: 4,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.action.hover,
        },
    } 
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const TagChip =  forwardRef(({classes: classesOverride, className, resource, record, disabled, ...props}, ref) => {
    const classes = useStyles({classes: classesOverride });
    const translate = useTranslate();
    const title = `${translate(`resources.${resource}.menu`, { _: resource, smart_count: 1 })} ${record.name}`;

    return (
        <Tooltip title={title} placement="top" enterDelay={700}>
            <div>
            <Chip
                className={classes.chip}
                label={record.name}
                // size="small"
                icon={<record.icon />}
                component={LinkRef}
                ref={ref}
                to={`/${resource}/${record.id}/show`}
                clickable
                disabled={disabled}
            />
            </div>
        </Tooltip>
        
    );
});

export default TagChip;