import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, required, ReferenceInput } from 'react-admin';
import AutocompleteInput from '../Inputs/AutoompleteInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import InputText from '../Inputs/ResourceAutocompleteInputText';
import ReportReferenceArrayInputWithDefault from "../Report/ReportReferenceArrayInputWithDefault";
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";
import ReferenceArrayGasDistributorStationOutput from "./ReferenceArrayGasDistributorStationOutput";



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
//     flexReferenceArrayInput: {
//         display: "flex",
//         alignItems: " flex-end",
//     },
// }));

const GasDistributorStationCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'GasDistributorStation', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasDistributorStation', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasDistributorStation', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'GasDistributorStation', 'balanceRegion', 'BalanceRegion') && <ReferenceInput source="balanceRegion.id" reference="BalanceRegion" sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStation.fields.balanceRegion" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText={InputText} suggestionItem={<SuggestionItem />} matchSuggestion={matchTrue} />
                </ReferenceInput>}
                {can('create', 'GasDistributorStation', 'gasDistributorStationOutputs', 'GasDistributorStationOutput') && <ReferenceArrayGasDistributorStationOutput source="gasDistributorStationOutputsIds" reference="GasDistributorStationOutput" filter={{ gasDistributorStation: null }} label="resources.GasDistributorStation.fields.gasDistributorStationOutputs" fullWidth />}
                {can('create', 'GasDistributorStation', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStation.fields.reports" fullWidth />}
                {can('create', 'GasDistributorStation', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasDistributorStation" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorStation.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasDistributorStationCreate;