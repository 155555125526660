import React from 'react';
import { SimpleForm, required, email } from 'react-admin';
import ContactUserReferenceInput from './ContactUserReferenceInput';
import ContactEmailInput from './ContactEmailInput';
import { can } from "../../abilityProvider";



const validateUser = [required()];
const validateEmail = [required(), email()];


export const ContactQuickCreateForm = props => {
    // const classes = useStyles();
    return (
        <SimpleForm {...props}>
            {can('create', 'Contact', 'userId') && <ContactUserReferenceInput source="userId" label="resources.Contact.fields.user" reference="User" validate={validateUser} fullWidth />}
            {can('create', 'Contact', 'email') && <ContactEmailInput source="email" validate={validateEmail} />}
        </SimpleForm>
    )
};