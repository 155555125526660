import React, { Fragment } from 'react';
import { BulkExportButton } from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import BulkUpdateDisabledPeriodsButton from './GasMeterPadBulkUpdateDisabledPeriodsButton';
import BulkUpdateTagsButton from "../Buttons/BulkUpdateTagsButton";
import { can } from "../../abilityProvider";



const GasMeterPadBulkActionButtons = props => (
    <Fragment>
        <BulkUpdateDisabledPeriodsButton {...props} />
        {can('update', 'GasMeterPad', 'tags', 'Tag') && <BulkUpdateTagsButton {...props} />}
        {can('getList', 'GasMeterPad') && <BulkExportButton {...props} />}
        {can('remove', 'GasMeterPad') && <BulkRemoveButton {...props} />}
    </Fragment>
);



export default GasMeterPadBulkActionButtons;