import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BooleanInput, NumberInput, SimpleForm, TextInput, required } from 'react-admin';
import { can } from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    referenceArrayInput: {
        marginTop: 8,
        marginBottom: 4,
    },
}));

export const GasMeterPointQuickCreateForm = props => {
    return (
        <SimpleForm {...props}>
            {can('create', 'GasMeterPoint', 'name') && <TextInput source="name" validate={required()} fullWidth label="resources.GasMeterPoint.fields.name" />}
            {can('create', 'GasMeterPoint', 'description') && <TextInput source="description" fullWidth label="resources.GasMeterPoint.fields.description" />}
            {can('create', 'GasMeterPoint', 'aisId') && <TextInput source="aisId" />}
            {can('create', 'GasMeterPoint', 'number') && <NumberInput source="number" label="resources.GasMeterPoint.fields.number" validate={required()} />}
            {can('create', 'GasMeterPoint', 'disabled') && <BooleanInput source="disabled" label="resources.GasMeterPoint.fields.disabled" />}
        </SimpleForm>
    )
};
