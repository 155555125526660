import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, required, ReferenceArrayInput } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
import SuggestionItem from '../Inputs/ResourceAutocompleteSuggestionItem';
import SelectedItem from '../Inputs/ResourceAutocompleteSelectedItem';
import ReportReferenceArrayInputWithDefault from "../Report/ReportReferenceArrayInputWithDefault";
import TagReferenceArrayInput from '../Tag/TagReferenceArrayInput';
import { can } from "../../abilityProvider";



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
//     flexReferenceArrayInput: {
//         display: "flex",
//         alignItems: " flex-end",
//     },
// }));

const GasDistributorOrganizationCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {can('create', 'GasDistributorOrganization', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {can('create', 'GasDistributorOrganization', 'description') && <TextInput source="description" multiline fullWidth />}
                {can('create', 'GasDistributorOrganization', 'aisId') && <TextInput source="aisId" />}
                {can('create', 'GasDistributorOrganization', 'addrManual') && <TextInput source="addrManual" fullWidth />}
                {can('create', 'GasDistributorOrganization', 'gasMeterPads', 'GasMeterPad') && <ReferenceArrayInput source="gasMeterPadsIds" reference="GasMeterPad" filter={{ gasDistributorOrganization: null }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorOrganization.fields.gasMeterPads" fullWidth>
                    <AutocompleteArrayInput suggestionItem={<SuggestionItem />} selectedItem={<SelectedItem />} matchSuggestion={matchTrue} />
                </ReferenceArrayInput>}
                {can('create', 'GasDistributorOrganization', 'reports', 'Report') && <ReportReferenceArrayInputWithDefault source="reportsIds" reference="Report" filter={{ resource: "GasDistributorOrganization" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorOrganization.fields.reports" fullWidth />}
                {can('create', 'GasDistributorOrganization', 'tags', 'Tag') && <TagReferenceArrayInput source="tagsIds" reference="Tag" filter={{ resource: "GasDistributorOrganization" }} sort={{ field: 'name', order: 'ASC' }} perPage={100} label="resources.GasDistributorOrganization.fields.tags" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasDistributorOrganizationCreate;