import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5c0-1.1.9-2 2-2h14a2 2 0 012 2v4.04a4.95 4.95 0 00-7.56-.52 5.17 5.17 0 00-.3 6.85c-.97.32-1.9.8-2.63 1.43A4.1 4.1 0 009 19.9V21H5a2 2 0 01-2-2V5zm16.12 4.91a2.95 2.95 0 00-4.24 0 3.17 3.17 0 000 4.4 2.95 2.95 0 004.24 0 3.17 3.17 0 000-4.4zm-.76 12.18a9.09 9.09 0 001.64-4.9c1.8.55 3 1.53 3 2.7V23H11v-3.11c0-1.17 1.2-2.15 3-2.7 0 .83.21 2.75 1.64 4.9l.61-3.76-.7-1.46a12.5 12.5 0 012.9 0l-.7 1.46.6 3.76z" />
    </SvgIcon>
  );
}

export default Icon;