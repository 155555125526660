import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRefresh, fetchStart, fetchEnd, Button, useTranslate } from 'react-admin';
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy';
import PublishIcon from '@material-ui/icons/Publish';
import XLSX from 'xlsx';
import moment from 'moment';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient'
import importGasQualityCertificates from '../../dataProvider/Core/importGasQualityCertificates'
import { GET_LIST_GAS_DISTRIBUTOR_STATION } from '../../dataProvider/Core/typeDefs'
import GasQualityCertificateImportDialog from './GasQualityCertificateImportDialog';
import { validate as validateN2 } from './GasQualityCertificateN2Field';
import { validate as validateCO2 } from './GasQualityCertificateCO2Field';
import { validate as validateDensity } from './GasQualityCertificateDensityField';



const GasQualityCertificateImportButton = ({ basePath, className, record, resource, ...props }) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();
    const [open, setOpen] = React.useState(false);
    const [warnings, setWarnings] = useState();
    const [errors, setErrors] = useState()
    const [state, setState] = useState({
        data: undefined,
        error: undefined,
        loading: false,
        loaded: false,
    })

    const checkN2 = useCallback((value, record) => {
        const warning = validateN2(value, record)
        if (warning) setWarnings((prev = []) => [...prev, warning])
    }, [setWarnings])

    const checkCO2 = useCallback((value, record) => {
        const warning = validateCO2(value, record)
        if (warning) setWarnings((prev = []) => [...prev, warning])
    }, [setWarnings])

    const checkDensity = useCallback((value, record) => {
        const error = validateDensity(value, record)
        if (error) setErrors((prev = []) => [...prev, error])
    }, [setErrors])

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen])

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen])

    const handleExited = useCallback(() => {
        setState({
            data: undefined,
            error: null,
            loading: false,
            loaded: false,
        });
        setWarnings(undefined)
        setErrors(undefined)
    }, [setState])

    const handleImport = useCallback(() => {
        if (!state.data) return

        dispatch(fetchStart());

        const gasQualityCertificates = state.data.map(gqs => ({
            gqsId: gqs.gqsId,
            validFrom: gqs.validFrom,
            validTo: gqs.validTo,
            name: gqs.name,
            description: gqs.description,
            n2: gqs.n2,
            co2: gqs.co2,
            density: gqs.density,
            gasExtractionPoint: gqs.gasSelectionPoint_name,
            gasDistributorStationsIds: gqs.gasDistributorStationsIds,
        }))

        importGasQualityCertificates(gasQualityCertificates)
            .then((result) => {
                notify('ra.notification.gas_quality_certificate_imported', 'info', { smart_count: result.data.data.count });
                refresh();
            })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setOpen(false);
                dispatch(fetchEnd());
            });

    }, [state.data]);

    const handleDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {

                setState({
                    loading: true,
                    loaded: false,
                });

                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetPasportPoint = workbook.Sheets["Паспорт-Точка"];
                const sheetPointGrs = workbook.Sheets["Точка-ГРС"];
                const dataPasportPoint = XLSX.utils.sheet_to_json(sheetPasportPoint, { range: 1, raw: false, defval: null });
                const dataPointGrs = XLSX.utils.sheet_to_json(sheetPointGrs, { range: 1, raw: false, defval: null });
                const gasSelectionPointGroup = groupBy(dataPointGrs, 'gasSelectionPoint_aisId');
                const allGasDistributorStations = keyBy(dataPointGrs, 'gasDistributorStation_aisId');
                const allGasDistributorStationsAisIds = Object.keys(allGasDistributorStations)

                apolloCoreClient.query({
                    query: GET_LIST_GAS_DISTRIBUTOR_STATION,
                    variables: {
                        perPage: allGasDistributorStationsAisIds.length,
                        filter: {
                            aisId_in: allGasDistributorStationsAisIds
                        }
                    },
                    fetchPolicy: 'network-only',
                })
                    .then(({ data }) => {

                        const coreGasDistributorStations = data.items

                        const gasQualityCertificates = dataPasportPoint.map((item, index) => {

                            const gasDistributorStationsAisIds = (gasSelectionPointGroup[item.gasSelectionPoint_aisId] || []).map(grs => grs.gasDistributorStation_aisId)
                            const gasDistributorStations = gasDistributorStationsAisIds.map(aisId => {
                                const findGasDistributorStation = coreGasDistributorStations.find(item => item.aisId === aisId)
                                if (findGasDistributorStation) {
                                    return {
                                        id: findGasDistributorStation.id,
                                        aisId: findGasDistributorStation.aisId,
                                        name: findGasDistributorStation.name,
                                    }
                                } else {
                                    const find = allGasDistributorStations[aisId]
                                    return {
                                        id: undefined,
                                        aisId: find.gasDistributorStation_aisId,
                                        name: find.gasDistributorStation_name,
                                    }
                                }
                            })
                            const gasDistributorStationsIds = gasDistributorStations.filter(item => item.id).map(item => item.id)

                            const gasQualityCertificate = {
                                index: index + 1,
                                number: item.num,
                                gqsId: item.gqsId,
                                validFrom: moment(item.validFrom).toISOString(),
                                validTo: moment(item.validTo).toISOString(),
                                name: item.name,
                                description: item.description,
                                n2: parseFloat(item.n2),
                                co2: parseFloat(item.co2),
                                density: parseFloat(item.density),
                                gasSelectionPoint_name: item.gasSelectionPoint_name,
                                gasDistributorStations,
                                gasDistributorStationsIds,
                            }

                            checkN2(gasQualityCertificate.n2, gasQualityCertificate)
                            checkCO2(gasQualityCertificate.co2, gasQualityCertificate)
                            checkDensity(gasQualityCertificate.density, gasQualityCertificate)

                            return gasQualityCertificate
                        })

                        setState({
                            data: gasQualityCertificates,
                            loading: false,
                            loaded: true,
                        });
                    })
                    .catch(error => {
                        setState({
                            error,
                            loading: false,
                            loaded: false,
                        });
                    });
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])


    return (
        <>
            <Button
                onClick={handleOpen}
                label='ra.action.import'
                {...props}
            >
                <PublishIcon />
            </Button>
            <GasQualityCertificateImportDialog
                open={open}
                errors={errors}
                warnings={warnings}
                data={state.data}
                loading={state.loading}
                loaded={state.loaded}
                onImport={handleImport}
                onDrop={handleDrop}
                onClose={handleClose}
                onExited={handleExited}
            />
        </>
    );
};

export default GasQualityCertificateImportButton;

