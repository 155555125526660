import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { SelectInput } from "react-admin";
import { useDataProvider } from 'react-admin';



const EnumInput = ({ name, filter, record, resource, source, translate, useEnum, option, ...props }) => {
    const dataProvider = useDataProvider();
    const [enums, setEnums] = useState([])
    const calcEnumName = name || upperFirst(resource) + upperFirst(source)

    useEffect(() => {
        async function fetchData() {
            dataProvider.getEnum(resource, { name: calcEnumName })
                .then(({ data }) => {
                    setEnums(data);
                })
                .catch(error => {
                    console.log('error: ', error)
                })
        }
        fetchData();
    }, [name])

    const selected = enums.filter(choice => choice.name === get(record, source));

    const choices = (typeof filter === 'function') ? [...new Set([...selected, ...enums.filter(filter)])] : enums

    const getOption = choice => option ? `${option}.${choice.name}` : `resources.${resource}.enums.${source}.${choice.name}`;


    return (
        <SelectInput source={source} resource={resource} choices={choices} optionValue="name" optionText={getOption} {...props} />
    );

}

EnumInput.defaultProps = {
};

EnumInput.propTypes = {
    source: PropTypes.string,
};


export default EnumInput;