import React from 'react';
import { SelectInput } from 'react-admin';



const resources = [
    { id: 'GasRegion', name: 'resources.Tag.enums.resource.GasRegion' },
    { id: 'GasDistrict', name: 'resources.Tag.enums.resource.GasDistrict' },
    { id: 'GasDistributorOrganization', name: 'resources.Tag.enums.resource.GasDistributorOrganization' },
    { id: 'GasDistributorStation', name: 'resources.Tag.enums.resource.GasDistributorStation' },
    { id: 'GasDistributorStationOutput', name: 'resources.Tag.enums.resource.GasDistributorStationOutput' },
    { id: 'GasConsumer', name: 'resources.Tag.enums.resource.GasConsumer' },
    { id: 'GasMeterPad', name: 'resources.Tag.enums.resource.GasMeterPad' },
    { id: 'GasMeterUnit', name: 'resources.Tag.enums.resource.GasMeterUnit' },
    { id: 'GasQualityCertificate', name: 'resources.Tag.enums.resource.GasQualityCertificate' },
];

const TagResourceInput = props => {
    return (<SelectInput {...props} choices={resources} />);
};

export default TagResourceInput