import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import CheckboxBlank from '../Icons/CheckboxBlank';



const useStyles = makeStyles(theme => ({
        root: {},
        selected: { fontWeight: 500 },
        highlight: { 
            fontWeight: 500,
            color: "#000"
        },
        suggestionText: { 
            flex: '1 1 auto',
        },
        suggestionChip: { 
            marginLeft: 8,
            margin: 0,
        },
    }),
    { name: 'RaAutocompleteSuggestionItem' }
);

const Suggestion = ({
    suggestion,
    index,
    highlightedIndex,
    isSelected,
    filterValue,
    classes: classesOverride,
    getSuggestionText,
    ...props
}) => {
    const classes = useStyles({ classes: classesOverride });
    const suggestionText = getSuggestionText(suggestion);
    if (!suggestion.id) return <MenuItem key={suggestion.id} {...props}>{suggestionText}</MenuItem>;
    const isHighlighted = highlightedIndex === index;
    const matches = match(suggestionText, filterValue);
    const parts = parse(suggestionText, matches);
    const color = suggestion.color;

    return (
        <MenuItem
            key={suggestion.id}
            selected={isHighlighted}
            className={classnames(classes.root, {
                [classes.selected]: isSelected,
            })}
            {...props}
        >
            <CheckboxBlank style={{verticalAlign: "bottom", marginRight: 8, color: color}}/>
            <Typography variant="body1" className={classes.suggestionText}>
                {parts.map((part, index) => (<span key={index} className={(part.highlight) ? classes.highlight : ""}>{part.text}</span>))}
            </Typography>
        </MenuItem>
    );
};

export default Suggestion;
