import React from 'react';
import { ReferenceArrayInput } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';



const matchTrue = () => true;

const DashboardReferenceArrayInput = props => {

    return (
        <ReferenceArrayInput {...props}>
            <AutocompleteArrayInput matchSuggestion={matchTrue} />
        </ReferenceArrayInput>
    );
};

export default DashboardReferenceArrayInput;