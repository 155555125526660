import React from 'react';
import PropTypes from 'prop-types';
import { EditContextProvider, useCheckMinimumRequiredProps, useEditController } from 'ra-core';
import { EditView } from './EditView';


export const Edit = (props) => {
    useCheckMinimumRequiredProps('Edit', ['children'], props);
    const controllerProps = useEditController(props);
    return (
        <EditContextProvider value={controllerProps}>
            <EditView {...props} {...controllerProps} />
        </EditContextProvider>
    );
};

Edit.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    hasCreate: PropTypes.bool,
    hasEdit: PropTypes.bool,
    hasShow: PropTypes.bool,
    hasList: PropTypes.bool,
    id: PropTypes.any.isRequired,
    resource: PropTypes.string.isRequired,
    title: PropTypes.node,
    successMessage: PropTypes.string,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    transform: PropTypes.func,
    undoable: PropTypes.bool,
};

export default Edit