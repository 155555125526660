import React from 'react';
import { useDropzone } from 'react-dropzone'
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dropZone: ({isDragAccept, isDragReject}) => ({
        backgroundColor: isDragAccept ? "#c8e6c9" : isDragReject ? "#ffcdd2" : theme.palette.background.default,
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        transition: "background-color .24s ease-in-out",
    }),
}));

const Dropzone = ({ onDrop }) => {
 
    const translate = useTranslate();

    const {getRootProps, getInputProps, isDragAccept, isDragReject} = useDropzone({
        accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        disabled: false,
        multiple: false,
        onDrop,
    })

    const classes = useStyles({isDragAccept, isDragReject});

    return (
        <div className={classes.dropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{translate('ra.input.file.upload_single')}</p>
        </div>
    )
};

export default Dropzone

