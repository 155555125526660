import React, { memo, Fragment } from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import DeviceStatusField from './DeviceStatusField';



const DeviceStatusDatailsField = memo((props) => {
    const { className, resource, source, record = {}, emptyText, ...rest } = props
    const {
        date,
        dbId,
        id,
        status,
        __typename,
        ...other
    } = record

    const metrics = Object.keys(other).filter(key => {
        const metric = other[key]
        return metric.name && metric.status
    }).map(key => {
        const metric = other[key]
        const orderMap = {
            CRITICAL: 4,
            ERROR: 3,
            WARNING: 2,
            OK: 1,
            NOTCHECKED: 0
        }
        return {
            ...metric,
            order: orderMap[metric.status]
        }
    }).sort((a, b) => {
        return b.order - a.order
    })


    return (
        <List>
            {metrics.map((metric, index) => (
                <Fragment key={index}>
                    <Box component="li" display="flex" alignItems="center" py={0.5}  >
                        <Box mr={2}>
                            <DeviceStatusField resource={resource} source="status" record={metric} />
                        </Box>
                        <Typography variant="body2">{metric.name}</Typography>
                    </Box>
                    {metric.flowResults && metric.flowResults.length > 0 && metric.flowResults.map((flowResult, index2) => (
                        <Box ml={4} component="li" display="flex" alignItems="center" py={0.5} key={index2} >
                            <Box marginRight={2}>
                                <DeviceStatusField resource={resource} source="status" record={flowResult} />
                            </Box>
                            <div>
                                <Typography variant="body2" >{`Линия ${flowResult.flow}: ${flowResult.diagnostics}`}</Typography>
                                <Typography component="p" variant="caption" color="textSecondary"> {`Результат: ${flowResult.result} `}</Typography>
                                <Typography component="p" variant="caption" color="textSecondary" > {`Совет: ${flowResult.shortAction} `}</Typography>
                            </div>
                        </Box>
                    ))
                    }
                </Fragment>
            ))}
        </List>
    );
});

DeviceStatusDatailsField.displayName = 'DeviceStatusDatailsField';

DeviceStatusDatailsField.defaultProps = {
    addLabel: true,
};

export default DeviceStatusDatailsField;