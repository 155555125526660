import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    toolbar: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),

    },
    filters: {},
    actions: {},
}),
    { name: 'RaReferenceToolbar' }
);

const ReferenceManyToolbar = ({ classes: classesOverride, filters, actions, setFilter, ...props }) => {

    const classes = useStyles(props);
    return (
        <div className={classes.toolbar}>
            {filters &&
                React.cloneElement(filters, {
                    ...props,
                    setFilter,
                })}
            <span />
            {actions &&
                React.cloneElement(actions, {
                    ...props,
                })}
        </div>
    );
};

ReferenceManyToolbar.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.element,
    actions: PropTypes.element,
};

export default React.memo(ReferenceManyToolbar);
