import omit from 'lodash/omit';
import create from './create';
import getList from './getList';
import getMany from './getMany';
import getManyReference from './getManyReference';
import getOne from './getOne';
import { sanitizeResource } from '../utils';



const Contact = {
    create: (params) => {

        const { data } = params

        return create({
            input: {
                ...data,
            }
        }).then(({ data: { data: { item } } }) => {
            return {
                data: sanitizeResource(item),
            }
        });
    },
    getList: (params) => {
        const { filter, pagination: { page, perPage }, sort: { field, order } } = params;

        return getList({
            filter,
            sort: [{ [field]: order.toLowerCase() }],
            page,
            perPage,
        }).then(({ data: { data: { items, pageInfo } } }) => {
            return {
                data: items.map(sanitizeResource),
                total: pageInfo.totalItems,
            }
        });
    },
    getMany: (params, context) => {
        const { ids } = params;

        return getMany({
            filter: {
                ids
            },
            perPage: ids.length
        }).then(({ data: { data: { items } } }) => {
            return {
                data: items.map(sanitizeResource),
            }
        });
    },
    getManyReference: (params) => {
        const { filter, pagination: { page, perPage }, sort: { field, order } } = params;

        return getManyReference({
            filter,
            sort: [{ [field]: order.toLowerCase() }],
            page,
            perPage,
        }).then(({ data: { data: { items, pageInfo } } }) => {
            return {
                data: items.map(sanitizeResource),
                total: pageInfo.totalItems,
            }
        });
    },
    getOne: (params) => {
        const { id } = params

        return getOne({
            where: params,
        }).then(({ data: { data: { item } } }) => {
            return {
                data: sanitizeResource(item),
            }
        });
    },
};

export default Contact