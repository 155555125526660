import gql from 'graphql-tag'
import apolloClient from '../apolloClient';
import { CONTACT_FRAGMENT } from '../typeDefs';

export const GET_MANY_REFERENCE_CONTACTS = gql`
    query getManyReferenceContacts($filter: ContactFilter, $sort: [ContactSort!] $page: Int, $perPage: Int) {
        data: listContacts(filter: $filter, sort: $sort, page: $page, perPage: $perPage) {
            items {...ContactFragment}
            pageInfo { totalItems }
        }
    }
    ${CONTACT_FRAGMENT}
`

export default (args) => {
    return apolloClient.query({
        query: GET_MANY_REFERENCE_CONTACTS,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    })
};
