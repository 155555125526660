import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleShowLayout } from 'react-admin';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
}));

const CardShowLayout = ({children, classes: classesOverride, className, ...props}) => {    
    const classes = useStyles();
    return (
        <Card className={`${classes.root} ${className}`}>
            <SimpleShowLayout {...props}>
                {children}
            </SimpleShowLayout>
        </Card>
    );
};



export default CardShowLayout;