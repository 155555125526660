import React from 'react';
import keyBy from 'lodash/keyBy'
import { makeStyles } from '@material-ui/core/styles';
import { Datagrid, DateField, NumberField, TextField, ListContextProvider } from 'react-admin';
import NestedArrayField from '../Fields/NestedArrayField'
import ChipField from './GasDistributorStationChipField'
import { dateTimeOptions } from '../../utils';
import N2Field from './GasQualityCertificateN2Field'
import CO2Field from './GasQualityCertificateCO2Field'
import DensityField from './GasQualityCertificateDensityField'



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    paddingCell: {
        '&:first-child': {
            paddingLeft: 24,
        },
        '&:last-child': {
            paddingRight: 24,
        },
    },
    fullWidth: { width: "100%" },
}));

const GasQualityCertificateImportDatagrid = (props) => {

    const classes = useStyles();
    const { data = [] } = props;

    return (
        <ListContextProvider value={{
            resource: "GasQualityCertificate",
            basePath: "/GasQualityCertificate",
            data: keyBy(data, 'gqsId'),
            ids: data.map(({ gqsId }) => gqsId),
            total: data.length,
            currentSort: { field: 'name', order: 'ASC' },
            selectedIds: [],
            classes: { row: classes.row, headerCell: classes.paddingCell, rowCell: classes.paddingCell },
        }}>
            <Datagrid >
                <NumberField source="index" label="resources.GasQualityCertificate.fields.index" cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} sortable={false} />
                <NumberField source="number" label="resources.GasQualityCertificate.fields.number" cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} sortable={false} />
                <TextField source="gqsId" headerClassName={classes.noWrap} sortable={false} />
                <TextField source="name" sortable={false} />
                <N2Field source="n2" label="resources.GasQualityCertificate.fields.n2_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} sortable={false} />
                <CO2Field source="co2" label="resources.GasQualityCertificate.fields.co2_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} sortable={false} />
                <DensityField source="density" label="resources.GasQualityCertificate.fields.density_short" options={{ maximumFractionDigits: 4 }} cellClassName={classes.cellMinWidth} headerClassName={classes.noWrap} sortable={false} />
                <DateField source="validFrom" options={dateTimeOptions} textAlign="right" headerClassName={classes.noWrap} sortable={false} />
                <DateField source="validTo" options={dateTimeOptions} textAlign="right" headerClassName={classes.noWrap} sortable={false} />
                <TextField source="gasSelectionPoint_name" label="resources.GasQualityCertificate.fields.gasExtractionPoint" sortable={false} />
                <NestedArrayField source="gasDistributorStations" reference="GasDistributorStations" linkType={false} >
                    <ChipField source="name" />
                </NestedArrayField>
            </Datagrid>
        </ListContextProvider>
    );
};

export default GasQualityCertificateImportDatagrid;

