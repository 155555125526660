import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities'
import { getAccessToken } from '../../authPrivider/getAccessToken';
import { URI_ANALITICS, WS_ANALITICS } from '../../appsettings';



const httpLink = createHttpLink({
  uri: URI_ANALITICS
})

const authLink = setContext(async (_, { headers }) => {

  const token = await getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const wsLink = new WebSocketLink({
  uri: WS_ANALITICS,
  options: {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: 3,
    connectionParams: async () => {
      const token = await getAccessToken();

      return {
        authorization: token ? `Bearer ${token}` : "",
      }
    },
  }
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink),
)

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});
