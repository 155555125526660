require('dotenv').config();

const global_env = (process.env.NODE_ENV === 'development') ? process.env : window.ENV;

export const TITLE_APP = global_env.REACT_APP_TITLE_APP || 'АСКУГ Показатели'
export const PERMISSIONS_NAMESPACE = 'ASKUG'
export const GROUP_NAME_APP = 'ASKUG'
export const AUTH_TOKEN_COOKIE = `${GROUP_NAME_APP}-AUTH-TOKEN`
export const REFRESH_TOKEN_COOKIE = `${GROUP_NAME_APP}-REFRESH-TOKEN`
export const DOMAIN_COOKIE = global_env.REACT_APP_DOMAIN_COOKIE || "localhost"
export const LOCAL_STORAGE = `${GROUP_NAME_APP}-STORAGE`
export const PACKAGE_NAME = global_env.REACT_APP_NAME || "web-askug-main-portal"
export const PACKAGE_VERSION = global_env.REACT_APP_VERSION || "0.0.1"
export const PACKAGE_VERSION_COOKIE = `${GROUP_NAME_APP}-PACKAGE-VERSION`
export const CURRENT_DATERANGE = `${GROUP_NAME_APP}-CURRENT-DATERANGE`
export const REPORT_DATE_MIN_YEAR = global_env.REACT_APP_REPORT_DATE_MIN_YEAR && parseInt(global_env.REACT_APP_REPORT_DATE_MIN_YEAR) || 2008
export const REPORT_HOURS_MAX_DATERANGE_YEARS = global_env.REACT_APP_REPORT_HOURS_MAX_DATERANGE_YEARS && parseInt(global_env.REACT_APP_REPORT_HOURS_MAX_DATERANGE_YEARS) || 5

// SERVICE URI's
export const URI_CORE = global_env.REACT_APP_URI_CORE || 'http://localhost:5550'
export const WS_CORE = global_env.REACT_APP_WS_CORE || 'ws://localhost:5550'
export const URI_CASL = global_env.REACT_APP_URI_CASL || 'http://localhost:4555'
export const URI_AUTH = global_env.REACT_APP_URI_AUTH || 'http://localhost:5000'
export const URI_DATA = global_env.REACT_APP_URI_DATA || 'http://localhost:5560'
export const URI_DATA_ANALYTICS = global_env.REACT_APP_URI_DATA_ANALYTICS || 'http://localhost:5561'
export const URI_DATABUS = global_env.REACT_APP_URI_DATABUS || 'http://localhost:50052/graphql'
export const WS_DATABUS = global_env.REACT_APP_WS_DATABUS || 'ws://localhost:50052/graphql'
export const URI_PROMETHEUS = global_env.REACT_APP_URI_PROMETHEUS || 'http://localhost:9090'
export const URI_IMPORTEXPORT = global_env.REACT_APP_URI_IMPORTEXPORT || 'http://localhost:5570'
export const URI_SCADAJS = global_env.REACT_APP_URI_SCADAJS || 'http://localhost:5580'
export const URI_ANALITICS = global_env.REACT_APP_URI_ANALITICS || 'http://localhost:2380/graphql'
export const WS_ANALITICS = global_env.REACT_APP_WS_ANALITICS || 'ws://localhost:2380/graphql'
export const URI_REPORT_DESPATCH = global_env.REACT_APP_URI_REPORT_DESPATCH || 'http://localhost:8333'
export const URI_DEVICEGAPS = global_env.REACT_APP_URI_DEVICEGAPS || 'http://10.174.18.254:7778/graphql'