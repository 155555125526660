import React, { useMemo } from 'react';
import get from 'lodash/get'
import { useSelector, shallowEqual } from 'react-redux';
import { useFormState } from "react-final-form";
import { TextInput } from 'react-admin';





const ContactEmailInput = (props) => {

    const { values } = useFormState();
    const users = useSelector(state => state.admin.resources.User.data, shallowEqual);

    const helperText = useMemo(() => {
        const userId = get(values, ["userId"])
        const userEmail = get(users, [userId, "email"])
        const email = get(values, ["email"])

        return (userEmail && (userEmail !== email)) ? "Не совпадает с email пользователя" : null
    }, [users, values]);

    return (
        <TextInput {...props} helperText={helperText} />
    );
}

export default ContactEmailInput;