import Icon from '../Icons/Despatch';
import ReportDespatchList from './ReportDespatchList';
import ReportDespatchCreate from './ReportDespatchCreate';
import ReportDespatchEdit from './ReportDespatchEdit';
import ReportDespatchShow from './ReportDespatchShow';

const ReportDespatchIcon = Icon;


export {
    ReportDespatchIcon,
    ReportDespatchList,
    ReportDespatchShow,
    ReportDespatchCreate,
    ReportDespatchEdit,
};
